.button {
    min-height: 36px;
    margin-left: 16px;
    margin-right: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    text-decoration: none;
    padding: 7px 25px 9px;
    font-size: 16px;
    line-height: 1.125;
    font-weight: 500;
    color: $white;
    background-color: $primary-500;
    background-size: 0 !important;
    position: relative;
    z-index: 1;
    transition: background-color $transition, color $transition, border-color $transition;
    clip-path: polygon(15px 0%, calc(100% - 15px) 0%, 100% 50%, calc(100% - 15px )100%, 15px 100%, 0% 50%);

    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }

    @media(max-width: $phone-v){
		font-size: 15px;
    }

    @include hover{
        & {
            background-color: darken($primary-500, 8%);
        }
    }

    /*
    &:before,
    &:after {
        content: '';
        width: 25px;
        height: 25px;
        position: absolute;
        z-index: -1;
        top: 0px;
        left: 0px;
        transform-origin: top left;
        transform: scale(0.8, 1) rotate(45deg);
        background-color: $primary-500;
        transition: background-color $transition, color $transition, border-color $transition;
    }
    &:after {
        left: auto;
        right: 0px;
        transform-origin: top right;
        transform: scale(0.8, 1) rotate(-45deg);
    }
    @include hover{
        &,
        &:before,
        &:after {
            background-color: darken($primary-500, 8%);
        }
    }

    &--two-lines{
        &:before,
        &:after{
            height: 37px; width: 37px;
            transform: scale(0.6, 1) rotate(45deg);
        }
        &:after{
            transform: scale(0.6, 1) rotate(-45deg);
        }
        @media(max-width: $phone-v){
            &:before,
            &:after{
                height: 35px; width: 35px;
            }
        }
    }
    */


    i {
        margin: 1px 5px 0 0;
        font-size: 20px;
        line-height: 0;
        &:before{
            color: white;
            font-weight: 400;
        }
    }
    &--i-left {
        justify-content: flex-start;
    }
    &--i-right {
        justify-content: space-between;

        @-moz-document url-prefix() {
            display: table;
            margin-left: auto;
            margin-right: auto;
        }

        @supports (animation: calc(0s)) {
            display: inline-flex;
        }

        i {
            margin-left: 5px;
            margin-right: 0;

            @-moz-document url-prefix() {
                vertical-align: middle;
            }
        }
    }
    &--secondary {
        /*,
        &:before,
        &:after {
            background-color: $accent-500;
        }*/
        & {
            background-color: $accent-500;
        }
        @include hover{
            & {
                background-color: darken($accent-500, 8%);
            }
            /*
            &,
            &:before,
            &:after {
                background-color: darken($accent-500, 8%);
            }
            */
        }
    }
    &--accent {
        & {
            background-color: $accent-400;
        }
        /*
        ,
        &:before,
        &:after {
            background-color: $accent-400;
        }*/
        @include hover{
            & {
                background-color: darken($accent-400, 8%);
            }
            /*
            &,
            &:before,
            &:after {
                background-color: darken($accent-400, 8%);
            }
            */
        }
    }
    &--accent-2 {
        & {
            background-color: $accent-300;
        }
        @include hover{
            & {
                background-color: darken($accent-300, 8%);
            }
        }
        /*
        &,
        &:before,
        &:after {
            background-color: $accent-300;
        }
        @include hover{
            &,
            &:before,
            &:after {
                background-color: darken($accent-300, 8%);
            }
        }
        */
    }
    &--primary-2{
        &{
            color: $primary-500;
            background: $primary-400;
        }

        @include hover{
            &{
                background: $primary-400;
            }
        }
        /*
        &,
        &:before,
        &:after{
            color: $primary-500;
            background: $primary-400;
        }

        @include hover{
            &,
            &:before,
            &:after{
                background: $primary-400;
            }
        }
        */
    }
    &--outline {
        min-height: 35px;
        padding-top: 6px;
        padding-bottom: 7px;
        border: 2px solid $primary-500;
        background-color: transparent;
        color: $primary-500;
        clip-path: none;

        @include hover{
            & {
                background-color: transparent;
                border-color: darken($primary-500, 8%);
                color:  darken($primary-500, 8%);
            }
        }
        /*
        &:before,
        &:after {
            background-color: transparent;
            top: -2px;
        }
        &:before {
            border-left: 2px solid $primary-500;
            border-bottom: 2px solid $primary-500;
        }
        &:after {
            border-right: 2px solid $primary-500;
            border-bottom: 2px solid $primary-500;
        }
        */
        /*
        @include hover{
            &,
            &:before,
            &:after {
                background-color: transparent;
                border-color: darken($primary-500, 8%);
                color:  darken($primary-500, 8%);
            }
        }
        i {
            &:before{
                color: $primary-500;
            }
        }
        */
    }
    &--gray{
        background: $gray-060;

        @include hover{
            background: $gray-060
        }
        /*
        @include hover{
            background: $gray-060;
            &:before,
            &:after{
                background: $gray-060;
            }
        }

        &:before,
        &:after{
            background: $gray-060;
        }
        */

    }
    &--white{
        color: $primary-500;
        background: white;

        &:hover{
            color: $primary-500;
            background: white;

            /*
            &:before,
            &:after{
                background: white;
            }
            */
        }

        /*
        &:before,
        &:after{
            background: white;
        }
        */
    }
    &--medium {
        padding-top: 10px;
        padding-bottom: 12px;
        /*
        &:before,
        &:after {
            width: 28px;
            height: 28px;
        }
        &.button--two-lines{
            &:before,
            &:after{
                height: 41px; width: 41px;
                transform: scale(0.6, 1) rotate(45deg);
            }
            &:after{
                transform: scale(0.6, 1) rotate(-45deg);
            }
        }
        */
        &.button--outline {
            padding: 8px 9px 10px;
        }
    }
    &--small{
        min-height: 31px;
        padding-top: 8px;
        font-size: 12px;
        /*
        &:before,
        &:after{
            height: 22px; width: 22px;
        }

        &.button--two-lines{
            &:before,
            &:after{
                height: 31px; width: 31px;
            }
        }
        */
    }
    &--w-100 {
        width: 100%;
    }

    &--mw-180 {
        min-width: 158px;
    }
    &--mw-198 {
        min-width: 198px;
    }
    &--mt{
        margin-top: 16px;
    }
    & + &{
        margin-left: 24px;
    }

    &-location{
        width: 100%;
		min-height: 64px;
        padding: 12px 50px 12px 17px;
        position: relative;
		color: #6f7b8d;
        font: normal 16px/1.2 $Inter;
        text-align: left;
        cursor: pointer;
		background: #ebf1f5;
		border: 0;
		box-shadow: none;
        appearance: none;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
		transition: border-color $transition, background $transition;
        border-radius: 0;
        &[disabled]{
            cursor: default;
            background: #eee;
            &:after{
                opacity: .7;
            }
        }
        &:after{
            content: "\ec05";
            font-family: 'fibank-icons';
            position: absolute;
            top: 50%; right: 19px;
            font-size: 24px;
            color: $primary-500;
            transform: translateY(-50%);
        }
    }

    &[disabled]{
        cursor: default;
        color: rgba($primary-94, .48);
        background: $gray-060;

        /*
        &:before,
        &:after{
            background: $gray-060;
        }
        */

        /*
        [class^="i-"],
        [class*=" i-"]{
            &:before{
                color: rgba($primary-94, .48)!important;
            }
        }
        */
    }

    &-group{
        .button{
            margin-top: 16px;
            margin-left: 24px;
            margin-right: 24px;
        }
    }

    &-wrap{
        position: relative;

        .button{
            position: relative;
            z-index: 2;
        }

        &--expanded{
            @media(max-width: $tablet-v){
                z-index: 3;
            }
            .button{
                @include hover{
                    background: $primary-500;
                    /*
                    &:before,
                    &:after{
                        background: $primary-500;
                    }
                    */
                }
                &--secondary{
                    @include hover{
                        background: $accent-500;
                        /*
                        &:before,
                        &:after{
                            background: $accent-500;
                        }
                        */
                    }
                }

                &-dropdown{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

    }
    &-dropdown{
        opacity: 0;
        visibility: hidden;
        width: 295px;
        position: absolute;
        top: 100%; left: 50%;
        margin-top: -18px;
        padding: 17px 0 15px;
        color: white;
        font-size: 14px;
        line-height: 1.125;
        background-color: darken($primary-500, 8%);
        transform: translateX(-50%);
        transition: $transition;
        z-index: 1;
        white-space: normal;
        @media(max-width: $tablet-v){
            top: auto; bottom: 100%;
            margin: 0 0 -42px;
            padding: 0 0 30px;
        }
        &__list{
            &-item{
                padding: 3px 24px 16px;
                font-size: 16px;
                line-height: 1.125;
                text-align: center;
                border-top: 1px solid rgba(0,0,0,.24);
                &:first-child{
                    border-top-width: 0;
                }
                &:last-child{
                    padding-bottom: 1px;
                }
            }
            @media screen and (max-width: $tablet-v){
                max-height: 340px;
                overflow: auto;
            }
        }
        &__title{
            margin-top: 16px;
            display: inline-block;
            font-size: 20px;
            font-family: $Noto;
            [class^="i-"],
            [class*=" i-"]{
                position: relative;
                top: 4px;
                margin-right: 2px;
                font-size: 24px;
                &:before{
                    color: white;
                }
            }
        }
        &__buttons{
            padding-top: 6px;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;
            a{
                display: inline-block;
                margin: 8px;
                color: rgba(255,255,255,.8);
                font-size: 14px;
                font-weight: 500;
                text-decoration: none;
                @include hover{
                    color: white;
                }
                &.btn{
                    padding: 7px 12px 8px;
                    color: white;
                    font-weight: 400;
                    background: rgba(0,0,0,.12);
                    border-radius: 3px;
                    @include hover{
                        background: rgba(0,0,0,.2);
                    }
                }
            }
        }
    }

    &--secondary{
        ~ .button-dropdown{
            background-color: darken($accent-500, 8%);
        }
    }
}

.text{
    .button{
        @include hover{
            text-decoration: none;
        }
    }
}