.header-overlap{
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    background-color: black;

    @media(max-width: $tablet-v){
        margin-left: -15px;
        margin-right: -15px;
    }

    &__text{
        width: 100%;
        padding: 40px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        color: rgba(255,255,255,.8);
        @include fluid-size(font-size, 280px, 1330px, 18px, 26px);
        font-family: $Noto;
        font-style: italic;
        font-weight: 700;
        line-height: 1.3125;
        z-index: 3;
        @media(max-width: $phone-v){
            padding: 20px;
            br{
                display: none;
            }
        }
    }
    
    &__title{
        margin-bottom: 8px;
        color: white;
        @include fluid-size(font-size, 280px, 1330px, 24px, 45px);
    }

    &__image{
        width: 100%;
        overflow: hidden;

        picture {
            display: block;
            width: 100%;
            float: left;
            position: relative;

            &:before {
                content: '';
                width: 1px;
                margin-left: -1px;
                float: left;
                height: 0;
                padding-bottom: calc(100% / (1084/225));
                @media(max-width: $phone-h) {
                    padding-bottom: calc(100% / (500/288));
                }
            }
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center top;
            font-family: 'object-fit: cover;';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 2;
        }
    }

    a{
        display: block;
        width: 100%;
        color: inherit;
        text-decoration: none;
    }

    /*&--lg{
        min-height: 400px;
        @media(max-width: $phone-h){
            min-height: 280px;
        }
    }*/

    &--center{
        text-align: center;

        .header-overlap{
            &__text{
                color: white;
            }

            &__title{
                @include fluid-size(margin-top, 280px, 1330px, 16px, 21px);
                @include fluid-size(margin-bottom, 280px, 1330px, 21px, 37px);
                line-height: 1;
                @include fluid-size(font-size, 280px, 1330px, 34px, 68px);
                &:only-child{
                    margin: 0;
                    @include fluid-size(line-height, 280px, 1330px, 1.275, 1.4125);
                }
            }

            &__sub-title{
                @include fluid-size(font-size, 280px, 1330px, 22px, 28px);
            }
        }
    }
}