.card-list {
    &--sb {
        @include fluid-size(margin-bottom, 320px, 1330px, 24px, 48px);
    }
    &__item {        
        position: relative;
        background-color: rgba($primary-400, 0.64);
        display: flex;
        align-items: center;    
        margin-bottom: 32px;
        @include hover{
            .card-item__title{
                color: $primary-500;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
        &--alt{
            @media(max-width: $tablet-h){
                flex-wrap: wrap;
            }
        }
        &-preview {
            display: flex;
            align-self: stretch;
            align-items: center;
            position: relative;
            z-index: 1;
            padding: 50px 145px 50px 85px;
            flex-shrink: 0;
            margin-right: -10px;
            &:before {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                bottom: 0;
                width: 50%;
                max-width: 210px;
                background-color: #1890d2;
            }
            &--alt {                
                padding: 24px 55px 24px 30px;
                margin-right: 0;
                @media(max-width: $tablet-h){
                    flex: 0 0 32%;
                }
                .card-slider {
                    left: -15px;
                    padding-left: 0;
                    padding-right: 29px;
                    .slick-dots {
                        left: auto;
                        right: -29px;
                        padding-left: 0;
                        padding-right: 4px;
                    }
                    .slick-arrow {
                        margin-left: auto;
                        margin-right: 82px;
                    }
                }
            }
            &--alt:before {
                background-color: #fff;
                z-index: 1;
                width: 25%;
            }            
        }
        &-text {
            flex-grow: 1;
            padding: 42px 22px 42px 0;
            &-link{
                display: block;
                text-decoration: none;
            }
            
            &--alt{
                @media(max-width: $tablet-h){
                    flex: 0 0 68%;
                }
            }

            .card-features {
                margin-bottom: 24px;

                &--five-features{
                    .card-features__item{
                        @media(max-width: $tablet-h){
                            flex: 0 0 33.3333%;
                        }
                    }
                }
            }
        }
        &-action {            
            align-self: stretch;
            display: flex;
            align-items: center;
            align-content: center;
            flex-wrap: wrap;
            position: relative;
            z-index: 1;
            padding: 0 64px;
            flex-shrink: 0;
            overflow: hidden;
            width: 300px;
            &--alt{
                @media(max-width: $tablet-h){
                    width: 100%;
                    padding-top: 12px;
                    justify-content: center;
                    background: white;
                    &:before,
                    &:after{
                        display: none;
                    }
                }
            }
            &:before,
            &:after {
                content: '';
                position: absolute;
                z-index: -1;             
                top: 0;
                left: 0;
                height: 50%;
                width: 360px;
                background-color: rgba($primary-500, 0.03);
                transform: skew(-20deg);                
                transform-origin: bottom left;
            }
            &:after {
                top: auto;
                bottom: 0;
                transform: skew(20deg);
                transform-origin: top left;
            }
            .button {
                margin: 9px 22px;
            }
        }
        @media(max-width: $tablet-v){            
            flex-wrap: wrap;
            &-preview {
                margin: 0;
                padding: 24px 0 0 0;
                width: 100%;
                flex: 1 0 100%;
                justify-content: center;
                &:before {
                    width: 100%;
                    max-width: inherit;
                    bottom: calc(50% - 12px);
                }
                .card-slider {
                    left: 50%;
                    transform: translate(-50%, -50%) translateX(24px) translateY(12px);
                }
            }
            &-preview--alt {
                padding: 0;
                &:before {
                    width: 100%;
                    bottom: 50%;
                    z-index: -1;
                }
                .card-slider {
                    transform: translate(-50%, -50%) translateX(-24px);
                }
            }
            &-text {
                width: 100%;
                flex: 1 0 100%;
                padding-left: 24px;
                padding-right: 24px;

                .card-features{
                    &--four-features{
                        .card-features__item{
                            flex: 0 0 25%;
                            @media(max-width: $tablet-v){
                                display: flex;
                                flex: 0 0 50%;
                                align-items: center;
                            }
                            @media(max-width: $phone-s){
                                flex: 0 0 100%;
                            }
                            &-icon{
                                @media(max-width: $tablet-v){
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    &--five-features{
                        .card-features__item{
                            @media(max-width: $tablet-v){
                                display: flex;
                                flex: 0 0 50%;
                                align-items: center;
                            }
                            @media(max-width: $phone-s){
                                flex: 0 0 100%;
                            }
                            &-icon{
                                @media(max-width: $tablet-v){
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
            &-action {                
                width: 100%;                
                justify-content: center;
                flex-wrap: wrap;
                padding: 24px;
                background: rgba($primary-500, 0.03);
                .button {
                    margin: 16px 24px;               
                    &:first-child{
                        margin-top: 16px;
                    }
                    &:last-child {
                        margin-bottom: 16px;
                    }
                }
            }
        }
        @media(max-width: $phone-h) {
            &-action {
                padding: 10px;
                .button {
                    margin-top: 8px;
                    margin-bottom: 8px;
                    &:last-child {
                        margin-bottom: 8px;
                    }
                }
            }
            &-preview--alt .card-slider {
                padding-left: 10px;
            }
        }
    }
    /*
    .card-features__item {
        flex-basis: 164px;
    }
    */
    &__hexagon {    
        /*filter: drop-shadow(0 2px 56px rgba(0, 0, 0, 0.12));*/
        path {
            fill: #3da2d9;
            fill-rule: evenodd;        
        }
    }
}
.card-slider {    
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 85px;
    width: 340px;    
    padding-left: 28px;
    transform: translateY(-50%);
    &__item {        
        padding: 15px;
        border: 1px solid transparent;
        &:not(:first-child) {            
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }
        .slick-initialized & {
            position: static;
            opacity: 1;
        }
    }
    
    .card-list__item-preview-img {
        max-width: 238px;
        box-shadow: 0 2px 11px rgba(68, 82, 103, 0.24);
        border-radius: 12px;        
    }
    .card-list__item-preview-icon {
        display: block;
        margin-left: auto;
        margin-right: 34px;
    }
    .slick-dots {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -29px;
        display: flex;
        width: 44px;
        padding-left: 5px;
        flex-direction: column;
        justify-content: center;
        align-items: center;        
        li {
            margin: 0;
        }
        button {
            width: 12px;
            height: 12px;
            border: 0;
            background: transparent;
            font-size: 0;
            display: flex;
            justify-content: center;
            align-items: center;            
            margin-bottom: 1px;
            &:after {
                content: '';
                width: 6px;
                height: 6px;
                background: $white;
                opacity: 0.4;
                border-radius: 50%;                
            }
        }
        li.slick-active{
            button{
                background: transparent;
                &:after {
                    opacity: 1;
                }
            }
        }
    }
    .slick-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0;        
        width: 32px;
        height: 32px;
        background: transparent;
        padding: 0;
        margin-left: 80px;
        &:before {
            content: "\e9a1";
            /*content: "\e916";*/
            font-family: 'fibank-icons';
            font-size: 18px;
            color: $white;                         
        }
        &.slick-prev {
            transform: rotate(-90deg);
            margin-bottom: -5px;
        }
        &.slick-next {
            transform: rotate(90deg);
            margin-top: -5px;
        }
        &.slick-disabled {
            opacity: 0.4;
        }
    }

    &--alt{
        .card-slider{
            &__item{
                &:has(.card-list__item-preview-img--vertical) {
                    text-align: center;
                }

                img{
                    max-width: 270px;

                    &.card-list__item-preview-img--vertical {
                        max-width: 136px;
                        margin-inline: auto;
                        aspect-ratio: 136/216;
                    }
                }
            }
             
        }

        & .slick-slide .card-slider__item img.card-list__item-preview-img--vertical {
            max-width: 106px;  
        } 
    }

    @media(max-width: $phone-h) {        
        padding-right: 10px;        
        .card-list__item-preview-img {            
            max-width: 100%;
        }
        &--alt .slick-slide &__item img.card-list__item-preview-img--vertical {
            max-width: 106px;  
        }  
        .card-list__item-preview-icon { 
            margin-right: 30px;
        }
    }
}