.faq{
    &-list{
        margin: -11px -25px 0;
        padding: 0;
        display: flex;
        flex-flow: row wrap;
    
        @media (max-width: $phone-h){
            margin-left: 0;
            margin-right: 0;
        }
    
        &__item{
            flex: 0 0 50%;
            color: $primary-90;
            font-size: 15px;
            font-weight: 500;
            line-height: 1.5;
    
            @media (max-width: $phone-h){
                flex: 1 0 100%;
            }
    
            &-inner{
                padding: 48px 25px 0;
    
                @media (max-width: $phone-h){
                    padding: 25px 0 0;
                }
            }
    
            &:before,
            &:after{
                display: none;
            }
        }
    
        &__title{
            margin-bottom: 17px;
            color: $dark-gray;
            @include fluid-size(font-size, 320px, 1330px, 20px, 22px);
            font-family: $Noto;
            font-weight: 700;
            font-style: italic;
            line-height: 1.375;
    
            a{
                color: inherit;
                text-decoration: none;
    
                @include hover{
                    color: $primary-500;
                }
            }
        }
    
        p ~ p{
            margin-top: .625em;
        }
    }

    &-results{
        display: none;
        flex: 1 0 100%;
        margin-bottom: 37px;
        align-items: center;
        justify-content: space-between;
        position: relative;

        &:before{
            content: '';
            height: 2px; width: 100%;
            position: absolute;
            top: 50%; left: 0;
            margin-top: 2px;
            background: $primary-94;
            z-index: 1;
        }

        &__letter{
            padding: 0 16px 0 2px;
            font-size: 30px;
            font-family: $Noto;
            font-weight: 700;
            font-style: italic;
            line-height: 1;
            position: relative;
            background: white;
            z-index: 2;
            @media(max-width: $phone-v){
                font-size: 24px;
            }
        }

        &__count{
            padding: 2px 2px 0 16px;
            font-size: 18px;
            font-family: $Noto;
            font-weight: 700;
            font-style: italic;
            line-height: 1;
            position: relative;
            background: white;
            z-index: 2;
        }
    }

    &-container{
        display: flex;
        flex-flow: row;
        @media(max-width: $tablet-v){
            flex-wrap: wrap;
        }
        &--fixed{
            
            .faq{
                &-container{
                    &__aside{
                        backface-visibility: hidden;
                        @media(max-width: $tablet-v){
                            position: fixed;
                            top: 0;
                            left: 0; right: 0;
                            background: $primary-400;
                            transition: $transition;
                            z-index: 20;
                        }
                    }
                }
            }
        }

        .accordion{
            &-list{
                &__sub-title{
                    display: none;
                }
            }
        }

        &--filtered{
            flex-wrap: wrap;
            .faq{
                &-results{
                    display: flex;
                }
                &-container{
                    &__content{
                        padding-left: 0;
                    }
                    &__title,
                    &__sub-title{
                        display: none;
                    }
                }
                &-group{
                    margin-bottom: 0;
                }
                &-navigation{
                    display: none;
                    &--results-navigation{
                        display: block;
                    }
                }
            }
            .accordion{
                &-list{
                    &__item{
                        display: none;
                        &--active{
                            display: block;
                        }
                    }
                    &__sub-title{
                        display: block;
                    }
                }
                &-title{
                    display: none;
                }
            }
        }

        &__content{
            padding-left: 47px;
            padding-right: 40px;
            width: calc(100% - 300px);
            @media(min-width: $desktop){
                padding-right: 80px;
            }
            @media(max-width: $tablet-v){
                order: 1;
                width: 100%;
                padding-right: 0;
                padding-left: 0;
            }
        }
        &__aside{
            flex: 0 0 300px;
            position: relative;
            z-index: 10;
            &--fixed{
                position: fixed;
                top: 0;
            }
            @media(max-width: $tablet-v){
                height: 45px;
                order: 0;
                flex: 1 0 100%;
                margin-bottom: 45px;
                padding: 0;
                background: $primary-400;
            }
        }

        &__title{
            position: relative;
            color: $primary-94;
            @include fluid-size(font-size, 320px, 1330px, 22px, 38px);
            font-family: $Noto;
            font-weight: 700;
            font-style: italic;
            line-height: 1.125;
            margin-bottom: 30px;

            [class^="i-"],
            [class*="i-"]{
                position: absolute;
                top: 50%; right: 100%;
                margin: 2px 18px 0 0;
                color: inherit;
                @include fluid-size(font-size, 320px, 1330px, 22px, 40px);
                transform: translateY(-50%);
            }

            & + .faq-container__sub-title{
                margin-top: -20px;
                &:empty{
                    display: none;
                }
            }
        }
        &__sub-title{
            margin: 10px 0 30px;
            color: rgba($primary-90, .8);
            font-size: 14px;
            font-weight: 500;
            line-height: 1.125;
        }

        &--alt{
            flex-wrap: nowrap;
            @media(max-width: $tablet-v){
                flex-wrap: wrap;
            }
            .faq{
                &-container{
                    &__content{
                        padding-left: 0;
                    }
                    &__aside{
                        padding-top: 0;
                    }
                }
            }
        }

        &--active{
            .faq-navigation{
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                &__button-navigation{
                    &:after{
                        transform: translateY(-40%) rotateZ(180deg);
                    }
                }
            }
        }
    }

    &-navigation{
        position: sticky;
        top: 8px; left: 0;
        @media(max-width: $tablet-v){
            opacity: 0;
            visibility: hidden;
            max-height: calc(100vh - 45px);
            overflow: hidden;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            position: absolute;
            top: 100%;
            left: 0; right: 0;
            padding: 10px 0;
            background: $primary-400;
            border-top: 1px solid white;
            transition: $transition;
            transform: translateY(-45px);
            z-index: 1;
        }

        &__item{
            padding: 18px 0;
            @media(max-width: $tablet-v){
                padding: 7px 15px;
                @media (orientation: landscape){
                    @supports (left: env(safe-area-inset-left)){
                        padding-left: calc(env(safe-area-inset-left) + 15px);
                    }
                }
            }

            &-header{
                display: flex;
                flex-flow: row;
                align-items: center;
            }

            &.expanded{
                .faq-navigation{
                    &__button{
                        &-arrow{
                            &:after{
                                color: $primary-500;
                                transform: translate(-50%, -50%) rotateZ(180deg);
                            }
                        }
                    }
                }
            }

            &.active,
            &.expanded{
                .faq-navigation{
                    &__button{
                        [class^="i-"],
                        [class*=" i-"]{
                            &:before{
                                color: $primary-500;
                            }
                        }
                    }
                }
            }
        }
        &__title{
            margin-bottom: 5px;
            color: $primary-94;
            font-size: 22px;
            font-weight: 700;
            font-style: italic;
            font-family: $Noto;
            @media(max-width: $tablet-v){
                font-size: 16px;
            }
        }

        &--results-navigation{
            display: none;
            .faq{
                &-navigation{
                    &__item{
                        padding: 7px 0;
                        @media(max-width: $tablet-v){
                            padding: 7px 15px;
                            @media (orientation: landscape){
                                @supports (left: env(safe-area-inset-left)){
                                    padding-left: calc(env(safe-area-inset-left) + 15px);
                                }
                            }
                        }
                    }
                }
            }
            .check-radio{
                &__element{
                    color: rgba($dark-gray, .8);
                    font-weight: 500;
                }
            }
        }

        &__button{
            display: block;
            padding-left: 30px;
            position: relative;
            color: $primary-90;
            font-size: 18px;
            font-family: $Noto;
            font-weight: 700;
            font-style: italic;
            line-height: 1.125;
            text-align: left;
            text-decoration: none;
            border: 0;
            background: transparent;
            transition: $transition;
            @include hover{
                color: $primary-500;
                [class^="i-"],
                [class*=" i-"]{
                    &:before{
                        color: $primary-500;
                    }
                }
            }
            @media(max-width: $tablet-v){
                font-size: 16px;
                [class^="i-"],
                [class*=" i-"]{
                    font-size: 16px;
                }
            }
            [class^="i-"],
            [class*=" i-"]{
                position: absolute;
                top: 50%; left: 0;
                margin-top: 1px;
                font-size: 22px;
                transform: translateY(-50%);
            }
            
            &-arrow{
                height: 20px; width: 20px;
                position: relative;
                background: transparent;
                &:after{
                    content: '\e99f';
                    font-family: 'fibank-icons';
                    font-size: 20px;
                    color: $primary-90;
                    position: absolute;
                    top: 50%; left: 50%;
                    margin-top: 2px;
                    transform: translate(-50%, -50%);
                    transition: $transition;
                }
                
            }

            &-navigation{
                display: none;
                height: 45px; width: 100%;
                position: relative;
                padding: 0 15px 1px;
                font-family: $Noto;
                font-size: 16px;
                font-weight: 700;
                font-style: italic;
                line-height: 1.125;
                text-align: left;
                color: $primary-90;
                background: $primary-400;
                z-index: 2;
                @media (orientation: landscape){
                    @supports (left: env(safe-area-inset-left)){
                        padding-left: calc(env(safe-area-inset-left) + 15px);
                    }
                }
                &:after{
                    content: '\e99f';
                    font-family: 'fibank-icons';
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    color: $primary-90;
                    position: absolute;
                    top: 50%; right: 14px;
                    transform: translateY(-50%);
                    transition: $transition;
                }
                @media(max-width: $tablet-v){
                    display: block;
                }
            }

            &.active{
                color: $primary-500;
                [class^="i-"],
                [class*=" i-"]{
                    &:before{
                        color: $primary-500;
                    }
                }
            }
        }

        &__dropdown{
            display: none;
            margin-bottom: -7px;
            padding: 9px 0 0 32px;
            &-item{
                padding: 2px 0;
            }
            &-button{
                display: block;
                padding: 5px 0;
                color: rgba($dark-gray, .64);
                font-size: 15px;
                line-height: 1.125;
                text-decoration: none;
                transition: $transition;
                &.active{
                    color: $primary-500;
                }
            }
        }


        &__item{
            &.active{
                .faq-navigation{
                    &__button{
                        color: $primary-500;
                    }
                }
            }
            &.expanded{
                .faq-navigation{
                    &__button{
                        color: $primary-500;
                        &:after{
                            transform: translateY(-10%) rotateZ(-180deg);
                        }
                    }
                }
            }
        }
    }

    &-group{
        margin-bottom: 84px;
        &:last-child{
            margin-bottom: 0;
        }
        @media(max-width: $tablet-v){
            margin-bottom: 62px;
        }
        @media(max-width: $phone-h){
            margin-bottom: 32px;
        }
    }

    &-categories{
        display: flex;
        flex-flow: row wrap;
        margin: 0 -60px 0 0;
        @media(max-width: $tablet-h){
            margin-right: -20px;
        }
        @media(max-width: $phone-sm){
            margin-right: 0;
        }
        &__item,
        &__item > a{
            display: flex;
            flex-flow: row;
            flex: 0 0 33.3333%;
            align-items: center;
            justify-content: flex-start;
            overflow: hidden;
            padding: 32px 70px 0 0;
            color: rgba(0,0,0,.64);
            font-size: 13px;
            font-weight: 500;
            line-height: 1.275;
            text-decoration: none;
            @media (-ms-high-contrast: active), (-ms-high-contrast: none){
                padding-right: 0;
            }
            @media(max-width: $tablet-h){
                flex: 0 0 50%;
                padding-right: 20px;
            }
            @media(max-width: $phone-sm){
                flex: 0 0 100%;
                padding-right: 0;
            }
            a{
                color: inherit;
                text-decoration: none;
            }
        }

        &__item > a{
            padding: 0;
            flex: 1 0 100%;
            @include hover{
                color: $accent-300;
            }
        }

        [class^="i-"],
        [class*=" i-"]{
            width: 50px;
            font-size: 36px;
            &:before{
                color: $accent-300;
            }
        }
    }
}

.show-fixed-header{
    .faq-container{
        &--fixed{
            .faq{
                &-container{
                    &__aside{
                        top: 95px;
                    }
                }
            }
        }
    }
}

.code-box{
    display: inline-block;
    padding: 12px 25px;
    color: $primary-90;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    background: #f4f4f4;
    border-radius: 4px;
}