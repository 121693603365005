.payments{
    &-list{
        padding: 0 !important;
        display: flex;
        flex-flow: row wrap;
        @include fluid-size(margin-left, 320px, 1330px, -10px, -16px);
        @include fluid-size(margin-right, 320px, 1330px, -10px, -16px);
        &--second{
            .payments{
                &-list{
                    &__item{
                        flex: 1 0 33.3333%;
                        @media(max-width: $tablet-v){
                            flex: 1 0 50%;
                        }
                        @media(max-width: $phone-v){
                            flex: 1 0 100%;
                        }
                    }
                }
            }
        }

        &__item{
            flex: 0 0 33.3333%;
            margin: 0;
            @include fluid-size(padding-top, 320px, 1330px, 10px, 16px);
            @include fluid-size(padding-bottom, 320px, 1330px, 10px, 16px);
            transform-style: preserve-3d;
            &:before,
            &:after{
                display: none;
            }
            @include hover{
                .payments-list{
                    &__title{
                        color: $primary-500;
                    }
                }
            }

            @media (max-width: $tablet-h){
                flex: 0 0 50%;
            }

            @media (max-width: $phone-v){
                flex: 0 0 100%;
                &:first-child{
                    padding-top: 0;
                }
            }

            &-inner{
                display: block;
                height: 100%;
                margin: 0 16px;
                position: relative;
                @include fluid-size(margin-left, 320px, 1330px, 10px, 16px);
                @include fluid-size(margin-right, 320px, 1330px, 10px, 16px);
                @include fluid-size(padding-top, 320px, 1330px, 20px, 32px);
                @include fluid-size(padding-bottom, 320px, 1330px, 82px, 94px);
                padding-left: 24px;
                padding-right: 16px;
                text-decoration: none;
                background: $gray-002;

                @media (max-width: $tablet-h){
                    padding-right: 24px;
                }
                @media(max-width: $phone-v){
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }

            ul{
                list-style: none;
                margin-top: 30px;
                padding-left: 5px;
                color: black;
                font-size: 14px;
                font-weight: 500;
                @media(max-width: $phone-h){
                    padding-left: 0;
                }
                @media(max-width: $phone-s){
                    font-size: 13px;
                }
            
                li{
                    position: relative;
                    margin: 16px 0;
                    padding-left: 20px;
            
                    &:before{
                        content: '\ea3e';
                        font-family: 'fibank-icons';
                        position: absolute;
                        top: -6px; left: -4px;
                        font-size: 22px;
                    }
                }
            }
        }

        &__flex{
            display: flex;
            flex-flow: row;
        }

        &__text{
            flex-grow: 1;
        }

        &__title{
            margin-bottom: 16px;
            color: $primary-94;
            @include fluid-size(font-size, 320px, 1330px, 22px, 30px);
            font-weight: 700;
            font-family: $Noto;
            font-style: italic;
            @include fluid-size(line-height, 320px, 1330px, 1.125, 1.275);
            transition: color $transition;

            a{
                color: inherit;
                text-decoration: none;
            }
        }

        &__text{
            color: rgba($primary-94, .54);
            font-size: 14px;
            font-weight: 500;
            line-height: 1.571428;
        }

        &__bottom{
            position: absolute;
            left: 24px; bottom: 32px;
            @media(max-width: $phone-v){
                left: 20px; bottom: 28px;
            }
        }

        &--two-items{
            .payments-list{
                &__item{
                    flex: 0 0 50%;
                    @media (max-width: $phone-v){
                        flex: 0 0 100%;
                    }
                }
            }
        }

        &--full-width{
            .payments-list__item{
                flex: 1 0 100%;
            }
        }

        &--alt{
            .payments{
                &-list{
                    &__item{
                        flex: 0 0 50%;
                        @media (max-width: $tablet-h){
                            flex: 0 0 100%;
                        }
                        &-inner{
                            margin-right: 64px;
                            padding-right: 200px;
                            @media(max-width: $phone-v){
                                padding-right: 20px;
                                @include fluid-size(margin-right, 320px, 1330px, 10px, 16px);
                            }
                        }
                    }
                    &__title{
                        @include fluid-size(font-size, 320px, 1330px, 22px, 36px);
                    }
                    &__image{
                        max-width: 220px;
                        position: absolute;
                        top: 50%; right: -63px;
                        transform: translateY(-50%);
                        @media(max-width: $phone-v){
                            display: none;
                        }
                        img{
                            max-width: 100%;
                        }
                    }
                }
            }
        }

    }
}

.text{
    .payments-list{
        &__item{
            margin: 0 !important;
        }
        p{
            margin: 0 !important;
            color: rgba($primary-94, .54) !important;
            font-size: 14px !important;
            font-weight: 500 !important;
            line-height: 1.571428 !important;
        }
    }
}