.presentation{
    &-carousel{
        min-height: 350px;
        opacity: 0;
        visibility: hidden;
        transition: opacity $transition, visibility $transition;
        transition-delay: 500ms;
        &.slick-initialized{
            opacity: 1;
            visibility: visible;
            + .presentation-carousel__loader{
                opacity: 0;
                visibility: hidden;
            }
        }

        @media(max-width: $tablet-v){
            width: auto;
            margin-left: -15px;
            margin-right: -16px;
            @media (orientation: landscape){
                @supports (left: env(safe-area-inset-left)){
                    margin-left: calc(env(safe-area-inset-left) - env(safe-area-inset-left) - env(safe-area-inset-left) - 16px);
                }
            }
        }

        &__item{
            display: flex !important;
            height: 100%;
            position: relative;
            border-right: 1px solid white;
            background-color: black;
            @media(max-width: 560px){
                border-right: 0;
            }
        }

        &__figure{
            height: 100%;
            width: 100%;
            max-height: 470px;
            overflow: hidden;
            background-color: black;
        }

        &__figcaption{
            display: flex;
            flex-flow: column;
            justify-content: flex-end;
            position: absolute;
            top: 0; bottom: 0;
            left: 0; right: 0;
            padding: 40px 20px 35px 40px;
            color: white;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.4;
            background: linear-gradient(to bottom, rgba(0,0,0,.08) 0%,rgba(0,0,0,.4) 100%);

            @media(max-width: $tablet-v){
                padding: 20px 20px 30px;
            }

            ul{
                list-style: none;
                padding-left: 5px;
                @media(max-width: $phone-h){
                    padding-left: 0;
                }
                @media(max-width: $phone-s){
                    font-size: 13px;
                }
            
                li,
                &__item{
                    position: relative;
                    margin: 16px 0;
                    padding-left: 20px;
                    &:first-child{
                        margin-top: 0;
                    }
            
                    &:before{
                        content: '\ea3e';
                        font-family: 'fibank-icons';
                        position: absolute;
                        top: -6px; left: -4px;
                        font-size: 22px;
                    }
                }
            }
        }

        &__title{
            margin-bottom: 16px;
            @include fluid-size(font-size, 320px, 1330px, 24px, 28px);
            font-family: $Noto;
            font-weight: 700;
            font-style: italic;
            a{
                color: white;
                text-decoration: none;
                @include hover{
                    opacity: .85;
                }
            }
        }

        &__image{
            height: 100%;
            width: 100%;
            height: calc(100% + 80px);
            width: calc(100% + 80px) !important;
            max-height: 550px;
            max-width: inherit;
            object-fit: cover;
            position: relative;
            top: -40px; bottom: -40px;
            left: -40px; right: -40px;
            background-color: black;
        }

        .slick-track{
            display: flex;
        }
    
        .slick-slide{
            display: flex;
            height: auto;
            align-items: center;
    
            > div{
                display: flex;
                height: 100%;
                align-items: center;
            }

            &.slick-current{
                .presentation{
                    &-carousel{
                        &__figcaption{
                            /*
                            @media (orientation: landscape){
                                @supports (left: env(safe-area-inset-left)){
                                   padding-left: env(safe-area-inset-left);
                                }
                            }
                            */
                        }
                    }
                }
            }
        }

        .check-list{
            li{
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }

        &__holder{
            position: relative;
        }

        &__loader{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: $transition;
            transition-delay: 500ms;
            z-index: 1;
        }
    }

    
}