.cards-list{
    display: flex;
    flex-flow: row nowrap;
    margin-top: 56px;

    &__wrap{
        margin-bottom: 78px;

        &:last-of-type{
            margin-bottom: 0;
        }
    }

    &__holder{
        margin: -56px 0 0;
        padding-bottom: 16px;
        position: relative;
        overflow: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    &__bottom{
        margin-top: 16px;
        text-align: center;
    }
}

.cards-table{
    margin-top: 68px;
    min-width: 100%;
    color: $primary-94;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.125;
    table-layout: fixed;
    border-spacing: 0px;

    &__th{
        width: 300px;
        padding: 25px;
        text-align: left;
        border-left: 8px solid white;
        border-right: 8px solid white;
        background: rgba($primary-400, .64);
        vertical-align: top;
        &:first-child{
            border-left-width: 0;
        }
        &:last-child{
            border-right-width: 0;
        }
        &:hover{
            .cards-table{
                &__image{
                    transform: translateY(-10px);
                }
                &__title{
                    color: $primary-500;
                }
            }
        }

        a{
            display: inline-block;
        }
    }

    &__td{
        padding: 16px 25px;
        position: relative;
        text-align: left;
        border-left: 8px solid white;
        border-right: 8px solid white;
        background: rgba($primary-400, .64);
        
        &:before{
            content: '';
            height: 1px;
            position: absolute;
            top: 0;
            left: 25px; right: 25px;
            background: rgba($gray-060, .32);
        }

        &:empty{
            &:before{
                display: none;
            }
        }

        &:first-child{
            border-left-width: 0;
        }
        &:last-child{
            border-right-width: 0;
        }

        p{
            display: inline-flex;
            flex-flow: row;
            align-items: center;
        }

        a{
            margin-left: 4px;
            color: inherit;
        }
        
        [class^="i-"]:not(.external-icon),
        [class*=" i-"]:not(.external-icon){
            min-width: 20px;
            margin-right: 6px;
            position: relative;
            top: 1px;
            font-size: 24px;
            color: $accent-400;
        }

        .button{
            [class^="i-"],
            [class*=" i-"]{
                font-size: 20px;
                top: 0;
                margin-right: 0;
            }
        }
    }

    &__image{
        max-width: 216px;
        margin: -68px 0 21px;
        border-radius: 12px;
        box-shadow: 0 2px 11px rgba(68,82,103,.24);
        transition: $transition;
    }

    &__image--vertical {
        margin-top: -139px;
        max-width: 136px;
        aspect-ratio: 136/216;
    }

    &:has(.cards-table__image--vertical) {
        margin-top: 139px;

        @media screen and (max-width: $tablet-h) {
            margin-top: 155px;
        }
    }

    &__th:has(.cards-table__image--vertical) > p {
        // transform: rotate(90deg);

    }

    &__title{
        color: $dark-gray;
        font-family: $Noto;
        font-size: 24px;
        font-weight: 700;
        font-style: italic;
        line-height: 1.125;

        a{
            color: inherit;
            text-decoration: none;

            &:hover{
                color: $primary-500;
            }
        }

        .icon,
        [class^="i-"],
        [class*=" i-"]{
            margin: -2px 0 0 3px;
            font-size: 16px;
            vertical-align: baseline;

            &:before{
                transition: none;
            }
        }
        .i-PayPass-PayWave-not-support{
            color: $accent-500;
        }
    }

    tbody{
        tr:nth-child(5){
            ~ tr{
                display: none;
            }
        }
    }

    tfoot{
        .cards-table{
            &__td{
                padding-top: 0;
                padding-bottom: 40px;
                text-align: center;
                vertical-align: bottom;
                &:before{
                    display: none;
                }
                &:after{
                    content: '';
                    height: 200px;
                    position: absolute;
                    bottom: 0;
                    left: 0; right: 0;
                    background-image: linear-gradient(0, transparent 0%, #eff2f9 100%);
                }

                .button{
                    min-width: 155px;
                    margin: 18px 16px 0;
                    color: white;
                    text-decoration: none;
                    &--outline{
                        color: $primary-500;
                    }
                }
            }
        }
    }
}

_:-ms-input-placeholder, :root{
    .cards-table{
        &__td{
            p{
                display: block;
            }
        }
    }

    tfoot{
        .cards-table{
            &__td{
                &:after{
                    height: 300px;
                    background: url('../images/table-gradient.png') repeat-x 0 0;
                }
            }
        }
    }
}

.cards-list--expanded{
    .cards-table{
        tbody{
            tr:nth-child(5){
                ~ tr{
                    display: table-row;
                }
            }
        }
        tfoot{
            .cards-table{
                &__td{
                    &:after{
                        display: none;
                    }
                }
            }
        }
    }

    .js-expand__cards-list{
        display: none;
    }
}

.site-block:first-of-type{
    .cards-list__wrap{
        margin-top: 78px;
    }
}

@media screen and (max-width: $tablet-h){
    .cards-table{
        &__title{
            font-size: 20px;
        }
    }
}