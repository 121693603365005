.info-box{
    display: flex;
    flex-flow: row;
    margin-top: 30px;
    @include fluid-size(padding, 320px, 1330px, 20px, 40px);
    background: $gray-002;
    @media(max-width: $phone-h){
        flex-flow: column;
    }

    &__text{
        flex-grow: 1;
        @include fluid-size(padding-right, 320px, 1330px, 20px, 40px);
        color: rgba($primary-94, .54);
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        &:only-child{
            padding-right: 0;
        }
    }
    &__title{
        margin-bottom: 25px;
        color: $primary-94;
        @include fluid-size(font-size, 320px, 1330px, 24px, 30px);
        font-family: $Noto;
        font-weight: 700;
        font-style: italic;
    }
    &__buttons{
        max-width: 227px;
        @media(max-width: $phone-h){
            margin-top: 24px;
        }
        .button{
            width: 100%;
            width: calc(100% - 32px);
            white-space: nowrap;
            & ~ .button{
                margin-top: 14px;
            }
        }
    }
}

.border-box{
    @include fluid-size(padding, 280px, 1330px, 15px, 32px);
    border: 1px solid $primary-400;
    .text{
        margin-bottom: 0;
    }
}