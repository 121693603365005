.quotes-slider{
	max-width: 765px;
	margin: 0 auto;
	padding: 37px 40px;
	position: relative;
	text-align: center;
	@media(max-width: $phone-sm){
		padding-left: 20px;
		padding-right: 20px;
	}

	&__quote{
		color: $primary-94;
		@include fluid-size(font-size, 280px, 1330px, 18px, 26px);
		font-family: $Noto;
		line-height: 1.3275;
		&-by{
			@include fluid-size(margin-top, 280px, 1330px, 12px, 20px);
			color: rgba($primary-94, .54);
			font-family: $Noto;
			line-height: 1.125;
		}
	}


	.slick{
		&-arrow{
			width: 30px;
			position: absolute;
			top: 0; bottom: 0;
			left: 0;
			text-indent: -10000px;
			background: transparent;
			transition: opacity $transition;
			@media(max-width: $phone-sm){
				width: 20px;
			}
			@include hover{
				opacity: .8;
			}
			&:before{
				content: '\e904';
				font-family: 'fibank-icons';
				font-size: 18px;
				font-weight: 700;
				text-indent: 0;
				color: $primary-500;
				position: absolute;
				top: 50%; left: 0;
				transform: translateY(-50%);
				@include fluid-size(margin-top, 280px, 1330px, -12px, -20px);
			}
			&.slick-next{
				left: auto; right: 0;
				&:before{
					content: '\e912';
					left: auto; right: 0;
				}
			}
		}
		&-dots{
			position: absolute;
			top: 0;
			left: 0; right: 0;
			li{
				button{
					height: 8px; width: 8px;
					background: #cbcbd3;
					border: 0;
				}
				&.slick-active{
					button{
						background: $primary-500;
					}
				}
			}
		}
	}
}