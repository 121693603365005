.credit-list{
    display: flex;
    flex-flow: row wrap;
    padding: 0 !important;
    text-align: center;
    line-height: 1.571428571428571;
    &--alt{
        text-align: left;
        .credit-list{
            &__item{
                flex: 0 0 100%;
            }
            &__header{
                [class^="i-"],
                [class*=" i-"]{
                    left: 0;
                    transform: translateX(0);
                }
            }
        }
        ul{
            align-items: flex-start !important;
        }
    }
    &__item{
        flex: 0 0 50%;
        margin: 0 !important;
        @media(max-width: $phone-v){
            flex: 0 0 100%;
        }
        &:before{
            display: none;
        }
    }
    &__header{
        margin-bottom: 12px;
        padding-top: 77px;
        position: relative;
        [class^="i-"],
        [class*=" i-"]{
            font-size: 80px;
            color: rgba(0,0,0,.05);
            position: absolute;
            bottom: 0; left: 50%;
            transform: translateX(-50%);
            z-index: 1;
        }
    }
    &__title{
        position: relative;
        color: $primary-94;
        @include fluid-size(font-size, 320px, 1330px, 24px, 30px);
        font-family: $Noto;
        font-weight: 700;
        font-style: italic;
        line-height: 1;
        text-decoration: none;
        background-size: 0 1px!important;
        z-index: 2;
        
    }
    ul{
        display: flex;
        flex-flow: column;
        align-items: center;
        margin: 0 !important;
        padding: 0 0 0 16px !important;
        li{
            font-size: 16px !important;
            position: relative;
            margin: 6px 0 !important;
            line-height: 1.571428571428571;
            &:before {
                content: '';
                width: 6px !important;
                height: 6px !important;
                background-color: $primary-500 !important;
                transform: rotate(45deg);
                position: absolute;
                top: 9px !important;
                left: -16px !important;
                @media(max-width: $phone-h) {
                    top: 7px !important;
                }
            }
            a{
                color: $dark-gray;
                text-decoration: none;
                &:hover{
                    color: $primary-500;
                    background-size: 0 1px;
                }
            }
        }
    }
}