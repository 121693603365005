.banner-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $primary-94;
    background: #99daff;
    @media(max-width: $tablet-v){
        margin-left: -15px;
        margin-right: -15px;
    }
    @media(max-width: $phone-v){
        flex-flow: column;
    }

    &__link{
        color: inherit;
        text-decoration: none;
    }

    &__text{
        flex: 1 0 50%;
        @include fluid-size(padding-top, 320px, 1330px, 16px, 53px);
        @include fluid-size(padding-bottom, 320px, 1330px, 16px, 53px);
        @include fluid-size(padding-left, 320px, 1330px, 16px, 33px);
        @include fluid-size(padding-right, 320px, 1330px, 16px, 33px);
    }

    &__title{
        @include fluid-size(font-size, 320px, 1330px, 18px, 26px);
        font-family: $Noto;
        font-weight: 700;
        font-style: italic;
        line-height: 1.375;
    }

    &__image{
        align-self: flex-end;
        position: relative;
    }


    &--vertical{
        display: block;
        @media(max-width: $tablet-v){
            margin-left: 0;
            margin-right: 0;
        }

        .banner-item__text{
            @include fluid-size(padding, 320px, 1330px, 16px, 24px);
        }
    }

    a{
        color: inherit;
    }
    [class^="i-"],
    [class*=" i-"]{
        font-size: 24px;
    }
}

.banner-focus{
    min-height: 326px;
    display: flex;
    align-items: center;
    position: relative;

    &__text{
        width: 100%;
        padding: 20px;
        color: rgba(255,255,255,.8);
        font-size: 18px;
        font-weight: 700;
        font-style: italic;
        font-family: $Noto;
        text-align: center;
        position: relative;
        z-index: 2;
        @media(max-width: $phone-v){
            padding-left: 20px;
            padding-right: 20px;
        }

        p + p{
            margin-top: .625em;
        }

        .button{
            margin-top: 20px;
            font-style: normal;
            font-family: $Inter;
            transition: $transition;
            @include hover{
                opacity: .8;
            }
        }
    }

    &__title{
        margin: 13px auto;
        color: white;
        font-size: 35px;
        @media(max-width: $phone-v){
            font-size: 24px;
        }
    }

    &__image{
        height: 100%; width: 100%;
        position: absolute !important;
        top: 0; left: 0;
        z-index: 1;
        img{
            height: 100%; width: 100%;
            object-fit: cover;
        }
    }
}

.aside-offer{
    min-height: 135px;
    overflow: hidden;
    position: fixed;
    top: 50%; right: 0;
    transform: translateY(-50%) translateX(100%);
    margin-right: 30px;
    transition: $transition;
    backface-visibility: hidden;
    z-index: 101;

    &--expanded{
        transform: translateY(-50%) translateX(0);
        margin-right: 0;

        .aside-offer__expand{
            opacity: 0;
            visibility: hidden;
        }
    }

    &__image{
        display: block;
    }

    &__expand{
        height: 100%; width: 30px;
        position: absolute;
        top: 0; left: 0;
        transition: $transition;
        border: 0;
        background: transparent;

        &:before{
            content: '\e9a0';
            position: absolute;
            top: 50%; left: 50%;
            font-family: 'fibank-icons';
            color: white;
            font-size: 24px;
            line-height: 1;
            transform: translate(-50%, -50%);
        }
    }

    &__close{
        height: 20px; width: 20px;
        position: absolute;
        top: 9px; right: 16px;
        border: 0;
        background: white;
        box-shadow: 0 2px 5px rgba(0,0,0,.06);
        border-radius: 100%;
        &:after{
            content: '';
            position: absolute;
            top: -9px; bottom: -9px;
            left: -9px; right: -9px;
            background: rgba(255,255,255,0);
        }

        &:before{
            content: "\ec3c";
            position: absolute;
            top: 5px; left: 5px;
            font-family: 'fibank-icons';
            color: black;
            font-size: 11px;
            line-height: 1;
        }
    }
}

.mini-banner{
    max-width: 240px;
    position: fixed;
    bottom: 0; left: 0;
    cursor: pointer;
    background: white;
    transition: 500ms ease;
    backface-visibility: hidden;
    z-index: 1001;
    @media(max-width: $phone-v){
        display: none;
    }

    &--alt{
        .mini-banner{
            &__text{
                right: 31px;
                max-width: inherit;
            }
        }
    }

    &--active{
        max-width: 694px;
        .mini-banner{
            &__link{
                opacity: 1;
                visibility: visible;
            }
            &__text{
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition-delay: 550ms;
            }
            &__button{
                &:before{
                    content: '\e9bd';
                    font-size: 22px;
                }
            }
        }
    }
    &--offset{
        transform: translateX(-110%);
    }

    &__link{
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 0; bottom: 0;
        left: 0; right: 0;
        text-indent: -10000px;
        z-index: 2;
        background: rgba(255,255,255,0);
    }

    &__text{
        opacity: 0;
        visibility: hidden;
        max-width: 330px;
        position: absolute;
        top: 120px; left: 31px;
        color: white;
        font-size: 24px;
        font-family: $Noto;
        font-weight: 700;
        font-style: italic;
        line-height: 1.4583;
        transition: 350ms ease;
        transform: translateY(50px);
    }

    &__button{
        height: 40px; width: 40px;
        position: absolute;
        top: 0; right: 0;
        text-indent: -10000px;
        border: 0;
        background: transparent;
        z-index: 3;
        &:before{
            content: "\ec3c";
            color: rgba($primary-94, .8);
            font-size: 20px;
            font-family: 'fibank-icons';
            text-indent: 0;
            position: absolute;
            top: 50%; left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.banner-payment{
    display: flex;
    flex-flow: row;
    background: #24529c;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    &__text{
        padding: 40px 24px 40px 48px;
        flex: 1 0 50%;
        align-self: center;
        color: rgba(255,255,255,.48);
        @media(max-width: $phone-h){
            padding: 24px;
        }

        small{
            color: inherit;
        }
    }

    &__image{
        flex-basis: 427px;
        align-self: flex-end;
        @media(max-width: $tablet-v){
            flex-basis: 250px;
        }
        @media(max-width: $phone-h){
            display: none;
        }
    }

    &__title{
        margin-bottom: 40px;
        color: white;
        @include fluid-size(font-size, 320px, 1330px, 26px, 42px);
        font-family: $Noto;
        font-style: italic;
        font-weight: 700;
        line-height: 1.3;
        @media(max-width: $tablet-h){
            line-height: 1.125;
        }
        @media(max-width: $phone-h){
            margin-bottom: 20px;
        }
    }

    &__buttons{
        display: flex;
        flex-flow: row wrap;
        margin: 23px 0;

        a{
            margin: 8px 23px 0 0;
            @media(max-width: $phone-v){
                margin-right: 12px;
            }
            &:last-child{
                margin-right: 0;
            }
            img{
                max-width: 140px;
                @media(max-width: $phone-v){
                    max-width: 130px;
                }
                @media(max-width: $phone-sm){
                    max-width: 100px;
                }
            }
        }
    }
}