.tags{
	margin: -8px 0 10px;
	font-size: 0;

	.tag{
		display: inline-block;
		margin: 8px 8px 0 0;
		padding: 3px 6px 4px;
		color: white;
		font-size: 14px;
		font-weight: 500;
		line-height: 1.125;
		font-style: normal;
		font-family: $Inter;
		vertical-align: top;

		&.primary-200{ background: $primary-200; }
		&.primary-500{ background: $primary-500; }
		&.primary-700{ background: $primary-700; }
		&.primary-800{ background: $primary-800; }

		&.accent-300{ background: $accent-300; }
		&.accent-400{ background: $accent-400; }
		&.accent-500{ background: $accent-500; }
		&.accent-600{ background: $accent-600; }

		&:last-child{
			margin-right: 0;
		}
	}

	&--small{
		.tag{
			font-size: 12px;
		}
	}

	&--small-spaced{
		.tag{
			margin-right: 1px;
		}
	}
}