.header{
    width: 100%;
    position: relative;
    z-index: 8000;
    transition: height $transition;
    @media(max-width: $tablet-v){
        background: $primary-500;
    }

    &-top{
        background: #dae2ef;
        @media(max-width: $tablet-v){
            display: none;
        }
        &__wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            color: $primary-94;
            line-height: 1.125;
            &-item {
                transition: transform ease 0.25s;
            }
        }

        &__dropdown{
            min-width: 227px;
            max-width: 227px;
            position: relative;
            user-select: none;
            z-index: 3;

            &-header{
                position: relative;
                cursor: pointer;
                transition: opacity $transition;

                > div{
                    display: inline-block;
                    max-width: 100%;
                    padding: 12px 16px;
                    position: relative;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    vertical-align: top;
                    &:after{
                        content: '';
                        height: 0; width: 0;
                        position: absolute;
                        top: 50%; right: 0;
                        margin-top: -2px;
                        border-style: solid;
                        border-width: 5px 4px;
                        border-color: rgba(black, .4) transparent transparent;
                    }
                }
            }

            &-body{
                opacity: 0;
                visibility: hidden;
                width: 100%;
                position: absolute;
                top: 100%; left: 0;
                padding: 2px 16px;
                background: #f4f4f4;
                transform: translateY(10px);
                transition: $transition;
            }

            &--active{
                .header-top__dropdown{
                    &-header{
                        > div{
                            &:after{
                                margin-top: -6px;
                                border-color: transparent transparent rgba($primary-500, .64);
                            }
                        }
                    }
                    &-body{
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0);
                    }
                }
            }

            &-list{
                &__item{
                    & + &{
                        border-top: 1px solid rgba($primary-800, .12);
                    }
                    a{
                        display: block;
                        padding: 11px 0 12px;
                        color: $primary-94;
                        text-decoration: none;

                        &:hover{
                            color: $primary-500;
                        }

                        &.active{
                            color: rgba($primary-94, .48);
                        }
                    }
                }
            }

            &--alt{
                .header-top__dropdown{
                    &-header{
                        > div{
                            padding: 11px 22px 13px 36px;
                            background: rgba($primary-500, .08);
                            &:before,
                            &:after{
                                content: '\e9e0';
                                height: auto; width: auto;
                                font-family: 'fibank-icons';
                                position: absolute;
                                top: 50%;
                                left: 10px; right: auto;
                                margin: 0;
                                color: #3963a8;
                                font-size: 21px;
                                border: 0;
                                transform: translateY(-50%);
                                transition: opacity $transition;
                                backface-visibility: hidden;
                            }
                            &:after{
                                content: '\ec3c';
                                opacity: 0;
                                margin-top: -1px;
                            }
                        }
                    }
                }
                &.header-top__dropdown--active{
                    .header-top__dropdown{
                        &-header{
                            > div{
                                &:before{
                                    opacity: 0;
                                }
                                &:after{
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }

        &__list{
            height: 40px;
            display: flex;
            flex-flow: row;
            font-size: 0;
            &-item{
                margin-right: 25px;
                font-size: 14px;
                &--less-mr{
                    margin-right: 14px;
                }
                &:last-child{
                    margin-right: 0;
                }

                a{
                    height: 100%;
                    display: flex;
                    align-items: center;
                    color: $primary-94;
                    font-size: 14px;
                    line-height: 1.125;
                    text-decoration: none;
                    &:hover{
                        color: $primary-500;
                    }

                    [class^="i-"],
                    [class*="i-"]{
                        margin-right: 4px;
                        color: $primary-500;
                        font-size: 20px;
                    }
                }
            }

            &--alt{
                height: auto;
                max-width: 300px;
                margin: auto;
                flex-wrap: wrap;
                justify-content: center;
                .header-top__list{
                    &-item{
                        margin: 14px;
                        a{
                            color: $primary-94;
                        }
                    }
                }
            }
        }
    }
    &--expanded{
        .header-wrapper__menu{
            &-button{
                span{
                    &:first-child{
                        top: 13px; width: 0;
                        left: 50%;
                    }
                    &:nth-child(2){
                        transform: rotate(45deg);
                    }
                    &:nth-child(3){
                        transform: rotate(-45deg);
                    }
                    &:nth-child(4){
                        top: 13px; width: 0;
                        left: 50%;
                    }
                }
            }
        }
    }

    &-wrapper{
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        padding-left: 200px;
        @media(max-width: $tablet-v){
            height: 56px;
            padding-left: 130px;
            position: static;
        }

        &__logo{
            position: absolute;
            top: 50%; left: 0;
            transform: translateY(-50%);
            text-decoration: none;
            user-select: none;
            @media(max-width: $tablet-v){
                top: 14px; left: 15px;
                transform: translateY(0);
                @media (orientation: landscape){
                    @supports (left: env(safe-area-inset-left)){
                        left: calc(env(safe-area-inset-left) + 15px);
                    }
                }
            }
            &-element{
                max-height: 40px;
                transition: $transition;
                &--alt{
                    display: none;
                }
                @media(max-width: $tablet-v){
                    display: none;
                    max-height: 28px;
                    &--alt{
                        display: block;
                    }
                }
            }
            &--corporate{
                .header-wrapper__logo{
                    &-element{
                        max-height: 47px;
                        @media(max-width: $tablet-v){
                            max-height: 32px;
                        }
                    }
                }
            }
        }

        &__menu{
            height: 96px;
            flex-grow: 1;
            flex-shrink: 1;
            text-align: right;
            transition: $transition;

            &-button{
                opacity: 0;
                visibility: hidden;
                height: 30px; width: 30px;
                position: absolute;
                top: 50%; right: 0;
                transform: translateY(-50%);
                background: transparent;
                transition: $transition;
                background: transparent;
                @media(max-width: $tablet-v){
                    top: 14px; right: 15px;
                    transform: translateY(0);
                }
                span{
                    display: block;
                    position: absolute;
                    height: 2px; width: 20px;
                    background: white;
                    border-radius: 2px;
                    opacity: 1;
                    left: 5px;
                    transform: rotate(0);
                    transition: $transition;
                    &:first-child{
                        top: 8px;
                    }
                    &:nth-child(2),
                    &:nth-child(3){
                        top: 13px;
                    }
                    &:nth-child(4){
                        top: 18px;
                    }
                }
            }

            &-contacts{
                opacity: 0;
                visibility: hidden;
                display: flex;
                flex-flow: row;
                align-items: center;
                position: absolute;
                top: 19px; right: 65px;
                color: white;
                font-size: 16px;
                font-family: $Noto;
                font-weight: 700;
                font-style: italic;
                white-space: nowrap;
                transition: transform ease 0.25s, opacity $transition, visibility $transition;
                @media(max-width: $tablet-v){
                    opacity: 1;
                    visibility: visible;
                }
                a{
                    display: inline-block;
                    margin-left: 19px;
                    color: white;
                    text-decoration: none;
                    @media(max-width: $phone-sm){
                        margin-left: 10px;
                        font-size: 14px;
                    }
                }
            }
        }

        &__box{
            display: none;
            margin: 0 -15px;
            padding: 13px 15px;
            position: relative;
            border-top: 1px solid $primary-400;
            @media(max-width: $tablet-v){
                display: block;
                &:last-of-type{
                    padding-bottom: 53px;
                    &:after{
                        content: "\e900";
                        font-family: 'fibank-icons';
                        color: rgba(0,0,0,.24);
                        font-size: 16px;
                        position: absolute;
                        bottom: 24px; left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }

        &__buttons{
            display: flex;
            flex-flow: row nowrap;
            .button{
                white-space: nowrap;
                margin-left: 22px;
                z-index: 3;
                &:first-child{
                    margin-left: 16px;
                }
                [class^="i-"],
                [class*=" i-"]{
                    font-size: 18px;
                    font-weight: 400;
                }
            }
            .button-wrap{
                margin-left: 10px;
            }
        }
    }

    &-bottom{
        display: none;
        min-height: 40px;
        padding: 5px 0 9px;
        background: $primary-200;
        color: white;
        font-size: 12px;
        font-weight: 500;
        @media(max-width: $tablet-v){
            display: block;
        }
        a{
            color: white;
            text-decoration: none;
        }
        [class^="i-"],
        [class*=" i-"]{
            margin-right: 6px;
            position: relative;
            top: 3px;
            font-size: 16px;
        }
        &__list{
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            margin: 0 -15px;
            &-item{
                position: relative;
                white-space: nowrap;
                &:after{
                    content: '';
                    width: 1px;
                    position: absolute;
                    top: 5px; bottom: 5px;
                    right: 1px;
                    background: rgba(255,255,255,.24);
                }
                &:last-child{
                    &:after{
                        display: none;
                    }
                }
                a{
                    display: block;
                    padding: 5px 15px 6px;
                }
            }
        }
    }
}

.main-nav{
    opacity: 0;
    visibility: hidden;
    height: 96px;
    background: white;
    transition: opacity $transition 500ms, visibility $transition 500ms;
    &--visible{
        opacity: 1;
        visibility: visible;
    }

    &__list{
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: flex-end;
        white-space: nowrap;
        &-item{
            padding: 0 9px;
            font-size: 15px;
            text-align: left;
            &--alt{
                position: relative;
            }
            &:last-child{
                padding-right: 0;
            }
            &:hover{
                .main-nav{
                    &__link{
                        &:after{
                            opacity: 1;
                            visibility: visible;
                            transition-delay: 350ms;
                        }
                    }
                    &__dropdown:not(.main-nav__dropdown--other){
                        opacity: 1;
                        visibility: visible;
                        transition-delay: 350ms;
                        &:after{
                            display: block;
                        }
                    }
                }
            }
            &--expanded{
                .main-nav{
                    &__dropdown{
                        opacity: 1;
                        visibility: visible;
                    }
                    &__button{
                        &:before{
                            opacity: 0;
                            visibility: hidden;
                        }
                        &:after{
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }

            &--adjacent {
                pointer-events: none;

                @media(max-width: 1264px){
                    pointer-events: all;
                }
            }
        }
    }

    &__menu{
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: flex-end;
        &-item{
            padding: 0 16px;
            font-size: 14px;
            font-weight: 500;
            white-space: nowrap;
            a{
                color: rgba($primary-90, .54);
                text-decoration: none;
                &:hover,
                &.active{
                    color: $primary-500;
                }
            }
        }

        &-scrollbar{
            display: none;
            position: relative;
            @media(max-width: $tablet-v){
                margin-right: 0;
            }
            .viewport{
                padding: 35px 0 30px;
                overflow: hidden;
                position: relative;
            }

            .overview{
                position: absolute;
                top: 50%; left: 0;
                padding-left: 44%;
                transform: translateY(-50%);
                transition: $transition;
            }

            .scrollbar{
                position: absolute;
                left: 0; bottom: 0;
            }
        }
    }

    &__link{
        display: flex;
        align-items: center;
        height: 96px;
        position: relative;
        font-size: 16px;
        color: $dark-gray;
        text-decoration: none;
        background: transparent;
        &:after{
            content: '';
            opacity: 0;
            visibility: hidden;
            height: 2px; width: 100%;
            position: absolute;
            left: 0; bottom: 0;
            background: $primary-500;
            transition: $transition;
            transition-delay: 0;
        }
        &--active{
            color: $primary-500;
        }
        &-arrow{
            height: 20px; width: 20px;
            position: absolute;
            top: -1000px; right: -10000px;
            background: transparent;
            &:after{
                content: '\e99f';
                font-family: 'fibank-icons';
                font-size: 18px;
                font-weight: 400;
                font-style: normal;
                color: $primary-500;
                position: absolute;
                top: 50%; left: 50%;
                transform: translate(-50%, -50%);
                transition: $transition;
            }
        }
    }
    &__button{
        display: flex; width: 24px;
        align-items: center;
        height: 96px;
        position: relative;
        font-size: 16px;
        color: $dark-gray;
        text-decoration: none;
        background: transparent;
        font-size: 0;
        text-indent: -10000px;
        &:before,
        &:after{
            content: "\eb02";
            color: $primary-90;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            font-family: 'fibank-icons';
            text-indent: 0;
            position: absolute;
            top: 50%; left: 50%;
            transform: translate(-51%, -51%) translateZ(0);
            transition: $transition;
        }
        &:after{
            opacity: 0;
            content: "\ec3c";
            color: $primary-500;
        }
        &:hover{
            &:before{
                color: $primary-500;
            }
        }
    }

    &__dropdown{
        opacity: 0;
        visibility: hidden;
        display: flex;
        flex-flow: row;
        position: absolute;
        top: 100%;
        width: 100vw;
        max-width: 1344px;
        left: 50%;
        transform: translateX(-50%);
        background: #ebf1f5;
        white-space: normal;
        transition: opacity $transition, visibility $transition;
        transition-delay: 0;
        z-index: 2;
        &:after{
            content: '';
            display: none;
            height: 150vh; width: 100vw;
            position: absolute;
            top: 0; left: 50%;
            transform: translateX(-50%);
            background: rgba(56,54,54,.48);
            pointer-events: none;
            z-index: -1;
            @media(max-width: $tablet-h){
                display: none !important;
            }
        }

        &-banner{
            max-width: 350px;
            background: #ebf1f5;
            @media(max-width: $tablet-h){
                max-width: 300px;
                background: transparent;
            }
            @media(max-width: $tablet-v){
                display: none;
            }
        }
        &-columns{
            display: flex;
            flex-flow: row wrap;
            &__holder{
                flex-grow: 1;
                padding-bottom: 30px;
                background: #ebf1f5;
                @media(max-width: $tablet-h){
                    background: transparent;
                }
            }

            &__item{
                flex: 0 0 25%;
                padding-top: 30px;
                @media(max-width: $tablet-h){
                    padding-top: 20px;
                }
            }
            &__inner{
                padding-left: 30px;
                @media(max-width: $tablet-h){
                    padding-left: 20px;
                }
            }
            &__title{
                margin-bottom: 5px;
                font-family: $Noto;
                font-size: 19px;
                font-weight: 700;
                font-style: italic;
                color: black;
                @media(max-width: $tablet-h){
                    font-size: 16px;
                }

                a{
                    color: inherit;
                    text-decoration: none;
                    &:hover{
                        opacity: .64;
                    }
                }
            }
            &__list{

                &-item{
                    margin-top: 8px;
                    line-height: 1.3125;

                    a{
                        display: inline-block;
                        padding: 5px 0;
                        color: #0a3e93;
                        font-size: 15px;
                        text-decoration: none;
                        @media(max-width: $tablet-h){
                            font-size: 14px;
                        }

                        &:hover{
                            color: $accent-300;
                            [class^="i-"],
                            [class*=" i-"]{
                                color: $accent-300;
                            }
                        }

                        &.more{
                            font-size: 13px;
                            color: $primary-90;
                            &:hover{
                                opacity: .64;
                            }
                        }

                        [class^="i-"],
                        [class*=" i-"]{
                            color: #6f7b8d;
                        }
                    }
                }
            }
        }
        &--alt{
            max-width: 500px;
            left: auto; right: 0;
            transform: translateX(0);
            .main-nav__dropdown{
                &-columns{
                    flex-flow: column;
                    &__holder{
                        padding: 0 16px;
                    }
                    &__item{
                        padding: 19px 0 14px;
                        border-top: 1px solid rgba($primary-800, .12);
                        &:first-child{
                            border-top: 0;
                        }
                    }
                    &__inner{
                        padding: 0;
                    }
                    &__title{
                        color: $primary-94;
                        font-size: 16px;
                        font-weight: 500;
                        font-family: $Inter;
                        font-style: normal;
                    }
                }
            }
        }
    }
}
.header{
    @media(max-width: $tablet-v){
        background: $primary-500;
        .header{
            &-wrapper{
                &__logo{
                    &-element{
                        display: none;
                        max-height: 28px;
                        &--alt{
                            display: block;
                        }
                    }
                    &--corporate{
                        .header-wrapper__logo{
                            &-element{
                                max-height: 32px;
                            }
                        }
                    }
                }
                &__menu{
                    height: 54px;
                    &-button{
                        opacity: 1;
                        visibility: visible;
                    }
                }
                &__buttons{
                    flex-flow: column wrap;
                    margin-top: -24px;
                    .button{
                        min-width: 186px;
                        margin: 24px auto 0;
                        justify-content: center;
                        &:first-child{
                            margin-left: auto;
                        }
                        &-wrap{
                            margin-left: 0;
                        }
                    }
                }
            }
        }
        .main-nav{
            opacity: 0;
            visibility: hidden;
            height: auto; width: 100%;
            max-width: 410px;
            position: absolute;
            top: 100%;
            right: -15px;
            padding: 15px 15px 0;
            text-align: center;
            background: white;
            transition: opacity $transition, visibility $transition;
            z-index: 5;
            @media(max-width: $tablet-v){
                width: auto;
                max-width: inherit;
                left: 0;
                right: 0;
                margin-top: 55px;
                padding-top: 0;
            }
            &__list{
                display: flex;
                flex-flow: column;
                white-space: normal;
                text-align: center;
                margin-bottom: 15px;
                &-item{
                    width: 100%;
                    margin: 17px 0 18px;
                    padding: 0;
                    position: relative;
                    text-align: center;
                    &--expanded{
                        .main-nav{
                            &__link{
                                &-arrow{
                                    &:after{
                                        transform: translate(-50%, -50%) rotateZ(-180deg);
                                    }
                                }
                            }
                            &__button{
                                &.js-nav__dropdown{
                                    &:before{
                                        transform: translateY(-50%) rotateZ(-180deg);
                                    }
                                }
                            }
                            &__dropdown{
                                max-height: 500px;
                                transition-duration: 750ms;
                            }
                        }
                    }
                }
            }
            &__link,
            &__button{
                display: inline-block;
                height: auto;
                color: $dark-gray;
                font-family: $Noto;
                font-size: 19px;
                font-weight: 700;
                font-style: italic;
                text-indent: 0;

                &-wrap{
                    display: inline-block;
                    padding: 0 24px;
                    position: relative;
                }

                &:after{
                    display: none;
                }
                &:hover{
                    color: inherit;
                }
                &-arrow{
                    top: 3px; right: 0;
                }
                &.js-nav__dropdown{
                    width: auto;
                    padding-left: 24px;
                    padding-right: 24px;
                    &:before{
                        content: '\e99f';
                        font-family: 'fibank-icons';
                        font-size: 18px;
                        font-weight: 400;
                        font-style: normal;
                        color: $primary-500;
                        position: absolute;
                        top: 50%;
                        left: auto; right: 0;
                        transform: translateY(-50%);
                        margin-top: 2px;
                        transition: $transition;
                        opacity: 1 !important;
                        visibility: visible !important;
                    }
                }
            }
            &__dropdown{
                max-height: 0;
                overflow: hidden;
                width: auto;
                position: relative;
                top: 0; left: 0;
                opacity: 1;
                visibility: visible;
                background: transparent;
                transform: translateX(0);
                transition: 250ms ease;
                &:after{
                    display: none;
                }
                &-banner{
                    display: none;
                }
                &-columns{
                    flex-flow: column;
                    &__holder{
                        margin: 3px 0 -7px;
                        padding-bottom: 0;
                        background: transparent;
                    }
                    &__inner{
                        padding-left: 0;
                    }
                    &__list{
                        display: none;
                    }
                    &__title{
                        margin-bottom: 9px;
                        color: rgba($dark-gray,.64);
                    }
                }
                &--alt{
                    max-width: inherit;
                    .main-nav__dropdown{
                        &-columns{
                            &__item{
                                padding: 20px 0 0;
                                border-width: 0;
                            }
                        }
                    }
                }
            }
            &__menu{
                &-scrollbar{
                    @media(max-width: $tablet-v){
                        display: block;
                    }
                }
            }
        }

        .button-wrap--expanded{
            z-index: 3;
        }
    }

    &--mobile{
        background: $primary-500;
        .header{
            &-wrapper{
                &__logo{
                    &-element{
                        display: none;
                        max-height: 28px;
                        &--alt{
                            display: block;
                        }
                    }
                    &--corporate{
                        .header-wrapper__logo{
                            &-element{
                                max-height: 32px;
                            }
                        }
                    }
                }
                &__menu{
                    height: 54px;
                    &-button{
                        opacity: 1;
                        visibility: visible;
                    }
                }
                &__buttons{
                    flex-flow: column wrap;
                    margin-top: -24px;
                    .button{
                        min-width: 186px;
                        margin: 24px auto 0;
                        justify-content: center;
                        &:first-child{
                            margin-left: auto;
                        }
                        &-wrap{
                            margin-left: 0;
                        }
                    }
                }
            }
        }
        .main-nav{
            opacity: 0;
            visibility: hidden;
            height: auto; width: 100%;
            max-width: 410px;
            position: absolute;
            top: 100%;
            right: -15px;
            padding: 15px 15px 0;
            text-align: center;
            background: white;
            transition: opacity $transition, visibility $transition;
            z-index: 5;
            @media(max-width: $tablet-v){
                width: auto;
                max-width: inherit;
                left: 0;
                right: 0;
                margin-top: 55px;
                padding-top: 0;
            }
            &__list{
                display: flex;
                flex-flow: column;
                white-space: normal;
                text-align: center;
                margin-bottom: 15px;
                &-item{
                    width: 100%;
                    margin: 17px 0 18px;
                    padding: 0;
                    position: relative;
                    text-align: center;
                    &:hover{
                        .main-nav{
                            &__dropdown:not(.main-nav__dropdown--other){
                                &:after{
                                    display: none;
                                }
                            }
                        }
                    }
                    &--expanded{
                        .main-nav{
                            &__link{
                                &-arrow{
                                    &:after{
                                        transform: translate(-50%, -50%) rotateZ(-180deg);
                                    }
                                }
                            }
                            &__button{
                                &.js-nav__dropdown{
                                    &:before{
                                        transform: translateY(-50%) rotateZ(-180deg);
                                    }
                                }
                            }
                            &__dropdown{
                                max-height: 500px;
                                transition-duration: 750ms;
                            }
                        }
                    }
                }
            }

            &__link,
            &__button{
                display: inline-block;
                height: auto;
                color: $dark-gray;
                font-family: $Noto;
                font-size: 19px;
                font-weight: 700;
                font-style: italic;
                text-indent: 0;

                &-wrap{
                    display: inline-block;
                    padding: 0 24px;
                    position: relative;
                }

                &:after{
                    display: none;
                }
                &:hover{
                    color: inherit;
                }
                &-arrow{
                    top: 3px; right: 0;
                }
                &.js-nav__dropdown{
                    width: auto;
                    padding-left: 24px;
                    padding-right: 24px;
                    &:before{
                        content: '\e99f';
                        font-family: 'fibank-icons';
                        font-size: 18px;
                        font-weight: 400;
                        font-style: normal;
                        color: $primary-500;
                        position: absolute;
                        top: 50%;
                        left: auto; right: 0;
                        transform: translateY(-50%);
                        margin-top: 2px;
                        transition: $transition;
                        opacity: 1 !important;
                        visibility: visible !important;
                    }
                }
            }
            &__dropdown{
                max-height: 0;
                overflow: hidden;
                width: auto;
                position: relative;
                top: 0; left: 0;
                opacity: 1;
                visibility: visible;
                background: transparent;
                transform: translateX(0);
                transition: 250ms ease;
                &:after{
                    display: none;
                }
                &-banner{
                    display: none;
                }
                &-columns{
                    flex-flow: column;
                    &__holder{
                        margin: 3px 0 -7px;
                        padding-bottom: 0;
                        background: transparent;
                    }
                    &__inner{
                        padding-left: 0;
                    }
                    &__list{
                        display: none;
                    }
                    &__title{
                        margin-bottom: 9px;
                        color: rgba($dark-gray,.64);
                    }
                }
                &--alt{
                    max-width: inherit;
                    .main-nav__dropdown{
                        &-columns{
                            &__item{
                                padding: 20px 0 0;
                                border-width: 0;
                            }
                        }
                    }
                }
            }
            &__menu{
                &-scrollbar{
                    @media(max-width: $tablet-v){
                        display: block;
                    }
                }
            }
        }

        .button-wrap--expanded{
            z-index: 3;
        }
    }

    &--expanded{
        .main-nav{
            opacity: 1;
            visibility: visible;
        }
    }
}

.touchevents{
    .main-nav{
        &__menu{
            &-scrollbar{
                overflow: hidden;
                .viewport{
                    position: relative;
                    top: 10px;
                    margin-top: -10px;
                    overflow: hidden;
                    overflow-x: auto;
                    -webkit-overflow-scrolling: touch;
                }
                .overview{
                    padding-left: 30vw;
                }
            }
        }
    }
}

.fixed-header{
    padding-top: 97px;
    .header{
        position: fixed;
        top: 0; left: 0;
        transform: translateY(-100%);
    }
}

.fixed-header-transition{
    .header{
        transition: $transition;
    }
}

.show-fixed-header{
    .header{
        transform: translateY(0);
    }
    .main-nav{
        max-height: calc(100vh - 154px);
        max-height: calc(var(--vh, 1vh) * 100 - 153px);
        overflow: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        overscroll-behavior: contain;
    }
}

.footer{
    .header-top__dropdown:hover{
        .header-top__dropdown{
            &-header{
                opacity: .6;
            }
        }
    }
}