.fast-links{
    display: flex;
    flex-flow: row wrap;
    margin: -12px -12px 0;

    &__item{
        flex: 1 0 25%;
        padding: 12px 0;
        overflow: hidden;
        transform-style: preserve-3d;
        
        @media (max-width: $tablet-h){
            flex: 1 0 50%;
        }

        @media (max-width: $phone-v){
            flex: 1 0 100%;
        }

        &-inner{
            height: 100%;
            margin: 0 12px;
            padding: 25px 24px 26px;
            position: relative;
            overflow: hidden;
            background: $gray-002;

            [class^="i-"],
            [class*=" i-"]{
                position: absolute;
                top: 50%; right: -15px;
                transform: translateY(-50%);
                color: $primary-500;
                font-size: 72px;
                opacity: .12;
                z-index: 1;
            }
        }
    }

    &__title{
        position: relative;
        @include fluid-size(font-size, 320px, 1330px, 20px, 22px);
        font-family: $Noto;
        font-weight: 700;
        font-style: italic;
        color: $dark-gray;
        line-height: 1.375;
        z-index: 2;
        @media(max-width: $tablet-v){
            overflow-wrap: break-word;
            word-wrap: break-word;
            hyphens: auto;
        }
        &:last-child{
            height: calc(100% + 51px);
            margin: -25px -24px -26px;
            padding: 25px 24px 26px;

            a{
                height: calc(100% + 51px);
                margin: -25px -24px -26px;
                padding: 25px 24px 26px;
            }
        }

        a{
            display: flex;
            flex-flow: column;
            justify-content: center;
            color: inherit;
            text-decoration: none;

            &:hover{
                color: $primary-500;
            }
        }
    }

    &--alt{
        margin: -21px -16px 0;
        .fast-links{
            &__item{
                flex: 0 0 50%;
                padding: 16px 0;
                &-inner{
                    margin: 0 16px;
                    padding: 35px 90px 36px 34px;
                    @media(max-width: $phone-v){
                        padding-left: 20px;
                    }
                }
                @media(max-width: $phone-h){
                    flex: 0 0 100%;
                }
            }
            &__title{
                @include fluid-size(font-size, 320px, 1330px, 20px, 32px);
                @include fluid-size(line-height, 320px, 1330px, 1.3, 1.5);
                &:last-child{
                    height: calc(100% + 71px);
                    margin: -35px -90px -36px -34px;
                    padding: 35px 90px 36px 34px;
        
                    a{
                        height: calc(100% + 71px);
                        margin: -35px -90px -36px -34px;
                        padding: 35px 90px 36px 34px;
                    }
                }
            }
            &__image{
                position: absolute;
                top: 50%; right: -15px;
                transform: translateY(-50%);
                z-index: 1;
            }
        }
    }

    &--second{
        margin: 0 -16px;
        .fast-links{
            &__item{
                flex: 0 0 50%;
                padding: 16px 0;
                &-inner{
                    margin: 0 16px;
                    padding: 21px 90px 26px 24px;
                    @media(max-width: $phone-v){
                        padding-left: 20px;
                    }
                }
                @media(max-width: $phone-h){
                    flex: 0 0 100%;
                }
            }
            &__title{
                display: flex;
                flex-flow: row;
                align-items: center;
                @include fluid-size(font-size, 320px, 1330px, 18px, 22px);
                @include fluid-size(line-height, 320px, 1330px, 1.3, 1.5);
                &:last-child{
                    height: calc(100% + 47px);
                    margin: -21px -90px -26px -24px;
                    padding: 21px 90px 26px 24px;
        
                    a{
                        display: flex;
                        flex-flow: row;
                        align-items: center;
                        justify-content: flex-start;
                        flex-grow: 1;
                        height: calc(100% + 47px);
                        margin: -21px -90px -26px -24px;
                        padding: 21px 90px 26px 24px;
                    }
                }
            }
            &__image{
                position: absolute;
                top: 50%; right: -15px;
                transform: translateY(-50%);
                z-index: 1;

                [class^="i-"],
                [class*=" i-"]{
                    position: relative;
                    top: 0; right: 0;
                    transform: translate(0,0);
                    color: $primary-500;
                    font-size: 72px;
                    opacity: .12;
                    z-index: 1;
                }
            }
        }
    }
}