.error-page{
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 25px 0;
    text-align: center;
    line-height: 1.125;

    &__logo{
        @include fluid-size(margin-bottom, 280px, 1920px, 25px, 40px);
        img{
            width: 240px;
            @media(max-width: $phone-v){
                width: 200px;
            }
            @media(max-width: $phone-sm){
                width: 160px;
            }
        }
    }

    &__type{
        color: $primary-90;
        font-family: $Noto;
        font-style: italic;
        font-weight: 700;
        @include fluid-size(font-size, 280px, 1920px, 100px, 180px);
        line-height: .9;
    }

    &__message{
        @include fluid-size(margin-top, 320px, 1920px, 12px, 20px);
        color: #6f7b8d;
        font-family: $Noto;
        font-style: italic;
        font-weight: 700;
        @include fluid-size(font-size, 320px, 1330px, 20px, 28px);
    }

    &__button{
        @include fluid-size(margin-top, 320px, 1330px, 22px, 43px);
    }

    &__suggestion{
        &-title{
            @include fluid-size(margin-top, 320px, 1920px, 35px, 65px);
            color: $primary-90;
            font-family: $Noto;
            @include fluid-size(font-size, 320px, 1330px, 18px, 20px);
        }
        &-list{
            max-width: 720px;
            margin: 23px auto 0;
            display: flex;
            flex-flow: row wrap;
            width: 100%;
            &__item{
                flex: 0 0 33.3333%;
                padding: 11px 20px 12px 0;
                font-size: 17px;
                font-weight: 500;
                text-align: left;
                @media (-ms-high-contrast: active), (-ms-high-contrast: none){
                    padding-right: 0;
                }
                @media(max-width: $phone-h){
                    flex: 0 0 50%;
                    font-size: 16px;
                }
                @media(max-width: $phone-s){
                    flex: 0 0 100%;
                    padding: 6px 0 7px;
                }
                a{
                    text-decoration: none;
                    background-image: linear-gradient(currentColor, currentColor);
                    background-position: 0 100%;
                    background-repeat: no-repeat;
                    background-size: 0 1px;
                    transition: $transition;
                    &:hover{
                        background-size: 100% 1px;
                    }
                }
            }
        }
    }
}