.cards-banner{
	min-height: 424px;
	position: relative;
	overflow: hidden;
	background: $primary-500;
	@media(max-width: $tablet-v){
		min-height: inherit;
		margin-left: -15px;
		margin-right: -15px;
	}

	.slick{
		&-track{
			display: flex;
		}
		&-slide{
			display: flex !important;
			height: auto;
			justify-content: center;
		}
		&-dots{
			margin-top: 18px;
			li{
				button{
					border-color: white;
					background: transparent;
				}

				&.slick-active{
					button{
						background: white;
						border-color: white;
					}
				}
			}
		}
	}

	&__list{
		position: relative;
		margin: 70px 0 48px 40px;
		z-index: 2;
		@media(max-width: $tablet-v){
			margin: 143px 0 18px 33px;
		}

		&-item{
			display: none;
			height: 100%;
			max-width: 342px;
			@media(max-width: $phone-s){
				max-width: 300px;
			}
		}

		&-inner{
			display: block;
			height: 100%;
			margin-right: 32px;
			padding: 32px 16px 98px 32px;
			position: relative;
			color: $primary-90;
			font-size: 14px;
			line-height: 1.4285;
			text-decoration: none;
			background: white;
			@include hover{
				.cards-banner{
					&__list{
						&-title{
							color: $primary-500;
						}
						&-link{
							background: darken($accent-500, 8%);
						}
					}
				}
			}
			@media(max-width: $tablet-v){
				padding: 28px 28px 98px;
			}
		}

		&-title{
			margin-bottom: 14px;
			color: $dark-gray;
			font-size: 26px;
			font-family: $Noto;
			font-weight: 700;
			font-style: italic;
			line-height: 1.285;
			transition: color $transition;
			@media(max-width: $tablet-v){
				font-size: 23px;
			}

			a{
				color: inherit;
				text-decoration: none;

				&:hover{
					color: $primary-500;
				}
			}
		}

		&-link{
			display: inline-block;
			height: 40px; width: 40px;
			position: absolute;
			left: 32px; bottom: 12px;
			transform: rotateZ(-45deg);
			transform-origin: left top;
			background: $accent-500;
			text-decoration: none;
			transition: background $transition;

			span{
				display: block;
				height: 40px; width: 40px;
				position: relative;
				transform: rotateZ(45deg);
				text-indent: -10000px;

				&:before{
					content: '\e9a1';
					position: absolute;
					top: 50%; left: 50%;
					transform: translate(-50%, -50%);
					color: white;
					text-indent: 0;
					font-size: 24px;
					font-family: 'fibank-icons';
				}
			}
		}
	}

	&__image{
		height: 100%; width: 100%;
		position: absolute;
		top: 0; left: 0;
		object-fit: cover;
		z-index: 1;
		@media(max-width: $tablet-v){
			max-height: 200px;
		}
	}
}