.details-list{
    display: flex;
    flex-flow: row wrap;
    margin: 0 -16px;
    &__item{
        flex: 0 0 33.3333%;
        padding: 16px 0;
        overflow: hidden;
        @media(max-width: $tablet-v){
            flex: 0 0 50%;
        }
        @media(max-width: $phone-v){
            flex: 0 0 100%;
        }
    }
    &__inner{
        display: block;
        min-height: 235px;
        position: relative;
        margin: 0 16px;
        padding: 28px 96px 107px 32px;
        overflow: hidden;
        text-decoration: none;
        background: radial-gradient(ellipse at center, $primary-400 0%,rgba(233,237,246,.39) 100%);
        transform-style: preserve-3d;
        &:after{
            content: '';
            height: 100%; width: 125%;
            position: absolute;
            top: 0; left: 0;
            background: url('../images/element-fibank.svg') no-repeat right center;
            background-size: auto 155%;
            z-index: 1;
        }
        @include hover{
            .details-list{
                &__title{
                    color: $primary-500;
                }
            }
        }
    }
    &__title{
        position: relative;
        z-index: 2;
        font-family: $Noto;
        font-style: italic;
        font-weight: 700;
        color: $dark-gray;
        @include fluid-size(font-size, 280px, 1330px, 24px, 30px);
        line-height: 1.375;
    }
    .button{
        position: absolute;
        bottom: 32px; left: 32px;
        z-index: 2;
    }
}