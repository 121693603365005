.card-box-slider {    
    position: relative; 
    &__controls {        
        display: flex;
        align-items: center;
        .slick-next,
        .slick-prev {            
            flex-shrink: 0;
            font-size: 0;
            padding: 0 16px 0 0;
            position: relative;
            background-color: transparent;
            &:before {
                font-family: 'fibank-icons';
                font-size: 24px;
                line-height: 1;
                color: $primary-500;
                transition: color $transition;
                display: flex;
                width: 26px;
                height: 40px;
                justify-content: center;
                align-items: center;
                background: $primary-400;
                position: relative;
                z-index: 2;
            }                        
            &:after {          
                content: '';        
                width: 28px;
                height: 28px;
                position: absolute;
                z-index: 1;
                top: 0px;
                left: 16px;
                transform-origin: top left;
                transform: scale(0.8, 1) rotate(45deg);        
                background-color: $primary-400;        
                transition: background-color $transition, color $transition;        
            }
        }
        .slick-prev {
            padding: 0 0 0 16px;
        }
        .slick-next:after {            
            left: calc(100% - 16px);
        }
        .slick-next:before {
            content: '\e99d';
        }
        .slick-prev:before {
            content: '\e99c';
        }
        .slick-arrow.slick-disabled:before {
            color: $gray-060;
        }
    }
    &__carousel {        
        margin: 0 -20px;
        position: relative;
        &:before,
        &:after {
            content: '';
            position: absolute;
            z-index: 1;
            width: 160px;
            top: 0px;
            bottom: 0px;
            right: 0;
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 90%, rgba(255,255,255,1) 100%);
            transition: opacity $transition;
            pointer-events: none;
        }
        &:before {
            opacity: 0;
            right: auto;
            left: 0;
            background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 100%);
            
        }
        &.slick-end:before {
            opacity: 1;
        }
        &.slick-end:after {
            opacity: 0;
        }
        @media(max-width: $phone-s) {
            &:before,
            &:after {
                display: none;
            }
        }
    }
    
    & .slick-slide {
        width: 366px;
    }
    &__slide {        
        padding: 10px 20px;
        height: 100%;
        width: 366px;
        &:not(:first-child) {
            position: absolute;
            opacity: 0;
            top: 0;            
        }
        .slick-initialized & {
            opacity: 1;
            position: static;
            width: auto;
        }
    }
    
    & .slick {
        &-track {
            display: flex !important;
        }
        &-slide {
            height: inherit !important;
            & > div {
                height: 100%;
            }
        }
    }
}
.card-box {
    display: block;
    text-decoration: none;
    padding-top: 56px;    
    height: 100%;    
    &__body {
        background-color: rgba($primary-400, 0.64);
        padding: 24px;
        height: 100%;
    }
    &__image {
        max-width: 216px;
        margin: -80px 0 15px 0;
        box-shadow: 0 2px 11px rgba(68, 82, 103, 0.24);
        border-radius: 12px;
        transition: transform $transition;
    }
    &__title {
        font-size: 24px;
        line-height: 1.416666666666667;
        font-family: $Noto;
        font-weight: 700;
        font-style: italic;
        color: $dark-gray;
        margin: 0 0 6px 0;
        i {            
            display: inline-block;            
            font-size: 18px;
            margin: -2px 0 0 6px;
        }
    }
    &__text {
        font-size: 14px;
        line-height: 1.285714285714286;
        font-weight: 500;
        color: rgba($primary-94, 0.45);
    }
    &:hover &__image {
        transform: translateY(-10px);
    }
    &:hover &__title {
        color: $primary-500;
    }
    &--min {        
        padding: 0;
        max-width: 205px;
    }
    &--min &__body {
        padding: 0;
        background-color: transparent;
    }
    &--min &__image {
        margin-top: 0;
        margin-bottom: 7px;
        max-width: 205px;
    }
    &--min &__title {
        font-size: 20px;
    }
}
.card-box-list {    
    display: flex;
    flex-wrap: wrap;
    margin: -24px;
    &__item {
        padding: 24px;
    }
}