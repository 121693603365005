.content-header {    
    background-image: linear-gradient(180deg, rgba(10, 62, 147, 0.04) 0%, rgba(10, 62, 147, 0) 100%);
    text-align: center;
    @include fluid-size(padding-top padding-bottom, 320px, 1330px, 24px, 54px);
    &--spaced {
        @include fluid-size(margin-bottom, 320px, 1330px, 25px, 50px);
    }
    &--half-spaced{
        margin-bottom: 25px;
    }
    &--text-left{
        text-align: left;
    }
    &--empty{
        @include fluid-size(padding-top padding-bottom, 320px, 1330px, 24px, 34px);
    }
    &--empty-2 {
        @include fluid-size(padding-top padding-bottom, 320px, 1330px, 26px, 36px);
        @include fluid-size(padding-bottom, 320px, 1330px, 26px, 25px);
    }
    &__title {
        font-family: $Noto;
        @include fluid-size(font-size, 320px, 1330px, 25px, 58px);
        font-weight: 700;
        font-style: italic;
        color: $primary-94;
        margin-bottom: 15px;

        &--medium{
            @include fluid-size(font-size, 320px, 1330px, 25px, 45px);
        }
        &--small {
            @include fluid-size(font-size, 320px, 1330px, 28px, 32px);
        }
        &--xs {
            @include fluid-size(font-size, 320px, 1330px, 22px, 30px);
        }
    }
    &__subtitle {
        @include fluid-size(margin-bottom, 320px, 1330px, 26px, 46px);
        font-family: $Noto;
        @include fluid-size(font-size, 320px, 1330px, 18px, 26px);
        font-weight: 700;
        font-style: italic;
        color: $primary-90;

        &:last-child{
            margin-bottom: 0;
        }

        u{
            color: $primary-500;
        }
    }
    &__date{
        display: inline-block;
        margin-top: 15px;
        color: rgba($primary-94, .48);
        font-size: 16px;
        font-weight: 500;
        & + .content-header__title{
            margin-top: 14px;
        }
    }
    [class^="i-"],
    [class*=" i-"]{
        margin-bottom: 27px;
        font-size: 78px;
        color: $accent-400;
    }

    .button{
        [class^="i-"],
        [class*=" i-"]{
            margin-bottom: 0;
            font-size: 20px;
            color: inherit;
        }
    }

    .breadcrumbs {
        padding-top: 0;
        padding-bottom: 7px;
    }

    &--alt{
        @include fluid-size(padding-top, 320px, 1330px, 24px, 38px);
        text-align: left;

        .content-header{
            &__title{
                @include fluid-size(font-size, 320px, 1330px, 25px, 40px);
            }

            &__flex{
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                @media(max-width: $phone-h){
                    display: block;
                }
            }
        }
    }

    &--center-left {
        text-align: center;

        @media(max-width: $phone-h) {
            text-align: left;
        }
    }
}