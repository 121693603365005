@font-face {
  font-family: 'fibank-icons';
  src:
    url('../fonts/fibank-icons.woff2?vlq5w9') format('woff2'),
    url('../fonts/fibank-icons.ttf?vlq5w9') format('truetype'),
    url('../fonts/fibank-icons.woff?vlq5w9') format('woff'),
    url('../fonts/fibank-icons.svg?vlq5w9#fibank-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="i-"], [class*=" i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  display: inline-block;
  font-family: 'fibank-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:before{
    transition: color $transition;
  }
}

.i-xhtml:before {
  content: "\e909";
}
.i-atm:before {
  content: "\e906";
}
.i-branch:before {
  content: "\e907";
}
.i-repair:before {
  content: "\e908";
}
.i-logo-studiox:before {
  content: "\e900";
}
.i-1-language-en:before {
  content: "\e901";
}
.i-2-language-bg:before {
  content: "\e902";
}
.i-arrow-down-alt:before {
  content: "\e903";
}
.i-back:before {
  content: "\e904";
}
.i-check-list:before {
  content: "\e905";
}
.i-pdf:before {
  content: "\e90a";
  color: #db5565;
}
.i-0-plus:before {
  content: "\e90b";
}
.i-arrow-up-alt:before {
  content: "\e90c";
}
.i-money:before {
  content: "\e911";
}
.i-next:before {
  content: "\e912";
}
.i-zip:before {
  content: "\e916";
  color: #fd9f17;
}
.i-3-plus:before {
  content: "\e917";
}
.i-pptx:before {
  content: "\e91b";
  color: #e24325;
}
.i-promo1:before {
  content: "\e91c";
}
.i-see:before {
  content: "\e91d";
}
.i-6-plus:before {
  content: "\e920";
}
.i-check-list1:before {
  content: "\e921";
}
.i-docx:before {
  content: "\e924";
  color: #306ead;
}
.i-gold2:before {
  content: "\e925";
}
.i-paywave:before {
  content: "\e926";
}
.i-10-plus:before {
  content: "\e929";
}
.i-arrow-right-alt:before {
  content: "\e92a";
}
.i-contact-us-call-location:before {
  content: "\e92b";
}
.i-statistics:before {
  content: "\e930";
}
.i-xlsx:before {
  content: "\e931";
  color: #02955f;
}
.i-12-plus:before {
  content: "\e932";
}
.i-calculator:before {
  content: "\e935";
}
.i-close-thin:before {
  content: "\e938";
}
.i-image:before{
  content: "\e939";
  color: #51bf87;
}
.i-promo:before {
  content: "\e93a";
}
.i-13-plus:before {
  content: "\e93b";
}
.i-close-bold:before {
  content: "\e93e";
}
.i-location-call:before {
  content: "\e93f";
}
.i-txt:before {
  content: "\e942";
  color: #b1bac1;
}
.i-wallet1:before {
  content: "\e943";
}
.i-16-plus:before {
  content: "\e944";
}
.i-calculator1:before {
  content: "\e945";
}
.i-code:before {
  content: "\e946";
  color: #708a9f;
}
.i-info:before {
  content: "\e947";
}
.i-piggy-bank:before {
  content: "\e94a";
}
.i-plis:before {
  content: "\e94b";
}
.i-17-plus:before {
  content: "\e94e";
}
.i-gold1:before {
  content: "\e951";
}
.i-phone:before {
  content: "\e952";
}
.i-video:before {
  content: "\e958";
  color: #db4564;
}
.i-18-plus:before {
  content: "\e959";
}
.i-a-lot-of-money:before {
  content: "\e95a";
}
.i-music:before {
  content: "\e95b";
  color: #ff7e7e;
}
.i-travel1:before {
  content: "\e95c";
}
.i-21-plus:before {
  content: "\e95d";
}
.i-audio:before {
  content: "\e95e";
  color: #ff6f52;
}
.i-cart-shopping:before {
  content: "\e95f";
}
.i-travel-bag:before {
  content: "\e960";
}
.i-500px:before {
  content: "\e961";
}
.i-card1:before {
  content: "\e962";
}
.i-locations-branches:before {
  content: "\e963";
}
.i-snippet:before {
  content: "\e964";
  color: #708a9f;
}
.i-abacus:before {
  content: "\e965";
}
.i-car:before {
  content: "\e966";
}
.i-coins:before {
  content: "\e967";
}
.i-accessible-icon-alt:before {
  content: "\e968";
}
.i-cards:before {
  content: "\e969";
}
.i-eml:before {
  content: "\e96a";
  color: #00acde;
}
.i-tv:before {
  content: "\e96b";
}
.i-adjust-alt:before {
  content: "\e96c";
}
.i-just-file:before {
  content: "\e96d";
  color: #0a3e93;
}
.i-student:before {
  content: "\e96e";
}
.i-student-alt:before {
  content: "\e96f";
}
.i-adjust-circle:before {
  content: "\e970";
}
.i-learn-book:before {
  content: "\e971";
}
.i-learning-book:before {
  content: "\e972";
}
.i-adjust-half:before {
  content: "\e973";
}
.i-happy:before {
  content: "\e974";
}
.i-home1:before {
  content: "\e975";
}
.i-adjust:before {
  content: "\e976";
}
.i-notebook:before {
  content: "\e977";
}
.i-sad:before {
  content: "\e978";
}
.i-adobe:before {
  content: "\e979";
}
.i-store:before {
  content: "\e97a";
}
.i-strucutre-folder:before {
  content: "\e97b";
}
.i-airplay:before {
  content: "\e97c";
}
.i-company:before {
  content: "\e97d";
}
.i-list:before {
  content: "\e97e";
}
.i-align-alt:before {
  content: "\e97f";
}
.i-empire:before {
  content: "\e980";
}
.i-flag:before {
  content: "\e981";
}
.i-align-center-alt:before {
  content: "\e982";
}
.i-love-shield:before {
  content: "\e983";
}
.i-align-center-h:before {
  content: "\e984";
}
.i-network:before {
  content: "\e985";
}
.i-align-center-justify:before {
  content: "\e986";
}
.i-money1:before {
  content: "\e987";
}
.i-align-center-v:before {
  content: "\e988";
}
.i-spesific-location:before {
  content: "\e989";
}
.i-align-center:before {
  content: "\e98a";
}
.i-news:before {
  content: "\e98b";
}
.i-align-justify:before {
  content: "\e98c";
}
.i-statistics1:before {
  content: "\e98d";
}
.i-align-left-justify:before {
  content: "\e98e";
}
.i-crown:before {
  content: "\e98f";
}
.i-align-left:before {
  content: "\e990";
}
.i-team:before {
  content: "\e991";
}
.i-align-letter-right:before {
  content: "\e992";
}
.i-align-right-justify:before {
  content: "\e993";
}
.i-align-right:before {
  content: "\e994";
}
.i-align:before {
  content: "\e995";
}
.i-ambulance:before {
  content: "\e996";
}
.i-analysis:before {
  content: "\e997";
}
.i-analytics:before {
  content: "\e998";
}
.i-anchor:before {
  content: "\e999";
}
.i-android-phone-slash:before {
  content: "\e99a";
}
.i-angle-double-down:before {
  content: "\e99b";
}
.i-angle-double-left:before {
  content: "\e99c";
}
.i-angle-double-right:before {
  content: "\e99d";
}
.i-angle-double-up:before {
  content: "\e99e";
}
.i-angle-down:before {
  content: "\e99f";
}
.i-angle-left:before {
  content: "\e9a0";
}
.i-angle-right-b:before {
  content: "\e9a1";
}
.i-angle-right:before {
  content: "\e9a2";
}
.i-angle-up:before {
  content: "\e9a3";
}
.i-angry:before {
  content: "\e9a4";
}
.i-ankh:before {
  content: "\e9a5";
}
.i-annoyed-alt:before {
  content: "\e9a6";
}
.i-annoyed:before {
  content: "\e9a7";
}
.i-apple:before {
  content: "\e9a8";
}
.i-apps:before {
  content: "\e9a9";
}
.i-archive-alt:before {
  content: "\e9aa";
}
.i-archive:before {
  content: "\e9ab";
}
.i-archway:before {
  content: "\e9ac";
}
.i-arrow-break:before {
  content: "\e9ad";
}
.i-arrow-circle-down:before {
  content: "\e9ae";
}
.i-arrow-circle-left:before {
  content: "\e9af";
}
.i-arrow-circle-right:before {
  content: "\e9b0";
}
.i-arrow-circle-up:before {
  content: "\e9b1";
}
.i-arrow-compress-h:before {
  content: "\e9b2";
}
.i-arrow-down-left:before {
  content: "\e9b3";
}
.i-arrow-down-right:before {
  content: "\e9b4";
}
.i-arrow-down:before {
  content: "\e9b5";
}
.i-arrow-from-right:before {
  content: "\e9b6";
}
.i-arrow-from-top:before {
  content: "\e9b7";
}
.i-arrow-growth:before {
  content: "\e9b8";
}
.i-arrow-left:before {
  content: "\e9b9";
}
.i-arrow-random:before {
  content: "\e9ba";
}
.i-arrow-resize-diagonal:before {
  content: "\e9bb";
}
.i-arrow-right:before {
  content: "\e9bc";
}
.i-arrow-to-bottom:before {
  content: "\e9bd";
}
.i-arrow-to-right:before {
  content: "\e9be";
}
.i-arrow-up-left:before {
  content: "\e9bf";
}
.i-arrow-up-right:before {
  content: "\e9c0";
}
.i-arrow-up:before {
  content: "\e9c1";
}
.i-arrows-h-alt:before {
  content: "\e9c2";
}
.i-arrows-h:before {
  content: "\e9c3";
}
.i-arrows-left-down:before {
  content: "\e9c4";
}
.i-arrows-maximize:before {
  content: "\e9c5";
}
.i-arrows-merge:before {
  content: "\e9c6";
}
.i-arrows-resize-h:before {
  content: "\e9c7";
}
.i-arrows-resize-v:before {
  content: "\e9c8";
}
.i-arrows-resize:before {
  content: "\e9c9";
}
.i-arrows-right-down:before {
  content: "\e9ca";
}
.i-arrows-shrink-h:before {
  content: "\e9cb";
}
.i-arrows-shrink-v:before {
  content: "\e9cc";
}
.i-arrows-up-right:before {
  content: "\e9cd";
}
.i-arrows-v-alt:before {
  content: "\e9ce";
}
.i-arrows-v:before {
  content: "\e9cf";
}
.i-assistive-listening-systems:before {
  content: "\e9d0";
}
.i-at:before {
  content: "\e9d1";
}
.i-atm-card:before {
  content: "\e9d2";
}
.i-atom:before {
  content: "\e9d3";
}
.i-auto-flash:before {
  content: "\e9d4";
}
.i-award-alt:before {
  content: "\e9d5";
}
.i-award:before {
  content: "\e9d6";
}
.i-baby-carriage:before {
  content: "\e9d7";
}
.i-backpack:before {
  content: "\e9d8";
}
.i-backspace:before {
  content: "\e9d9";
}
.i-backward:before {
  content: "\e9da";
}
.i-bag-alt:before {
  content: "\e9db";
}
.i-bag-slash:before {
  content: "\e9dc";
}
.i-bag:before {
  content: "\e9dd";
}
.i-ball:before {
  content: "\e9de";
}
.i-ban:before {
  content: "\e9df";
}
.i-bars:before {
  content: "\e9e0";
}
.i-basketball-hoop:before {
  content: "\e9e1";
}
.i-basketball:before {
  content: "\e9e2";
}
.i-battery-bolt:before {
  content: "\e9e3";
}
.i-battery-empty:before {
  content: "\e9e4";
}
.i-bed-double:before {
  content: "\e9e5";
}
.i-bed:before {
  content: "\e9e6";
}
.i-behance:before {
  content: "\e9e7";
}
.i-bell-school:before {
  content: "\e9e8";
}
.i-bell-slash:before {
  content: "\e9e9";
}
.i-bell:before {
  content: "\e9ea";
}
.i-bill:before {
  content: "\e9eb";
}
.i-bitcoin-circle:before {
  content: "\e9ec";
}
.i-bitcoin:before {
  content: "\e9ed";
}
.i-black-berry:before {
  content: "\e9ee";
}
.i-bluetooth-b:before {
  content: "\e9ef";
}
.i-bold:before {
  content: "\e9f0";
}
.i-bolt-alt:before {
  content: "\e9f1";
}
.i-bolt-slash:before {
  content: "\e9f2";
}
.i-bolt:before {
  content: "\e9f3";
}
.i-book-alt:before {
  content: "\e9f4";
}
.i-compare:before {
  content: "\e9f5";
}
.i-book-open:before {
  content: "\e9f6";
}
.i-book-reader:before {
  content: "\e9f7";
}
.i-book:before {
  content: "\e9f8";
}
.i-bookmark-full:before {
  content: "\e9f9";
}
.i-bookmark:before {
  content: "\e9fa";
}
.i-books:before {
  content: "\e9fb";
}
.i-boombox:before {
  content: "\e9fc";
}
.i-border-alt:before {
  content: "\e9fd";
}
.i-border-bottom:before {
  content: "\e9fe";
}
.i-border-clear:before {
  content: "\e9ff";
}
.i-border-horizontal:before {
  content: "\ea00";
}
.i-border-inner:before {
  content: "\ea01";
}
.i-border-left:before {
  content: "\ea02";
}
.i-border-out:before {
  content: "\ea03";
}
.i-border-right:before {
  content: "\ea04";
}
.i-border-top:before {
  content: "\ea05";
}
.i-border-vertical:before {
  content: "\ea06";
}
.i-box:before {
  content: "\ea07";
}
.i-briefcase-alt:before {
  content: "\ea08";
}
.i-briefcase:before {
  content: "\ea09";
}
.i-bright:before {
  content: "\ea0a";
}
.i-brightness-empty:before {
  content: "\ea0b";
}
.i-brightness-half:before {
  content: "\ea0c";
}
.i-brightness-low:before {
  content: "\ea0d";
}
.i-brightness-minus:before {
  content: "\ea0e";
}
.i-brightness-plus:before {
  content: "\ea0f";
}
.i-brightness:before {
  content: "\ea10";
}
.i-bring-bottom:before {
  content: "\ea11";
}
.i-bring-front:before {
  content: "\ea12";
}
.i-brush-alt:before {
  content: "\ea13";
}
.i-bug:before {
  content: "\ea14";
}
.i-building:before {
  content: "\ea15";
}
.i-bullseye:before {
  content: "\ea16";
}
.i-bus-alt:before {
  content: "\ea17";
}
.i-bus-school:before {
  content: "\ea18";
}
.i-bus:before {
  content: "\ea19";
}
.i-calcualtor:before {
  content: "\ea1a";
}
.i-calculator-alt:before {
  content: "\ea1b";
}
.i-calendar-alt:before {
  content: "\ea1c";
}
.i-calendar-slash:before {
  content: "\ea1d";
}
.i-calender:before {
  content: "\ea1e";
}
.i-calling:before {
  content: "\ea1f";
}
.i-camera-change:before {
  content: "\ea20";
}
.i-camera-plus:before {
  content: "\ea21";
}
.i-camera-slash:before {
  content: "\ea22";
}
.i-camera:before {
  content: "\ea23";
}
.i-cancel:before {
  content: "\ea24";
}
.i-capsule:before {
  content: "\ea25";
}
.i-capture:before {
  content: "\ea26";
}
.i-car-sideview:before {
  content: "\ea27";
}
.i-car-slash:before {
  content: "\ea28";
}
.i-car-wash:before {
  content: "\ea29";
}
.i-car1:before {
  content: "\ea2a";
}
.i-card-atm:before {
  content: "\ea2b";
}
.i-caret-right:before {
  content: "\ea2c";
}
.i-cart:before {
  content: "\ea2d";
}
.i-cell:before {
  content: "\ea2e";
}
.i-celsius:before {
  content: "\ea2f";
}
.i-chart-bar-alt:before {
  content: "\ea30";
}
.i-chart-bar:before {
  content: "\ea31";
}
.i-chart-down:before {
  content: "\ea32";
}
.i-chart-growth-alt:before {
  content: "\ea33";
}
.i-chart-growth:before {
  content: "\ea34";
}
.i-chart-line:before {
  content: "\ea35";
}
.i-chart-pie-alt:before {
  content: "\ea36";
}
.i-chart-pie:before {
  content: "\ea37";
}
.i-chart:before {
  content: "\ea38";
}
.i-chat-bubble-user:before {
  content: "\ea39";
}
.i-chat-info:before {
  content: "\ea3a";
}
.i-chat:before {
  content: "\ea3b";
}
.i-check-circle:before {
  content: "\ea3c";
}
.i-check-square:before {
  content: "\ea3d";
}
.i-check:before {
  content: "\ea3e";
}
.i-circle-layer:before {
  content: "\ea3f";
}
.i-circle:before {
  content: "\ea40";
}
.i-circuit:before {
  content: "\ea41";
}
.i-clapper-board:before {
  content: "\ea42";
}
.i-clipboard-alt:before {
  content: "\ea43";
}
.i-clipboard-blank:before {
  content: "\ea44";
}
.i-clipboard-notes:before {
  content: "\ea45";
}
.i-clipboard:before {
  content: "\ea46";
}
.i-clock-eight:before {
  content: "\ea47";
}
.i-clock-five:before {
  content: "\ea48";
}
.i-clock-nine:before {
  content: "\ea49";
}
.i-clock-seven:before {
  content: "\ea4a";
}
.i-clock-ten:before {
  content: "\ea4b";
}
.i-clock-three:before {
  content: "\ea4c";
}
.i-clock-two:before {
  content: "\ea4d";
}
.i-clock:before {
  content: "\ea4e";
}
.i-closed-captioning:before {
  content: "\ea4f";
}
.i-cloud-block:before {
  content: "\ea50";
}
.i-cloud-bookmark:before {
  content: "\ea51";
}
.i-cloud-check:before {
  content: "\ea52";
}
.i-cloud-computing:before {
  content: "\ea53";
}
.i-cloud-data-connection:before {
  content: "\ea54";
}
.i-cloud-database-tree:before {
  content: "\ea55";
}
.i-cloud-download:before {
  content: "\ea56";
}
.i-cloud-drizzle:before {
  content: "\ea57";
}
.i-cloud-exclamation:before {
  content: "\ea58";
}
.i-cloud-hail:before {
  content: "\ea59";
}
.i-cloud-heart:before {
  content: "\ea5a";
}
.i-cloud-info:before {
  content: "\ea5b";
}
.i-cloud-lock:before {
  content: "\ea5c";
}
.i-cloud-meatball:before {
  content: "\ea5d";
}
.i-cloud-moon-hail:before {
  content: "\ea5e";
}
.i-cloud-moon-meatball:before {
  content: "\ea5f";
}
.i-cloud-moon-rain:before {
  content: "\ea60";
}
.i-cloud-moon-showers:before {
  content: "\ea61";
}
.i-cloud-moon:before {
  content: "\ea62";
}
.i-cloud-question:before {
  content: "\ea63";
}
.i-cloud-rain-sun:before {
  content: "\ea64";
}
.i-cloud-rain:before {
  content: "\ea65";
}
.i-cloud-redo:before {
  content: "\ea66";
}
.i-cloud-set:before {
  content: "\ea67";
}
.i-cloud-share:before {
  content: "\ea68";
}
.i-cloud-shield:before {
  content: "\ea69";
}
.i-cloud-showers-alt:before {
  content: "\ea6a";
}
.i-cloud-showers-heavy:before {
  content: "\ea6b";
}
.i-cloud-showers:before {
  content: "\ea6c";
}
.i-cloud-slash:before {
  content: "\ea6d";
}
.i-cloud-sun-hail:before {
  content: "\ea6e";
}
.i-cloud-sun-meatball:before {
  content: "\ea6f";
}
.i-cloud-sun-rain-alt:before {
  content: "\ea70";
}
.i-cloud-sun-rain:before {
  content: "\ea71";
}
.i-cloud-sun-tear:before {
  content: "\ea72";
}
.i-cloud-sun:before {
  content: "\ea73";
}
.i-cloud-times:before {
  content: "\ea74";
}
.i-cloud-unlock:before {
  content: "\ea75";
}
.i-cloud-upload:before {
  content: "\ea76";
}
.i-cloud-wifi:before {
  content: "\ea77";
}
.i-cloud-wind:before {
  content: "\ea78";
}
.i-cloud:before {
  content: "\ea79";
}
.i-clouds:before {
  content: "\ea7a";
}
.i-club:before {
  content: "\ea7b";
}
.i-code1:before {
  content: "\ea7c";
}
.i-coffee:before {
  content: "\ea7d";
}
.i-cog:before {
  content: "\ea7e";
}
.i-coins1:before {
  content: "\ea7f";
}
.i-columns:before {
  content: "\ea80";
}
.i-comment-alt-block:before {
  content: "\ea81";
}
.i-comment-alt-chart-lines:before {
  content: "\ea82";
}
.i-comment-alt-check:before {
  content: "\ea83";
}
.i-comment-alt-dots:before {
  content: "\ea84";
}
.i-comment-alt-download:before {
  content: "\ea85";
}
.i-comment-alt-edit:before {
  content: "\ea86";
}
.i-comment-alt-exclamation:before {
  content: "\ea87";
}
.i-comment-alt-heart:before {
  content: "\ea88";
}
.i-comment-alt-image:before {
  content: "\ea89";
}
.i-comment-alt-info:before {
  content: "\ea8a";
}
.i-comment-alt-lines:before {
  content: "\ea8b";
}
.i-comment-alt-lock:before {
  content: "\ea8c";
}
.i-comment-alt-medical:before {
  content: "\ea8d";
}
.i-comment-alt-message:before {
  content: "\ea8e";
}
.i-comment-alt-notes:before {
  content: "\ea8f";
}
.i-comment-alt-plus:before {
  content: "\ea90";
}
.i-comment-alt-question:before {
  content: "\ea91";
}
.i-comment-alt-redo:before {
  content: "\ea92";
}
.i-comment-alt-search:before {
  content: "\ea93";
}
.i-comment-alt-share:before {
  content: "\ea94";
}
.i-comment-alt-shield:before {
  content: "\ea95";
}
.i-comment-alt-slash:before {
  content: "\ea96";
}
.i-comment-alt-upload:before {
  content: "\ea97";
}
.i-comment-alt-verify:before {
  content: "\ea98";
}
.i-comment-alt:before {
  content: "\ea99";
}
.i-comment-block:before {
  content: "\ea9a";
}
.i-comment-chart-line:before {
  content: "\ea9b";
}
.i-comment-check:before {
  content: "\ea9c";
}
.i-comment-dots:before {
  content: "\ea9d";
}
.i-comment-download:before {
  content: "\ea9e";
}
.i-comment-edit:before {
  content: "\ea9f";
}
.i-comment-exclamation:before {
  content: "\eaa0";
}
.i-comment-heart:before {
  content: "\eaa1";
}
.i-comment-image:before {
  content: "\eaa2";
}
.i-comment-info-alt:before {
  content: "\eaa3";
}
.i-comment-info:before {
  content: "\eaa4";
}
.i-comment-lines:before {
  content: "\eaa5";
}
.i-comment-lock:before {
  content: "\eaa6";
}
.i-comment-medical:before {
  content: "\eaa7";
}
.i-comment-message:before {
  content: "\eaa8";
}
.i-comment-notes:before {
  content: "\eaa9";
}
.i-comment-plus:before {
  content: "\eaaa";
}
.i-comment-question:before {
  content: "\eaab";
}
.i-comment-redo:before {
  content: "\eaac";
}
.i-comment-search:before {
  content: "\eaad";
}
.i-comment-share:before {
  content: "\eaae";
}
.i-comment-shield:before {
  content: "\eaaf";
}
.i-comment-slash:before {
  content: "\eab0";
}
.i-comment-upload:before {
  content: "\eab1";
}
.i-comment-verify:before {
  content: "\eab2";
}
.i-comment:before {
  content: "\eab3";
}
.i-comments-alt:before {
  content: "\eab4";
}
.i-comments:before {
  content: "\eab5";
}
.i-commnet-alt-slash:before {
  content: "\eab6";
}
.i-compact-disc:before {
  content: "\eab7";
}
.i-compass:before {
  content: "\eab8";
}
.i-compress-alt-left:before {
  content: "\eab9";
}
.i-compress-alt:before {
  content: "\eaba";
}
.i-compress-arrows:before {
  content: "\eabb";
}
.i-compress-lines:before {
  content: "\eabc";
}
.i-compress-point:before {
  content: "\eabd";
}
.i-compress-v:before {
  content: "\eabe";
}
.i-compress:before {
  content: "\eabf";
}
.i-computer-mouse:before {
  content: "\eac0";
}
.i-confused:before {
  content: "\eac1";
}
.i-constructor:before {
  content: "\eac2";
}
.i-copy-alt:before {
  content: "\eac3";
}
.i-copy-landscape:before {
  content: "\eac4";
}
.i-copy:before {
  content: "\eac5";
}
.i-copyright:before {
  content: "\eac6";
}
.i-corner-down-left:before {
  content: "\eac7";
}
.i-corner-down-right-alt:before {
  content: "\eac8";
}
.i-corner-down-right:before {
  content: "\eac9";
}
.i-corner-left-down:before {
  content: "\eaca";
}
.i-corner-right-down:before {
  content: "\eacb";
}
.i-corner-up-left-alt:before {
  content: "\eacc";
}
.i-corner-up-left:before {
  content: "\eacd";
}
.i-corner-up-right-alt:before {
  content: "\eace";
}
.i-corner-up-right:before {
  content: "\eacf";
}
.i-creative-commons-pd-alt:before {
  content: "\ead0";
}
.i-creative-commons-pd:before {
  content: "\ead1";
}
.i-crockery:before {
  content: "\ead2";
}
.i-crop-alt-rotate-left:before {
  content: "\ead3";
}
.i-crop-alt-rotate-right:before {
  content: "\ead4";
}
.i-crop-alt:before {
  content: "\ead5";
}
.i-crosshair-alt:before {
  content: "\ead6";
}
.i-crosshair:before {
  content: "\ead7";
}
.i-crosshairs:before {
  content: "\ead8";
}
.i-cube:before {
  content: "\ead9";
}
.i-data-sharing:before {
  content: "\eada";
}
.i-database-alt:before {
  content: "\eadb";
}
.i-database:before {
  content: "\eadc";
}
.i-desert:before {
  content: "\eadd";
}
.i-desktop-alt-slash:before {
  content: "\eade";
}
.i-desktop-alt:before {
  content: "\eadf";
}
.i-desktop-cloud-alt:before {
  content: "\eae0";
}
.i-desktop-slash:before {
  content: "\eae1";
}
.i-desktop:before {
  content: "\eae2";
}
.i-dialpad-alt:before {
  content: "\eae3";
}
.i-dialpad:before {
  content: "\eae4";
}
.i-diamond:before {
  content: "\eae5";
}
.i-diary-alt:before {
  content: "\eae6";
}
.i-diary:before {
  content: "\eae7";
}
.i-dice-five:before {
  content: "\eae8";
}
.i-dice-four:before {
  content: "\eae9";
}
.i-dice-one:before {
  content: "\eaea";
}
.i-dice-six:before {
  content: "\eaeb";
}
.i-dice-three:before {
  content: "\eaec";
}
.i-dice-two:before {
  content: "\eaed";
}
.i-direction:before {
  content: "\eaee";
}
.i-directions:before {
  content: "\eaef";
}
.i-dizzy-meh:before {
  content: "\eaf0";
}
.i-dna:before {
  content: "\eaf1";
}
.i-document-layout-center:before {
  content: "\eaf2";
}
.i-document-layout-left:before {
  content: "\eaf3";
}
.i-document-layout-right:before {
  content: "\eaf4";
}
.i-document:before {
  content: "\eaf5";
}
.i-dollar-alt:before {
  content: "\eaf6";
}
.i-dollar-sign-alt:before {
  content: "\eaf7";
}
.i-dollar-sign:before {
  content: "\eaf8";
}
.i-down-arrow:before {
  content: "\eaf9";
}
.i-download-alt:before {
  content: "\eafa";
}
.i-dribbble:before {
  content: "\eafb";
}
.i-drill:before {
  content: "\eafc";
}
.i-dropbox:before {
  content: "\eafd";
}
.i-dumbbell:before {
  content: "\eafe";
}
.i-edit-alt:before {
  content: "\eaff";
}
.i-edit:before {
  content: "\eb00";
}
.i-ellipsis-h:before {
  content: "\eb01";
}
.i-ellipsis-v:before {
  content: "\eb02";
}
.i-emoji:before {
  content: "\eb03";
}
.i-enter:before {
  content: "\eb04";
}
.i-entry:before {
  content: "\eb05";
}
.i-envelope-add:before {
  content: "\eb06";
}
.i-envelope-alt:before {
  content: "\eb07";
}
.i-envelope-block:before {
  content: "\eb08";
}
.i-envelope-bookmark:before {
  content: "\eb09";
}
.i-envelope-check:before {
  content: "\eb0a";
}
.i-envelope-download-alt:before {
  content: "\eb0b";
}
.i-envelope-download:before {
  content: "\eb0c";
}
.i-envelope-edit:before {
  content: "\eb0d";
}
.i-envelope-exclamation:before {
  content: "\eb0e";
}
.i-envelope-heart:before {
  content: "\eb0f";
}
.i-envelope-info:before {
  content: "\eb10";
}
.i-envelope-lock:before {
  content: "\eb11";
}
.i-envelope-minus:before {
  content: "\eb12";
}
.i-envelope-open:before {
  content: "\eb13";
}
.i-envelope-question:before {
  content: "\eb14";
}
.i-envelope-receive:before {
  content: "\eb15";
}
.i-envelope-redo:before {
  content: "\eb16";
}
.i-envelope-search:before {
  content: "\eb17";
}
.i-envelope-send:before {
  content: "\eb18";
}
.i-envelope-share:before {
  content: "\eb19";
}
.i-envelope-shield:before {
  content: "\eb1a";
}
.i-envelope-star:before {
  content: "\eb1b";
}
.i-envelope-times:before {
  content: "\eb1c";
}
.i-envelope-upload-alt:before {
  content: "\eb1d";
}
.i-envelope-upload:before {
  content: "\eb1e";
}
.i-envelope:before {
  content: "\eb1f";
}
.i-envelopes:before {
  content: "\eb20";
}
.i-equal-circle:before {
  content: "\eb21";
}
.i-euro-circle:before {
  content: "\eb22";
}
.i-euro:before {
  content: "\eb23";
}
.i-exchange-alt:before {
  content: "\eb24";
}
.i-exchange:before {
  content: "\eb25";
}
.i-exclamation-circle:before {
  content: "\eb26";
}
.i-exclamation-octagon:before {
  content: "\eb27";
}
.i-exclamation-triangle:before {
  content: "\eb28";
}
.i-exclude:before {
  content: "\eb29";
}
.i-exit:before {
  content: "\eb2a";
}
.i-expand-alt:before {
  content: "\eb2b";
}
.i-expand-arrows-alt:before {
  content: "\eb2c";
}
.i-expand-arrows:before {
  content: "\eb2d";
}
.i-expand-from-corner:before {
  content: "\eb2e";
}
.i-expand-left:before {
  content: "\eb2f";
}
.i-expand-right:before {
  content: "\eb30";
}
.i-export:before {
  content: "\eb31";
}
.i-exposure-alt:before {
  content: "\eb32";
}
.i-exposure-increase:before {
  content: "\eb33";
}
.i-external-link-alt:before {
  content: "\eb34";
}
.i-eye-slash:before {
  content: "\eb35";
}
.i-eye:before {
  content: "\eb36";
}
.i-facebook-f:before {
  content: "\eb37";
}
.i-facebook-messenger:before {
  content: "\eb38";
}
.i-facebook:before {
  content: "\eb39";
}
.i-fahrenheit:before {
  content: "\eb3a";
}
.i-fast-mail-alt:before {
  content: "\eb3b";
}
.i-fast-mail:before {
  content: "\eb3c";
}
.i-favorite:before {
  content: "\eb3d";
}
.i-feedback:before {
  content: "\eb3e";
}
.i-file-alt:before {
  content: "\eb3f";
}
.i-file-blank:before {
  content: "\eb40";
}
.i-file-block-alt:before {
  content: "\eb41";
}
.i-file-bookmark-alt:before {
  content: "\eb42";
}
.i-file-check-alt:before {
  content: "\eb43";
}
.i-file-check:before {
  content: "\eb44";
}
.i-file-contract-dollar:before {
  content: "\eb45";
}
.i-file-copy-alt:before {
  content: "\eb46";
}
.i-file-download-alt:before {
  content: "\eb47";
}
.i-file-download:before {
  content: "\eb48";
}
.i-file-edit-alt:before {
  content: "\eb49";
}
.i-file-exclamation-alt:before {
  content: "\eb4a";
}
.i-file-exclamation:before {
  content: "\eb4b";
}
.i-file-heart:before {
  content: "\eb4c";
}
.i-file-info-alt:before {
  content: "\eb4d";
}
.i-file-landscape-alt:before {
  content: "\eb4e";
}
.i-file-landscape:before {
  content: "\eb4f";
}
.i-file-lanscape-slash:before {
  content: "\eb50";
}
.i-file-lock-alt:before {
  content: "\eb51";
}
.i-file-medical-alt:before {
  content: "\eb52";
}
.i-file-medical:before {
  content: "\eb53";
}
.i-file-minus-alt:before {
  content: "\eb54";
}
.i-file-minus:before {
  content: "\eb55";
}
.i-file-network:before {
  content: "\eb56";
}
.i-file-plus-alt:before {
  content: "\eb57";
}
.i-file-plus:before {
  content: "\eb58";
}
.i-file-question-alt:before {
  content: "\eb59";
}
.i-file-question:before {
  content: "\eb5a";
}
.i-file-redo-alt:before {
  content: "\eb5b";
}
.i-file-search-alt:before {
  content: "\eb5c";
}
.i-file-share-alt:before {
  content: "\eb5d";
}
.i-file-shield-alt:before {
  content: "\eb5e";
}
.i-file-slash:before {
  content: "\eb5f";
}
.i-file-times-alt:before {
  content: "\eb60";
}
.i-file-times:before {
  content: "\eb61";
}
.i-file-upload-alt:before {
  content: "\eb62";
}
.i-file-upload:before {
  content: "\eb63";
}
.i-file:before {
  content: "\eb64";
}
.i-files-landscapes-alt:before {
  content: "\eb65";
}
.i-files-landscapes:before {
  content: "\eb66";
}
.i-film:before {
  content: "\eb67";
}
.i-filter-slash:before {
  content: "\eb68";
}
.i-filter:before {
  content: "\eb69";
}
.i-flask-potion:before {
  content: "\eb6a";
}
.i-flask:before {
  content: "\eb6b";
}
.i-flip-h-alt:before {
  content: "\eb6c";
}
.i-flip-h:before {
  content: "\eb6d";
}
.i-flip-v-alt:before {
  content: "\eb6e";
}
.i-flip-v:before {
  content: "\eb6f";
}
.i-flower:before {
  content: "\eb70";
}
.i-focus-add:before {
  content: "\eb71";
}
.i-focus-target:before {
  content: "\eb72";
}
.i-focus:before {
  content: "\eb73";
}
.i-folder-check:before {
  content: "\eb74";
}
.i-folder-download:before {
  content: "\eb75";
}
.i-folder-exclamation:before {
  content: "\eb76";
}
.i-folder-heart:before {
  content: "\eb77";
}
.i-folder-info:before {
  content: "\eb78";
}
.i-folder-lock:before {
  content: "\eb79";
}
.i-folder-medical:before {
  content: "\eb7a";
}
.i-folder-minus:before {
  content: "\eb7b";
}
.i-folder-network:before {
  content: "\eb7c";
}
.i-folder-plus:before {
  content: "\eb7d";
}
.i-folder-question:before {
  content: "\eb7e";
}
.i-folder-slash:before {
  content: "\eb7f";
}
.i-folder-times:before {
  content: "\eb80";
}
.i-folder-upload:before {
  content: "\eb81";
}
.i-folder:before {
  content: "\eb82";
}
.i-food:before {
  content: "\eb83";
}
.i-football-american:before {
  content: "\eb84";
}
.i-football-ball:before {
  content: "\eb85";
}
.i-football:before {
  content: "\eb86";
}
.i-forecastcloud-moon-tear:before {
  content: "\eb87";
}
.i-forwaded-call:before {
  content: "\eb88";
}
.i-forward:before {
  content: "\eb89";
}
.i-frown:before {
  content: "\eb8a";
}
.i-game-structure:before {
  content: "\eb8b";
}
.i-game:before {
  content: "\eb8c";
}
.i-gift:before {
  content: "\eb8d";
}
.i-glass-martini-alt-slash:before {
  content: "\eb8e";
}
.i-glass-martini-alt:before {
  content: "\eb8f";
}
.i-glass-martini:before {
  content: "\eb90";
}
.i-glass-tea:before {
  content: "\eb91";
}
.i-glass:before {
  content: "\eb92";
}
.i-globe:before {
  content: "\eb93";
}
.i-gold:before {
  content: "\eb94";
}
.i-google-drive:before {
  content: "\eb95";
}
.i-graduation-hat:before {
  content: "\eb96";
}
.i-graph-bar:before {
  content: "\eb97";
}
.i-grid:before {
  content: "\eb98";
}
.i-grids:before {
  content: "\eb99";
}
.i-grin-tongue-wink-alt:before {
  content: "\eb9a";
}
.i-grin-tongue-wink:before {
  content: "\eb9b";
}
.i-grin:before {
  content: "\eb9c";
}
.i-grip-horizontal-line:before {
  content: "\eb9d";
}
.i-hdd:before {
  content: "\eb9e";
}
.i-headphones-alt:before {
  content: "\eb9f";
}
.i-headphones:before {
  content: "\eba0";
}
.i-heart-alt:before {
  content: "\eba1";
}
.i-heart-medical:before {
  content: "\eba2";
}
.i-heart-rate:before {
  content: "\eba3";
}
.i-heart-sign:before {
  content: "\eba4";
}
.i-heart:before {
  content: "\eba5";
}
.i-heartbeat:before {
  content: "\eba6";
}
.i-history-alt:before {
  content: "\eba7";
}
.i-history:before {
  content: "\eba8";
}
.i-home-alt:before {
  content: "\eba9";
}
.i-home2:before {
  content: "\ebaa";
}
.i-horizontal-align-center:before {
  content: "\ebab";
}
.i-horizontal-align-left:before {
  content: "\ebac";
}
.i-horizontal-align-right:before {
  content: "\ebad";
}
.i-horizontal-distribution-center:before {
  content: "\ebae";
}
.i-horizontal-distribution-left:before {
  content: "\ebaf";
}
.i-horizontal-distribution-right:before {
  content: "\ebb0";
}
.i-hunting:before {
  content: "\ebb1";
}
.i-image-alt-slash:before {
  content: "\ebb2";
}
.i-image-block:before {
  content: "\ebb3";
}
.i-image-broken:before {
  content: "\ebb4";
}
.i-image-check:before {
  content: "\ebb5";
}
.i-image-download:before {
  content: "\ebb6";
}
.i-image-edit:before {
  content: "\ebb7";
}
.i-image-lock:before {
  content: "\ebb8";
}
.i-image-minus:before {
  content: "\ebb9";
}
.i-image-plus:before {
  content: "\ebba";
}
.i-image-question:before {
  content: "\ebbb";
}
.i-image-redo:before {
  content: "\ebbc";
}
.i-image-resize-landscape:before {
  content: "\ebbd";
}
.i-image-resize-square:before {
  content: "\ebbe";
}
.i-image-search:before {
  content: "\ebbf";
}
.i-image-share:before {
  content: "\ebc0";
}
.i-image-shield:before {
  content: "\ebc1";
}
.i-image-slash:before {
  content: "\ebc2";
}
.i-image-times:before {
  content: "\ebc3";
}
.i-image-upload:before {
  content: "\ebc4";
}
.i-image-v:before {
  content: "\ebc5";
}
.i-image1:before {
  content: "\ebc6";
}
.i-images:before {
  content: "\ebc7";
}
.i-incoming-call:before {
  content: "\ebc8";
}
.i-info-circle:before {
  content: "\ebc9";
}
.i-instagram-alt:before {
  content: "\ebca";
}
.i-instagram:before {
  content: "\ebcb";
}
.i-intercom:before {
  content: "\ebcc";
}
.i-invoice:before {
  content: "\ebcd";
}
.i-italic:before {
  content: "\ebce";
}
.i-jackhammer:before {
  content: "\ebcf";
}
.i-kayak:before {
  content: "\ebd0";
}
.i-key-skeleton-alt:before {
  content: "\ebd1";
}
.i-key-skeleton:before {
  content: "\ebd2";
}
.i-keyboard-alt:before {
  content: "\ebd3";
}
.i-keyboard-hide:before {
  content: "\ebd4";
}
.i-keyboard-show:before {
  content: "\ebd5";
}
.i-keyboard:before {
  content: "\ebd6";
}
.i-keyhole-circle:before {
  content: "\ebd7";
}
.i-keyhole-square-full:before {
  content: "\ebd8";
}
.i-keyhole-square:before {
  content: "\ebd9";
}
.i-kid:before {
  content: "\ebda";
}
.i-label-alt:before {
  content: "\ebdb";
}
.i-label:before {
  content: "\ebdc";
}
.i-lamp:before {
  content: "\ebdd";
}
.i-laptop-cloud:before {
  content: "\ebde";
}
.i-laptop:before {
  content: "\ebdf";
}
.i-laughing:before {
  content: "\ebe0";
}
.i-layer-group-slash:before {
  content: "\ebe1";
}
.i-layer-group:before {
  content: "\ebe2";
}
.i-layers-alt:before {
  content: "\ebe3";
}
.i-layers-slash:before {
  content: "\ebe4";
}
.i-layers:before {
  content: "\ebe5";
}
.i-left-arrow-from-left:before {
  content: "\ebe6";
}
.i-left-arrow-to-left:before {
  content: "\ebe7";
}
.i-left-indent-alt:before {
  content: "\ebe8";
}
.i-left-indent:before {
  content: "\ebe9";
}
.i-left-to-right-text-direction:before {
  content: "\ebea";
}
.i-life-ring:before {
  content: "\ebeb";
}
.i-lightbulb-alt:before {
  content: "\ebec";
}
.i-lightbulb:before {
  content: "\ebed";
}
.i-line-alt:before {
  content: "\ebee";
}
.i-line-spacing:before {
  content: "\ebef";
}
.i-line:before {
  content: "\ebf0";
}
.i-link-alt:before {
  content: "\ebf1";
}
.i-link-broken:before {
  content: "\ebf2";
}
.i-link-h:before {
  content: "\ebf3";
}
.i-link:before {
  content: "\ebf4";
}
.i-linkedin:before {
  content: "\ebf5";
}
.i-list-ui-alt:before {
  content: "\ebf6";
}
.i-list-ul:before {
  content: "\ebf7";
}
.i-location-arrow-alt:before {
  content: "\ebf8";
}
.i-location-arrow .path1:before {
  content: "\ebf9";
  color: rgb(255, 255, 255);
}
.i-location-arrow .path2:before {
  content: "\ebfa";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.i-location-pin-alt:before {
  content: "\ebfb";
}
.i-location-point:before {
  content: "\ebfc";
}
.i-location:before {
  content: "\ebfd";
}
.i-lock-access:before {
  content: "\ebfe";
}
.i-lock-alt:before {
  content: "\ebff";
}
.i-lock-open-alt:before {
  content: "\ec00";
}
.i-lock-slash:before {
  content: "\ec01";
}
.i-lock:before {
  content: "\ec02";
}
.i-mailbox-alt:before {
  content: "\ec03";
}
.i-mailbox:before {
  content: "\ec04";
}
.i-map-marker-alt:before {
  content: "\ec05";
}
.i-map-marker-edit:before {
  content: "\ec06";
}
.i-map-marker-info:before {
  content: "\ec07";
}
.i-map-marker-minus:before {
  content: "\ec08";
}
.i-map-marker-plus:before {
  content: "\ec09";
}
.i-map-marker-question:before {
  content: "\ec0a";
}
.i-map-marker-shield:before {
  content: "\ec0b";
}
.i-map-marker-slash:before {
  content: "\ec0c";
}
.i-map-marker:before {
  content: "\ec0d";
}
.i-map-pin-alt:before {
  content: "\ec0e";
}
.i-map-pin:before {
  content: "\ec0f";
}
.i-map:before {
  content: "\ec10";
}
.i-mars:before {
  content: "\ec11";
}
.i-maximize-left:before {
  content: "\ec12";
}
.i-medal:before {
  content: "\ec13";
}
.i-medical-drip:before {
  content: "\ec14";
}
.i-medical-square-full:before {
  content: "\ec15";
}
.i-medical-square:before {
  content: "\ec16";
}
.i-medical:before {
  content: "\ec17";
}
.i-medkit:before {
  content: "\ec18";
}
.i-meeting-board:before {
  content: "\ec19";
}
.i-meh-alt:before {
  content: "\ec1a";
}
.i-meh-closed-eye:before {
  content: "\ec1b";
}
.i-meh:before {
  content: "\ec1c";
}
.i-message:before {
  content: "\ec1d";
}
.i-microphone-slash:before {
  content: "\ec1e";
}
.i-microphone:before {
  content: "\ec1f";
}
.i-minus-circle:before {
  content: "\ec20";
}
.i-minus-path:before {
  content: "\ec21";
}
.i-minus-square-full:before {
  content: "\ec22";
}
.i-minus-square:before {
  content: "\ec23";
}
.i-minus:before {
  content: "\ec24";
}
.i-missed-call:before {
  content: "\ec25";
}
.i-mobey-bill-slash:before {
  content: "\ec26";
}
.i-mobile-android-alt:before {
  content: "\ec27";
}
.i-mobile-android:before {
  content: "\ec28";
}
.i-mobile-vibrate:before {
  content: "\ec29";
}
.i-modem:before {
  content: "\ec2a";
}
.i-money-bill-stack:before {
  content: "\ec2b";
}
.i-money-bill:before {
  content: "\ec2c";
}
.i-money-insert:before {
  content: "\ec2d";
}
.i-money-stack:before {
  content: "\ec2e";
}
.i-money-withdraw:before {
  content: "\ec2f";
}
.i-money-withdrawal:before {
  content: "\ec30";
}
.i-moneybag-alt:before {
  content: "\ec31";
}
.i-moneybag:before {
  content: "\ec32";
}
.i-monitor-heart-rate:before {
  content: "\ec33";
}
.i-monitor:before {
  content: "\ec34";
}
.i-moon-eclipse:before {
  content: "\ec35";
}
.i-moon:before {
  content: "\ec36";
}
.i-moonset:before {
  content: "\ec37";
}
.i-mountains-sun:before {
  content: "\ec38";
}
.i-mountains:before {
  content: "\ec39";
}
.i-mouse-alt:before {
  content: "\ec3a";
}
.i-mouse:before {
  content: "\ec3b";
}
.i-multiply:before {
  content: "\ec3c";
}
.i-music-note:before {
  content: "\ec3d";
}
.i-music-tune-slash:before {
  content: "\ec3e";
}
.i-music1:before {
  content: "\ec3f";
}
.i-n-a:before {
  content: "\ec40";
}
.i-navigator:before {
  content: "\ec41";
}
.i-nerd:before {
  content: "\ec42";
}
.i-newspaper:before {
  content: "\ec43";
}
.i-ninja:before {
  content: "\ec44";
}
.i-no-entry:before {
  content: "\ec45";
}
.i-notebooks:before {
  content: "\ec46";
}
.i-notes:before {
  content: "\ec47";
}
.i-object-group:before {
  content: "\ec48";
}
.i-object-ungroup:before {
  content: "\ec49";
}
.i-octagon:before {
  content: "\ec4a";
}
.i-outgoing-call:before {
  content: "\ec4b";
}
.i-package:before {
  content: "\ec4c";
}
.i-padlock:before {
  content: "\ec4d";
}
.i-paint-tool:before {
  content: "\ec4e";
}
.i-palette:before {
  content: "\ec4f";
}
.i-panorama-h-alt:before {
  content: "\ec50";
}
.i-panorama-h:before {
  content: "\ec51";
}
.i-panorama-v:before {
  content: "\ec52";
}
.i-paperclip:before {
  content: "\ec53";
}
.i-paragraph:before {
  content: "\ec54";
}
.i-parcel:before {
  content: "\ec55";
}
.i-parking-square:before {
  content: "\ec56";
}
.i-pathfinder-unite:before {
  content: "\ec57";
}
.i-pathfinder:before {
  content: "\ec58";
}
.i-pause-circle:before {
  content: "\ec59";
}
.i-pause:before {
  content: "\ec5a";
}
.i-pen:before {
  content: "\ec5b";
}
.i-pentagon:before {
  content: "\ec5c";
}
.i-phone-alt:before {
  content: "\ec5d";
}
.i-phone-pause:before {
  content: "\ec5e";
}
.i-phone-slash:before {
  content: "\ec5f";
}
.i-phone-times:before {
  content: "\ec60";
}
.i-phone-volume:before {
  content: "\ec61";
}
.i-phone1:before {
  content: "\ec62";
}
.i-picture:before {
  content: "\ec63";
}
.i-plane-arrival:before {
  content: "\ec64";
}
.i-plane-departure:before {
  content: "\ec65";
}
.i-plane-fly:before {
  content: "\ec66";
}
.i-plane:before {
  content: "\ec67";
}
.i-play-circle:before {
  content: "\ec68";
}
.i-play:before {
  content: "\ec69";
}
.i-plug:before {
  content: "\ec6a";
}
.i-plus-circle:before {
  content: "\ec6b";
}
.i-plus-square:before {
  content: "\ec6c";
}
.i-plus:before {
  content: "\ec6d";
}
.i-podium:before {
  content: "\ec6e";
}
.i-polygon:before {
  content: "\ec6f";
}
.i-post-stamp:before {
  content: "\ec70";
}
.i-postcard:before {
  content: "\ec71";
}
.i-pound-circle:before {
  content: "\ec72";
}
.i-pound:before {
  content: "\ec73";
}
.i-power:before {
  content: "\ec74";
}
.i-prescription-bottle:before {
  content: "\ec75";
}
.i-presentation-check:before {
  content: "\ec76";
}
.i-presentation-edit:before {
  content: "\ec77";
}
.i-presentation-line:before {
  content: "\ec78";
}
.i-presentation-lines-alt:before {
  content: "\ec79";
}
.i-presentation-minus:before {
  content: "\ec7a";
}
.i-presentation-play:before {
  content: "\ec7b";
}
.i-presentation-plus:before {
  content: "\ec7c";
}
.i-presentation-times:before {
  content: "\ec7d";
}
.i-presentation:before {
  content: "\ec7e";
}
.i-previous:before {
  content: "\ec7f";
}
.i-pricetag-alt:before {
  content: "\ec80";
}
.i-print-slash:before {
  content: "\ec81";
}
.i-print:before {
  content: "\ec82";
}
.i-processor:before {
  content: "\ec83";
}
.i-pump:before {
  content: "\ec84";
}
.i-puzzle-piece:before {
  content: "\ec85";
}
.i-question-circle:before {
  content: "\ec86";
}
.i-rainbow:before {
  content: "\ec87";
}
.i-raindrops-alt:before {
  content: "\ec88";
}
.i-raindrops:before {
  content: "\ec89";
}
.i-receipt-alt:before {
  content: "\ec8a";
}
.i-receipt:before {
  content: "\ec8b";
}
.i-record-audio:before {
  content: "\ec8c";
}
.i-redo:before {
  content: "\ec8d";
}
.i-refresh:before {
  content: "\ec8e";
}
.i-registered:before {
  content: "\ec8f";
}
.i-repeat:before {
  content: "\ec90";
}
.i-restaurant:before {
  content: "\ec91";
}
.i-right-indent-alt:before {
  content: "\ec92";
}
.i-right-to-left-text-direction:before {
  content: "\ec93";
}
.i-robot:before {
  content: "\ec94";
}
.i-rope-way:before {
  content: "\ec95";
}
.i-rotate-360:before {
  content: "\ec96";
}
.i-rss-alt:before {
  content: "\ec97";
}
.i-rss-interface:before {
  content: "\ec98";
}
.i-rss:before {
  content: "\ec99";
}
.i-ruler-combined:before {
  content: "\ec9a";
}
.i-ruler:before {
  content: "\ec9b";
}
.i-sad-cry:before {
  content: "\ec9c";
}
.i-sad-crying:before {
  content: "\ec9d";
}
.i-sad-dizzy:before {
  content: "\ec9e";
}
.i-sad-squint:before {
  content: "\ec9f";
}
.i-sad1:before {
  content: "\eca0";
}
.i-scaling-left:before {
  content: "\eca1";
}
.i-scaling-right:before {
  content: "\eca2";
}
.i-scenery:before {
  content: "\eca3";
}
.i-schedule:before {
  content: "\eca4";
}
.i-science:before {
  content: "\eca5";
}
.i-screw:before {
  content: "\eca6";
}
.i-scroll-h:before {
  content: "\eca7";
}
.i-scroll:before {
  content: "\eca8";
}
.i-search-alt:before {
  content: "\eca9";
}
.i-search-minus:before {
  content: "\ecaa";
}
.i-search-plus:before {
  content: "\ecab";
}
.i-search:before {
  content: "\ecac";
}
.i-selfie:before {
  content: "\ecad";
}
.i-server-alt:before {
  content: "\ecae";
}
.i-server-connection:before {
  content: "\ecaf";
}
.i-server-network-alt:before {
  content: "\ecb0";
}
.i-server-network:before {
  content: "\ecb1";
}
.i-server:before {
  content: "\ecb2";
}
.i-servers:before {
  content: "\ecb3";
}
.i-servicemark:before {
  content: "\ecb4";
}
.i-share-alt:before {
  content: "\ecb5";
}
.i-shield-check:before {
  content: "\ecb6";
}
.i-shield-exclamation:before {
  content: "\ecb7";
}
.i-shield-question:before {
  content: "\ecb8";
}
.i-shield-slash:before {
  content: "\ecb9";
}
.i-shield:before {
  content: "\ecba";
}
.i-ship:before {
  content: "\ecbb";
}
.i-shop:before {
  content: "\ecbc";
}
.i-shopping-basket:before {
  content: "\ecbd";
}
.i-shopping-cart-alt:before {
  content: "\ecbe";
}
.i-shopping-trolley:before {
  content: "\ecbf";
}
.i-shovel:before {
  content: "\ecc0";
}
.i-shrink:before {
  content: "\ecc1";
}
.i-shuffle:before {
  content: "\ecc2";
}
.i-shutter-alt:before {
  content: "\ecc3";
}
.i-shutter:before {
  content: "\ecc4";
}
.i-sick:before {
  content: "\ecc5";
}
.i-sigma:before {
  content: "\ecc6";
}
.i-sign-alt:before {
  content: "\ecc7";
}
.i-sign-in-alt:before {
  content: "\ecc8";
}
.i-sign-left:before {
  content: "\ecc9";
}
.i-sign-out-alt:before {
  content: "\ecca";
}
.i-sign-right:before {
  content: "\eccb";
}
.i-signal-alt-3:before {
  content: "\eccc";
}
.i-signal-alt:before {
  content: "\eccd";
}
.i-signal:before {
  content: "\ecce";
}
.i-silence:before {
  content: "\eccf";
}
.i-silent-squint:before {
  content: "\ecd0";
}
.i-sim-card:before {
  content: "\ecd1";
}
.i-sitemap:before {
  content: "\ecd2";
}
.i-skip-forward-alt:before {
  content: "\ecd3";
}
.i-skip-forward-circle:before {
  content: "\ecd4";
}
.i-skip-forward:before {
  content: "\ecd5";
}
.i-slack:before {
  content: "\ecd6";
}
.i-sliders-v-alt:before {
  content: "\ecd7";
}
.i-sliders-v:before {
  content: "\ecd8";
}
.i-smile-beam:before {
  content: "\ecd9";
}
.i-smile-dizzy:before {
  content: "\ecda";
}
.i-smile-squint-wink-alt:before {
  content: "\ecdb";
}
.i-smile-squint-wink:before {
  content: "\ecdc";
}
.i-smile-wink-alt:before {
  content: "\ecdd";
}
.i-smile-wink:before {
  content: "\ecde";
}
.i-smile:before {
  content: "\ecdf";
}
.i-snow-flake:before {
  content: "\ece0";
}
.i-snowflake-alt:before {
  content: "\ece1";
}
.i-snowflake:before {
  content: "\ece2";
}
.i-sort-amount-down:before {
  content: "\ece3";
}
.i-sort-amount-up:before {
  content: "\ece4";
}
.i-sort:before {
  content: "\ece5";
}
.i-sorting:before {
  content: "\ece6";
}
.i-space-key:before {
  content: "\ece7";
}
.i-spade:before {
  content: "\ece8";
}
.i-sperms:before {
  content: "\ece9";
}
.i-spin:before {
  content: "\ecea";
}
.i-sport:before {
  content: "\eceb";
}
.i-square-full:before {
  content: "\ecec";
}
.i-square-shape:before {
  content: "\eced";
}
.i-square:before {
  content: "\ecee";
}
.i-squint:before {
  content: "\ecef";
}
.i-star-half-alt:before {
  content: "\ecf0";
}
.i-star:before {
  content: "\ecf1";
}
.i-step-backward-alt:before {
  content: "\ecf2";
}
.i-step-backward-circle:before {
  content: "\ecf3";
}
.i-step-backward:before {
  content: "\ecf4";
}
.i-step-forward:before {
  content: "\ecf5";
}
.i-stop-circle:before {
  content: "\ecf6";
}
.i-stopwatch-slash:before {
  content: "\ecf7";
}
.i-stopwatch:before {
  content: "\ecf8";
}
.i-store-alt:before {
  content: "\ecf9";
}
.i-store1:before {
  content: "\ecfa";
}
.i-streering:before {
  content: "\ecfb";
}
.i-stretcher:before {
  content: "\ecfc";
}
.i-subject:before {
  content: "\ecfd";
}
.i-subway-alt:before {
  content: "\ecfe";
}
.i-subway:before {
  content: "\ecff";
}
.i-suitcase-alt:before {
  content: "\ed00";
}
.i-suitcase:before {
  content: "\ed01";
}
.i-sun:before {
  content: "\ed02";
}
.i-sunset:before {
  content: "\ed03";
}
.i-surprise:before {
  content: "\ed04";
}
.i-swatchbook:before {
  content: "\ed05";
}
.i-swimmer:before {
  content: "\ed06";
}
.i-symbol:before {
  content: "\ed07";
}
.i-sync-exclamation:before {
  content: "\ed08";
}
.i-sync-slash:before {
  content: "\ed09";
}
.i-sync:before {
  content: "\ed0a";
}
.i-syringe:before {
  content: "\ed0b";
}
.i-table:before {
  content: "\ed0c";
}
.i-tablet:before {
  content: "\ed0d";
}
.i-tablets:before {
  content: "\ed0e";
}
.i-tachometer-fast:before {
  content: "\ed0f";
}
.i-tag-alt:before {
  content: "\ed10";
}
.i-tag:before {
  content: "\ed11";
}
.i-tape:before {
  content: "\ed12";
}
.i-taxi:before {
  content: "\ed13";
}
.i-tear:before {
  content: "\ed14";
}
.i-technology:before {
  content: "\ed15";
}
.i-telescope:before {
  content: "\ed16";
}
.i-temperature-empty:before {
  content: "\ed17";
}
.i-temperature-half:before {
  content: "\ed18";
}
.i-temperature-minus:before {
  content: "\ed19";
}
.i-temperature-plus:before {
  content: "\ed1a";
}
.i-temperature-quarter:before {
  content: "\ed1b";
}
.i-temperature-three-quarter:before {
  content: "\ed1c";
}
.i-temperature:before {
  content: "\ed1d";
}
.i-text-fields:before {
  content: "\ed1e";
}
.i-text-size:before {
  content: "\ed1f";
}
.i-text-strike-through:before {
  content: "\ed20";
}
.i-text:before {
  content: "\ed21";
}
.i-th-large:before {
  content: "\ed22";
}
.i-th-slash:before {
  content: "\ed23";
}
.i-th:before {
  content: "\ed24";
}
.i-thermometer:before {
  content: "\ed25";
}
.i-thumbs-down:before {
  content: "\ed26";
}
.i-thumbs-up:before {
  content: "\ed27";
}
.i-thunderstorm-moon:before {
  content: "\ed28";
}
.i-thunderstorm-sun:before {
  content: "\ed29";
}
.i-thunderstorm:before {
  content: "\ed2a";
}
.i-ticket:before {
  content: "\ed2b";
}
.i-times-circle:before {
  content: "\ed2c";
}
.i-times-square:before {
  content: "\ed2d";
}
.i-times:before {
  content: "\ed2e";
}
.i-toggle-off:before {
  content: "\ed2f";
}
.i-toggle-on:before {
  content: "\ed30";
}
.i-top-arrow-from-top:before {
  content: "\ed31";
}
.i-top-arrow-to-top:before {
  content: "\ed32";
}
.i-tornado:before {
  content: "\ed33";
}
.i-trademark-circle:before {
  content: "\ed34";
}
.i-trademark:before {
  content: "\ed35";
}
.i-traffic-barrier:before {
  content: "\ed36";
}
.i-trash-alt:before {
  content: "\ed37";
}
.i-trash:before {
  content: "\ed38";
}
.i-trees:before {
  content: "\ed39";
}
.i-triangle:before {
  content: "\ed3a";
}
.i-trophy:before {
  content: "\ed3b";
}
.i-trowel:before {
  content: "\ed3c";
}
.i-truck-case:before {
  content: "\ed3d";
}
.i-truck-loading:before {
  content: "\ed3e";
}
.i-truck:before {
  content: "\ed3f";
}
.i-tumblr-square:before {
  content: "\ed40";
}
.i-tumblr:before {
  content: "\ed41";
}
.i-tv-retro-slash:before {
  content: "\ed42";
}
.i-tv-retro:before {
  content: "\ed43";
}
.i-twitter:before {
  content: "\ed44";
}
.i-umbrella:before {
  content: "\ed45";
}
.i-unamused:before {
  content: "\ed46";
}
.i-underline:before {
  content: "\ed47";
}
.i-unlock-alt:before {
  content: "\ed48";
}
.i-unlock:before {
  content: "\ed49";
}
.i-upload-alt:before {
  content: "\ed4a";
}
.i-upload:before {
  content: "\ed4b";
}
.i-usd-circle:before {
  content: "\ed4c";
}
.i-usd-square:before {
  content: "\ed4d";
}
.i-user-check:before {
  content: "\ed4e";
}
.i-user-circle:before {
  content: "\ed4f";
}
.i-user-exclamation:before {
  content: "\ed50";
}
.i-user-hard-hat:before {
  content: "\ed51";
}
.i-user-minus:before {
  content: "\ed52";
}
.i-user-plus:before {
  content: "\ed53";
}
.i-user-square:before {
  content: "\ed54";
}
.i-user-times:before {
  content: "\ed55";
}
.i-user:before {
  content: "\ed56";
}
.i-users-alt:before {
  content: "\ed57";
}
.i-utensils-alt:before {
  content: "\ed58";
}
.i-utensils:before {
  content: "\ed59";
}
.i-vector-square-alt:before {
  content: "\ed5a";
}
.i-vector-square:before {
  content: "\ed5b";
}
.i-venus:before {
  content: "\ed5c";
}
.i-vertical-align-bottom:before {
  content: "\ed5d";
}
.i-vertical-align-center:before {
  content: "\ed5e";
}
.i-vertical-align-top:before {
  content: "\ed5f";
}
.i-vertical-distribute-bottom:before {
  content: "\ed60";
}
.i-vertical-distribution-center:before {
  content: "\ed61";
}
.i-vertical-distribution-top:before {
  content: "\ed62";
}
.i-video-slash:before {
  content: "\ed63";
}
.i-video1:before {
  content: "\ed64";
}
.i-visual-studio:before {
  content: "\ed65";
}
.i-voicemail-rectangle:before {
  content: "\ed66";
}
.i-voicemail:before {
  content: "\ed67";
}
.i-volleyball:before {
  content: "\ed68";
}
.i-volume-down:before {
  content: "\ed69";
}
.i-volume-mute:before {
  content: "\ed6a";
}
.i-volume-off:before {
  content: "\ed6b";
}
.i-volume-up:before {
  content: "\ed6c";
}
.i-volume:before {
  content: "\ed6d";
}
.i-wall:before {
  content: "\ed6e";
}
.i-wallet2:before {
  content: "\ed6f";
}
.i-watch-alt:before {
  content: "\ed70";
}
.i-watch:before {
  content: "\ed71";
}
.i-water-drop-slash:before {
  content: "\ed72";
}
.i-water-glass:before {
  content: "\ed73";
}
.i-water:before {
  content: "\ed74";
}
.i-web-grid-alt:before {
  content: "\ed75";
}
.i-web-grid:before {
  content: "\ed76";
}
.i-web-section-alt:before {
  content: "\ed77";
}
.i-web-section:before {
  content: "\ed78";
}
.i-webcam:before {
  content: "\ed79";
}
.i-weight:before {
  content: "\ed7a";
}
.i-whatsapp:before {
  content: "\ed7b";
}
.i-wheel-barrow:before {
  content: "\ed7c";
}
.i-wheelchair-alt:before {
  content: "\ed7d";
}
.i-wheelchair:before {
  content: "\ed7e";
}
.i-wifi-router:before {
  content: "\ed7f";
}
.i-wifi-slash:before {
  content: "\ed80";
}
.i-wifi:before {
  content: "\ed81";
}
.i-wind:before {
  content: "\ed82";
}
.i-window-grid:before {
  content: "\ed83";
}
.i-window-maximize:before {
  content: "\ed84";
}
.i-window-restore:before {
  content: "\ed85";
}
.i-window-section:before {
  content: "\ed86";
}
.i-window:before {
  content: "\ed87";
}
.i-windsock:before {
  content: "\ed88";
}
.i-wrap-text:before {
  content: "\ed89";
}
.i-wrench:before {
  content: "\ed8a";
}
.i-yellow:before {
  content: "\ed8b";
}
.i-yen-circle:before {
  content: "\ed8c";
}
.i-yen:before {
  content: "\ed8d";
}
.i-youtube-alt:before {
  content: "\ed8e";
}
.i-youtube:before {
  content: "\ed8f";
}
.i-compare-added:before {
  content: "\ed90";
}
.i-compare-view:before {
  content: "\ed91";
}
.i-compare-arrows:before {
  content: "\ed92";
}
.i-percentage:before {
  content: "\ed93";
}
.i-calendar-gratis:before {
  content: "\ed94";
}
.i-PayPass-PayWave:before {
  content: "\ed95";
}
.i-fax:before {
  content: "\ed96";
}
.i-credit:before {
  content: "\ed97";
}
.i-PayPass-PayWave-not-support:before {
  content: "\ed98";
}
.i-arrow-open-banner:before {
  content: "\ed99";
}
.i-fibank-logo:before {
  content: "\ed9a";
}