.card-features{
	display: flex;
	flex-flow: row wrap;
    margin-top: -24px;
	@include fluid-size(margin-left, 570px, 1330px, -10px, -30px);
	&--alt{
		margin-left: 0;
		.card-features{
			&__item{
				flex: 0 0 50%;
				display: flex;
				flex-flow: row;
				align-items: center;
				@media(max-width: $phone-v){
					flex: 0 0 100%;
				}
				&-icon{
					margin: 0;
				}
				&-text{
					margin-left: 16px;
					margin-right: 16px;
					@media(max-width: $phone-v){
						margin-right: 0;
					}
				}
			}
		}
	}
	@media(max-width: $phone-v){
		margin-top: -20px;
	}

	&__item{
		margin-top: 24px;
		flex: 1 0 33.3333%;
        align-items: center;
		overflow: hidden;
		@media(max-width: $phone-v){
			margin-top: 20px;
		}

		&-icon{
            margin-bottom: 11px;
            @include fluid-size(margin-left, 570px, 1330px, 15px, 35px);

			&__shape{
				height: 72px; width: 62px;
				position: relative;
				background: $primary-94;
				clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 50% 100%, 0 75%, 0 25%);

				@media(max-width: $phone-v){
					height: 53px; width: 47px;
				}

				&:before{
					content: '';
					height: 68px; width: 58px;
					position: absolute;
					top: 2px; left: 2px;
					background: white;
					clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 50% 100%, 0 75%, 0 25%);
					z-index: 1;

					@media(max-width: $phone-v){
						height: 49px; width: 43px;
					}
				}
			}

			img{
				max-width: 20px;
				position: absolute;
				top: 50%; left: 50%;
				transform: translate(-50%, -50%);
				z-index: 5;
				@media(max-width: $phone-v){
					max-width: 16px;
				}
			}

			i,
			.icon{
				color: black;
				font-size: 20px;
				position: absolute;
				top: 50%; left: 50%;
				transform: translate(-50%, -50%);
				z-index: 5;
				@media(max-width: $phone-v){
					font-size: 16px;
				}
			}
		}

		&-text{
			position: relative;
            margin-top: -2px;
            @include fluid-size(margin-left, 570px, 1330px, 10px, 30px);
			color: $primary-94;
			font-size: 14px;
			font-weight: 500;
			line-height: 1.285;

			small{
				color: #999898;
				font-size: 12px;
			}
		}

		&-button{
			flex-grow: 1;
			padding-right: 16px;
		}

		&--highlighted{
			.card-features__item-icon{
				
				&__shape{
					&:after{
						content: '';
						height: 62px; width: 54px;
						position: absolute;
						top: 5px; left: 4px;
						background: $accent-500;
						clip-path: polygon(50% 0,100% 25%,100% 75%,50% 100%,50% 100%,0 75%,0 25%);
						z-index: 2;
						@media(max-width: $phone-v){
							height: 43px; width: 39px;
						}
					}
				}

				i,
				.icon{
					color: white;
				}
			}
		}
	}

	&--horizontal{
		margin: 0;

		.card-features__item{
			display: flex;
			flex-flow: row;
			flex: 0 0 50%;
			@media(max-width: $tablet-v){
				flex: 0 0 100%;
			}
		}

		.card-features__item{
			&-icon{
				margin: 0;
				flex-basis: 56px;
				@media(max-width: $phone-v){
					flex-basis: 46px;
				}
	
				&__shape{
					height: 64px; width: 56px;
					@media(max-width: $phone-v){
						height: 53px; width: 47px;
					}
	
					&:before{
						height: 60px; width: 52px;
						@media(max-width: $phone-v){
							height: 49px; width: 43px;
						}
					}
				}
			}

			&--highlighted{
				.card-features__item-icon{
					&__shape{
						&:after{
							height: 54px; width: 48px;
							@media(max-width: $phone-v){
								height: 43px; width: 39px;
							}
						}
					}
				}
			}
		}

		.card-features__item-text{
			max-width: calc(100% - 56px);
			flex-grow: 1;
			margin: 0;
			margin-top: -3px;
			padding: 0 0 0 16px;
			font-size: 15px;
			&:last-child{
				padding-right: 16px;
			}
			@media(max-width: $phone-v){
				padding: 0 0 0 12px;
				font-size: 14px;
				&:last-child{
					padding-right: 12px;
				}
			}
		}
	}

	&--full-width{
		.card-features{
			&__item{
				flex: 0 0 100%;
			}
		}
	}

	&--four-features{
		.card-features__item{
			flex: 1 0 25%;
			@media(max-width: $tablet-v){
				flex: 1 0 50%;
			}
			@media(max-width: $phone-v){
				flex: 1 0 100%;
			}
		}
	}

	&--five-features{
		.card-features__item{
			flex: 1 0 20%;
			@media(max-width: $tablet-v){
				flex: 1 0 50%;
			}
			@media(max-width: $phone-v){
				flex: 1 0 100%;
			}
		}
	}

	&__button{
		display: inline-block;
		padding: 3px 12px 5px;
		font-size: 12px;
		line-height: 18px;
		text-decoration: none;
		background: #f4f4f4;
		border-radius: 2px;
	}
}

.accounts-row__title + .card-features--horizontal{
	margin-top: -19px;
}

/* If clip-path is not supported */
.no-clip-path{
	.card-features__item{
		&-icon{
			&__shape{
				height: 64px; width: 56px;
				background: url('../images/bgr-card-features.svg');
				background-size: cover;
		
				&:before,
				&:after{
					display: none;
				}
			}
		}

		&--highlighted{
			.card-features__item{
				&-icon{
					&__shape{
						background: url('../images/bgr-card-features-highlighted.svg');
						background-size: cover;
					}
				}
			}
		}
	}
}

@supports (-ms-ime-align:auto){
	.card-features__item{
		&-icon{
			&__shape{
				height: 64px; width: 56px;
				background: url('../images/bgr-card-features.svg');
				background-size: cover;
		
				&:before,
				&:after{
					display: none;
				}
			}
		}

		&--highlighted{
			.card-features__item{
				&-icon{
					&__shape{
						background: url('../images/bgr-card-features-highlighted.svg');
						background-size: cover;
					}
				}
			}
		}
	}
}