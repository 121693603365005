.breadcrumbs {    
    display: flex;
    flex-wrap: wrap;    
    align-items: center;
    padding: 11px 0;        
    @include fluid-size(padding-left, 320px, 1330px, 13px, 18px);    
    &--content-header {
        padding-top: 0;
        padding-bottom: 0;
        justify-content: center;
        @include fluid-size(margin-bottom, 320px, 1330px, 20px, 24px);
    }    
    &__home {
        flex-shrink: 0;
        transition: color $transition;        
        @include fluid-size(font-size, 320px, 1330px, 13px, 16px);
        @include fluid-size(margin-left, 320px, 1330px, -13px, -16px);
        margin-top: -3px;
        &:hover:before {
            color: $primary-500;
        }
        [class^="i-"],
        [class*=" i-"]{
            position: relative;
            top: 3px;
            margin: 0;
            color: $primary-90;
            font-size: 18px !important;
            &:hover{
                &:before{
                    color: $primary-90;
                }
            }
        }
    }
    &__link {
        display: inline-block;
        text-decoration: none;
        margin: 0;
        padding: 2px 0;
        color: $primary-90;        
        @include fluid-size(font-size, 320px, 1330px, 10px, 14px);
        font-weight: 500;
        line-height: 1.285714285714286;
        transition: color $transition;                
        &:before {
            content: '/';
            margin: 0 5px;
            color: $primary-90;
        }
        &:last-child {            
            color: rgba($primary-90, 0.54);
            cursor: default;
        }
        &:hover:not(:last-child) {
            color: $primary-500;
        }
    }    
}