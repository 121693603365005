// https://css-tricks.com/snippets/css/fluid-typography/ + upgrade to custom property

@mixin fluid-size($properties, $min-vw, $max-vw, $min-size, $max-size) {
    @each $property in $properties {
        #{$property}: $min-size;
    }
    
    @media (min-width: $min-vw) {
        @each $property in $properties {
            #{$property}: calc(#{$min-size} + #{strip-unit($max-size - $min-size)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});        
        }
    }

    @media (min-width: $max-vw) {
        @each $property in $properties {
            #{$property}: $max-size;
        }
    }
}
  
@function strip-unit($number) {
    @if type-of($number) == "number" and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }
    
    @return $number;
}


/* @include fluid-size(font-size, 320px, 1920px, 16px, 40px); */
  
/* Multiple properties with same values and unit
    .selector {
        @include fluid-size(padding-top padding-bottom, 20em, 70em, 2em, 4em);    
    } 
*/


/**
Hover styling for all devices and browsers
*/
@mixin hover(){
    @media (hover: none){
        &:active{
            @content;
        }
    }

    @media (hover: hover), all and (-ms-high-contrast: none), (-ms-high-contrast: active){
        &:hover{
            @content;
        }
    }
}
/*
.selector{
    @include hover{
         background: red;
    }
}
*/