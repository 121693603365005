/* Colors */
$primary-94: #383636;
$primary-90: #445267;
$primary-30: #ebf1f5;
$primary-050: #677a8d;
$primary-200: #1861d9;
$primary-400: #e6ebf5;
$primary-500: #0a3e93;
$primary-700: #06396a;
$primary-800: #012241;
$primary-900: #003d7d;

$accent-300: #830775;
$accent-400: #07a25d;
$accent-500: #d8232a;
$accent-600: #fd9f17;
$accent-700:  #169340;

$gray-002: #f5f7fa;
$gray-060: #8794a8;
$gray-003: #cbcbd3;
$gray-004: #f8f9fa;

$red: #d90118;
$dark-gray: #151515;

$pink: #cd238e;
$yellow: #ffdf70;
$blue: #0a3e93;

$white: #fff;

/* Fonts */
$Inter: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$Noto: 'Noto Serif', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

/* Transitions */
$transition: 250ms ease;
$transition2: 450ms ease;

/* Text alignment */
.text-left{ text-align: left; }
.text-right{ text-align: right; }
.text-center{ text-align: center; }
.text-pink{ color: $pink !important; }
.text-dark{ color: #262626 !important; }
.text-black{ color: black !important; }
.text-blue{ color: $primary-500 !important; }
.text-thin{ font-weight: 300 !important; }
.text-uppercase{ text-transform: uppercase; }
.no-wrap{ white-space: nowrap; }

.el-show{ display: block !important; }

.gray-block{
  background: $gray-002;
}



/* media queries vars */
$desktop-h: 91.9em;
$desktop: 83.9em;
$tablet-h: 74.9em;
$tablet-v: 61.9em;
$phone-h: 47.9em;
$phone-v: 37.9em;
$phone-s: 32.9em;
$phone-sm: 360px;