.currency{
    display: flex;
    flex-flow: row wrap;
    @include fluid-size(margin-left, 320px, 1330px, -20px, -48px);
    @include fluid-size(margin-right, 320px, 1330px, -20px, -48px);

    &__wrapper{
        overflow: hidden;
        margin-top: -12px;
        padding-top: 12px;
    }

    &__item{
        flex: 1 0 50%;
        @media(max-width: $tablet-v){
            flex: 1 0 100%;

            & + &{
                margin-top: 32px;
                padding-top: 32px;
                border-top: 1px solid rgba($primary-94, .12);
            }
        }

        &-inner{
            display: flex;
            flex-flow: column;
            height: 100%;
            position: relative;
            @include fluid-size(padding-left, 320px, 1330px, 20px, 48px);
            @include fluid-size(padding-right, 320px, 1330px, 20px, 48px);

            &:before{
                content: '';
                height: 100%; width: 1px;
                position: absolute;
                top: 0; left: 0;
                background: rgba($gray-060, .32);
            }
        }
    }

    &__header{
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        width: 100%;
        @media(max-width: $phone-v){
            display: block;
            text-align: center;
        }

        &-left{
            font-size: 13px;
            font-weight: 500;
            color: rgba($dark-gray, .6);
        }

        &-right{
            padding-left: 30px;
            text-align: right;
            @media(max-width: $phone-v){
                padding: 24px 0 0;
            }
        }

        a{
            text-decoration: none;
        }
    }

    &__title{
        margin-bottom: 9px;
        color: $dark-gray;
        font-size: 24px;
        font-family: $Noto;
        font-weight: 700;
        font-style: italic;
        @media(max-width: $phone-v){
            margin-bottom: 12px;
            font-size: 30px;
        }
    }

    &__select{
        min-width: 173px;
        margin-top: -12px;
        @media(max-width: $phone-v){
            max-width: 302px;
            margin: 0 auto;
        }

        select{
            color: $dark-gray;
            border-color: #6f7b8d;
        }
    }

    &__body{
        padding-top: 28px;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        flex: 1;
        @media(max-width: $phone-v){
            padding-top: 32px;
        }
    }

    &__block{
        display: none;
    }

    &__list{
        display: flex;
        flex-flow: row wrap;
        margin-top: -33px;
        @include fluid-size(margin-left, 320px, 1330px, -20px, -80px);
        @media(max-width: $phone-v){
            margin-top: -26px;
        }

        &-item{
            padding-top: 33px;
            flex: 0 0 30%;
            @media(max-width: $phone-v){
                flex: 1 0 100%;
                padding-top: 26px;
            }
        }

        &-inner{
            @include fluid-size(padding-left, 320px, 1330px, 20px, 80px);
            @media(max-width: $phone-v){
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-content: space-between;
            }
        }

        &-title{
            margin-bottom: 12px;
            font-size: 16px;
            color: rgba($dark-gray, .54);
            @media(max-width: $phone-v){
                margin-bottom: 0;
                color: $dark-gray;
            }
        }

        &-price{
            color: $dark-gray;
            font-size: 20px;
            white-space: nowrap;
            @media(max-width: $phone-v){
                font-size: 18px;
            }

            &-value{
                font-size: 26px;
                font-weight: 500;
                @media(max-width: $phone-v){
                    font-size: 24px;
                }
            }
        }
    }

    &-block{
        display: flex;
        flex-flow: row;
        margin: 0 -45px;
        @media(max-width: $tablet-v){
            flex-wrap: wrap;
            margin-left: 0;
            margin-right: 0;
        }

        &__wrapper{
            overflow: hidden;
            margin-top: -6px;
        }

        &__item{
            flex: 1 0 50%;
            @include fluid-size(padding-left, 320px, 1330px, 20px, 40px);
            @include fluid-size(padding-right, 320px, 1330px, 20px, 40px);
            @media(max-width: $tablet-v){
                flex: 1 0 100%;
                padding: 30px 0;
            }
            text-align: center;
            & + &{
                border-left: 1px solid rgba($gray-060, .32);
                @media(max-width: $tablet-v){
                    border-left: 0;
                    border-top: 1px solid rgba($gray-060, .32);
                }
            }
        }

        &__title{
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;
            @include fluid-size(font-size, 280px, 1330px, 24px, 32px);
            font-family: $Noto;
            font-weight: 700;
            font-style: italic;
            line-height: 1.3375;
            @media(max-width: $tablet-v){
                min-height: inherit !important;
            }
        }

        &__list{
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            &-item{
                flex: 0 0 100%;
                @include fluid-size(padding-top, 280px, 1330px, 35px, 50px);
                @media(max-width: $phone-v){
                    flex: 1 0 100%;
                }
                small{
                    color: rgba($dark-gray, .54);
                    font-size: 12px;
                }
                span{
                    color: rgba($dark-gray, .54);
                    font-size: 16px;
                }
                span,
                small{
                    display: inline-block;
                    max-width: 230px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
            &-title{
                margin-bottom: 7px;
                color: $dark-gray;
                font-family: $Noto;
                font-size: 26px;
                line-height: 1.125;
                a{
                    color: inherit;
                    text-decoration: none;
                    &[href*="tel"]{
                        @include hover{
                            color: $primary-500;
                        }
                    }
                }
                &--bold{
                    font-weight: 700;
                    font-style: italic;
                }
                sub{
                    bottom: 0;
                    font-size: 22px;
                }
            }
        }

        .text-black{
            a{
                color: $primary-500;
                text-decoration: underline;
            }
        }
    }
}