.scrollable-content {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    table {
        margin: 0 1px;
        th,
        td {
            @media (max-width: $tablet-v) {
                min-width: 180px;
            }
        }
    }
}

.mw-950 {
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
}
.mw-440 {
    max-width: 440px;
    margin-left: auto;
    margin-right: auto;
}
.center {
    text-align: center !important;
}

.relative-position {
    position: relative;
}

.align-left {
    text-align: left !important;
}

.align-right {
    text-align: right !important;
}

.nowrap {
    white-space: nowrap !important;
}

.hidden {
    display: none !important;
}

.sr {
    border: 0;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}

.sr.focusable:active,
.sr.focusable:focus {
    clip: auto;
    -webkit-clip-path: none;
    clip-path: none;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
    white-space: inherit;
}

.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: '';
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

.background-white {
    background-color: $white !important;
}
.background-1 {
    background-color: $gray-002;
    & .parsley-errors-list {
        background-color: $gray-002;
    }
}

.pa-m {
    @include fluid-size(padding, 320px, 1440px, 24px, 32px);
}
.pb-m {
    @include fluid-size(padding-bottom, 320px, 1440px, 24px, 32px);
}
.mb-m {
    @include fluid-size(margin-bottom, 320px, 1440px, 16px, 25px);
}
.mb-xl {
    margin-bottom: 35px;
}
.mb-l {
    @include fluid-size(margin-bottom, 320px, 1440px, 32px, 64px);
}
.no-margin {
    margin: 0 !important;
}
.title-m {
    font-family: $Noto;
    @include fluid-size(font-size, 320px, 1330px, 18px, 30px);
    font-weight: 700;
    font-style: italic;
    color: $primary-90;
}
.border-after {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        margin: 0 auto;
        border-bottom: 1px solid $gray-003;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.iban {
    &-valid,
    &-invalid {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        & i {
            margin-right: 8px;
            font-size: 30px;
        }
    }
    &-valid {
        color: $accent-700;
    }

    &-invalid {
        color: $accent-500;
    }
}


.floating-label__label {
    display: block;
    position: relative;
}

.floating-label__label label,
.floating-label__label>span {
    position: absolute;
    left: 17px;
    top: 6px;
    cursor: text;
    font-size: 120%;
    opacity: 1;
    -webkit-transition: all .3s;
    transition: all .3s ease;
}

.floating-label__label input {
    font-size: 15px;
    font-weight: normal !important;
    padding-top: 1.3em;
    margin-bottom: 2px;
    border: 0;
    height: 45px;
    border-radius: 0;
    border-bottom: 2px solid rgba(0, 0, 0, .1);
}

.floating-label__label input::-webkit-input-placeholder {
    opacity: 1;
    -webkit-transition: all .2s;
    transition: all .2s;
}

.floating-label__label input::-moz-placeholder {
    opacity: 1;
    transition: all .2s;
}

.floating-label__label input:-ms-input-placeholder {
    opacity: 1;
    transition: all .2s;
}

.floating-label__label input::placeholder {
    opacity: 1;
    -webkit-transition: all .2s;
    transition: all .2s;
}

.floating-label__label input:invalid:not(:focus)::-webkit-input-placeholder {
    opacity: 0;
}

.floating-label__label input:invalid:not(:focus)::-moz-placeholder {
    opacity: 0;
}

.floating-label__label input:invalid:not(:focus):-ms-input-placeholder {
    opacity: 0;
}

.floating-label__label input:invalid:not(:focus)::placeholder {
    opacity: 0;
}

.floating-label__label input:invalid:not(:focus)+* {
    font-size: 150%;
    top: 1.2em;
}

.floating-label__label select {
    padding-right: 1em;
    background: #fff;
    background-size: 8px 10px;
}

.floating-label__input {
    background: #fff !important;
    text-transform: uppercase;
    font-size: 18px !important;
    color: #383636 !important;
    font-weight: 500 !important;
    padding: 20px 17px 10px !important;
}

.floating-label__input:focus,
.floating-label__input:active,
.floating-label__input:focus-within {
    &~.floating-label__placeholder {
    top: 4px !important;
    }
}

.floating-label__input::placeholder {
    color: transparent !important;
}

.floating-label {
    &__input {
        &::placeholder {
            color: transparent !important;
        }
    }

    &__label .floating-label__placeholder {

        @-moz-document url-prefix() {
            background: $white;
            padding: 0 20px 5px 0;

            @supports (animation: calc(0s)) {
                background: transparent;
                padding: 0;
            }
        }
    }
}
