.site-body{
    .header{
        &-search{
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 0; bottom: 0;
            left: 0; right: 0;
            background: white;
            transition: opacity $transition, visibility $transition;
            z-index: 10;
            @media(max-width: $tablet-v){
                height: 56px;
                top: 100%;
                background: #f4f4f4;
            }
            &--active{
                opacity: 1;
                visibility: visible;
            }
    
            &__form,
            form{
                height: 100%; width: 100%;
                display: flex;
                align-items: center;
                flex-flow: row;
                position: relative;
            }
    
            &__form{
                &:before,
                &:after{
                    content: '';
                    height: 100%; width: 10000px;
                    position: absolute;
                    top: 0; left: 100%;
                    margin-left: -1px;
                    background: white;
                    @media(max-width: $tablet-v){
                        display: none;
                    }
                }
                &:after{
                    left: auto; right: 100%;
                    margin-left: 0;
                    margin-right: -1px;
                }
            }
    
            &__input:not([type="checkbox"]):not([type="radio"]){
                min-height: inherit;
                height: 98px; width: 100%;
                padding: 16px;
                flex-grow: 1;
                color: $primary-500;
                font-size: 32px;
                font-family: $Noto;
                font-weight: 400;
                border: 0;
                background: transparent;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                @media(max-width: $tablet-v){
                    height: 56px;
                    font-size: 16px;
                    font-family: $Inter;
                    color: $dark-gray;
                }
                @media (orientation: landscape){
                    @supports (left: env(safe-area-inset-left)){
                        padding-left: calc(env(safe-area-inset-left) + 16px);
                    }
                }
                &::placeholder{
                    color: rgba($primary-94, .24);
                    opacity: 1;
                    @media(max-width: $tablet-v){
                        color: rgba($primary-94, .54);
                    }
                }
            }
    
            &__button{
                height: 100%; width: 40px;
                position: relative;
                text-indent: -10000px;
                background: transparent;
                @media(max-width: $tablet-v){
                    display: none;
                }
                &:before{
                    content: "\ec3c";
                    color: $primary-94;
                    font-size: 24px;
                    font-family: 'fibank-icons';
                    text-indent: 0;
                    position: absolute;
                    top: 50%; left: 50%;
                    transform: translate(-50%, -51%);
                    @media(max-width: $tablet-v){
                        left: 0;
                        transform: translate(0, -51%);
                    }
                }
                &[type="submit"]{
                    display: none;
                    @media(max-width: $tablet-v){
                        display: block;
                    }
                    &:before{
                        content: "\eca9";
                        color: $primary-500;
                        font-size: 22px;
                    }
                }
            }

            &__results{
                display: none;
                padding: 48px 32px 32px;
                background: $gray-002;
                position: relative;
                z-index: 2;
                border-top: 1px solid $primary-400;
                @media(max-width: $tablet-v){
                    max-height: 79vh;
                    overflow: hidden;
                    overflow-y: auto;
                    -webkit-overflow-scrolling: touch;
                    overscroll-behavior: contain;
                }
                @media(max-width: $phone-h){
                    padding-left: 16px;
                    padding-right: 16px;
                }

                &:before,
                &:after{
                    content: '';
                    width: 10000px;
                    position: absolute;
                    top: -1px; bottom: 0;
                    left: 100%;
                    margin-left: -1px;
                    background: inherit;
                    border-top: 1px solid $primary-400;
                    @media(max-width: $tablet-v){
                        display: none;
                    }
                }
                &:after{
                    left: auto; right: 100%;
                    margin-left: 0;
                    margin-right: -1px;
                }

                &-title{
                    margin: 9px auto;
                    text-align: center;
                    color: $primary-94;
                    @include fluid-size(font-size, 280px, 1330px, 24px, 32px);
                    font-family: $Noto;
                    font-style: italic;
                    font-weight: 700;
                }
                &-sub-title{
                    text-align: center;
                    color: $primary-500;
                    font-size: 16px;
                    font-weight: 500;
                }
                &-list{
                    margin-top: 26px;
                    &__item{
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: center;
                        padding: 13px 16px;
                        border-top: 1px solid #e0e0e0;
                        @media(max-width: $phone-h){
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }
                    &__left{
                        flex-grow: 1;
                    }
                    &__right{
                        @media(max-width: $phone-v){
                            display: none;
                        }
                    }
                    &__title{
                        margin-top: 4px;
                        color: $dark-gray;
                        font-family: $Noto;
                        font-style: italic;
                        font-weight: 700;
                        @include fluid-size(font-size, 280px, 1330px, 20px, 24px);
                        &:first-child{
                            margin-top: 0;
                        }
                        a{
                            color: inherit;
                            text-decoration: none;
                            &:hover{
                                color: $primary-500;
                            }
                        }
                    }
                    &__sub-title{
                        color: $primary-500;
                        font-size: 16px;
                        font-weight: 500;
                        a{
                            text-decoration: none;
                        }
                    }
                    img{
                        max-width: 104px;
                    }
                }
                &-message{
                    padding: 43px 0 70px; 
                    text-align: center;
                    color: rgba($primary-90, .4);
                    font-family: $Noto;
                    font-style: italic;
                    font-weight: 700;
                    @include fluid-size(font-size, 280px, 1330px, 20px, 22px);

                    [class*="i-"]{
                        margin-bottom: 26px;
                        font-size: 80px;
                    }
                }
            }
        }

        &--mobile{
            .header{
                &-search{
                    &__input:not([type="checkbox"]):not([type="radio"]){
                        height: 56px;
                        font-size: 16px;
                        font-family: $Inter;
                    }
                }
            }
        }
        &--expanded{
            .header{
                &-search{
                    @media(max-width: $tablet-v){
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}

.search{
    &-results{
        padding-top: 5px;
        &__item{
            display: flex;
            padding-bottom: 32px;
            @media(max-width: $phone-h){
                flex-flow: column;
            }
            & + &{
                padding-top: 32px;
                border-top: 1px solid #e0e0e0;
            }
        }
        &__text{
            padding-top: 22px;
            color: $primary-90;
            font-size: 15px;
            font-weight: 500;
            line-height: 1.6;
            flex-grow: 1;
            @media(max-width: $phone-h){
                padding-top: 0;
            }
        }
        &__preview{
            flex-shrink: 0;
            width: 317px;
            @include fluid-size(margin-left, 320px, 1330px, 20px, 60px);
            text-align: center;
            @media(max-width: $phone-h){
                width: auto;
                margin: 26px auto 0;
            }
            &:empty{
                display: none;
            }
        }
        &__title{
            display: inline-block;
            margin-bottom: 5px;
            color: $dark-gray;
            @include fluid-size(font-size, 320px, 1330px, 20px, 24px);
            font-family: $Noto;
            font-style: italic;
            font-weight: 700;
            text-decoration: none;
            line-height: 1.125;
            @include hover{
                color: $primary-500;
            }
        }
        &__category{
            display: inline-block;
            margin-bottom: 19px;
            color: rgba($primary-94, .54);
            font-style: 13px;
            font-weight: 500;
            line-height: 1.15;
        }
        mark{
            color: inherit;
            padding-left: 5px;
            padding-right: 5px; 
        }
    }
}