.app{
	position: relative;
	padding: 20px 100px 21px 49px;
	background: url('../images/app-icon.svg') no-repeat 0 center;

	&__wrap{
		position: relative;
		padding-left: 49px;
		color: rgba(255,255,255,.8);
		font-size: 12px;
		font-weight: 500;
		background: url('../images/bgr-app.jpg') no-repeat center center;
		background-size: cover;
		@media (orientation: landscape){
			@supports (left: env(safe-area-inset-left)){
				padding-left: calc(env(safe-area-inset-left) + 49px);
			}
		}
	}

	&__title{
		margin-bottom: 2px;
		font-size: 14px;
		font-weight: 700;
	}

	&__button{
		display: inline-block;
		padding: 6px 12px;
		position: absolute;
		top: 50%; right: 16px;
		color: #007afd;
		font-size: 11px;
		font-weight: 700;
		text-transform: uppercase;
		text-decoration: none;
		background: #fcfcfc;
		transform: translateY(-50%);
        border-radius: 14px;
        &--android{
			color: white;
			border-radius: 2px;
			background: #689f38;			
        }
	}


	&__close{
		height: 22px; width: 22px;
		position: absolute;
		top: 50%; left: 16px;
		background: rgba($primary-800, .64);
		transform: translateY(-50%);
		border-radius: 100%;
		text-indent: -10000px;
		@media (orientation: landscape){
			@supports (left: env(safe-area-inset-left)){
				left: calc(env(safe-area-inset-left) + 16px);
			}
		}

		&:before{
			content: '\ec3c';
			font-family: 'fibank-icons';
			color: white;
			font-size: 10px;
			text-indent: 0;
			position: absolute;
			top: 50%; left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

.apps-list{
	margin: -3px -7px;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	&__item{
		padding: 8px 12px;
		a{
			display: block;
			transition: $transition;
			&:hover{
				opacity: .8;
			}
		}
	}

	&--alt{
		margin: 23px 0 0;
		flex-flow: column;
		justify-content: flex-start;
		.apps-list__item{
			padding: 11px 0 0;
		}
		img{
			max-width: 156px;
		}
		a{
			background-size: 0!important;
		}
	}
}