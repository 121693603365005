.filter{
    &-dropdown{
        display: inline-block;
        position: relative;
        user-select: none;
        z-index: 15;
        @media(max-width: $phone-h){
            display: block;
        }
    
        &__header{
            display: flex;
            align-items: center;
            height: 64px; width: 300px;
            padding: 0 75px 0 17px;
            position: relative;
            color: $primary-90;
            font-weight: 500;
            line-height: 1;
            cursor: pointer;
            background: #f4f4f4;
            perspective: 1200px;
            transform-style: preserve-3d;
            @media(max-width: $phone-h){
                width: 100%;
            }
    
            &:after{
                content: '\eb69';
                font-family: 'fibank-icons';
                font-size: 24px;
                color: $primary-500;
                position: absolute;
                top: 50%; right: 16px;
                transform: translateY(-50%);
            }
    
            &-placeholder{
                position: absolute;
                top: 50%; left: 17px;
                transform: translateY(-50%);
                color: #6f7b8d;
                font-size: 16px;
                transition: $transition;
            }
    
            &-filter{
                opacity: 0;
                visibility: hidden;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-top: 15px;
                font-size: 16px;
                transition: $transition;
            }
    
            &-button{
                opacity: 0;
                visibility: hidden;
                height: 16px; width: 16px;
                position: absolute;
                top: 50%; right: 50px;
                text-indent: -10000px;
                background: rgba(0,0,0,.24);
                border-radius: 100%;
                transform: translateY(-50%);
                transition: $transition;
                &:before{
                    content: '\ec3c';
                    font-family: 'fibank-icons';
                    color: white;
                    font-size: 10px;
                    text-indent: 0;
                    position: absolute;
                    top: 50%; left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    
        &__body{
            opacity: 0;
            visibility: hidden;
            min-width: 100%;
            position: absolute;
            top: 100%; right: 0;
            padding: 15px 0 18px;
            background: white;
            border: 1px solid #c5cad1;
            transform: translateZ(0);
            transition: $transition;
        }
    
        &__row{
            display: flex;
            flex-flow: row;
    
            &-item{
                min-width: 250px;
                flex: 1 0 25%;
                border-left: 1px solid $primary-400;
    
                @media(max-width: $tablet-h){
                    min-width: 236px;
                }
    
                &:first-child{
                    margin-top: 0;
                    padding-top: 0;
                    border-width: 0;
                }
    
                &-inner{
                    padding: 0 20px;
                }
            }
    
            &-title{
                padding: 3px 0;
                color: $primary-90;
                font-size: 16px;
                font-weight: 500;
                line-height: 1.125;
                pointer-events: none;
            }
        }
    
        &-filters{
            &__item{
                margin-top: 13px;
            }
    
            .check-radio{
                &__element{
                    padding-left: 22px;
                    font-size: 14px;
                    font-weight: 500;
                    &:before{
                        height: 16px; width: 16px;
                        top: 2px;
                    }
                    &:after{
                        height: 8px !important;
                        width: 8px !important;
                        top: 6px !important;
                        left: 4px !important;
                    }
                }
            }
        }
    
        &.active{
            .filter-dropdown__header{
                &-placeholder{
                    font-size: .9em;
                    opacity: .8;
                    transform: translateY(-1.5em);
                }
    
                &-filter{
                    opacity: 1;
                    visibility: visible;
                }
    
                &-button{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    
        &.expanded{
            .filter-dropdown__body{
                opacity: 1;
                visibility: visible;
                transform: translateY(16px) translateZ(0);
            }
        }
    
        &--white{
            .filter-dropdown{
                &__header{
                    color: #6f7b8d;
                    background: white;
                }
            }
        }
    
        &--alt{
            .filter-dropdown{
                &__row{
                    &-item{
                        min-width: 150px;
                    }
                }
                &-filters{
                    max-height: 250px;
                    overflow: hidden;
                    overflow-y: auto;
                    -webkit-overflow-scrolling: touch;
                    overscroll-behavior: contain;
                }
            }
        }
    }

    &-results{
        padding-top: 76px;
        text-align: center;
        color: $primary-90;
        font-family: $Noto;
        font-size: 20px;
        line-height: 1.5;
        border-top: 1px solid #ebf1f5;
        &__message{
            max-width: 400px;
            margin: auto;
            [class*="i-"]{
                margin-bottom: 14px;
                font-size: 80px;
                color: rgba($primary-90, .8);
            }
        }
    }
}

@media(max-width: $tablet-v){
    .filter-dropdown{
        &__row{
            flex-flow: column;

            &-item{
                margin-top: 15px;
                padding-top: 15px;
                border-left: 0;
                border-top: 1px solid #e6ebf5;

                &.expanded{
                    .filter-dropdown__row-title{
                        color: $primary-500;

                        &:after{
                            content: '\ec3c';
                        }
                    }

                    .filter-dropdown-filters{
                        display: block;
                    }
                }
            }

            &-title{
                margin: -15px -20px;
                padding: 15px 40px 15px 20px;
                position: relative;
                font-size: 15px;
                cursor: pointer;
                pointer-events: all;
                &:after{
                    content: '\e99f';
                    font-family: 'fibank-icons';
                    position: absolute;
                    top: 50%; right: 16px;
                    transform: translateY(-50%);
                    color: $primary-500;
                    font-size: 18px;
                }
            }
        }

        &-filters{
            display: none;
        }
    }
}

.filter-dropdown{
    &__row-item{
        &:only-child{
            .filter-dropdown{
                &__row{
                    &-title{
                        &:after{
                            display: none;
                        }
                    }
                }
                &-filters{
                    display: block;
                }
            }
        }
    }
}