.positions{
    &-list{
        margin-top: 40px;
        &__item{
            border-top: 1px solid $primary-400;
            border-bottom: 1px solid $primary-400;
            margin: -1px 0;
            
            &:hover{
                .positions-list__button{
                    opacity: 1;
                    visibility: visible;
                }
            }

            &.expanded{
                .positions-list{
                    &__title{
                        color: $primary-500;
                    }
                    &__button{
                        opacity: 1;
                        visibility: visible;
                        &:before{
                            opacity: 0;
                            visibility: hidden;
                        }
                        &:after{
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                    &__body{
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            &--expanded{
                .positions-list{
                    &__button{
                        opacity: 1;
                        visibility: visible;
                        &:before{
                            opacity: 0;
                            visibility: hidden;
                        }
                        &:after{
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                    &__body{
                        display: block;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }

        &__header{
            padding: 25px 0;
            cursor: pointer;
            &-wrap{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 6px;
                padding-right: 40px;
                position: relative;
                &:first-child{
                    margin-top: 0;
                }
                @media(max-width: $phone-v){
                    display: block;
                }
            }
        }

        &__title{
            padding-right: 20px;
            color: $dark-gray;
            @include fluid-size(font-size, 320px, 1330px, 18px, 24px);
            font-family: $Noto;
            font-weight: 700;
            font-style: italic;
            @include fluid-size(line-height, 320px, 1330px, 1.125, 1.5);
            text-align: left;
            transition: color $transition;
            flex: 1;
            &:last-child{
                min-width: 212px;
                max-width: 212px;
                text-align: left;
                padding-right: 0;
            }
            @media(max-width: $phone-v){
                padding-right: 0;
                &:last-child{
                    max-width: inherit;
                    color: rgba($primary-90, .64);
                    font-size: 16px;
                    padding: 6px 0 0;
                }
            }
        }

        &__button{
            opacity: 0;
            visibility: hidden;
            height: 32px; width: 32px;
            position: absolute;
            top: 50%; right: 0;
            margin-top: 1px;
            border: 0;
            text-indent: -10000px;
            background: $primary-400;
            border-radius: 100%;
            transform: translateY(-50%);
            transition: $transition;
            &:before,
            &:after{
                content: "\e99f";
                position: absolute;
                top: 50%; left: 50%;
                margin-top: 1px;
                text-indent: 0;
                color: $primary-500;
                font-size: 22px;
                font-family: 'fibank-icons';
                transform: translate(-50%, -50%);
                transition: $transition;
            }
            &:after{
                opacity: 0;
                visibility: hidden;
                content: "\ec3c";
                font-size: 18px;
            }
        }

        &__body{
            display: none;
            opacity: 0;
            visibility: hidden;
            padding: 23px 0 45px;
            transition: opacity $transition, visibility $transition;
            @media(max-width: $phone-v){
                padding: 0 0 25px;
            }
        }

        & + &{
            margin-top: 0;
            .positions-list{
                &__item{
                    &:first-child{
                        border-top-width: 0;
                    }
                }
            }
        }
    }

    &-box{
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 10px 15px 13px;
        color: $primary-90;
        font-size: 14px;
        border: 1px solid #e0e0e0;
        @media(max-width: $phone-v){
            flex-flow: column;
        }
        &__left{
            padding-right: 20px;
            flex-grow: 1;
            @media(max-width: $phone-v){
                width: 100%;
                padding: 0 0 12px;
            }
            &:only-child{
                padding: 0;
            }
        }
        &__right{
            @media(max-width: $phone-v){
                width: 100%;
            }
            a{
                text-decoration: none;
            }
        }
        &__title{
            font-size: 16px;
            font-weight: 500;
        }
        p ~ p{
            margin-top: 4px;
        }
    }
}

.touchevents{
    .positions{
        &-list{
            &__button{
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.position {
    &-banner {
        margin-bottom: 6px;
        img {
            width: 100%;
        }
    }

    &-box {
        padding: 26px 38px 47px;
        background-color: $gray-004;
        @media(max-width: $phone-h) {
            padding: 24px;
        }
    }
}

.blue-box {
    margin: 24px 0;
    padding: 32px;
    color: $white;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    background-color: $primary-900;
    border-radius: 16px;
    @media(max-width: $phone-h) {
        padding: 16px;
        font-size: 18px;
        line-height: 24px;
    }

    &--accent {
        font-size: 28px;
        font-weight: bold;
        @media(max-width: $phone-h) {
            font-size: 18px;
            line-height: 26px;
        }
    }

    a {
        color: $white;
        text-decoration: none;
    }

    .external-icon {
        display: none;
    }
}

.benefits-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__item {
        width: 100%;
        max-width: 33.3333%;
        padding: 16px;
        text-align: center;
        @media(max-width: $tablet-v) {
            max-width: 50%;
        }
        @media(max-width: $phone-v) {
            max-width: inherit;
        }
    }

    &__image {
        img {
            margin-bottom: 22px !important;
        }
    }

    &__text {
        font-size: 12px;
        line-height: 1.5;
    }
}