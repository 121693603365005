.accordion{
    &-title{
        margin: 47px 0 23px;
        position: relative;
        color: $primary-500;
        font-size: 26px;
        font-family: $Noto;
        line-height: 1.125;
        @media(max-width: $phone-h){
            margin-top: 23px;
            font-size: 24px;
        }
        &:before{
            content: '';
            height: 1px; width: 100%;
            position: absolute;
            top: 50%; left: 0;
            margin-top: 2px;
            background: $primary-500;
            z-index: 1;
        }
        strong{
            display: inline-block;
            padding: 5px 12px 5px 0;
            position: relative;
            font-weight: 400;
            background: white;
            z-index: 2;
        }
    }

    &-list{
        & + &{
            margin-top: 18px;
        }
        &__item{
            border: 1px solid $primary-400;
            & + &{
                margin-top: 18px;
            }

            &.expanded{
                .accordion-list{
                    &__header{
                        &:after{
                            opacity: 0;
                        }
                    }
                }
            }
            &.active{
                .accordion-list{
                    &__header{
                        &:after{
                            opacity: 0;
                        }
                    }
                }
            }
            &.active-body{
                .accordion-list{
                    &__header{
                        &:after{
                            opacity: 0;
                        }
                    }
                    &__body{
                        display: block;
                    }
                }
            }
        }

        &__header{
            position: relative;
            padding: 20px 46px 21px 23px;
            user-select: none;
            cursor: pointer;
            &:before,
            &:after{
                content: '';
                height: 2px; width: 16px;
                position: absolute;
                top: 50%; right: 19px;
                transform: translateY(-50%);
                background: $primary-500;
                transition: $transition;
            }
            &:after{
                height: 16px; width: 2px;
                right: 26px;
            }
        }

        &__title{
            color: $primary-94;
            font-size: 18px;
            font-family: $Noto;
            font-weight: 700;
            font-style: italic;
            line-height: 1.125;
            @media(max-width: $phone-h){
                font-size: 17px;
            }
        }
        &__sub-title{
            margin: 1px 0 7px;
            color: $primary-94;
            font-size: 12px;
            font-family: $Noto;
            span{
                color: rgba($primary-94, .24);
            }
        }

        &__body{
            display: none;
            padding: 0 23px 21px;
        }

        &--alt{
            .accordion{
                &-list{
                    margin-top: -20px;
                    &__item{
                        padding: 0;
                        border: 0;
                        &.active-body{
                            .accordion{
                                &-list{
                                    &__title{
                                        &:before{
                                            content: '\ec3c';
                                            color: $accent-500;
                                        }
                                    }
                                    &__body{
                                        display: block;
                                    }
                                }
                            }
                        }
                        &.active{
                            .accordion{
                                &-list{
                                    &__title{
                                        &:before{
                                            content: '\ec3c';
                                            color: $accent-500;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &__header{
                        padding: 22px 0;
                        &:before,
                        &:after{
                            display: none;
                        }
                    }
                    &__body{
                        padding: 11px 0 10px 54px;
                        @media(max-width: $phone-v){
                            padding: 0 0 10px;
                        }
                    }
                    &__title{
                        display: inline-block;
                        padding-right: 44px;
                        position: relative;
                        font-size: 24px;
                        @media(max-width: $phone-v){
                            font-size: 20px;
                        }
                        &:after{
                            content: '';
                            height: 32px; width: 32px;
                            position: absolute;
                            top: 50%; right: 0;
                            transform: translateY(-50%);
                            margin-top: 1px;
                            background: $gray-002;
                            border-radius: 100%;
                            z-index: 1;
                        }
                        &:before{
                            content: '\ec6d';
                            font-family: 'fibank-icons';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 18px;
                            color: $primary-500;
                            position: absolute;
                            top: 50%; right: 0;
                            transform: translateY(-50%);
                            margin: 1px 7px 0 0;
                            z-index: 2;
                        }
                    }
                }
            }
        }
    }
}