.accounts-list{
	display: flex;
	flex-flow: row wrap;
	margin: -48px 0 0 -48px;
    @media(max-width: $tablet-h){
        margin: -24px 0 0 -24px;
    }

	&__item{
		flex: 0 0 50%;
		margin-top: 48px;
		overflow: hidden;
        @media(max-width: $tablet-h){
            margin-top: 24px;
        }
        @media(max-width: $phone-v){
            flex: 1 0 100%;
        }
	}

	&__inner,
	&__inner > a{
		height: 100%;
		display: flex;
		flex-flow: row;
		/*align-items: center;*/
		margin-left: 48px;
		background: $primary-400;
		text-decoration: none;
		transform-style: preserve-3d;
        @media(max-width: $tablet-h){
            margin-left: 24px;
        }
        @media(max-width: $tablet-v){
            flex-flow: column-reverse;
		}
		@include hover{
			.accounts-list{
				&__title{
					color: $primary-500;
				}
			}
		}
	}

	&__inner > a{
		margin-left: 0;
	}

	&__preview{
		display: flex;
		flex: 0 0 260px;
		align-self: flex-end;
        @media(max-width: $tablet-h){
            flex: 0 0 180px;
        }
        @media(max-width: $tablet-v){
            align-self: flex-start;
            align-items: flex-end;
            max-height: 200px;
        }
        @media(max-width: $phone-v){
            max-height: 150px;
        }

		img{
			max-width: 100%;
			mix-blend-mode: multiply;
            @media(max-width: $tablet-v){
                max-height: 190px;
            }
            @media(max-width: $phone-v){
                max-height: 140px;
            }
		}
	}

	&__title{
		margin-bottom: 11px;
		color: $dark-gray;
		@include fluid-size(font-size, 320px, 1330px, 19px, 30px);
		font-family: $Noto;
		font-style: italic;
		font-weight: 700;
		line-height: 1.125;
		overflow-wrap: break-word;
		word-wrap: break-word;
		hyphens: auto;
		transition: color $transition;
		&:only-child{
			margin-bottom: 0;
		}

		a{
			color: inherit;
			text-decoration: none;

			@include hover{
				color: $primary-500;
			}
		}
	}

	&__text{
		display: flex;
		flex-flow: column;
		justify-content: center;
		flex-grow: 1;
        @include fluid-size(padding-top, 320px, 1330px, 20px, 30px);
        @include fluid-size(padding-bottom, 320px, 1330px, 20px, 30px);
        @include fluid-size(padding-left, 320px, 1330px, 15px, 30px);
        @include fluid-size(padding-right, 320px, 1330px, 15px, 25px);
		color: rgba($primary-94, .54);
        font-size: 14px;
		font-weight: 500;
		line-height: 1.4285;
		overflow: hidden;

		ul{
			margin-top: -3px;
			padding-left: 16px;
			color: rgba($dark-gray,.54);
			line-height: 1.285714285714286‬;
		}    
		ul li{
			position: relative;
			margin-top: 10px;

			&:before{
				content: '';
				width: 6px;
				height: 6px;            
				background-color: $primary-500;            
				transform: rotate(45deg);
				position: absolute;
				top: 7px;
				left: -16px;
			}
		}
	}

	&__preview ~ .accounts-list__text{
		margin: 13px 0 10px;
	}
}