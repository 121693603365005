.sticky-block {
  position: fixed;
  left: 48px; 
  right: 48px;
  bottom: 26px;
  padding: 22px 44px;
  background-color: $white;
  box-shadow: 0px 16px 56px 0px rgba(0, 0, 0, 0.16);
  z-index: 1000;

  @media screen and (max-width: $desktop-h) {
    inset-inline: 20px;
    padding: 22px 24px;
  }

  @media screen and (max-width: $tablet-h) {
    left: 12px;
    right: 12px;
    bottom: 12px;
    padding: 16px;
  }

  @media screen and (max-width: $phone-v) {
    left: 6px;
    right: 6px;
    bottom: 6px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: $phone-h) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    .button {
      flex-shrink: 0;
    }
  }

  &__title {
    font-family: $Noto;
    font-size: 22px;
    font-style: italic;
    font-weight: 700;
    line-height: 1.3;
    color: $dark-gray;
  }

  &__text {
    margin: 0;
    font-family: $Noto;
    font-size: 16px;
    font-weight: 900;
    line-height: normal;
    color: rgba(0, 0, 0, 0.32);
  }
}

.site:has(.sticky-block.compare-hidden) .compare__button-view {
  display: none;
}

.site:has(.sticky-block) {
  .footer {
    padding-bottom: 150px;

    @media screen and (max-width: $tablet-h) {
      padding-bottom: 205px;
    }
  }
}