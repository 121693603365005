.box-shape-img {
    position: relative;
    margin: 0 auto;
    padding: 19px 0 17px 121px;
    width: 100%;
    max-width: 991px;
    min-height: 288px;

    @media (max-width: 991px) {
        margin-bottom: 32px;
        padding: 66px 0 0;
    }

    &.is-small {
        margin-bottom: 32px;
        padding: 66px 0 0;
    }

    &__image {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        @media (max-width: 991px) {
            top: 0;
            left: 50%;
            transform-origin: center top;
            transform: translateX(-50%) scale(0.466);
        }
    }

    &.is-small &__image {
        top: 0;
        left: 50%;
        transform-origin: center top;
        transform: translateX(-50%) scale(0.466);
    }

    &__cont {
        padding: 29px 72px 39px 170px;
        min-height: 250px;
        border: 2px solid #b2bed1;

        @media (max-width: 991px) {
            padding-left: 150px;
            padding-right: 20px;
        }

        @media (max-width: 991px) {
            padding: 74px 16px 40px;
            text-align: center;
        }
    }

    &.is-small &__cont {
        padding: 74px 16px 40px;
        text-align: center;
    }

    &__title {
        margin-bottom: 4px;
        font-family: "Noto Serif",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        font-size: 35px;
        font-weight: 700;
        font-style: italic;
        color: $dark-gray;

        @media (max-width: 991px) {
            margin-bottom: 10px;
            font-size: 24px;
        }
    }

    &.is-small &__title {
        margin-bottom: 10px;
        font-size: 24px;
    }

    &__text {
        margin-bottom: 16px;
        font-family: "Noto Serif",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        font-size: 18px;
        font-weight: 700;
        font-style: italic;
        color: $dark-gray;
        line-height: 1.4;
        opacity: 0.8;

        @media (max-width: 991px) {
            font-size: 14px;
        }
    }

    &.is-small &__text {
        font-size: 14px;
    }

    &__link {
        position: relative;
        display: inline-block;
        padding-right: 30px;
        font-size: 15px;
        font-weight: 500;
        color: $primary-500;
        text-decoration: none;

        .i-arrow-right {
            position: absolute;
            top: 50%;
            right: 0;
            width: 22px;
            height: 22px;
            transform: translateY(-50%);

            &::before {
                font-size: 22px;
            }
        }

        &:hover {
            color: darken($primary-500, 10%);
        }
    }

    &__footer {
        margin-top: 44px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 991px) {
            margin-top: 32px;
            display: block;
            justify-content: flex-start;
            align-items: flex-start;

            .parsley-required {
                text-align: center;
            }
        }

        @media (max-width: $phone-v) {
            margin-left: auto;
            margin-right: auto;
            max-width: 230px;
        }

        .check-radio {
            margin-top: 10px;

            @media (max-width: 991px) {
                margin-top: 0;
                text-align: left;
            }
        }

        .check-radio__element {
            font-size: 16px;
            font-weight: 500;
            color: $primary-90;

            a {
                color: $primary-90;
            }
        }

        .button {
            margin-top: 10px;
            font-size: 16px;

            @media (max-width: 991px) {
                margin-top: 25px;
            }
        }
    }

    &.is-small &__footer {
        margin-top: 32px;
        display: block;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: auto;
        margin-right: auto;
        max-width: 230px;

        .parsley-required {
            text-align: center;
        }

        .check-radio {
            margin-top: 0;
            text-align: left;
        }

        .button {
            margin-top: 25px;
        }
    }
}
