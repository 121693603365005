/* upgrade browser */
.upgrade-browser{
	position: relative;
	top: 0;
	left: 0; right: 0;
	padding: 10px 60px 10px 0;
	text-align: center;
	background: #ffe490;
	z-index: 10000;

	a{
		height: 50px; width: 50px;
		display: inline-block;
		margin: 0 5px 0 0;
		vertical-align: middle;
		font-size: 0;
		background: url('../images/browser-sprite.jpg') no-repeat -1000px 0;

		&.chrome{
			background-position: 0 0;
		}

		&.ie{
			background-position: -50px 0;
		}

		&.firefox{
			background-position: -100px 0;
		}

		&.opera{
			background-position: -150px 0;
		}

		&.safari{
			background-position: -200px 0;
		}
	}

	span{
		display: inline-block;
		margin: 10px;
		font-size: 16px;
		line-height: 18px;
	}

	&__close{
		height: 50px; width: 50px;
		position: absolute;
		top: 10px; right: 10px;		
		cursor: pointer;
		background: url('../images/browser-sprite.jpg') no-repeat -250px 0;
	}
}
/* end of upgrade browser */