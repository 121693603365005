.compare{
    &-body{
        background: #f5f7fa;
    }

    &-header{
        width: 100%;
        position: relative;
        top: 0; left: 0;
        background: $primary-500;
        backface-visibility: hidden;
        z-index: 1000;
        .wrapper{
            height: 84px;
            transition: $transition;
        }
    }
    &-navigation{
        height: 100%;
        display: flex;
        flex-flow: row;
        align-items: flex-end;
        margin: 0 -15px;
        overflow: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        &__item{
            height: 100%;
            flex: 1 0 25%;
            display: flex;
            justify-content: center;
        }

        &__button{
            display: flex;
            flex-flow: row;
            align-items: center;
            position: relative;
            margin: 0 30px;
            padding-bottom: 4px;
            font-size: 26px;
            font-family: $Noto;
            font-weight: 700;
            font-style: italic;
            line-height: .9;
            color: rgba(255,255,255,.4);
            text-decoration: none;
            background: transparent;
            transition: $transition;
            white-space: nowrap;
            @include hover{
                color: white;
                var{
                    color: black;
                }
            }
            &.active{
                color: white;
                &:after{
                    content: '';
                    height: 0; width: 0;
                    position: absolute;
                    bottom: 0; left: 50%;
                    transform: translateX(-50%);
                    margin-bottom: -2px;
                    border-style: solid;
                    border-width: 12px;
                    border-color: transparent transparent #f5f7fa;
                }
                var{
                    color: black;
                }
            }
            var{
                display: inline-block;
                height: 20px; width: 20px;
                margin: 3px 0 0 9px;
                vertical-align: middle;
                color: $primary-500;
                font-size: 12px;
                font-weight: 500;
                font-family: $Inter;
                font-style: normal;
                line-height: 20px;
                text-align: center;
                background: white;
                border-radius: 100%;
            }
        }
    }

    &-table{
        min-width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
        &[data-items="1"]{
            th{
                width: 600px;
                &.compare-item__placeholder{
                    width: auto;
                    .compare-item{
                        max-width: inherit;
                    }
                }
                .compare-item{
                    max-width: 600px;
                }
            }
        }
        &[data-items="2"]{
            th{
                width: auto;
                &.compare-item__placeholder{
                    width: auto;
                }
            }
            .compare{
                &-item{
                    &__title{
                        @include fluid-size(font-size, 280px, 1330px, 22px, 26px);
                    }
                    .card{
                        &-features{
                            margin-bottom: 12px;
                            &__item{
                                flex: 1 0 100%;
                            }
                        }
                    }
                }
            }
        }
        &[data-items="3"]{
            th{
                width: 440px;
            }
            .compare{
                &-item{
                    &__title{
                        @include fluid-size(font-size, 280px, 1330px, 22px, 24px);
                    }
                    .card{
                        &-features{
                            margin-bottom: 12px;
                            &__item{
                                flex: 1 0 100%;
                            }
                        }
                    }
                }
            }
        }

        &__wrapper{
            overflow: hidden;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            margin: 42px -15px 120px;
            @media(max-width: $phone-h){
                margin-bottom: 80px;
            }
        }

        th{
            padding: 0 32px 16px 16px;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.2857142857;
            text-align: left;
            vertical-align: top;
            &.compare-item__placeholder{
                text-align: center;
            }
        }

        td{
            position: relative;
            padding: 18px 16px 16px;
            vertical-align: top;
            line-height: 1.2857142857;
            &:before{
                content: '';
                height: 1px;
                position: absolute;
                top: 0;
                left: 16px; right: 16px;
                background: #e0e0e0;
            }
        }

        .buttons{
            margin: 0 -16px;
            padding-top: 7px;
            text-align: center;
            .button{
                margin: 18px 20px 0;
            }
        }

        p{
            margin-bottom: 10px;
            &:last-child{
                margin-bottom: 0;
            }
        }

        .cell-title{
            color: $primary-500;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.125;
        }

        ul, ol{
            padding-bottom: 10px;
            &:last-child{
                padding-bottom: 0;
            }
        }
        ol{
            list-style: decimal;
            padding-left: 16px;
        }    
        ul{
            padding-left: 16px;
        }    
        ul li{
            position: relative;
            margin: 12px 0;
            &:last-child{
                margin-bottom: 0;
            }
            &:before{
                content: '';
                height: 6px; width: 6px;                
                background: $primary-500;            
                transform: rotate(45deg);
                position: absolute;
                top: 7px; left: -14px;
            }
        }
        ul ul,
        ul ol,
        ol ol,
        ol ul {
            margin-top: 10px;
            li {
                font-size: 13px;
                margin-bottom: 6px;
                &:before {
                    background-color: $primary-200;
                    width: 5px;
                    height: 5px;
                    top: 9px;
                    @media(max-width: $phone-h) {
                        top: 7px;
                    }
                }
            } 
        }
    }

    &-item{
        min-width: 400px;
        max-width: 426px;
        margin-right: -16px;
        @media(max-width: $phone-v){
            min-width: 91vw;
        }
        &__preview{
            position: relative;
            margin-bottom: 18px;
            padding-bottom: 44px;
            img{
                min-height: 200px;
                object-fit: cover;
            }
            &-bottom{
                height: 44px;
                position: absolute;
                bottom: 0;
                left: 0; right: 0;

                .tags{
                    position: absolute;
                    bottom: 0;
                    left: 0; right: 105px;
                    margin: 0;
                }
            }
        }
        &__remove{
            position: absolute;
            bottom: 0; right: 0;
            padding-right: 27px;
            color: $primary-94;
            font-size: 14px;
            font-weight: 500;
            font-family: $Inter;
            background: transparent;
            transition: $transition;
            @include hover{
                color: $accent-500;
            }
            &:after{
                content: "\ed37";
                font-family: 'fibank-icons';
                color: $accent-500;
                font-size: 22px;
                position: absolute;
                top: 50%; right: 0;
                transform: translateY(-50%);
                margin-top: -1px;
            }
        }

        &__title{
            margin-bottom: 32px;
		    color: $dark-gray;
            @include fluid-size(font-size, 280px, 1330px, 22px, 30px);
            font-family: $Noto;
            font-weight: 700;
            font-style: italic;
            line-height: 1.125;
            a{
                color: inherit;
                text-decoration: none;
                @include hover{
                    color: $primary-500;
                }
            }
        }
        &__placeholder{
            .compare{
                &-item{
                    &__title{
                        max-width: 400px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }

        .card{
            &-features{
                margin: -31px 0 18px;
                &__item{
                    display: flex;
                    flex-flow: row;
                    flex: 1 0 50%;
                    align-items: center;
                    margin-top: 18px;
                    @media(max-width: $phone-h){
                        flex: 1 0 100%;
                    }
                    &-icon{
						margin: 0;
						flex-basis: 50px;
						&__shape{
							height: 58px; width: 50px;
							&:before{
                                height: 52px; width: 46px;
                                top: 3px; left: 2px;
							}
							&:after{
                                height: 46px; width: 42px;
                                top: 6px;
							}
						}
					}
					&-text{
						margin: 0;
                        padding: 0 14px;
                        p{
                            margin: 0;
                        }
					}
                }
            }
        }
    }

    &__button{
        color: white;
        text-decoration: none;
        &-view,
        &-close{
            height: 56px; width: 64px;
            position: fixed;
            bottom: 40px; right: 36px;
            backface-visibility: hidden;
            background: transparent;
            z-index: 1000;
            @include hover{
                path{
                    fill: darken($accent-300, 8%);
                }
            }
            @media(max-width: $phone-h){
                bottom: 20px; right: 10px;
            }
            [class^="i-"],
            [class*=" i-"]{
                position: absolute;
                top: 50%; left: 50%;
                transform: translate(-50%, -50%);
                color: white;
                font-size: 26px;
                z-index: 2;
            }
        }
        &-close{
            opacity: 0;
            visibility: hidden;
        }
        &-count{
            height: 20px; width: 20px;
            position: absolute;
            top: 3px; right: -1px;
            padding-top: 2px;
            color: white;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            text-align: center;
            line-height: 1.2;
            background: $accent-500;
            border-radius: 100%;
            @media (min--moz-device-pixel-ratio:0) and (min-resolution: 3e1dpcm){
                line-height: 1;
            }
        }
        &-shape{
            path{
                fill: $accent-300;
                fill-rule: evenodd;
                transition: $transition;
            }
        }
    }

    &-popup{
        height: 100vh;
        max-height: 0;
        position: fixed;
        bottom: 0;
        left: 0; right: 0;
        overflow: hidden;
        background: #f5f7fa;
        transition: $transition2;
        z-index: 9999;
    }
}

.compare-header{
    opacity: 0;
    visibility: hidden;
    &-fixed{
        .compare{
            &-header{
                position: fixed;
                transform: translateY(-100%);
                .wrapper{
                    height: 72px;
                }
            }
            &-body{
                padding-top: 114px;
            }
            &-navigation{
                &__button{
                    font-size: 22px;
                }
            }
        }
    }
    &-transition{
        .compare{
            &-header{
                transition: $transition;
            }
        }
    }
    &-show{
        .compare{
            &-header{
                transform: translateY(0);
            }
        }
    }
}

.compare-enabled{
    overflow: hidden;

    .compare{
        &-popup{
            max-height: 100vh;
            max-height: calc(var(--vh, 1vh) * 100);
            overflow: auto;
        }
        &-header{
            opacity: 1;
            visibility: visible;
        }
        &__button{
            &-view{
                opacity: 0;
                visibility: hidden;
            }
            &-close{
                opacity: 1;
                visibility: visible;
            }
        }
    }
}