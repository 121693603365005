.useful-links{
	margin-top: 44px;
	display: flex;
	flex-flow: row wrap;
	color: $primary-94;
	font-size: 14px;
	line-height: 1.285714285714286‬;
	background: #ebf1f5;
	&:first-child{
		margin-top: 0;
	}

	&__row{
        flex: 1 0 25%;
        @media(max-width: $tablet-v){
            flex: 1 0 50%;
            border-top: 1px solid rgba($gray-060, .24);

            &:nth-child(1),
            &:nth-child(2){
                border-top-width: 0;
            }
            &:nth-child(2n+1){
                .useful-links__inner{
                    border-left-width: 0;
                }
            }
        }
        @media(max-width: $phone-v){
            flex: 1 0 100%;
            &:nth-child(2){
                border-top-width: 1px;
            }
            .useful-links__inner{
				border-left-width: 0;
			}
        }

		&:first-child{
			max-width: 25%;
			@media (max-width: $tablet-h){
				max-width: inherit;
			}
			.useful-links__inner{
				border-left-width: 0;
			}
		}
	}

	&__inner{
		height: 100%;
		padding: 31px 24px 32px;
		@include fluid-size(padding, 280px, 1330px, 27px 18px 28px, 31px 24px 32px);
		border-left: 1px solid rgba($gray-060, .24);
	}

	&__title{
		margin-bottom: 21px;
		@include fluid-size(font-size, 280px, 1330px, 16px, 18px);
		font-weight: 500;
	}

	a{
		color: $red;
		text-decoration: none;
		background-image: linear-gradient(currentColor, currentColor);
		background-position: 0 100%;
		background-repeat: no-repeat;
		background-size: 0 1px;
		transition: $transition;
		@include hover{
			background-size: 100% 1px;
		}
	}

	&__list{
		font-weight: 500;
		line-height: 1.2857;

		li{
			& + li{
				margin-top: 14px;
			}
			a{
				color: $primary-500;
			}
		}
	}

	&__table{
        width: 100%;
        table-layout: fixed;

		th{
			padding-bottom: 13px;
			font-size: 18px;
			font-weight: 500;
			vertical-align: middle;

			&:first-child{
				padding-right: 6px;
				text-align: left;
			}

			&:last-child{
                width: 60%;
				padding-left: 6px;
				text-align: right;
			}
		}

		td{
			padding-top: 6px;
			padding-bottom: 6px;
			font-size: 16px;
			font-weight: 500;
			vertical-align: middle;

			&:first-child{
				padding-right: 6px;
				text-align: left;
			}

			&:last-child{
				padding-left: 6px;
				text-align: right;
			}
		}
	}

	hr{
		margin: 14px 0;
	}

	.big{
		display: inline-block;
		margin-bottom: 7px;
		font-family: $Noto;
		font-style: italic;
		font-weight: 700;
		@include fluid-size(font-size, 280px, 1330px, 20px, 22px);
		color: black;
		word-wrap: break-word;
		word-break: break-all;
		text-decoration: none;
		a{
			color: inherit;
			text-decoration: none;
		}
	}

	&--alt{
		.useful-links{
			&__row{
				&:nth-child(2){
					flex: 1 0 50%;
				}
				@media(max-width: $tablet-v){
					&{
						border-top: 1px solid rgba($gray-060, .24);
					}
					&:nth-child(1){
						order: 1;
					}
					&:nth-child(2){
						order: 0;
						flex: 1 0 100%;
						border-top: 0;
					}
					&:nth-child(3){
						order: 2;
					}
				}
				@media(max-width: $phone-v){
					flex: 1 0 100%;
				}
			}
			&__inner{
				@media(max-width: $tablet-v){
					border: 0;
				}
			}
		}
	}
}