.packs-list{
    &__headers{
        position: sticky;
        top: 0;
        background: white;
        transition: top $transition;
        z-index: 2;
    }

    &__scroller{
        display: flex;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
        background-image:
        linear-gradient(to right, white, white),
        linear-gradient(to right, white, white),
        linear-gradient(to right, rgba(0,0,0,.1),rgba(255,255,255,0)),
        linear-gradient(to left, rgba(0,0,0,.1), rgba(255,255,255,0));
        background-position: left center, right center, left center, right center;
        background-repeat: no-repeat;
        background-color: white;
        background-size: 60px 100%, 60px 100%, 15px 100%, 15px 100%;
        background-attachment: local, local, scroll, scroll;

        &::-webkit-scrollbar{
            display: none;
        }
    }

    &__tracks{
        max-height: 550px;
        overflow: auto;
        overflow-y: hidden;
        position: relative;
        background-image:
        linear-gradient(to right, white, white),
        linear-gradient(to right, white, white),
        linear-gradient(to right, rgba(0,0,0,.1),rgba(255,255,255,0)),
        linear-gradient(to left, rgba(0,0,0,.1), rgba(255,255,255,0));
        background-position: left center, right center, left center, right center;
        background-repeat: no-repeat;
        background-color: white;
        background-size: 60px 100%, 60px 100%, 15px 100%, 15px 100%;
        background-attachment: local, local, scroll, scroll;
    }

    &__item{
        flex: 1 0 260px;
        position: relative;
        border: 1px solid #e0e0e0;
        border-top-width: 0;
        border-right-width: 0;

        &:before{
            content: '';
            height: 4px;
            position: absolute;
            top: 0;
            left: -1px; right: -1px;
            background: $gray-060;
        }

        &:first-child{
            flex: 1 0 240px;
            @media(max-width: 74.9em){
                flex: 1 0 230px;
            }
        }

        &:last-child{
            border-right-width: 1px;
        }

        &-header{
            height: 100%;

            &__inner{
                display: flex;
                flex-flow: column;
                height: 100%;
                padding: 30px 15px 76px;
                justify-content: start;
            }
        }

        &--empty{
            border-color: transparent;
            position: sticky;
            background: #fff;
            z-index: 999;
            left: 0;
            top: 0;
            border-right: 1px solid #e0e0e0;

            @media(max-width: $phone-s){
                position: static;
                background: transparent;
                z-index: 10;
                border-right: none;
            }

            &:before{
                display: none;
            }
        }

        .packs-list__or{
            padding: 9px 0 11px;
        }

        .button{
            position: absolute;
            left: 15px; bottom: 22px;
        }

        &--highlight{
            &:before{
                background: $primary-500;
            }

            .button{
                background: $primary-500;
                &:before,
                &:after{
                    background: $primary-500;
                }
            }
        }
    }

    &__row{
        display: flex;
        flex-flow: row;
        margin-top: -1px;

        &-item{
            flex: 1 0 260px;
            border: 1px solid #e0e0e0;
            border-right-width: 0;

            &:first-child{
                flex: 1 0 240px;
                position: sticky;
                left: 0;
                background: #fff;
                border-right: 1px solid #ebf1f5;

                @media(max-width: $phone-s){
                    position: static;
                    background: transparent;
                    border-right: none;
                }
                @media(max-width: 74.9em){
                    flex: 1 0 230px;
                }
            }

            &:last-child{
                border-right-width: 1px;
            }

            &__inner{
                height: 100%;
                padding: 20px 15px;
                color: $primary-90;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.15;

                p{
                    display: flex;
                }

                p ~ p{
                    margin-top: .625em;
                }

                small{
                    display: inline-block;
                    color: $primary-90;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 1.125;
                }

                [class^="i-"]:not(.external-icon),
                [class*=" i-"]:not(.external-icon){
                    color: $accent-400;
                    font-size: 20px;
                    margin: -2px 5px 0 0;
                    vertical-align: middle;
                }
            }
        }

        &--header{
            background: #ebf1f5;
            min-width: auto !important;
            position: sticky;
            left: 0px;

            .packs-list__row-item{
                border-color: #ebf1f5;
                background-color: #ebf1f5;
                max-width: 230px;

                @media(max-width: $phone-s){
                    background-color: transparent;
                    max-width: auto;
                }
            }

            .packs-list__row-item__inner{
                color: $dark-gray;
                font-size: 16px;
                font-weight: 500;
            }

            & + .packs-list__row{
                .packs-list__row-item{
                    border-top-color: #ebf1f5;
                }
            }
        }
    }

    &__title{
        margin: 0 0 11px !important;
        color: $dark-gray !important;
        @include fluid-size(font-size, 320px, 1330px, 20px !important, 26px !important);
        font-family: $Noto !important;
        font-weight: 700 !important;
        font-style: italic !important;
        line-height: 1.125 !important;

        a{
            color: $dark-gray;
            text-decoration: none;
            &:hover{
                color: rgba($dark-gray, .72);
            }
        }
    }

    &__price{
        color: $dark-gray;
        font-size: 15px;
        font-weight: 500;

        &-text{
            margin-top: -4px;
            font-size: 13px;
            font-weight: 500;
            line-height: 1.275;
        }
    }

    &__card-name{
        color: $primary-500;
        font-family: $Noto;
        font-size: 15px;
        font-weight: 700;
        font-style: italic;
        text-decoration: none;

        &:hover{
            text-decoration: underline;
        }
    }

    &__or{
        color: rgba($primary-90, .54);
        font-size: 12px;
    }

    &__card{
        display: flex;
        flex-flow: row;
        align-items: center;

        &-preview{
            flex: 0 0 44%;
            img{
                margin: 0;
            }
        }

        &-text{
            flex: 1 0 56%;
            padding-left: 12px;
        }

        &-name{
            font-size: 14px;
            line-height: 1.125;
        }

        &-or{
            padding: 12px 0 13px 44px;
            color: rgba($primary-90, .56);
            font-size: 12px;
        }
    }

    &__mask{
        height: 180px;
        width: 100%;
        position: absolute;
        bottom: 0; left: 0;
        background-image: linear-gradient(rgba(255,255,255,0) 0%, white 85%);
    }

    &__bottom{
        margin: 16px auto 48px;
        text-align: center;
    }

    &__holder--expanded{
        margin-bottom: 48px;

        .packs-list{
            &__tracks{
                max-height: inherit
            }
            &__mask,
            &__bottom{
                display: none;
            }
        }
    }

}

_:-ms-fullscreen, :root{
    .packs-list__row-item__inner p{
        display: block;
    }
}

.text{
    .packs-list{
        &__holder{
            line-height: 1.125;
            li,
            p{
                min-height: inherit;
                line-height: 1.125;
                color: $primary-90;
            }
            a{
                background-image: none;
            }
        }
        &__row{
            &-item{
                &__inner{
                    p{
                        font-size: 14px;
                    }
                }
            }
            &--header{
                .packs-list{
                    &__row{
                        &-item{
                            &__inner{
                                p{
                                    color: $dark-gray;
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &__card{
            &-preview{
                img{
                    margin: 0;
                }
            }
        }
    }
}

@media (max-width: $tablet-v){
    .show-fixed-header .packs-list__headers {
        top: 98px;
    }
}