.sitemap {
    font-size: 1.125rem;
    &__list {     
        & & {
            padding-left: 1em;
        }
        & & &-item {
            padding-left: 1em;        
            &:before {
                content: '';
                position: absolute;
                left: -0.5em;
                top: 0.625em;
                width: 1em;
                height: 1px;
                background: rgba($gray-060,.48);
            }
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: -0.5em;
                bottom: 0;
                width: 1px;
                background: rgba($gray-060,.48);
            }
            &:first-child {
                padding-top: 1em;
                &:before {
                    top: 1.625em;
                }
                &:after {
                    top: 0.625em;
                }
            }
            &:last-child {
                &:after {
                    bottom: auto;
                    height: 0.625em;
                }
            }
        }    
        &-item {  
            padding-bottom: 1em;                      
            position: relative;
            &:last-child {
                padding-bottom: 0;
            }
        }
        &-link {
            font-size: 1em;
            line-height: 1;
            color: $primary-500;
            text-decoration: none;
            transition: $transition;
            background-image: linear-gradient(currentColor, currentColor);
            background-position: 0 100%;
            background-repeat: no-repeat;
            background-size: 0 1px;
            transition: $transition;
            @media(max-width: $phone-v){
                font-size: 0.875em;
            }
            &--large {
                font-weight: 700;
                font-size: 1.125em;
                color: $accent-300;
                @media(max-width: $phone-v){
                    font-size: 1em;
                }
            }
            &--small {                
                font-size: .875em;
                color: $primary-90;
                @media(max-width: $phone-v){
                    font-size: .8125em;
                }
            }
            @include hover{
                background-size: 100% 1px;
            }
        }
    }    
}
