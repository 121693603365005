.system-message{
    color: white;
    font-size: 14px;
    line-height: 1.4285;
    background: $accent-300;
    position: relative;
    z-index: 9000;
    @media(max-width: $phone-v){
        font-size: 13px;
    }

    &-wrapper {
        position: relative;
    }

    .wrapper{
        position: relative;
        padding: 22px 100px 24px 20px;

        @media (orientation: landscape){
            @supports (left: env(safe-area-inset-left)){
                padding-left: calc(env(safe-area-inset-left) + 20px);
            }
        }

        @media (max-width: $phone-h){
            padding-right: 64px;
        }
    }

    &__title{
        display: block;
        padding-bottom: 5px;
        font-size: 18px;
        font-weight: 500;
    }

    &__close{
        position: absolute;
        top: 50%; right: 15px;
        background: transparent;
        transform: translateY(-50%);

        @media (max-width: $phone-h){
            top: 0;
            transform: translateY(0);
        }

        span{
            display: inline-block;
            height: 24px; width: 42px;
            position: relative;
            text-indent: -10000px;
            background: rgba(0,0,0,.12);
            margin: 21px 0;

            &:before,
            &:after{
                content: '';
                position: absolute;
                left: 0;
                width: 0;
                border-left: 21px solid transparent;
                border-right: 21px solid transparent;
            }

            &:before{
                bottom: 100%;
                border-bottom: 12.12px solid rgba(0,0,0,.12);
            }

            &:after{
                top: 100%;
                width: 0;
                border-top: 12.12px solid rgba(0,0,0,.12);
            }
        }

        [class^="i-"],
        [class*=" i-"]{
            position: absolute;
            top: 50%; left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-size: 20px;
        }
    }

    &__show {
        position: absolute;
        display: none;
        background-color: $accent-300;
        position: absolute;
        width: 32px;
        height: 27px;
        right: 8px;
        z-index: 9999;

        @media(max-width: $phone-sm){
            right: 4px;
            width: 24px;
            height: 24px;
        }
        &:before {
            content: '';
            width: 25px;
            height: 25px;
            position: absolute;
            z-index: -1;
            bottom: 0;
            left: 50%;
            right: 50%;
            transform: translate(-50%, 50%) scale(.9,.5) rotate(45deg);
            background-color: $accent-300;
            transition: background-color 250ms ease,color 250ms ease,border-color 250ms ease;
            @media(max-width: $phone-sm){
                transform: translate(-50%,50%) scale(.7,.5) rotate(45deg);
            }
        }
        &:after {
            content: '\e9b5';
            font-family: 'fibank-icons';
            color: $white;
            font-size: 26px;
            position: absolute;
            top: calc(50% + 3px);
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    a{
        color: inherit;
    }

    &--success{
        background: $accent-400;
        & ~ .wrapper .system-message__show,
        & ~ .wrapper .system-message__show:before {
            background-color: $accent-400;
        }
    }

    &--info{
        background: $primary-200;
        & ~ .wrapper .system-message__show,
        & ~ .wrapper .system-message__show:before {
            background-color: $primary-200;
        }
    }

    &--warning{
        background: $accent-500;
        & ~ .wrapper .system-message__show,
        & ~ .wrapper .system-message__show:before {
            background-color: $accent-500;
        }
    }

    &--alt{
        .wrapper{
            padding-right: 20px;
        }
    }
}