.account-preview{
	display: flex;
	flex-flow: row;
	align-items: center;
	min-height: 500px;
	position: relative;
	margin-bottom: 25px;

    @media(max-width: $tablet-v){
		margin: 0 -15px;
    }
    @media(max-width: $phone-h){
        display: block;
        min-height: inherit;
        background: #ebf1f5;
    }

	&__image{
		height: 100%;
		position: absolute;
		top: 0;
		left: 0; right: 0;
		overflow: hidden;
		background-color: black;
		z-index: 1;
		@media (orientation: landscape){
			@supports (left: env(safe-area-inset-left)){
				left: calc(env(safe-area-inset-left) - env(safe-area-inset-left) - env(safe-area-inset-left));
			}
		}

        @media(max-width: $phone-h){
            position: relative;
            height: 0;
            padding-top: 56.25%;
        }

		img{
			height: 100%;
			width: 100%;
			object-fit: cover;
		}

		&--left{
			img{
				object-position: left top;
			}
		}

		&--right{
			img{
				object-position: right top;
			}
		}

		video{
			min-height: 100%;
			height: auto;
			width: 100%;
			object-fit: cover;
			position: absolute;
			top: 50%; left: 50%;
			transform: translate(-50%, -50%);
			z-index: -1;

			&::-webkit-media-controls{
				display: none !important;
			}
			&::-webkit-media-controls-enclosure{
				display: none !important;
			}
        }
        
        @media(max-width: $phone-h){
            img,
            video{
                height: auto;
                position: absolute;
                top: 50%; left: 50%;
                transform: translate(-50%, -50%);
            }
        }

		&:before{
			content: '';
			height: 100%; width: 50%;
			position: absolute;
			top: 0; left: 0;
            background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.54) 31%, rgba(0,0,0,.54) 100%);
			z-index: 2;
			@media(max-width: $phone-h){
				display: none;
			}
		}
	}

	&__text{
		@include fluid-size(padding, 320px, 1330px, 20px, 40px);
		position: relative;
		color: white;
		font-size: 14px;
		font-weight: 500;
		line-height: 1.275;
		z-index: 2;
        @media(max-width: $phone-h){
            color: $primary-90;
        }
	}

	&__title{
		margin: 2px 0 0;
		@include fluid-size(font-size, 320px, 1330px, 20px, 42px);
		font-family: $Noto;
		font-weight: 700;
		font-style: italic;
		line-height: 1.125;
		word-wrap: break-word;
        word-break: break-word;
        @media(max-width: $phone-h){
            color: $dark-gray;
		}
		
		a{
			color: inherit;
			text-decoration: none;
		}
	}
}

.check-list{
	list-style: none;
    padding-left: 5px;
    @media(max-width: $phone-h){
        padding-left: 0;
    }
    @media(max-width: $phone-s){
        font-size: 13px;
    }

	li,
	&__item{
		position: relative;
		margin: 16px 0;
		padding-left: 20px;

		&:before{
			content: '\ea3e';
			font-family: 'fibank-icons';
			position: absolute;
			top: -6px; left: -4px;
			font-size: 22px;
		}
	}
}