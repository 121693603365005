.cards-slider{
	opacity: 0;
	visibility: hidden;
	max-height: 360px;
	overflow: hidden;
	position: relative;
	transition: $transition;
	z-index: 3;

	&.slick-initialized{
		opacity: 1;
		visibility: visible;
		max-height: inherit;
	}

	&__holder{
		min-height: 402px;
		position: relative;
		overflow: hidden;
		background: #ebf1f5;
		@media(max-width: $tablet-h){
			min-height: inherit;
		}
        @media(max-width: $tablet-v){
            margin: 0 -15px;
        }

		&:before{
			content: '';
			height: 100%; width: 332px;
			position: absolute;
			top: 0; left: 0;
			background: $accent-400;
			z-index: 1;
			@media(max-width: $tablet-v){
				height: 277px; width: 100%;
			}
		}

		&-hexagon{
			position: absolute;
			top: 50%; left: 206px;
			transform: translateY(-50%);
			z-index: 2;
			@media(max-width: $tablet-v){
				height: 151px;
				top: 126px; left: 50%;
				margin-left: 19px;
				overflow: hidden;
				transform: translate(-50%, 0);
			}
			
			path{
				fill: #2fb177;
				fill-rule: evenodd;
			}
		}

		.card-item{
			&__title{
				@media(max-width: $tablet-v){
					color: white;
					a:hover{
						color: white;
					}
				}
			}
		
			&__type{
				@media(max-width: $tablet-v){
					color: rgba(255,255,255,.4);
				}
			}
		}
	}

	.slick-track{
		display: flex;
	}

	.slick-slide{
		display: flex;
		height: auto;
		align-items: center;

		> div{
			display: flex;
			height: 100%;
			align-items: center;
		}
	}

	.slick-dots{
		position: absolute;
		bottom: 20px;
		left: 20px; right: 20px;
		text-align: center;
		@media(max-width: $phone-v){
			top: 20px; bottom: auto;
		}

		button{
			height: 7px; width: 7px;
			border: 0;
			text-indent: -10000px;
			background: #cfd4d8;
			border-radius: 10px;
			@media(max-width: $phone-v){
				height: 8px; width: 8px;
				background: transparent;
				border: 1px solid white;
			}
		}

		.slick-active{
			button{
				background: $primary-500;
				@media(max-width: $phone-v){
					background: white;
				}
			}
		}
	}
}

.card-item{
	display: flex;
	flex-flow: row;
	align-items: center;
	position: relative;
	padding-top: 80px;
	padding-bottom: 80px;
	@include fluid-size(padding-left, 320px, 1330px, 40px, 250px);
	@include fluid-size(padding-right, 320px, 1330px, 40px, 250px);

	@media(max-width: $tablet-h){
		padding-left: 53px;
		padding-right: 53px;
	}
    @media(max-width: $tablet-v){
		display: block;
		text-align: center;
	}
	@media(max-width: $phone-h){
		padding-top: 72px;
		padding-bottom: 32px;
	}
	@media(max-width: $phone-sm){
		padding-left: 35px;
		padding-right: 35px;
	}

	&__preview{
        max-width: 320px;
        position: absolute;
        top: 50%; left: 0;
		transform: translateY(-50%);
		transition: 450ms ease;

        @media(max-width: $tablet-v){
            position: relative;
			top: 0;
			margin: 20px auto 40px;
            transform: translateY(0);
        }

		img{
			width: 100%;
			max-height: 100%;
			border-radius: 12px;
		}
	}

	&__link{
		display: flex;
		flex: 1;
		text-decoration: none;
	}

	&__text{
		width: 100%;
        padding-left: 380px;
		position: relative;
        @media(max-width: $tablet-h){
            padding-left: 350px;
        }
        @media(max-width: $tablet-v){
			max-width: 320px;
			margin: auto;
			padding-left: 0;
        }
	}

	&__title{
		margin-bottom: 5px;
		color: $primary-94;
		@include fluid-size(font-size, 320px, 1330px, 30px, 38px);
		font-family: $Noto;
		font-weight: 700;
		font-style: italic;
		line-height: 1.125;
		transition: color $transition;
		a{
			color: inherit;
			text-decoration: none;

			&:hover{
				color: $primary-500;
			}
		}
        i {
            font-size: 0.6em;
			&:before{
				transition: none;
			}
        }
	}

	&__type{
		display: block;
		margin-bottom: 28px;
		color: rgba($primary-94, .4);
		font-style: normal;
		font-size: 15px;
		font-weight: 500;
		font-family: $Inter;
	}

	.card-features{
		@media(max-width: $tablet-v){
			margin: -18px 0 0;
			text-align: left;
		}
		&__item{
			@media(max-width: $tablet-v){
				flex: 1 0 100%;
				display: flex;
				align-items: center;
				margin-top: 18px;
				&-icon{
					margin: 0;
				}
			}
		}
	}

	&-preview{
		opacity: 0;
		visibility: hidden;
		display: flex;
		align-items: center;
		height: 100%; width: 320px;
		position: absolute;
		top: 0; left: 50%;
		transform: scale(.86);
		cursor: pointer;
		z-index: 2;
		@media(min-width: $tablet-v){
			transition: 450ms ease;
		}
		@media(max-width: $tablet-v){
			align-items: flex-start;
			padding-top: 184px;
			transition: opacity 450ms ease, visibility 450ms ease;
		}
		@media(max-width: $phone-v){
			left: auto;
			transform: scale(.7);
			padding-top: 169px;
		}
	}
}

.prev-slide{
	.card{
		&-item{
			&-preview{
				opacity: 1;
				visibility: visible;
				left: 100%;
				margin-left: -200px;
				@media(max-width: $tablet-h){
					margin-left: -270px;
				}
				@media(max-width: $tablet-v){
					margin-left: -200px;
				}
				@media(max-width: $phone-v){
					margin-left: -244px;
				}
				@media(max-width: $phone-s){
					margin-left: -254px;
				}
				img{
					border-radius: 12px;
				}
			}
		}
	}
}

.next-slide{
	.card{
		&-item{
			&-preview{
				opacity: 1;
				visibility: visible;
				left: -120px;
				@media(max-width: $tablet-h){
					left: -50px;
				}
				@media(max-width: $tablet-v){
					left: -120px;
				}
				@media(max-width: $phone-v){
					left: -76px;
				}
				@media(max-width: $phone-s){
					left: -66px;
				}
				img{
					border-radius: 12px;
				}
			}
		}
	}
}