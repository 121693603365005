.notification-box {
    position: fixed;
    z-index: 1000;
    bottom: 1.5em;
    right: 1.5em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    /*max-width: 38.5em;*/
    z-index: 10000;
    @media(max-width: $phone-v) {        
        max-width: none;
        width: 90%;
    }
}
.notification {
    display: inline-flex;
    justify-content: space-between;    
    align-items: center; 
    padding: 13px 16px 11px 15px;
    background-color: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.06);
    animation: notification-open $transition ;
    animation-fill-mode: forwards;
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 1s, transform 1s;    
    margin-bottom: 1em;
    &:last-child {
        margin-bottom: 0;
    }    
    &.is-closed {
        animation: notification-close $transition;
    }
    @keyframes notification-open {
        0% {
            opacity: 0;
            transform: translateX(100%);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
    @keyframes notification-close {
        0% {
            opacity: 1;
            transform: translateX(0);
        }
        100% {
            opacity: 0;
            transform: translateX(100%);
        }
    }
    &--info {
        
    }
    &--warning {
        
    }
    &--success {
        
    }
    &--error {
        .notification__button{
            color: $accent-500;
            background: rgba($accent-500, .16);
        }
    }
    &__icon { 
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 1.5em;
        height: 1.5em;
        fill: currentColor;        
    }
    &__close {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-left: 16px;
        padding: 0;
        border: 0;
        outline: none;
        background: #f1f1f1;
        i{
            color: $primary-500;
            font-size: 14px;
        }
    }
    &__text {
        font-size: 15px; 
        flex-grow: 1;
        padding: 0;
        margin: 0;
        color: $primary-90;
        & a {
            display: inline-block;
            font-size: 0.875em;
            text-decoration: none;
        }
    }
    &__button{
        display: inline-block;
        position: relative;
        top: -1px;
        margin-left: 10px;
        padding: 5px 8px 6px;
        font-size: 13px;
        font-weight: 500;
        border-radius: 2px;
        background: #f1f1f1;
    }
}

.compare__button-view ~ .notification-box,
.compare__button-close ~ .notification-box,
.mini-banner ~ .notification-box{
    bottom: 9em;
}