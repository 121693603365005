.presentation{
	opacity: 0;
	visibility: hidden;
	height: 500px;
	overflow: hidden;
	position: relative;
	user-select: none;
	transition: opacity $transition;
	z-index: 2;
	&.kwicks-processed{
		opacity: 1;
		visibility: visible;
		transition-delay: 500ms;

		~ .presentation-loader{
			opacity: 0;
			visibility: hidden;
			transition-delay: 500ms;
		}
	}
}

.presentation > * {
	display: block;
	overflow: hidden;
}

.presentation.kwicks-processed{
	> *{
		position: absolute;
	}
}

.presentation{
    @media(max-width: $desktop){
		margin-left: -15px;
		margin-right: -15px;
	}

	&-loader{
		position: absolute;
		top: 50%; left: 50%;
		transform: translate(-50%, -50%);
		transition: $transition;
		z-index: 1;
		@media(max-width: $tablet-v){
			display: none;
		}
	}

	&__wrap{
		position: relative;
		background: white;	  
	}
	
	&__link-wrap{
		display: none;
		position: absolute;
		top: 0; bottom: 0;
		left: 0; right: 0;
		text-indent: -10000px;
		background: rgba(255,255,255,0);
		z-index: 5;
	}

	&__item{
		height: 100%;
		float: left;
		cursor: pointer;
		position: relative;
		@include hover{
			.presentation{
				&__item{
					&--title{
						color: darken(white, 10%);
					}
				}
			}
		}
        @media(max-width: $tablet-v){
            width: 100% !important;
            left: 0 !important;
            display: none;
            &:first-child{
                display: block;
            }
		}
		
		> *{
			overflow: visible;
		}

		&:before{
			content: '';
			height: 24px; width: 24px;
			position: absolute;
			top: 20px; left: 50%;
			border: 1px solid white;
			transform: rotateZ(45deg);
			transform-origin: left top;
			transition: $transition;
			z-index: 2;
		}

		&:after{
			content: '\ec6d';
			font-family: 'fibank-icons';
			position: absolute;
			top: 27px; left: 50%;
			transform: translateX(-50%);
			color: white;
			font-size: 18px;
			transition: $transition;
			z-index: 2;
		}

		&--title{
			max-width: 400px;
			position: absolute;
			left: 50%; bottom: 24px;
			color: white;
			font-size: 18px;
			font-family: $Noto;
			font-style: italic;
			font-weight: 700;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			transform: rotateZ(-90deg) translateY(50%) translateZ(0);
			transform-origin: left bottom;
			transition: $transition;
			z-index: 3;
		}

		&.kwicks-expanded{
			cursor: default;

			&:before,
			&:after{
				opacity: 0;
				visibility: hidden;
			}

			.presentation{
				&__image{
					picture{
						transition: transform 100ms ease-out;
						&:before{
							opacity: 0;
						}
					}
					&--overlay{
						picture{
							&:before{
								opacity: 1;
								background: rgba(0,0,0,.32);
							}
						}
					}
					img{
						filter: blur(0);
						&.align-left{
							object-position: left center;
						}
						&.align-right{
							object-position: right center;
						}
					}
				}				
			}

			.presentation__item--title{
				opacity: 0;
				visibility: hidden;
			}
		}

		&.kwicks-completed{
			.presentation{
				&__link-wrap{
					display: block;
				}
				&__text{
					opacity: 1;
					visibility: visible;
					transform: translateY(0);
					transition-duration: 350ms;
					transition-delay: 150ms;
				}
			}
		}
	}

	&__image{
		position: absolute;
		top: -5px; bottom: -5px;
		left: -5px; right: -5px;
		background-color: black;
		picture{
			&:before{
				content: '';
				height: 100%; width: 100%;
				position: absolute;
				top: 0; left: 0;
				transition: $transition;
				background: rgba(0,0,0,.32);
				z-index: 1;
			}
		}

		&.object-fit-container{
			position: absolute !important;
		}

		picture{
			display: block;
			position: absolute;
			top: -40px; bottom: -40px;
			left: -40px; right: -40px;
		}

		img{
			height: 100%; width: 100%;
			object-fit: cover;
			filter: blur(2px);
			transition: $transition;
		}
	}

	&__text{
		opacity: 0;
		visibility: hidden;
		max-height: 90%;
		max-width: 70%;
		position: absolute;
		bottom: 45px; left: 5px;
		overflow: hidden;
		padding: 52px 40px 0;
		color: white;
		font-size: 14px;
		font-weight: 500;
		line-height: 1.4;
		transition: 100ms ease;
		transform: translateY(50px);
		z-index: 3;

		ul{
			list-style: none;

			li{
				position: relative;
				margin: 16px 0;
				padding-left: 20px;

				&:before{
					content: '\ea3e';
					font-family: 'fibank-icons';
					position: absolute;
					top: -6px; left: -4px;
					font-size: 22px;
				}
			}
		}
	}
	&__title{
		margin: 0 0 16px;
		padding: 0;
		color: white;
		@include fluid-size(font-size, 280px, 1330px, 22px, 42px);
		font-family: $Noto;
		font-weight: 700;
		font-style: italic;
		line-height: 1.238;
	}
}

@media(max-width: $tablet-h){
	.presentation{
		&__text{
			max-width: inherit;
			right: 5px;
		}
	}
}

@media(max-width: $tablet-v){
	.presentation.kwicks-processed > * {
		position: relative;
	}

	.presentation{
		opacity: 1;
		visibility: visible;
		height: auto;
		margin: 0 -15px;
        background: $primary-800;
        @media (orientation: landscape){
            @supports (left: env(safe-area-inset-left)){
                margin-left: calc(env(safe-area-inset-left) - env(safe-area-inset-left) - env(safe-area-inset-left) - 15px);
            }
		}

		&__item{
			cursor: default;

			&:before,
			&:after,
			&--title{
				display: none;
			}
		}

		&__image{
			position: relative;
			top: 0; bottom: 0;
			left: 0; right: 0;

			&.object-fit-container{
				position: relative !important;
			}

			picture{
				height: 0;
				position: relative;
				top: 0; bottom: 0;
				left: 0; right: 0;
				overflow: hidden;
				padding-top: 42.8571%;
				transform: none !important;
			}
			
			img{
				position: absolute;
				top: 0; left: 0;
				filter: blur(0);
			}
		}

		&__text{
			opacity: 1;
			visibility: visible;
			max-height: inherit;
			max-width: inherit;
			position: relative;
			overflow: visible;
			padding: 44px 20px 14px;
			left: 0; right: 0;
			bottom: 0;
			transform: translateY(0);
			@media(max-width: $tablet-v){
				padding: 33px 16px 14px;
			}

			@media (orientation: landscape){
				@supports (left: env(safe-area-inset-left)){
					padding-left: calc(env(safe-area-inset-left) + 16px);
				}
			}

			.tags{
				position: absolute;
				top: -12px;
				left: 16px; right: 16px;
				overflow: hidden;
				white-space: nowrap;
				@media (orientation: landscape){
					@supports (left: env(safe-area-inset-left)){
						left: calc(env(safe-area-inset-left) + 16px);
					}
				}
			}
		}

		.slick-dots{
			overflow: visible;
			position: absolute;
			top: 22px; left: 16px;
			margin-top: 42.8571%;
			transition: $transition;

			@media (orientation: landscape){
				@supports (left: env(safe-area-inset-left)){
					left: calc(env(safe-area-inset-left) + 16px);
				}
			}

			li{
				margin: 0 2px 0 0;

				button{
					height: 2px; width: 15px;
					position: relative;
					text-indent: -10000px;
                    background: rgba(255,255,255,.32);
                    border: 0;
					border-radius: 0;
					&:after{
						content: '';
						position: absolute;
						top: -10px; bottom: -10px;
						left: 0; right: 0;
						background: rgba(255,255,255,0);
					}
				}

				&.slick-active{
					button{
						background: white;
					}
				}
			}
		}
	}

	.presentation__link-wrap{
		display: block;
	}
}

@media(max-width: $phone-v){
	.presentation{
		&__image{
			picture{
				padding-top: 56.25%;
			}
		}

		.slick-dots{
			margin-top: 56.25%;
		}
	}
}
