.selected-slider{
	opacity: 0;
	visibility: hidden;
	max-height: 245px;
	overflow: hidden;
	margin: -10px;
	@include fluid-size(margin-right, 580px, 1330px, -15px, -30px);
	transition: $transition;

	@media(max-width: $phone-v){
		opacity: 1;
		visibility: visible;
		max-height: inherit;
		margin-right: 0;
	}

	&:after{
		content: '';
		height: 100%;
		@include fluid-size(width, 580px, 1330px, 16px, 32px);
		position: absolute;
		top: 0; right: 0;
		background: white;

		@media(max-width: $tablet-v){
			display: none;
		}
	}

	&__wrap{
		overflow: hidden;
		padding: 0 10px;
	}

	&.slick-initialized{
		opacity: 1;
		visibility: visible;
		max-height: inherit;
	}

	&__holder{
		position: relative;
		@media(max-width: $tablet-v){
			margin-right: -15px;
		}
		@media(max-width: $phone-v){
			margin-right: 0;
		}
	}

	&__item{
		padding: 10px 0;
		color: white;
		transform-style: preserve-3d;

		@media(max-width: $phone-v){
			display: none;

			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3){
				display: block;
			}
		}

		&-wrap{
			position: relative;
			margin: 0;
			@include fluid-size(margin-right, 580px, 1330px, 16px, 32px);

			@media(max-width: $phone-v){
				margin: 0 0 16px;
			}
		}
	}

	figure{
		position: relative;

		&:after{
			content: '';
			position: absolute;
			top: 80px; bottom: 0;
			left: 0; right: 0;
			background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 34%, rgba(0, 0, 0, 0.48) 100%);
		}

		img{
			max-height: 242px;
			width: 100%;
			object-fit: cover;
		}
	}

	figcaption{
		padding: 0 24px 27px;
		position: absolute;
		bottom: 0;
		left: 0; right: 0;
		z-index: 2;
		
	}

	&__title{
		font-size: 22px;
		line-height: 1.125;
		font-family: $Noto;
		font-weight: 700;
		font-style: italic;
	}

	&__link{
		position: absolute;
		top: 0; bottom: 0;
		left: 0; right: 0;
		text-indent: -10000px;
		z-index: 2;
	}

	&__controls{
		position: absolute;
		bottom: 100%; right: 0;
		margin-bottom: 25px;
		padding: 0 15px;
		background: $primary-400;
		clip-path: polygon(18% 0%, 82% 0%, 100% 45%, 100% 45%, 82% 100%, 18% 100%, 0 45%, 0 45%);
		@media(max-width: $tablet-v){
			right: 15px;
		}

		.slick-arrow{
			height: 40px; width: 20px;
			position: relative;
			margin: 0 4px;
			text-indent: -10000px;
			border: 0;
			background: transparent;
			@include hover{
				&:before{
					color: darken($primary-500, 10);
				}
			}

			&:before{
				content: '\e99c';
				font-family: 'fibank-icons';
				position: absolute;
				top: 50%; left: 50%;
				transform: translate(-50%, -50%);
				color: $primary-500;
				font-size: 24px;
				text-indent: 0;
				transition: $transition;
			}

			&.slick-next{
				&:before{
					content: '\e99d';
				}
			}
		}
	}

	&__button{
		display: none;
		margin-left: auto;
		margin-right: auto;
		@media(max-width: $phone-v){
			display: block;
		}
	}

	&--horizontal{
		border: 2px solid gold;
	}

}

.hide-controls{
	.selected-slider__controls{
		display: none;
	}
}