.site-body{
    .search-form{
        margin: 20px auto 15px;
        &--spaced{
            @include fluid-size(margin-bottom, 320px, 1330px, 33px, 63px);
        }
        &--no-spaced{
            margin-top: 0;
            margin-bottom: 0;
        }
    
        &__inner{
            position: relative;
            padding-right: 55px;
            background: #f4f4f4;
        }
    
        &--clear{
            .search-form__inner{
                padding-right: 80px;
            }
        }
    
        &__input:not([type="checkbox"]):not([type="radio"]){
            padding: 23px 17px 22px 17px;
            border: 0;
            font-weight: 500;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background: transparent;
        }
    
        &__button{
            height: 20px; width: 20px;
            border: 0;
            background: transparent;
            position: absolute;
            top: 50%; right: 18px;
            transform: translateY(-50%);
    
            [class^="i-"],
            [class*=" i-"]{
                position: absolute;
                top: 50%; left: 0;
                transform: translateY(-50%);
                font-size: 24px;
                color: $primary-500;
            }
        }
    
        &__icon{
            position: absolute;
            top: 50%; right: 17px;
            transform: translateY(-50%);
    
            &:before{
                color: $primary-500;
                font-size: 24px;
            }
    
            &--gray{
                &:before{
                    color: $gray-060;
                }
            }
        }
    
        &__clear{
            height: 16px; width: 16px;
            position: absolute;
            top: 50%; right: 55px;
            margin-top: 1px;
            border: 0;
            text-indent: -10000px;
            background: #c2c2c2;
            border-radius: 20px;
            transform: translateY(-50%);        
    
            &:before{
                content: '\ec3c';
                font-family: 'fibank-icons';
                color: white;
                font-size: 10px;
                text-indent: 0;
                position: absolute;
                top: 50%; left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    
        &__text{
            padding-top: 9px;
            color: rgba($primary-94, .54);
            font-size: 13px;
            font-weight: 500;
            line-height: 1.125;
    
            a{
                text-decoration: none;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
}