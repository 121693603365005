.tabs {    
    display: flex;
    flex-wrap: wrap;
    background: $primary-400;
    margin-bottom: 8px;
    @media(max-width: $tablet-v){
        display: block;
        background: transparent;
    }
    &--alt {
        justify-content: center;
        background: transparent;
    }
    &--left{
        justify-content: flex-start;
    }
    &__link {
        display: flex;
        align-items: center;
        font-size: 16px;    
        line-height: 1.125;
        padding: 22px 36px;
        text-decoration: none;
        color: rgba($dark-gray, 0.88);
        transition: background $transition, color $transition;
        user-select: none;
        /*max-width: 25%;*/
        text-align: center;
        @media(max-width: $tablet-h){
            padding: 18px 14px;
            font-size: 14px;
        }


        .tabs--alt & {
            padding: 14px 24px;
            background: $primary-400;
            @media(max-width: $tablet-v){
                padding: 27px 0 0;
                background: transparent;
            }
        }
        &.is-active {
            font-weight: 500;
            background: $primary-500;
            color: $white;            
        }        
        &:not(.is-active):hover {
            background: $gray-002;
        }
    }
    &__content {
        background: $white;
        width: 100%;
        flex-basis: 100%;
        display: none;
        order: 1;
        padding: 40px 0 0 0;
        border-top: 2px solid $white;
        &.is-active {
            display: block;
            border-top-color: $primary-500;            
        }
        .site-block{
            padding: 20px 0 !important;
            &:first-child{
                margin-top: 0;
                padding-top: 0;
            }
            &:last-child{
                margin-bottom: 0 !important;
                padding-bottom: 0 !important;
            }
        }
    }
    @media(max-width: $tablet-v) {
        &--alt {
            display: block;
            background: transparent;
        }
        &__link {
            margin-top: 13px;
            padding: 27px 0 0;
            color: $dark-gray;
            font-size: 24px;
            font-family: $Noto;
            font-weight: 700;
            font-style: italic;
            pointer-events: none;
            border-top: 1px solid rgba($gray-060, .32);

            &:first-child{
                margin-top: 0;
                padding-top: 0;
                border-top: 0;
            }

            &.is-active {
                color: $dark-gray;
                font-weight: 700;
                background: transparent;
            }
        }
        &__content {            
            display: block;
            order: initial;
            padding: 20px 0;
            &.is-active {
                border-top-color: $white;
            }
        }
    }

    &--icons{
        justify-content: center;
        background: transparent;
        .tabs{
            &__link{
                padding: 12px 29px 13px;
                color: $dark-gray;
                background: transparent;
                clip-path: polygon(8% 0, 92% 0, 100% 50%, 92% 100%, 8% 100%, 0 50%);
                &:hover{
                    background: transparent;
                }
                [class^="i-"],
                [class*=" i-"]{
                    margin: -1px 9px 0 0;
                    font-size: 24px;
                }

                &.is-active{
                    color: white;
                    font-weight: 400;
                    background: $accent-300;
                }
            }
            &__content{
                &.is-active{
                    border-top: 0;
                }
            }
        }
        @media(max-width: $tablet-v){
            .tabs{
                &__link{
                    padding: 27px 0 0;
                    clip-path: none;
                    text-align: left;
                    &:first-child{
                        padding-top: 0;
                    }
                    &.is-active{
                        color: $dark-gray;
                        font-weight: 700;
                        background: transparent;
                    }
                    @media(max-width: $phone-sm){
                        font-size: 20px;
                    }
                }
            }
        }
    }

    //reservation hall
    &--reservation-hall {
        margin: 0 auto;
        max-width: 720px;
        width: 100%;

        .button.button--medium.button--primary {
            margin-top: 20px;
        }

        .tabs__link {
            padding: 18px 28px;
            font-size: 18px;
        }


        @media(max-width: $tablet-v) {
            &.tabs--alt {
                display: flex;
            }
            .tabs {
                &__content {
                    display: none;
                    order: 1;

                    &.is-active {
                        display: block;
                    }
                }

                &__link {
                    width: 50%;
                    margin-top: 0;
                    padding: 16px;
                    font-size: 18px;
                    pointer-events: all;
                    border-top: none;
                    background: $primary-400;

                    &.is-active {                        
                        font-weight: 500;
                        background: $primary-500;
                        color: #fff;
                    } 
                }
            }
        }

        .datepicker {
            width: 100%;
        }

        .datepicker-item--100 {
            width: 100%;
        }

        .datepicker input:not([type="checkbox"]):not([type="radio"]):disabled {
            cursor: default;
        }

        .datepicker--dis input:not([type="checkbox"]):not([type="radio"]):disabled {
            opacity: 0.6;
        }

        // .datepicker input:not([type=checkbox]):not([type=radio]) {
        //     padding-right: 84px;
        // }

        .datepicker-center {
            padding: 0;
        }

        .datepicker-item {
            position: relative;
        }

        input:not([type=checkbox]):not([type=radio]), 
        select, 
        textarea {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .datepicker input:not([type=checkbox]):not([type=radio]) {
            font-weight: 400;
        }

        .pika-single.is-bound {
            position: absolute !important;
            right: 12px !important;
        }

        
        .pika-day {
            opacity: 0.5;
            pointer-events: none;
        }

        .has-event .pika-day {
            opacity: 1;
            pointer-events: all;
        }

        // .datepicker:after {
        //     right: 60px;

        //     @media(max-width: $phone-h) {
        //         right: 45px;
        //     }
        // }

        .datepicker.disabled:after {
            opacity: 0.5;
        }

        .datepicker-button {
            position: absolute;
			top: 50%; 
            right: 20px;
            top: 26px;
			// transform: translateY(-50%);
            width: 24px;
            height: 24px;
            z-index: 10;
            background-color: transparent;

            &[disabled] {
                opacity: 0.5;
            }
        }

        .datepicker-button::before {
            content: '\ea4c';
			color: $primary-500;
			font-family: 'fibank-icons';
            font-size: 24px;
            width: 24px;
            height: 24px;
        }
        
        .datepicker-time {
            position: absolute;
            right: 12px;
            left: 12px;
            .select {
                cursor: default;
                // min-height: 0; 
                background-color: transparent;
            }

            .select2-container {
                // margin-top: -10px;
            }
            .selection {
                display: none;
                position: absolute;
            }
        }

        .select2-results__option {
            &:nth-child(1) {
                padding: 0;
            }
        }

        .custom-phone .select2-results__option {
            padding: 16px;
        }

        .datepicker-time__box {
            position: relative;

            .select {
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    inset: 0;
                    z-index: 3;
                }
            }

            .select2-container--default .select2-selection--single .select2-selection__arrow:after {
                content: '\ea4c';
                color: $primary-500;
                font-family: 'fibank-icons';
                font-size: 24px;
                width: 24px;
                height: 24px;
            }

            .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow {
                transform: translateY(-50%) translateZ(0);
            }
        }

        .label-time {
            position: absolute;
            display: flex;
            align-items: center;
            width: 100%;
            color: rgba($primary-90, .64);
            min-height: 64px;
            padding: 12px 17px 12px;
            cursor: default;
            color: #445267;
            font: normal 16px/1.2 $Inter;
            z-index: 2;
        }

        .datepicker-time__box.disabled  {
            opacity: 0.6;
            pointer-events: none;

            .label-time {
                opacity: 0.6;
            }
        }

        // .select.disabled {
        //     opacity: 0.6;
        //     pointer-events: none;
        // }

        // .select2-container--disabled {
        //     opacity: 0.6;
        // }
    }
}
