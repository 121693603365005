.accounts-grid{
	display: flex;
	flex-flow: row wrap;
    @include fluid-size(margin-top, 320px, 1330px, -12px, -48px);
	@include fluid-size(margin-left, 767px, 1330px, -10px, -48px);
	@media(max-width: $tablet-v){
		margin-left: 0;
	}

	&__item{
        flex: 0 0 50%;
		@include fluid-size(margin-top, 320px, 1330px, 12px, 48px);
		@media(max-width: $tablet-v){
			flex: 0 0 100%;
			& + &{
				margin-top: 24px;
				padding-top: 24px;
				border-top: 1px solid rgba($gray-060, .32);
			}
		}
	}

	&__inner{
		@include fluid-size(margin-left, 767px, 1330px, 10px, 48px);
		@media(max-width: $tablet-v){
			margin-left: 0;
		}
	}

	&__preview{
		margin-bottom: 24px;

		img{
			width: 100%;
			min-height: 195px;
			object-fit: cover;
		}
	}

	&__title{
		color: $dark-gray;
		@include fluid-size(font-size, 320px, 1330px, 18px, 26px);
		font-family: $Noto;
		font-weight: 700;
		font-style: italic;
		line-height: 1.125;
		@media(max-width: $tablet-v){
			min-height: inherit !important;
		}

		a{
			color: inherit;
			text-decoration: none;

			&:hover{
				color: $primary-500;
			}
		}
	}

	@media(max-width: $tablet-h){
		.card-features__item,
		.card-features--horizontal .card-features__item{
			flex: 1 0 100%;
		}
	}

	@media(max-width: $tablet-v){
		.card-features__item,
		.card-features--horizontal .card-features__item{
			flex: 1 0 50%;
		}
	}

	@media(max-width: $phone-v){
		.card-features__item,
		.card-features--horizontal .card-features__item{
			flex: 1 0 100%;
		}
	}
	
}