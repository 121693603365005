.needs{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin: -50px 0 -25px;
	user-select: none;
	@media(max-width: $tablet-v){
		margin: -25px 0 0;
	}
	@media(max-width: $phone-v){
		justify-content: flex-start;
	}
	&--active{
		.needs{
			&-selected{
				&:before,
				&:after{
					opacity: 1;
					visibility: visible;
				}
				p{
					&:after{
						transform: translateY(-47%) rotateZ(180deg);
					}
				}
			}
		}
	}

	&-title{
		margin: 24px 16px 0 0;
		color: $dark-gray;
		@include fluid-size(font-size, 320px, 1330px, 24px, 38px);
		font-weight: 400;
		font-family: $Noto;
		line-height: 1;
		@media(max-width: $phone-v){
			flex: 0 0 100%;
		}
	}

	&-button{
		margin: 24px 16px 0 0;
		@media(max-width: $phone-h){
			margin-right: 0;
		}
	}

	&-link{
		margin: 24px 0 0;
		text-align: center;
		@media(max-width: $tablet-h){
			order: 4;
			flex: 1 0 100%;
		}
	}

	&-selected{
		display: flex;
		align-items: center;
		color: $primary-500;
		@include fluid-size(font-size, 320px, 1330px, 24px, 34px);
		font-weight: 700;
		font-style: italic;
		font-family: $Noto;
		line-height: 1;
		text-align: left;
		cursor: pointer;
		background: transparent;
		padding-right: 35px;
		position: relative;
		&:before,
		&:after{
			content: '';
			opacity: 0;
			visibility: hidden;
			height: 0; width: 0;
			border-style: solid;
			border-width: 10px;
			border-color: transparent transparent $gray-060;
			position: absolute;
			top: 100%; left: 50%;
			transform: translateX(-50%);
			margin-top: -3px;
			z-index: 1;
			transition: $transition;
		}
		&:after{
			border-color: transparent transparent white;
			margin-top: 0;
			z-index: 2;
		}
		@media(max-width: $phone-v){
			flex: 0 0 100%;
		}
		&__icon{
			display: flex;
			align-items: center;
			margin-right: 16px;
			height: 72px;
			width: 72px;
			img {
				max-width: 72px;
			}
		}
		p{
			&:after{
				content: "\e99f";
				font-family: 'fibank-icons';
				font-style: normal;
				font-size: 34px;
				font-weight: 400;
				position: absolute;
				top: 50%; right: 0;
				margin: 2px -3px 0 0;
				transform: translateY(-50%);
				transition: $transition;
			}
		}
	}

	&-options{
		display: none;
		width: 100%;
		@media(max-width: $tablet-h){
			order: 3;
		}
		&__inner{
			max-width: 1160px;
			margin: 16px auto 0;
			border-top: 2px solid $gray-060;
		}
	}

	&-list{
		display: flex;
		flex-wrap: wrap;
		padding: 24px 0 0 26px;
		@media(max-width: $phone-h){
			padding-left: 0;
		}
		&__item{
			display: flex;
			flex: 0 0 50%;
			align-items: center;
			padding: 10px 0;
			@media(max-width: $phone-h){
				flex: 0 0 100%;
			}
		}
		&__button{
			display: flex;
			align-items: center;
			padding-right: 25px;
			color: $dark-gray;
			@include fluid-size(font-size, 320px, 1330px, 20px, 26px);
			font-weight: 700;
			font-style: italic;
			font-family: $Noto;
			line-height: 1;
			text-align: left;
			border: 0;
			background: transparent;
			transition: color $transition;
			@include hover{
				color: $primary-500;
			}
			&.active{
				color: $primary-500;
			}
			.needs{
				&-selected{
					&__icon{
						height: 44px;
						width: 44px;
						margin-right: 25px;
					}
				}
			}
		}
	}
}