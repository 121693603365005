.scrollmagic-pin-spacer{
    overflow: hidden;
}

.scrollable{
    &-list{
        display: flex;
        flex-wrap: row nowrap;
        transform-style: preserve-3d;
        will-change: transform;
        padding-top: 25px;
        &.history-list,
        &.awards-list{
            transition: transform 350ms ease-out;
        }
        &.history-list{
            .scrollable-list__item{
                flex: 0 0 500px;
                margin-left: 35px;
                margin-right: 35px;
                text-align: center;
                font-size: 20px;
                @media(max-width: $phone-v){
                    flex: 0 0 270px;
                    flex: 0 0 80vw;
                    margin-left: 15px;
                    margin-right: 15px;
                }
            }
        }

        &__wrap{
            width: 100%;
            position: relative;
            &.loading{
                .scrollable-list__loader{
                    opacity: 1;
                    visibility: visible;
                }
            }
            &:after{
                content: '';
                height: 20px; width: 100%;
                position: absolute;
                bottom: 0; left: 0;
                background: white;
            }
        }
        &__loader{
            opacity: 0;
            visibility: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0; bottom: 0;
            left: 0; right: 0;
            background: rgba($gray-002, .8);
            transition: $transition;
            z-index: 3;
        }
        &__scroller{
            overflow: hidden;
            overflow-x: visible;
            -webkit-overflow-scrolling: touch;
            padding-bottom: 50px;
        }
        &__controls{
            display: flex;
            flex-flow: row;
            justify-content: center;
            align-items: center;
            padding: 17px 0;
            &-item{
                font-family: $Noto;
                font-size: 22px;
                font-weight: 700;
                color: rgba($dark-gray, .18);
                &--spacer{
                    @include fluid-size(padding-left, 280px, 1330px, 12px, 40px);
                    @include fluid-size(padding-right, 280px, 1330px, 12px, 40px);
                }
            }
            &-button{
                font-family: $Noto;
                @include fluid-size(font-size, 280px, 1330px, 24px, 42px);
                font-weight: 700;
                font-style: italic;
                color: rgba(#6f7b8d, .54);
                transition: $transition;
                background: transparent;
                &.active{
                    color: $dark-gray;
                }
            }
        }
        &__sub-controls{
            max-width: 90%;
            max-width: calc(100vw - 30px);
            margin: 10px auto 23px;
            padding: 0 30px 10px;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            position: relative;
            z-index: 3;
            &::-webkit-scrollbar{
                width: 0;
            }
            &::-webkit-scrollbar-thumb{
                background: transparent;
                border: 0;
                border-radius: 0;
            }
            &-button{
                margin: 5px 9px;
                color: rgba($primary-90, .64);
                font-size: 14px;
                font-weight: 500;
                transition: $transition;
                background: transparent;
                &.active{
                    color: $primary-500;
                }
            }
        }

        &__item{
            flex: 0 0 630px;
            margin-right: 35px;
            margin-left: 60px; 
            font-size: 14px;
            line-height: 1.571428;
            overflow: hidden;
            @media(max-width: $phone-v){
                flex: 0 0 270px;
                flex: 0 0 80vw;
                margin-left: 15px;
                margin-right: 15px;
                &:last-child{
                    padding-right: 30px;
                }
            }
            &:first-child{
                margin-left: 0;
            }
        }
        &__figure{
            display: flex;
            flex-flow: row;
            @media(max-width: $phone-v){
                flex-flow: column;
            }
            &-preview{
                flex-basis: 168px;
                flex-shrink: 0;
                margin-right: 24px;
                @media(max-width: $phone-v){
                    margin: 0 0 12px;
                }
            }
        }
        &__figcaption{
            flex-grow: 1;
        }
        &__title{
            margin-bottom: 7px;
            color: black;
            font-family: $Noto;
            font-style: italic;
            font-weight: 700;
            font-style: italic;
            font-size: 20px;
            line-height: 1.5;
            @media(max-width: $phone-v){
                font-size: 18px;
                line-height: 1.375;
            }
            a{
                color: inherit;
                text-decoration: none;
                &:hover{
                    color: $primary-500;
                }
            }
        }
        &__date{
            margin-bottom: 8px;
            font-size: 16px;
            opacity: .64;
        }
        .button{
            margin-top: 17px;
            margin-left: 12px;
        }
    }

    &-loader{
        height: 160px; width: 160px;
        position: fixed;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(0,0,0,.54);
        border-radius: 12px;
        z-index: 10000;
        transition: $transition2;
        &--loaded{
            opacity: 0;
            visibility: hidden;
        }
        &__mouse{
            height: 55px; width: 35px;
            display: block;
            position: absolute; 
            top: 50%; left: 50%;
            transform: translate(-50%, -50%);
            border: 3px solid white;
            border-radius: 23px;
            *{
                display: block;
                height: 8px; width: 8px;
                position: absolute;
                top: 29%; left: 50%;                
                transform: translate(-50%, -50%);
                background: white;
                border-radius: 100%;
                animation: ani-mouse 1.5s ease infinite;
            }
        }
    }

    &-tabs{
        position: relative;
        &__tab{
            position: absolute;
            top: -10000px;
            left: -10000px; right: -10000px;
            &.active{
                position: relative;
                top: 0;
                left: 0; right: 0;
            }
        }
    }
}

.trendy-list{
    &__item{
        flex: 0 0 530px;
        @media(max-width: $tablet-v){
            flex: 0 0 430px;
        }
        @media(max-width: $phone-v){
            flex: 0 0 330px;
        }
    }

    &__inner{
        padding: 0 20px;
        @media(max-width: $phone-h){
            padding: 0 15px;
        }
        @media(max-width: $phone-v){
            padding: 0 10px;
        }
        @include hover{
            .trendy-list{
                &__preview-wrap{
                    img{
                        transform: rotateZ(-2deg);
                    }       
                }
                &__title{
                    color: $primary-500;
                }
            }
        }
    }

    &__preview{
        perspective: 1200px;
        transform-style: preserve-3d;
        &-wrap{
            margin-bottom: 28px;
            @media(max-width: $phone-v){
                margin-bottom: 14px;
            }
            img{
                width: 100%;
                transition: $transition;
                transform-origin: left bottom;
            }
        }
    }

    &__title{
        margin-bottom: 15px;
        color: $dark-gray;
        font-size: 30px;
        font-weight: 700;
        font-family: $Noto;
        font-style: italic;
        @media(max-width: $phone-v){
            font-size: 24px;
        }

        a{
            color: inherit;
            text-decoration: none;
        }
    }

    &__text{
        max-width: 380px;
        color: rgba($primary-94, .54);
        font-size: 14px;
        font-weight: 500;
        line-height: 1.42857;
    }
}



.ie-10{
    .trendy-list{
        &__wrap{
            overflow-x: auto;
            &:after{
                display: none;
            }
        }
    }
}

.gray-block{
    .scrollable-list__wrap:after{
        background: $gray-002;
    }
}

@keyframes ani-mouse{
    0%{
        opacity: 1;
        top: 29%;
    }
    70%{
        opacity: 1;
        top: 50%;
    }
    90%{
        opacity: 0;
        top: 50%;
    }
    100%{
        opacity: 0;
        top: 29%;
    }
}

.site-block__scroll{
    .content-header{
        @media(max-height: 750px){
            padding: 27px 0;
        }
    }
}

.ie-10{
    .scrollable-list{
        &__scroller{
            margin-bottom: 25px;
            padding-bottom: 25px;
        }
        &__wrap:after{
            display: none;
        }
    }
}