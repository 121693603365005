.bring-client {
  margin: 0 auto;
  max-width: 490px;
  width: 100%;

  .grid-d-6 {

    @media screen and (min-width: $tablet-v) {
      width: 54%;
    }
  }

  &__label {
    position: absolute; 
      top: 12px;
      left: 16px;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #6f7b8d;
      opacity: 0;

      &.is-visible {
        opacity: 0.8;
      }
  }

  input:not([type='checkbox']):not([type='radio']) {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    color: $primary-90;
    
    &.is-visible {
      padding-top: 31px;
    }
  }

  &__box {
    padding-left: 20px;
    .button {
      margin-top: 12px;

      @media screen and (max-width: $phone-h) {
        margin-top: 4px;
        width: initial; 
      }
    }
  }

  &__number {
    position: relative;
  }
}

.has-error {
  display: flex;
  align-items: center;
  width: 100%;
  color: $accent-500;
  background: #fbe9e9!important;

  &--xs .bring-client__input:not([type='checkbox']):not([type='radio']) {
    color: $accent-500;
    background: #fbe9e9!important;
  }

  &__text {
    margin-top: 6px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.62;
    letter-spacing: normal;
    color: $accent-500;
  }

  .text p {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $accent-500;
  }

  .text p a {
    font-weight: normal;
    color: $primary-900;
    text-decoration: underline;
    text-decoration-color: currentColor;
    background-image: none;

    @include hover{
      text-decoration-color: transparent;
    }
  }

  &--l {
    min-height: 64px;
    padding: 12px 17px;
  }
}