.quick-links{
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	margin: 0 auto 50px;

	&__item{
		padding-bottom: 30px;
		flex: 1 0 16.6%;
		text-align: center;

		@media(max-width: $phone-h){
			flex: 1 0 33.3333%;
		}
		@media(max-width: $phone-v){
			flex: 1 0 50%;
		}
		@media(max-width: $phone-sm){
			flex: 1 0 50%;
		}
	}

	a{
		display: block;
		padding: 0 15px;
		color: $primary-94;
		text-decoration: none;
		@include hover{
			color: $accent-300;

			[class^="i-"],
			[class*=" i-"]{
				&:before{
					color: darken($accent-300, 10);
				}
			}
		}
	}

	[class^="i-"],
	[class*=" i-"]{
		margin-bottom: 16px;

		&:before{
			color: $accent-300;
			font-size: 48px;
		}
	}
}