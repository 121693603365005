.i-file-default:before{
    content: "\e96d";
    color: $primary-500;
}

.file-list {    
    margin-bottom: 48px;
    padding: 0;
    &:last-child {
        margin-bottom: 0;
    }
    &__item {        
        margin-bottom: 12px;
        position: relative;
        &:last-child {
            margin-bottom: 0;
        }

        &:before,
        &:after{
            display: none;
        }
    }
    &--template{
        margin-top: 24px;
        &:empty{
            margin-top: 0;
        }
        .file{
            padding-right: 50px;
        }
        .file{
            &-list{            
                &__remove{
                    height: 20px; width: 20px;
                    position: absolute;
                    top: 50%; right: 18px;
                    text-indent: -10000px;
                    transform: translateY(-50%);
                    background: transparent;

                    &:before{
                        content: '\ed37';
                        font-family: 'fibank-icons';
                        position: absolute;
                        top: 50%; left: 50%;
                        transform: translate(-50%, -50%);
                        font-size: 20px;
                        text-indent: 0;
                        color: $accent-500;
                    }

                    &--all{
                        height: auto; width: auto;
                        position: relative;
                        top: 0; right: 0;
                        text-indent: 0;
                        transform: translate(0, 0);
                        margin-top: 6px;
                        padding-right: 27px;
                        font-size: 14px;
                        font-weight: 500;
                        font-family: $Inter;
                        background: transparent;
                        &:before{
                            left: auto;
                            right: 0;
                            transform: translate(0, -50%);
                        }
                    }
                }
            }
        }
    }
}
.file {
    display: flex;    
    align-items: center;
    border: 1px solid #e0e0e0;
    padding: 7px 8px;
    text-decoration: none;
    transition: border-color $transition;
    @include hover{
        color: inherit!important;
        border-color: $primary-500;        
        text-decoration: none!important;
        background-size: 0 1px!important;
        i{
            color: $primary-500;
        }
    }
    &__type {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        align-self: stretch;        
        border-right: 1px solid #e0e0e0;
        [class^="i-"],
        [class*=" i-"]{            
            font-size: 38px;
            margin-right: 8px;
        }
        
    }
    &__text {
        padding: 0 0 0 16px;
        font-size: 0;
        &-name {
            display: block;
            color: $dark-gray;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.3;
            margin-bottom: 4px;
            transition: color $transition;
            .file:hover & {
                color: $primary-500;
            }
        }
        &-info {
            display: inline-block;
            vertical-align: middle;
            color: $primary-90;
            font-size: 11px;
            text-transform: uppercase;
            &:after {
                content: '';
                display: inline-block;
                width: 1px;
                height: 8px;
                background-color: #cbcbd3;
                margin: 0 4px;
            }
            &:last-child:after {
                display: none;
            }
        }
    }
    &__action {
            flex-shrink: 0;
            padding: 8px;
            margin-left: auto;
        i {
            font-size: 24px;            
        }        
    }
}

.download-list{
    padding: 0 !important;
    margin-bottom: 48px;
    padding: 0;
    &:last-child {
        margin-bottom: 0;
    }
    > li{
        padding: 7px 8px 7px 71px !important;
        margin-bottom: 12px;
        position: relative;
        border: 1px solid #e0e0e0;
        transition: border-color $transition;
        @include hover{
            border-color: $primary-500;
            a{
                color: $primary-500;
                text-decoration: none!important;
                background-size: 0 1px!important;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }

        &:before{
            display: none;
            height: auto !important;
            width: auto !important;
            font-size: 38px;
            top: 50% !important;
            left: 8px !important;
            transform: rotate(0) translateY(-50%) !important;
            font-family: 'fibank-icons';
            background: transparent !important;
        }

        &:after{
            content: '';
            height: auto; width: 46px;
            position: absolute;
            top: 7px; bottom: 7px;
            left: 9px;
            border-right: 1px solid #e0e0e0;
        }

        &.pdf{
            &:before{
                display: block;
                content: "\e90a";
                color: #db5565;
            }
        }

        a{
            display: flex;    
            align-items: center;            
            text-decoration: none;
            position: static;
            color: $dark-gray;
            &:before{
                content: '';
                height: 100%; width: 100%;
                position: absolute;
                top: 0; left: 0;
                z-index: 2;
            }
        }
        span{
            display: inline-block;
            vertical-align: middle;
            color: $primary-90;
            font-size: 11px;
            text-transform: uppercase;
            &:after {
                content: '';
                display: inline-block;
                width: 1px;
                height: 8px;
                background-color: #cbcbd3;
                margin: 0 4px;
            }
            &:last-child:after {
                display: none;
            }
        }
    }
    &__text{
        display: block;
        padding: 2px 0;
        font-size: 12px !important;
        line-height: 1.125;
        text-transform: none !important;
        &:after{
            display: none !important;
        }
    }
}