.dictionary{

    &-alphabet{
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin-bottom: 30px;
        line-height: 1;
    
        a{
            display: inline-block;
            margin: 4px;
            font-family: $Noto;
            font-size: 18px;
            font-weight: 700;
            font-style: italic;
            text-decoration: none;
            text-transform: lowercase;
        }
    }

    &-results{
        display: none;
        margin-bottom: 21px;
        padding: 30px 0 5px;
        align-items: center;
        justify-content: space-between;
        position: relative;
        &--visible{
            display: flex;
        }

        &:before{
            content: '';
            height: 2px; width: 100%;
            position: absolute;
            top: 50%; left: 0;
            margin-top: 15px;
            background: $primary-94;
            z-index: 1;
        }

        &__letter{
            padding: 0 16px 0 2px;
            font-size: 30px;
            font-family: $Noto;
            font-weight: 700;
            font-style: italic;
            line-height: 1;
            position: relative;
            background: white;
            z-index: 2;
            @media(max-width: $phone-v){
                font-size: 24px;
            }
        }

        &__count{
            padding: 2px 2px 0 16px;
            font-size: 18px;
            font-family: $Noto;
            font-weight: 700;
            font-style: italic;
            line-height: 1;
            position: relative;
            background: white;
            z-index: 2;
        }
    }

    &-grid{
        &__header{
            padding: 30px 0 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            &:before{
                content: '';
                height: 2px; width: 100%;
                position: absolute;
                top: 50%; left: 0;
                margin-top: 15px;
                background: $primary-94;
                z-index: 1;
            }

            &-letter{
                padding: 0 16px 0 2px;
                font-size: 30px;
                font-family: $Noto;
                font-weight: 700;
                font-style: italic;
                line-height: 1;
                position: relative;
                background: white;
                z-index: 2;
            }

            &-count{
                padding: 2px 2px 0 16px;
                font-size: 18px;
                font-family: $Noto;
                font-weight: 700;
                font-style: italic;
                line-height: 1;
                position: relative;
                background: white;
                z-index: 2;
            }
        }

        &__item{
            
            &-inner{
                padding: 21px 16px 20px;
                color: rgba($primary-94, .54);
                font-size: 14px;
                font-weight: 500;
                line-height: 1.571425;
                background: white;
                border: 1px solid $primary-400;
            }

            &-title{
                margin-bottom: 11px;
                color: $primary-94;
                font-size: 20px;
                font-family: $Noto;
                font-weight: 700;
                font-style: italic;
                line-height: 1.275;

                a{
                    color: inherit;
                    text-decoration: none;

                    @include hover{
                        color: $primary-500;

                        mark{
                            color: $primary-500;
                        }
                    }
                }
            }
        }
    }
}