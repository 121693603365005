:root{
	--scrollbar-size: .375rem;
	--scrollbar-minlength: 1.5rem;
	--scrollbar-ff-width: thin;
	--scrollbar-track-color: #f4f4f4;
	--scrollbar-color: rgba(142,157,170,1);
	--scrollbar-color-hover: rgba(142,157,170,1);
	--scrollbar-color-active: rgba(142,157,170,1);
}

.locations-map{
	height: 600px;
	display: flex;
	flex-flow: row;
	margin-top: 2px;
	background: white;

	&__map{
		height: 100%;
		flex-grow: 1;
	}

	&__sidebar{
		height: 100%; width: 380px;
		float: right;
		position: relative;
		padding: 49px 0 26px;
		overflow: hidden;
		border: 1px solid #cbcbd3;
		transition: $transition;
	}

	.locations{
		&__select{
			min-height: 50px;
			width: auto;
			position: absolute;
			top: -1px;
			left: -1px; right: -1px;
			color: white !important;
			background: #6F7B8D !important;
			z-index: 5;

			select{
				display: none;
			}

			.locations__select{
				min-height: 50px !important;
				position: relative;
				top: 0;
				left: 0; right: 0;
				z-index: unset;

				background: #6F7B8D !important;
				.select2-selection{
					&__rendered{
						color: white !important;
						padding-top: 16px;
						padding-bottom: 14px;
					}
					&__rendered--selected{
						color: white !important;
					}
					&__arrow{
						&:after{
							color: white !important;
						}
					}
				}
				.select2-selection--single{
					min-height: 50px !important;
				}
				.select2-dropdown{
					border: 0 !important;
				}
			}

			.select2{
				&-dropdown{
					border: 0 !important;
				}
				&-results{
					&__options{
						max-height: 472px !important;
						@media(max-width: $tablet-v){
							max-height: 324px !important;
						}
					}
				}
			}

		}
		&__sidebar{
			height: 100%;
			overflow: hidden;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			-ms-overflow-style: -ms-autohiding-scrollbar;
			overscroll-behavior: contain;
			scrollbar-width: thin;
			
			&::-webkit-scrollbar{
				height: var(--scrollbar-size);
				width: var(--scrollbar-size);
			}
			
			&::-webkit-scrollbar-track{
				background-color: var(--scrollbar-track-color);
			}
			
			&::-webkit-scrollbar-thumb{
				background-color: var(--scrollbar-color);
			}
			
			&::-webkit-scrollbar-thumb:hover{
				background-color: var(--scrollbar-color-hover);
			}
			
			&::-webkit-scrollbar-thumb:active{
				background-color: var(--scrollbar-color-active);
			}
	
			&::-webkit-scrollbar-thumb:vertical{
				min-height: var(--scrollbar-minlength);
			}
		}
	}

	&__sidebar-button{
		height: 26px; width: 100%;
		position: absolute;
		bottom: 0; left: 0;
		border: 0;
		padding-bottom: 2px;
		color: white;
		font-size: 12px;
		text-align: center;
		background: #8794a8;
		z-index: 4;

		span{
			display: inline-block;
			position: relative;
			padding-right: 16px;

			&:after{
				content: '\e99d';
				font-family: 'fibank-icons';
				font-size: 16px;
				position: absolute;
				top: 50%; right: 0;
				margin-top: 1px;
				transform: translateY(-50%);
			}
		}
	}

	&.map-shrinked{
		
		.locations-map__sidebar{
			width: 26px;
			white-space: nowrap;
			border-color: $gray-060;
		}

		.locations-map__sidebar-button{
			height: auto; width: 26px;
			top: -1px; bottom: -1px;
			left: -1px;
			z-index: 6;

			span{
				padding-right: 8px;
				text-indent: -10000px;

				&:after{
					content: '\e99c';
					text-indent: 0;
					right: -3px;
				}
			}
		}
	}
}

.map-filter{
	display: flex;
	flex-flow: row wrap;
	margin: -24px 0 0 -24px;
	@media(max-width: $tablet-h){
		margin: -18px 0 0 -18px;
	}

	&__item{
		@media(max-width: $tablet-v){
			flex: 1 0 50%;
		}
		@media(max-width: $phone-h){
			flex: 1 0 100%;
		}
	}
	&__inner{
		margin: 24px 0 0 24px;
		background: #f4f4f4;
		@media(max-width: $tablet-h){
			margin: 18px 0 0 18px;
		}
	}

	&__form{
		position: relative;
		&--active{
			.map-filter{
				&__reset{
					display: block;
				}
			}
		}
	}

	&__input{
		height: 64px; width: 400px;
		position: relative;
		@media(max-width: $tablet-h){
			width: 210px;
		}
		@media(max-width: $tablet-v){
			width: 100%;
		}

		&:after{
			content: '\eca9';
			font-family: 'fibank-icons';
			font-size: 24px;
			color: $blue;
			position: absolute;
			top: 50%; right: 18px;
			transform: translateY(-50%);
		}

		input:not([type='checkbox']):not([type='radio']){
			height: 64px; width: 100%;
			padding: 0 90px 0 16px;
			border: 0;
			color: $primary-90;
			font-size: 16px;
			font-weight: 500;
			background: transparent;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			border-radius: 0;
		}
	}

	&__reset{
		display: none;
		height: 16px; width: 16px;
		position: absolute;
		top: 50%; right: 55px;
		margin-top: 1px;
		border: 0;
		text-indent: -10000px;
		background: #c2c2c2;
		border-radius: 20px;
		transform: translateY(-50%);        

		&:before{
			content: '\ec3c';
			font-family: 'fibank-icons';
			color: white;
			font-size: 10px;
			text-indent: 0;
			position: absolute;
			top: 50%; left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&__dropdown{
		width: 280px;
		position: relative;
		font-weight: 500;
		user-select: none;
		z-index: 10;
		@media(max-width: $tablet-h){
			width: 240px;
		}
		@media(max-width: $tablet-v){
			width: 100%;
		}

		&-header{
			padding: 12px 40px 12px 16px;
			position: relative;
			color: $primary-90;
			font-size: 16px;
			cursor: pointer;
			user-select: none;

			&:after{
				content: '\eb69';
				font-family: 'fibank-icons';
				font-size: 24px;
				color: $blue;
				position: absolute;
				top: 50%; right: 18px;
				transform: translateY(-50%);
			}

			small{
				display: block;
				padding: 3px 0 5px;
				color: #939ca9;
				font-size: 12px;
			}

			span{
				display: block;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}

		&-body{
			opacity: 0;
			visibility: hidden;
			width: 100%;
			position: absolute;
			top: 100%; left: 0;
			padding: 3px 15px;
			background: white;
			border: 1px solid #c5cad1;
			transition: $transition;
		}

		&.dropdown-open{
			.map-filter__dropdown-body{
				opacity: 1;
				visibility: visible;
				margin-top: 16px;
			}
		}

		&-item{
			padding: 12px 0;

			&__body{
				padding-top: 8px;
			}

			& + &{
				border-top: 1px solid #e6ebf5;
			}
		}

		.item{
			padding: 6px 0;

			&:last-child{
				padding-bottom: 0;
			}
		}
	}
}

.location{

	&__item{
		position: relative;
		cursor: pointer;
		padding: 16px 20px 17px 60px;
		font-size: 14px;
		line-height: 1.125;
		background: white;
		border-bottom: 1px solid #cbcbd3;
		user-select: none;
		&:before{
			content: '';
			position: absolute;
			top: 50%; left: 18px;
			transform: translateY(-50%);
			font-family: 'fibank-icons';
			font-size: 21px;
		}

		&--title{
			padding-left: 19px;
			padding-right: 50px;
			position: sticky;
			top: 0; left: 0;
			cursor: default;
			color: rgba(56,54,54,.54);
			font-size: 16px;
			z-index: 2;
			&:after{
				content: '\ebfc';
				font-family: 'fibank-icons';
				position: absolute;
				top: 50%; right: 18px;
				color: rgba(0,0,0,.32);
				font-size: 24px;
				transform: translateY(-50%);
			}
		}

		&[data-type="0"]{
			&:before{
				content: '\e906';
				color: $accent-300;
			}
		}

		&[data-type="1"]{
			&:before{
				content: '\e907';
				color: $primary-500;
			}
		}

		&[data-type="2"]{
			&:before{
				content: '\eb27';
				color: #6f7b8d;
			}
		}

		&-name{
			padding-bottom: 5px;
			color: #383636;
			font-size: 16px;
			font-weight: 500;

			.location-distance{
				color: $primary-90;
				opacity: .54;
				font-size: 14px;
				font-weight: 400;
			}
		}

		&-address{
			color: $primary-90;
			opacity: .54;
		}

		b,
		strong{
			font-weight: 400;
		}

		&.active{
			color: #8ba3cc;
			background: $blue;
			border-color: $blue;
			&:before{
				color: white;
			}
			&:after{
				content: '';
				height: 1px; width: 100%;
				position: absolute;
				top: -1px; left: 0;
				background: $blue;
			}
	
			& + .location__item{
				border-top-color: $blue;
			}

			.location__item-name{
				color: white;

				.location-distance{
					opacity: 1;
					color: #86a0ca;
				}
			}

			.location__item-address{
				opacity: 1;
				color: #86a0ca;
			}
		}
	}

	&-message{
		margin: -1px 0;
		padding: 16px 20px 17px;
		color: white;
		font-size: 16px;
		font-weight: 500;
		text-align: center;
		background: $red;
		user-select: none;
	}
}

.infowindow{
	&-content{
		width: 100%;
		max-width: 288px;
		padding: 12px 10px;
		font-size: 13px;
		line-height: 1.125;

		strong{
			color: $dark-gray;
			font-weight: 500;
		}

		table{
			width: 100%;
			margin-top: 8px;
			font-size: 11px;
			border-collapse: collapse;

			th{
				padding: 10px 10px 9px;
				color: #445267;
				font-weight: 400;
				text-align: center;
				background: #f7f7f8;
				border: 1px solid #e0e0e0;
			}

			td{
				padding: 10px 10px 9px;
				color: #5c5c5c;
				text-align: center;
				border: 1px solid #e0e0e0;
			}
		}

		a{
			color: $blue;

			&.tel,
			&.fax,
			&.email{
				display: inline-block;
				margin-top: 12px;
				padding-left: 26px;
				position: relative;
				font-weight: 500;
				text-decoration: none;

				&:before{
					font-family: 'fibank-icons';
					font-size: 19px;
					position: absolute;
					top: -4px; left: 0;
				}
			}

			&.tel{
				&:before{
					top: -3px;
					content: '\ec5d';
				}
			}

			&.fax{
				&:before{
					content: '\ed7a';
				}
			}

			&.email{
				&:before{
					content: '\eb07';
				}
			}
		}
	}

	&-header{
		min-height: 33px;
		position: relative;
		padding-left: 42px;

		&:before{
			content: '';
			height: 32px; width: 32px;
			position: absolute;
			top: -1px; left: 0;
			background: url('../images/logo-fibank-symbol.svg') no-repeat 0 0;
		}
	}

	&-title{
		padding-bottom: 3px;
		padding-right: 18px;
		color: $primary-94;
		font-size: 15px;
		font-weight: 500;
	}

	&-address{
		color: #929292;
		font-size: 12px;
	}

	&-text{
		padding: 20px 0 5px;

		&:empty{
			padding: 0;
		}

		.address,
		.worktime,
		.phones,
		.fax,
		.email,
		.description,
		.features,
		.stop_reason,
		.deposit,
		.blind,
		.contactless{
			color: $blue;
			margin-top: 12px;
			padding-left: 26px;
			position: relative;
			font-weight: 500;
			text-decoration: none;
			&:first-of-type{
				margin-top: 0;
			}

			&:before{
				font-family: 'fibank-icons';
				font-size: 19px;
				position: absolute;
				top: -4px; left: 0;
			}			
		}

		.address{
			&:before{
				top: -3px;
				content: '\ebfc';
			}
		}

		.worktime{
			&:before{
				top: -3px;
				content: '\ea4b';
			}
		}

		.phones{
			&:before{
				top: -3px;
				content: '\ec5d';
			}
		}

		.fax{
			&:before{
				content: '\ed96';
			}
		}

		.email{
			&:before{
				content: '\eb07';
			}
		}

		.blind{
			&:before{
				content: '\e91d';
			}
		}

		.description{
			&:before{
				content: '\ebc9';
			}
		}

		.stop_reason{
			&:before{
				content: '\eb27';
			}
		}

		.features{
			&:before{
				content: '\ea3c';
			}
			&.extra_business_hours{
				&:before{
					content: '\ea4a';
				}
			}
			&.weekend_work,
			&.holiday_work{
				&:before{
					content: '\ed94';
				}
			}
			&.loan_spec_retail{
				&:before{
					content: '\ed56';
				}
			}
			&.loan_spec_micro_or_sme{
				&:before{
					content: '\ed57';
				}
			}
			&.stop_for_repairs{
				&:before{
					content: '\eb27';
				}
			}
			&.stop_for_repairs.stop_reason{
				&:before{
					content: '\eaa4';
				}
			}
			&.blind{
				&:before{
					content: '\e91d';
				}
			}
		}

		.deposit{
			&:before{
				content: '\ed97';
			}
		}

		.contactless{
			&:before{
				content: '\e926';
			}
		}
	}

	&-googlemaps{
		display: block;
		position: relative;
		padding: 14px 15px 15px 58px;
		color: #07a25d !important;
		font-size: 15px;
		font-weight: 500;
		text-decoration: none;
		border: 1px solid #07a25d;
		border-radius: 6px;

		&:before{
			content: '';
			height: 26px; width: 26px;
			position: absolute;
			top: 50%; left: 13px;
			margin-top: -14px;
			background: url('../images/icon-googlemaps.png') no-repeat 0 0;
		}
	}
}

.gm-style-iw-d{
	min-width: 280px;
	max-width: inherit !important;
}

.gm-style-iw.gm-style-iw-c{
	max-width: inherit !important;
    padding: 0 !important;
	border-radius: 0 !important;
	border: 1px solid #cbcbd3 !important;
	border-bottom-width: 0 !important;
}

button.gm-ui-hover-effect{
	opacity: 1 !important;

	img{
		display: none !important;
	}

	&:before{
		content: '\ec3c';
		font-family: 'fibank-icons';
		position: absolute;
		bottom: 0; left: 0;
		color: #adadad;
		font-size: 16px;
	}
}

.cluster{
	div{
		font-size: 20px !important;
		font-weight: 500 !important;
		font-family: $Inter !important;
		@media(max-width: $phone-h){
			font-size: 16px !important;
		}
	}
}

.pac{
	&-container{
		border-top: 0;
		background: #f4f4f4;
		box-shadow: none;
		font-family: $Inter;
		z-index: 1000000;
	}

	&-item{
		cursor: pointer;
		color: $primary-94;
		font-size: 14px;
		line-height: 34px;
		padding-bottom: 2px;

		&-query{
			color: $primary-94;
		}
	}

	&-icon{
		margin-top: 9px;
	}

	&-matched{
		font-weight: 400;
		text-decoration: underline;
	}
}

@media (max-width: $tablet-v){
	.locations{
		&-map{
			height: auto;
			display: block;

			&__map{
				height: 600px;
				margin: 0 -15px;
				@media (orientation: landscape){
					@supports (left: env(safe-area-inset-left)){
						margin-left: calc(env(safe-area-inset-left) - env(safe-area-inset-left) - env(safe-area-inset-left) - 15px);
					}
				}
			}

			.locations__sidebar{
				max-height: 400px;
				overflow: hidden;
				overflow-y: auto;
				-webkit-overflow-scrolling: touch;
			}

			&__sidebar{
				width: 100%;
				margin-top: 30px;
				padding-bottom: 0;
				float: none;
			}

			&__sidebar-button{
				display: none;
			}
		}
	}

	.infowindow{
		&-content{
			max-width: 330px;
		}
	}
}

@media (max-width: $phone-v){
	.infowindow{
		&-content{
			max-width: 300px;
		}
	}
}