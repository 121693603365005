.branches{
	display: flex;
	flex-flow: row wrap;
	margin: -24px;
	@media(max-width: $tablet-v){
		margin-left: 0;
		margin-right: 0;
	}
	&-item{
		flex: 1 0 50%;
		@media(max-width: $tablet-v){
			flex: 1 0 100%;
		}
		&:nth-child(1),
		&:nth-child(4),
		&:nth-child(5),
		&:nth-child(8),
		&:nth-child(9),
		&:nth-child(12),
		&:nth-child(13),
		&:nth-child(16){
			.branches{
				&-item{
					&__body{
						@media(min-width: $tablet-h){
							background: #ebf1f5;
							padding-left: 32px;
						}
					}
				}
			}
		}
		&:nth-child(2n+1){
			.branches{
				&-item{
					&__body{
						@media(max-width: $tablet-h){
							background: #ebf1f5;
							padding-left: 24px;
							padding-top: 30px;
						}
					}
				}
			}
		}
		&__inner{
			padding: 24px;
			@media(max-width: $tablet-v){
				padding-left: 0;
				padding-right: 0;
			}
		}
		&__header{
			margin-bottom: 35px;
			color: $primary-94;
			@include fluid-size(font-size, 280px, 1330px, 26px, 34px);
			font-weight: 700;
			font-style: italic;
			font-family: $Noto;
			@media(max-width: $tablet-h){
				margin-bottom: 25px;
			}
		}
		&__body{
			padding: 30px 30px 30px 0;
			color: $primary-90;
			font-size: 15px;
			font-weight: 500;
			@media(max-width: $tablet-h){
				padding-top: 0;
			}
			p + p{
				margin-top: 5px;
			}
			&-title{
				margin-bottom: 17px;
				color: $dark-gray;
				font-size: 20px;
				font-weight: 500;
			}
			small{
				color: rgba($dark-gray, .54);
				font-size: 13px;
			}
		}
	}

	&-phones{
		margin: -5px 0 25px;
		&:last-child{
			margin-bottom: 0;
		}
		a{
			display: inline-block;
			margin: 5px 17px 0 0;
			font-size: 15px;
			font-weight: 500;
			text-decoration: none;
			@include hover{
				color: darken($primary-500, 10);
			}
			[class*="i-"]{
				margin-right: 5px;
				position: relative;
				top: 5px;
				font-size: 24px;
			}
			.i-envelope-alt{
				top: 6px;
			}
		}
	}
}

.departments{
	display: flex;
	flex-flow: row wrap;
	margin: 0 -48px;
	@media(max-width: $tablet-v){
		margin-left: 0;
		margin-right: 0;
	}
	&-wrap{
		margin-top: 68px;
		overflow: hidden;
		&:first-child{
			margin-top: 0;
		}
	}
	&-title{
		margin-bottom: 33px;
		color: $primary-94;
		@include fluid-size(font-size, 280px, 1330px, 26px, 34px);
		font-weight: 700;
		font-style: italic;
		font-family: $Noto;
	}
	&-item{
		flex: 1 0 25%;
		position: relative;
		color: black;
		font-size: 13px;
		font-weight: 500;
		line-height: 1.375;
		@media(max-width: $tablet-v){
			padding: 24px 0;
			flex: 1 0 100%;
			border-top: 1px solid $primary-400;
			&:first-child{
				border-top-width: 0;
				padding-top: 0;
			}
		}
		&:before{
			content: '';
			height: 100%; width: 1px;
			position: absolute;
			top: 0; left: 0;
			background: $primary-400;
			@media(max-width: $tablet-v){
				display: none;
			}
		}
		&__inner{
			height: 100%;
			padding: 0 48px;
			@media(max-width: $tablet-v){
				padding: 0;
			}
		}
		&__title{
			margin-bottom: 34px;
			color: $primary-90;
			font-size: 20px;
			font-weight: 400;
			line-height: 1.125;
		}
	}

	a{
		color: $accent-500;
		text-decoration: underline;
		@include hover{
			text-decoration: none;
		}
	}

	.big{
		color: black;
		font-size: 26px;
		font-family: $Noto;
		font-style: italic;
		font-weight: 700;
		line-height: 1.125;
		text-decoration: none;
		word-wrap: break-word;
		word-break: break-all;
		@include hover{
			color: $primary-500;
		}
	}

	p ~ p{
		margin-top: 9px;
	}
}