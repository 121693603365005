small {
    display: inline-block;
    color: rgba($primary-94,.54);
    font-size: 12px;
    font-weight: 500;
    line-height: 1.416;

    a{
        color: inherit;
    }
}

.text-red {
    color: $red;
}

/* Typography */
.text {
    font-size: 14px;
    margin-bottom: 16px;
    line-height: 1.571428571428571;
    color: $dark-gray;
    &:before,
	&:after{
    	content: "";
    	display: table;
	}

	&:after{
    	clear: both;
    }
    &:last-child{
        clear: both;
        margin-bottom: 0;
    }

    &--sp-b {
        /* spaced bottom */
        @include fluid-size(margin-bottom, 320px, 1440px, 32px, 48px);
        /*
        @media(max-width: $phone-v){
            margin-bottom: 0;
        }
        */
    }
    &--sp-b-32 {
        /* spaced bottom */
        @include fluid-size(margin-bottom, 320px, 1440px, 16px, 32px);
        /*
        @media(max-width: $phone-v){
            margin-bottom: 0;
        }
        */
    }
    & > *:last-child {
        margin-bottom: 0;
        padding-bottom: 0;

        table{
            margin-bottom: 0;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 1.25em 0 0.5em 0;
        font-weight: 400;
        color: $primary-500;
        &:first-of-type{
            margin-top: 0;
        }
    }
    /*
    h4,
    h5,
    h6 {
        margin-bottom: 1em;
    }
    */
    /* scale -      40, 36, 32, 26, 22, 18, */
    /* responsive - 32, 28, 24, 20, 18, 16 */
    h1 {
        @include fluid-size(font-size, 320px, 1330px, 32px, 40px);
        font-family: $Noto;
        font-style: italic;
        font-weight: 700;
        color: $primary-94;
    }
    h2 {
        @include fluid-size(font-size, 320px, 1330px, 28px, 36px);
        font-family: $Noto;
        font-style: italic;
        font-weight: 700;
        color: $primary-94;
    }
    h3 {
        @include fluid-size(font-size, 320px, 1330px, 24px, 32px);
        font-family: $Noto;
        font-style: italic;
        font-weight: 700;
        color: $primary-94;
    }
    h4 {
        @include fluid-size(font-size, 320px, 1330px, 20px, 24px);
    }
    h5 {
        @include fluid-size(font-size, 320px, 1330px, 18px, 22px);
    }
    h6 {
        @include fluid-size(font-size, 320px, 1330px, 16px, 18px);
    }
    p, li {
        min-height: 1px;
        font-size: 14px;
        margin-bottom: 16px;
        line-height: 1.571428571428571;
        color: $dark-gray;
        @media(max-width: $phone-h) {
            margin-bottom: 8px;
            font-size: 14px;
            line-height: 1.384615384615385;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    p{
        margin-bottom: 8px;
    }
    li{
        @media(max-width: $phone-v){
            margin-bottom: 16px;
        }
    }

    p a, li a {
        text-decoration: none;
        background-image: linear-gradient(currentColor, currentColor);
        background-position: 0 100%;
        background-repeat: no-repeat;
        background-size: 0 1px;
        transition: $transition;
        @include hover{
            background-size: 100% 1px;
        }
    }
    ul, ol {
        padding-bottom: 16px;
    }
    ol {
        list-style: decimal;
        padding-left: 16px;
    }
    ul {
        padding-left: 16px;
    }
    ul li {
        position: relative;
        &:before {
            content: '';
            width: 6px;
            height: 6px;
            background-color: $primary-500;
            transform: rotate(45deg);
            position: absolute;
            top: 9px;
            left: -16px;
            @media(max-width: $phone-h) {
                top: 7px;
            }
        }
        small{
            color: inherit;
            font-size: 14px !important;
            font-weight: 400;
            a{
                color: $primary-500;
            }
        }
    }
    ul ul,
    ul ol,
    ol ol,
    ol ul {
        margin-top: 10px;
        li {
            font-size: 13px;
            margin-bottom: 6px;
            &:before {
                background-color: $primary-200;
                width: 5px;
                height: 5px;
                top: 9px;
                @media(max-width: $phone-h) {
                    top: 7px;
                }
            }
        }
    }

    .circle-list {
        li {
            &:before {
                transform: none;
                border-radius: 50%;
            }
        }
    }

    .faq-list,
    .file-list{
        padding: 0;
    }
    /* table */
    table {
        width: calc(100% - 2px);
        min-width: 32.9em;
        margin-bottom: 32px;
        border-collapse: collapse;
        color: $dark-gray;
        &.table-layout-fixed {
            table-layout: fixed;
        }
        &.table-width-100 {
            width: 100%;
        }
        td,
        th{
            font-size: 14px;
            line-height: 1.285714285714286;
            padding: 16px 24px;
            border: 1px solid #e0e0e0;
            text-align: left;
            p{
                padding-left: 0 !important;
            }
        }
        th {
            font-weight: 500;
            font-size: 16px;
            line-height: 1.25;
            color: $primary-500;
            text-align: center;
        }
        thead th {
            padding: 24px;
        }
        .small {
            font-size: 12px;
            line-height: 1.333333333333333;
            padding: 12px 24px;
            color: $dark-gray;
            background-color: rgba(111, 123, 141, 0.06);
        }
        .corner {
            color: rgba($dark-gray, 0.64);
            font-size: 11px;
            font-weight: 500;
            vertical-align: bottom;
            padding: 12px 24px;
            border: 0;
        }
        ul, li:last-child {
            margin-bottom: 0;
        }
        &.table-style {
            tr th {
                border: 0;
                text-align: left;
                width: 200px;
                vertical-align: top;
                padding-left: 0;
            }
            tr td {
                vertical-align: top;
            }
            tr:nth-child(even) {
                background-color: #f7f7f8;
            }
            tr:nth-child(even) th {
                background-color: #fff;
            }
            tr th + td {
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    background: #fff;
                    top: 18px;
                    left: -8px;
                    border-left: 1px solid #e0e0e0;
                    border-bottom: 1px solid #e0e0e0;
                    transform: rotate(45deg);
                }
            }
            tr:nth-child(even) td:after {
                background-color: #f7f7f8;
            }
            @media(max-width: $phone-v) {
                border: 1px solid #e0e0e0;
                tr, th, td {
                    font-size: 14px;
                    display: block;
                }
                th {
                    padding: 14px 14px 0 14px;
                    border: 0;
                }
                tr th {
                    width: auto;
                    padding-left: 14px;
                }
                td {
                    padding: 14px;
                    border: 0;
                }
                tr {
                    border-bottom: 1px solid #e0e0e0;
                    &:last-child {
                        border-bottom: 0;
                    }
                }
                tr:nth-child(even) {
                    background-color: #f7f7f8;
                }
                tr:nth-child(even) th {
                    background-color: transparent;
                }
                tr th + td:after {
                    display: none;
                }
            }
        }
    }

    &--md{
        font-size: 16px;
        line-height: 1.75;
        @media(max-width: $phone-h){
            font-size: 14px;
            line-height: 1.571428571428571;
        }
        p{
            font-size: 16px;
            line-height: 1.75;
            @media(max-width: $phone-h){
                font-size: 14px;
                line-height: 1.571428571428571;
            }
        }
    }

    &--lg{
        font-size: 18px;
        line-height: 1.5;
        @media(max-width: $phone-h){
            font-size: 14px;
            line-height: 1.571428571428571;
        }
        p, li{
            font-size: 18px;
            line-height: 1.5;
            @media(max-width: $phone-h){
                font-size: 14px;
                line-height: 1.571428571428571;
            }
        }
        ul li{
            &:before{
                top: 11px;
                @media(max-width: $phone-h){
                    top: 9px;
                }
            }
        }
    }

    &--color-1 {
        & p {
            color: rgba($primary-94, 0.54);
        }
    }
    /* floating images */
    img {
        margin-bottom: 16px;
        max-width: 100%;
        .center & {
            margin-left: auto;
            margin-right: auto;
        }
    }
    img[style*='float: left'] {
        margin: 4px 16px 16px 0;
        max-width: 50%;
    }
    img[style*='float: right'] {
        margin: 4px 0 16px 16px;
        max-width: 50%;
    }
    @media(max-width: $phone-v) {
        img[style*='float: left'],
        img[style*='float: right'] {
            margin: 0 0 16px 0;
            max-width: 100%;
            float: none!important;
        }
    }
    /* end of floating images */
}

/* Video embed */
.video-embed-wrapper {
    width: 50%;
    margin: 4px auto 16px auto;
    &--left {
        float: left;
        margin-right: 16px;
    }
    &--right {
        float: right;
        margin-left: 16px;
    }
    &--full {
        width: 100%;
    }
    @media(max-width: $phone-v) {
        width: 100%;
    }
}

.video-embed,
.mce-preview-object,
.mce-object-iframe{
    display: block;
    position: relative;
    padding-top: 56.25%; /* 16:9 */
    height: 0;
    margin: 0 auto;
    clear: both;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
/* end of Video embed */

.promo-box{
    margin-bottom: 40px;
    padding: 46px 40px 20px;
    position: relative;
    border: 2px solid $red;
    @media(max-width: $phone-v){
        padding: 40px 20px 20px;
    }

    &__label{
        position: absolute;
        top: 0; left: 0;
        padding: 2px 8px 4px 4px;
        color: white;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        background: $red;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6{
        color: $red;
    }

    ul{
        padding-left: 16px;
        li{
            position: relative;
            &:before {
                content: '';
                width: 6px;
                height: 6px;
                background: $red;
                transform: rotate(45deg);
                position: absolute;
                top: 10px;
                left: -16px;
                @media(max-width: $phone-h) {
                    top: 7px;
                }
            }
        }
    }

    p,
    li{
        font-size: 14px;
        margin-bottom: 16px;
        line-height: 1.571428571428571;
        color: $dark-gray;
        &:last-of-type{
            margin-bottom: 0;
        }
    }
}