.site-body{
	label,
	.label{
		display: block;
		padding-bottom: 5px;
		color: #828282;
		font-size: 12px;
	}

	.required{
		color: $red;
		font-style: normal;
	}


	input:not([type='checkbox']):not([type='radio']),
	select,
	.select,
	textarea{
		width: 100%;
		min-height: 64px;
		padding: 12px 17px 12px;
		cursor: default;
		color: #445267;
		font: normal 16px/1.2 $Inter;
		background: #ebf1f5;
		border: 0;
		box-shadow: none;
		appearance: none;
		transition: border-color $transition, background $transition;
		border-radius: 0;

		&::placeholder{
			color: rgba($primary-90, .64);
			opacity: 1;
		}

		&.parsley-error{
			&{
				color: $accent-500;
				background: #fbe9e9 !important;

				&::placeholder{
					color: $accent-500;
					opacity: 1;
				}
			}

			~ .select2{
				&-container{
					&--default{
						.select2-selection--single{
							background: #fbe9e9;

							.select2-selection{
								&__rendered{
									color: $accent-500;
								}
								&__arrow{
									&:after{
										color: $accent-500;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.select{
		padding: 0;
		position: relative;
		cursor: pointer;
		user-select: none;

		&:after{
			content: "\e99f";
			font-family: 'fibank-icons';
			position: absolute;
			top: 50%; right: 11px;
			color: $primary-500;
			font-size: 24px;
			transform: translateY(-50%);
			pointer-events: none;
			z-index: 1;
		}

		&.js-select{
			&:after{
				display: none;
			}
		}

		select{
			min-height: 64px;
			width: 100%;
			background: transparent;
			padding: 21px 35px 21px 22px;
			appearance: none;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			background: transparent;
			border-radius: 0;
			cursor: pointer;
			&::-ms-expand{
				display: none;
			}
		}

		&--outline{
			min-height: 41px;
			border: 1px solid #6f7b8d;
			background: transparent;
			select{
				min-height: 41px;
				min-width: 190px;
				padding: 10px 35px 10px 10px;
				color: $dark-gray;
			}
			&:after{
				color: $dark-gray;
			}
		}
	}

	.check-radio{
		display: inline-block;
		cursor: pointer;
		margin-right: 29px;
		padding: 0;
		vertical-align: middle;
		&:last-child{
			margin-right: 0;
		}

		&__element{
			display: inline-block;
			padding-left: 30px;
			position: relative;
			color: rgba($primary-90,.8);
			font-size: 16px;
			font-weight: 400;
			line-height: 1.2857;

			&:before,
			&:after{
				content: '';
				height: 20px; width: 20px;
				position: absolute;
				top: 1px; left: 0;
				background: white;
				border: 1px solid $gray-060;
				transition: $transition;
			}

			&:after{
				opacity: 0;
				visibility: hidden;
				height: 14px; width: 14px;
				top: 4px; left: 3px;
				border: 0;
				background: $primary-500;
			}

		}

		[type="checkbox"] ~ &__element{
			&:after{
				content: '\ea3e';
				font-family: 'fibank-icons';
				font-size: 14px;
				color: white;
				line-height: 14px;
				text-indent: 1px;
			}
		}

		&__input{
			border: 0;
			clip: rect(0 0 0 0);
			clip-path: inset(50%);
			height: 1px; width: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			white-space: nowrap;

			&:checked ~ .check-radio{
				&__element:after{
					opacity: 1;
					visibility: visible;
				}
			}

			&:disabled ~ .check-radio{
				&__element:after{
					background: $red;
				}
			}

			&.error ~ .check-radio{
				&__element:before{
					border-color: $red;
				}
			}

			&[type="radio"] ~ .check-radio{
				&__element:before,
				&__element:after{
					border-radius: 50%;
				}

				&__element:after{
					height: 10px; width: 10px;
					top: 6px; left: 5px;
					border-radius: 50%;
				}
			}
		}

		&--radio{
			.check-radio{
				&__element:before,
				&__element:after{
					border-radius: 50%;
				}

				&__element:after{
					height: 10px; width: 10px;
					top: 6px; left: 5px;
					border-radius: 50%;
				}
			}
		}
	}

	textarea{
		height: 204px;
		min-height: 204px;
		max-height: 304px;
		resize: vertical;
		overflow: auto;
	}

	input::-ms-clear,
	select::-ms-clear,
	textarea::-ms-clear{
		display: none;
	}

	.input{
		position: relative;

		input{
			padding-right: 40px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.filter-check-radio{
		padding: 0;
		display: block;
		position: relative;
		cursor: pointer;

		&__input{
			border: 0;
			clip: rect(0 0 0 0);
			-webkit-clip-path: inset(50%);
			clip-path: inset(50%);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
			white-space: nowrap;

			&:checked ~ .filter-check-radio__item{
				&:before{
					background: $blue;
				}

				&:after{
					opacity: 1;
					visibility: visible;
				}
			}
		}

		&__item{
			position: relative;
			padding: 3px 0 3px 32px;
			color: $primary-90;
			font-size: 16px;
			font-weight: 500;

			&:before{
				content: '';
				height: 24px; width: 24px;
				position: absolute;
				top: 0; left: 0;
				background: #dde1e7;
				transition: $transition;
			}

			&:after{
				content: '\ea3e';
				opacity: 0;
				visibility: hidden;
				font-family: 'fibank-icons';
				font-size: 24px;
				color: white;
				position: absolute;
				top: -1px; left: 1px;
				transition: $transition;
			}
		}
	}

	.small-filter-check-radio{
		display: block;
		padding: 0;

		&__input{
			border: 0;
			clip: rect(0 0 0 0);
			-webkit-clip-path: inset(50%);
			clip-path: inset(50%);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
			white-space: nowrap;

			&:checked ~ .small-filter-check-radio__item{
				color: rgba($primary-90, .8);
				&:after{
					right: 2px;
					background: $primary-200;
				}
			}

			&[disabled]{
				cursor: default;

				~ .small-filter-check-radio{
					&__item{
						cursor: default;
						color: rgba($primary-90, .4);

						&:before{
							background: #f3f5f7;
						}

						&:after{
							background: #cfd4dc;
						}
					}
				}

				&:checked{
					~ .small-filter-check-radio{
						&__item{
							&:after{
								background: rgba($primary-200, .3);
							}
						}
					}
				}
			}
		}

		&__item{
			position: relative;
			padding: 3px 34px 3px 0;
			color: rgba($primary-90, .4);
			cursor: pointer;
			font-size: 12px;
			font-weight: 500;
			transition: $transition;

			&:before{
				content: '';
				height: 14px; width: 30px;
				position: absolute;
				top: 50%; right: 0;
				margin-top: -7px;
				background: #e2e5ea;
				border-radius: 7px;
				transition: $transition;
			}

			&:after{
				content: '';
				height: 10px; width: 10px;
				position: absolute;
				top: 50%; right: 17px;
				margin-top: -5px;
				background: rgba($gray-060, .64);
				border-radius: 10px;
				transition: $transition;
			}
		}
	}

	.parsley{
		&-errors-list{

			text-align: left;
			li{
				padding-top: 5px;
				color: $accent-500;
				font-size: 13px;
			}
		}
		&-errors-list .parsley-type {
			padding-top: 5px;
			color: $accent-500;
			font-size: 13px;
		}
	}

	.select2-container{
		&--default{
			.select2-selection--single{
				height: auto;
				min-height: 64px;
				border: 0;
				border-radius: 0;
				transition: $transition;
				background: #ebf1f5;
			}

			.select2-selection--single{
				.select2-selection__rendered{
					padding: 22px 35px 22px 17px;
					color: #6f7b8d;
					font: normal 16px/1.2 $Inter;
					&--selected{
						color: #445267;
					}
				}
				.select2-selection__arrow{
					height: 20px;
					top: 50%; right: 18px;
					transform: translateY(-50%) translateZ(0);
					transition: $transition;

					&:after{
						content: '\e99f';
						font-family: 'fibank-icons';
						position: absolute;
						top: 50%; left: 50%;
						color: $primary-500;
						font-size: 24px;
						transform: translate(-50%, -50%);
						pointer-events: none;
						z-index: 1;
					}
					b{
						display: none;
					}
				}

				&.select-location{
					.select2-selection__arrow{
						transform: translateY(-50%) rotateZ(0) translateZ(0)!important;
						&:after{
							content: '\ebfc';
						}
					}
				}

			}

			.select2-search--dropdown{
				.select2-search{
					&__field{
						min-height: inherit;
						padding: 0;
						color: $primary-90;
						border: 0;
						background: transparent;
					}
				}
			}

			&.select2-container--open{
				.select2-selection--single{
					.select2-selection__arrow{
						transform: translateY(-50%) rotateZ(180deg) translateZ(0);
					}
				}
			}

			.select2-results{
				> .select2-results__options{
					max-height: 304px;
				}

				&__option{
					color: $primary-94;
					font-size: 16px;
					line-height: 1.125;
					&[aria-selected="true"]{
						color: white;
						background: $primary-500;
					}
					&--highlighted[aria-selected]{
						color: $primary-94;
						background: rgba($primary-500, .04);
					}
					&--highlighted[aria-selected="true"]{
						color: white;
						background: $primary-500;
					}
					&[aria-disabled="true"]{
						display: none;
					}
				}

				&__message{
					padding-top: 0;
					color: rgba($primary-94, .64);
					font-size: 14px;
					font-weight: 400;
					text-align: center;
					&:after{
						display: none;
					}
				}
			}
		}
	}

	.select2{
		&-search{
			position: relative;
			margin: 16px;
			padding: 14px 44px 13px 16px;
			background: white;
			border: 0;
			&:after{
				content: "\eca9";
				font-family: 'fibank-icons';
				color: #6f7b8d;
				font-size: 20px;
				position: absolute;
				top: 50%; right: 15px;
				transform: translateY(-50%);
			}

		}
		&-dropdown{
			border: 0;
			background: $gray-002;
			border-top: 1px solid $primary-500!important;
			&--above{
				border-top-width: 0 !important;
				border-bottom: 1px solid $primary-500 !important;
			}
		}
		&-results{
			&__option{
				color: $primary-94;
				padding: 16px;
				position: relative;
				margin-bottom: 1px;
				&:after{
					content: '';
					height: 1px;
					position: absolute;
					bottom: -1px;
					left: 16px; right: 16px;
					background: $primary-400;
				}
				&:last-child{
					&:after{
						display: none;
					}
				}
			}
		}
	}

	.custom-file{
		position: relative;
		padding: 0 0 0 240px;

		[type=file]{
			opacity: 0;
			position: absolute;
			top: 50%; left: 0;
			transform: translateY(-50%);
			cursor: pointer;
			z-index: 10;
		}

		input{
			&[file]{
				position: absolute;
				top: 0; right: 0;
				margin: 0; padding: 0;
				min-width: 100%;
				min-height: 100%;
				font-size: 28px;
				cursor: pointer;
				text-align: right;
				opacity: 0;
				display: block;
				z-index: 10;
			}
			&[readonly]{
				background: transparent;
			}
		}

		&__browse{
			width: 210px;
			position: absolute;
			top: 50%; left: 0;
			transform: translateY(-50%);
			cursor: pointer;
			text-align: center;
			z-index: 1;
		}

		&__error{
			margin-top: 6px;
			color: $accent-500;
			font-size: 14px;
			line-height: 1.6923;
			&:empty{
				display: none;
			}
		}
	}

	.form{
		&-step{
			margin: 16px 0 -2px;
			color: $primary-90;
			font-size: 18px;
			font-weight: 500;
		}

		&-terms{
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: space-between;
			padding-top: 10px;
			color: $primary-90;
			font-size: 16px;
			@media(max-width: $phone-h){
				justify-content: center;
			}

			.button{
				margin-top: 6px;
				margin-bottom: 6px;
				@media(max-width: $phone-h){
					margin-top: 12px;
				}
			}
		}

		&-label{
			margin: 7px 0 11px;
			color: $primary-90;
			font-size: 16px;
			font-weight: 500;
		}

		&-error{
			color: $accent-500;
			font-size: 16px;
			line-height: 1.375;
			&--visible{
				margin-bottom: 24px;
			}

			a{
				color: $accent-500;
			}
		}
	}

	.grid-container{
		[class*="grid-"]:first-child{
			.form-step{
				margin-top: 0;
			}
		}
	}

	.custom-phone{
		position: relative;
		&--active{
			padding-left: 105px;
			.custom-phone{
				&__select{
					display: block;
				}
			}
		}

		&__select{
			display: none;
			position: absolute;
			top: 0; left: 0;
			width: 105px;
			border-right: 1px solid white !important;
			z-index: 2;
			&:after{
				content: '';
				height: 100%; width: 10px;
				position: absolute;
				top: 0; bottom: 0; right: 0;
				background: white;
			}
		}

		.select2{
			&-container{
				vertical-align: top;
			}
			&-dropdown{
				margin-top: 64px;
				border-width: 0 !important;
				&:before{
					content: '';
					height: 1px; width: 104px;
					position: absolute;
					top: 0; left: 0;
					background: $primary-500;
				}

				&--above{
					margin-top: 0;
					margin-bottom: 64px;
					&:before{
						top: auto; bottom: 0;
					}
				}
			}
			&-selection__rendered{
				color: $primary-90!important;
			}
		}

		&__template{
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			align-items: center;
			&-name{
				color: $primary-94;
			}
			&-code{
				color: rgba($primary-94, .64);
			}
		}
	}

	.select2-container--default .select2-results__option[aria-selected="true"]{
		.custom-phone__template{
			*{
				color: white;
			}
		}
	}

	.datepicker{
		display: inline-block;
		position: relative;
		user-select: none;
		&--small{
			max-width: 240px;
		}
		&:after{
			content: '\ea1c';
			position: absolute;
			top: 50%; right: 20px;
			transform: translateY(-50%);
			color: $primary-500;
			font-family: 'fibank-icons';
			font-size: 24px;
			pointer-events: none;
		}
		input:not([type="checkbox"]):not([type="radio"]){
			padding-right: 60px;
			cursor: pointer;
			font-weight: 500;
		}

		&-center{
			margin: 0 -12px;
			padding: 5px;
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: center;
		}
		&-item{
			padding: 6px 12px;
		}
		&-title{
			color: $primary-500;
			@include fluid-size(font-size, 320px, 1330px, 20px, 24px);
			line-height: 1.125;
		}
	}
}