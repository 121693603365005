.newsletter-block{
    display: flex;
    flex-flow: row wrap;
    margin: 21px -12px;

    &__item{
        flex: 1 0 50%;
        padding: 12px 0;
        @media (max-width: $phone-h){
            flex: 0 0 100%;
        }

        &-inner{
            height: 100%;
            margin: 0 12px;
            padding: 26px 24px 24px;
            border: 1px solid $primary-400;
        }
    }

    &__title{
        padding-bottom: 8px;
        color: $primary-94;
        @include fluid-size(font-size, 320px, 1330px, 24px, 30px);
        font-family: $Noto;
        font-weight: 700;
        font-style: italic;
        line-height: 1.125;
    }

    &__text{
        color: rgba($primary-94, .54);
		font-size: 14px;
		font-weight: 500;
		line-height: 1.571428571428571‬;
    }

    .check-list{
        color: black;
        li{
            margin: 17px 0;
            font-size: 14px;
        }
    }

    &__bottom{
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        font-size: 16px;

        a{
            margin: 12px 35px 0 0;
            text-decoration: none;
            @include hover{
                color: darken($primary-500, 10);
            }

            &.button{
                margin-left: 15px;
                @include hover{
                    color: white;
                }
            }

            &:last-child{
                margin-right: 0;
            }
        }
    }
}

.newsletter{
    &-title{
        margin-bottom: 29px;
        position: relative;
        color: $primary-94;
        line-height: 1.125;

        &:after{
            content: '';
            height: 2px; width: 100%;
            position: absolute;
            top: 50%; left: 0;
            margin-top: 3px;
            background: $primary-94;
            border-radius: 1px;
            z-index: 1;
        }

        &__inner{
            display: inline-block;
            padding: 0 16px 0 0;
            position: relative;
            @include fluid-size(font-size, 280px, 1330px, 22px, 30px);
            font-weight: 700;
            font-family: $Noto;
            font-style: italic;
            background: white;
            z-index: 2;
        }
    }

    &-list{
        &__item {        
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }
        }

        .file{
            display: flex;    
            align-items: center;
            border: 1px solid $primary-400;
            padding: 7px 8px;
            text-decoration: none;
            transition: border-color $transition;
            &:hover{
                border-color: $primary-500;        
            }
            &__type{
                min-width: 50px;
                display: flex;
                flex-shrink: 0;
                align-items: center;
                align-self: stretch;
                border-right: 1px solid #e0e0e0;
                i{            
                    font-size: 40px;
                    margin-right: 8px;            
                }
                
            }
            &__text{
                padding: 10px 0 9px 16px;
                font-size: 0;
                &-name{
                    display: block;
                    color: $dark-gray;
                    @include fluid-size(font-size, 320px, 1330px, 17px, 20px);
                    font-weight: 700;
                    font-family: $Noto;
                    font-style: italic;
                    line-height: 1.125;
                    margin-bottom: 6px;
                    transition: color $transition;
                    .file:hover &{
                        color: $primary-500;
                    }
                }
                &-info{
                    display: inline-block;
                    vertical-align: middle;
                    color: $primary-90;
                    font-size: 11px;
                    text-transform: uppercase;
                    &:after{
                        content: '';
                        display: inline-block;
                        width: 1px;
                        height: 8px;
                        background-color: #cbcbd3;
                        margin: 0 4px;
                    }
                    &:last-child:after{
                        display: none;
                    }
                }
            }
            &__action{
                    flex-shrink: 0;
                    padding: 8px;
                    margin-left: auto;
                i{
                    font-size: 24px;            
                }
                
            }    
        }
    }
}