.minimize-section {    
    .site-block__title {
        margin-bottom: 0;
    }
    &__toggle {
        margin: -6px 0 0 10px;
        font-size: 0;
        width: 40px;
        height: 40px;
        background: transparent;        
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        &-icon {
            border: 2px solid $primary-500;
            width: 20px;
            height: 20px;
            border-radius: 1px;
            position: relative;
            &:before,
            &:after {
                content: '';
                width: 8px;
                height: 2px;
                border-radius: 1px;
                background-color: $primary-500;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: transform $transition;
            }
            &:after {
                transform: translate(-50%, -50%) rotate(90deg) scale(1,1);
            }
            .is-open &:after {
                transform: translate(-50%, -50%) rotate(90deg) scale(0,1);
            }
        }        
    }
    &__body {
        margin-top: 20px;
        padding-top: 24px;
        border-top: 1px solid #e0e0e0;
    }

    &.is-closed{
        .minimize-section{
            &__body{
                display: none;
            }
        }
    }
}