.calculator-big {
  margin: 0 auto;
  max-width: 720px;
  width: 100%;

  .grid-container {
    &--form{
      &{
        width: calc(100% + 24px);
        margin: -24px 0 0 -24px!important;

        @media(max-width: $tablet-v) {
          width: calc(100% + 24px);
          margin: -10px 0 0 -24px!important;
        }
  
        [class*="grid-"]{
          padding: 24px 0 0 24px;

          @media(max-width: $tablet-v) {
            padding-top: 10px;

            &:last-child {
              padding-top: 24px;
            }
          }
        }
      }
    }
  }

  &__box {
    input:not([type='checkbox']):not([type='radio']),
    select,
    .select,
    textarea {
      font-weight: 500;

      &::placeholder {
        font-weight: 500;
      }
    }

    &--center {
      text-align: center;
    }
  }

  &__box .datepicker {
    &--none {
      &::after {
        display: none;
      }

      input:not([type='checkbox']):not([type='radio']) {
        &.is-visible {
          padding-top: 31px;
        }
      }
    }
  }

  &__box .datepicker {
    &__label-visible {
      position: absolute;
      top: 12px;
      left: 16px;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #6f7b8d;
      opacity: 0;

      &.is-visible {
        opacity: 0.8;
      }
    }
  }

  &__box .datepicker-item {
    padding: 0;

    &--100 {
      width: 100%;
    }

    @media(max-width: $tablet-v) {
      padding: 0;
    }
  }

  &__box .datepicker-center {
    margin: 0;
    padding: 0;

    @media(max-width: $tablet-v) {
      padding: 0;
    }
  }

  &__box .datepicker-center .datepicker {
    width: 100%;
  }

  &__box p {
    margin-top: 6px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: rgba(68, 82, 103, 0.64);
  }

  .select2-container--default .select2-selection--single .select2-selection__rendered {
    opacity: 0.6;
  }

  .select2-container--default .select2-selection--single .select2-selection__rendered--selected,
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    font-weight: 500;
    color: $primary-90;
  }

  .select2-container--default .select2-selection--single .select2-selection__rendered--selected {
    opacity: 1;
  }


  .datepicker input:not([type="checkbox"]):not([type="radio"]):disabled {
    cursor: default;
  }

  .datepicker--dis input:not([type="checkbox"]):not([type="radio"]):disabled {
    opacity: 0.6;
  }

  &__error {
    padding: 20px;
    background-color: $accent-500;
    color: $white;

    .text,
    .text p,
    .text h3 {
      color: $white; 
      text-align: center;
    }
  }

}

.graphic-calculator {
  margin-bottom: 60px;

  &__period {
    display: block;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }

  // text:nth-child(1) {
  //   padding-left: 200px;
  //   text-anchor: start;
  // }
}

.info-calculator {
  .text {
    table {
      margin-bottom: 40px;

      @media(max-width: $tablet-v) {
        min-width: initial;
      }
    }
    td {
      padding-inline: 32px;
      width: 50%;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: $dark-gray;

      @media(max-width: $tablet-v) {
        padding: 14px;
        min-width: initial;
      }
    }

    tr > td:last-child {
      font-weight: bold;
    }
  }

  &__footnote {
    margin-top: 48px;
    padding-top: 24px;
    border-top: 1px solid $gray-003;

    .text {
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: normal;
      text-align: center;
      color: #383636;
      opacity: 0.5;

      p {
        font-size: 13px;
      }
    }
  }
}

