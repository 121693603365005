.similar-slider{
	opacity: 0;
	visibility: hidden;
	max-height: 245px;
	overflow: hidden;
	@include fluid-size(margin-right, 580px, 1330px, -16px, -32px);
    transition: $transition;
    
    &.slick-initialized{
		opacity: 1;
		visibility: visible;
		max-height: inherit;
	}

	&:after{
		content: '';
		height: 100%;
		@include fluid-size(width, 580px, 1330px, 16px, 32px);
		position: absolute;
		top: 0; right: 0;
		background: white;
	}

	&__holder{
        position: relative;
	}

	&__wrap{
		overflow: hidden;
	}

	&__item{
        color: white;
        @media(max-width: $phone-h){
            max-width: 328px;
        }
        @media(max-width: $phone-sm){
            max-width: 300px;
        }

		&-wrap{
			position: relative;
			@include fluid-size(margin-right, 580px, 1330px, 16px, 32px);
		}
	}

	figure{
		position: relative;

		&:after{
			content: '';
			position: absolute;
			top: 80px; bottom: 0;
			left: 0; right: 0;
			background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 34%, rgba(0, 0, 0, 0.48) 100%);
		}

		img{
			width: 100%;
		}
	}

	figcaption{
		padding: 0 24px 27px;
		position: absolute;
		bottom: 0;
		left: 0; right: 0;
		z-index: 2;
		
	}

	&__title{
		font-size: 22px;
		line-height: 1.125;
		font-family: $Noto;
		font-weight: 700;
		font-style: italic;
	}

	&__link{
		position: absolute;
		top: 0; bottom: 0;
		left: 0; right: 0;
		text-indent: -10000px;
		z-index: 2;
	}

	&__controls{
		position: absolute;
		bottom: 100%; right: 0;
		margin-bottom: 25px;
		padding: 0 15px;
		background: $primary-400;
		clip-path: polygon(18% 0%, 82% 0%, 100% 45%, 100% 45%, 82% 100%, 18% 100%, 0 45%, 0 45%);

		.slick-arrow{
			height: 40px; width: 20px;
			position: relative;
			margin: 0 4px;
			text-indent: -10000px;
			border: 0;
			background: transparent;
			@include hover{
				&:before{
					color: darken($primary-500, 10);
				}
			}

			&:before{
				content: '\e99c';
				font-family: 'fibank-icons';
				position: absolute;
				top: 50%; left: 50%;
				transform: translate(-50%, -50%);
				color: $primary-500;
				font-size: 24px;
				text-indent: 0;
				transition: color $transition;
			}

			&.slick-next{
				&:before{
					content: '\e99d';
				}
			}
		}
	}

}

.hide-controls{
	.similar-slider__controls{
		display: none;
	}
}