.site-body{
  .calculator{
    @include fluid-size(padding, 320px, 1330px, 26px 20px 20px, 30px 24px 24px);
    background: #ebf1f5;
    @media(max-width: $phone-h){
      padding-left: 15px;
      padding-right: 15px;
    }

    &__box{
      margin-bottom: 32px;

      &--inline{
        margin: 0;
      }

      &:last-child,
      &:last-of-type{
        margin-bottom: 0;
      }

      &-preview{
        max-width: 210px;
        margin-top: -16px;
        img{
          display: block;
          border-radius: 12px;
          box-shadow: 0 2px 11px rgba(68,82,103,.24);
        }
      }
    }

    &__title{
      margin-bottom: 20px;
      color: $dark-gray;
      font-size: 20px;
      font-weight: 500;
    }

    &__title.mb-xl {
      margin-bottom: 35px;
    }

    &__table{
      width: 100%;
      color: #68696a;
      font-size: 14px;
      line-height: 1.125;

      td{
        padding: 8px 8px 8px 0;
        vertical-align: middle;

        &:last-child{
          padding-left: 8px;
          padding-right: 0;
          color: $primary-94;
          font-size: 15px;
          font-weight: 500;
          text-align: right;
        }
      }
    }

    &__box{
      &-label{
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 1.125;
        color: rgba($primary-94, .8);
      }

      hr{
        margin: 24px auto 15px;
      }
    }

    &-loader{
      height: 18px;
    }
  }

  .calculator-box{
    min-height: 64px;
    padding: 32px 17px 10px;
    position: relative;
    background: white;
    user-select: none;

    &.disabled{

      .calculator-box__placeholder{
        top: 50%;
        transform: translateY(-50%);
        color: #6f7b8d;
        font-size: 16px;
        cursor: pointer;
      }

      .calculator-box__output,
      .calculator-box__button{
        opacity: 0;
        visibility: hidden;
      }

      .irs--fibank .irs-line,
      .irs--fibank .irs-handle:before{
        background: #6f7b8d;
      }

    }

    &__input{
      border: 0;
      clip: rect(0 0 0 0);
      -webkit-clip-path: inset(50%);
      clip-path: inset(50%);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      white-space: nowrap;
    }

    &__placeholder{
      position: absolute;
      top: 4px;
      left: 0; right: 0;
      padding: 10px 17px;
      color: #939292;
      font-size: 12px;
      line-height: 1;
      transition: $transition;
    }

    &__output{
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      color: $primary-94;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.125;
      transition: $transition;
    }

      &__input-element:not([type="checkbox"]):not([type="radio"]){
          display: inline-block;
          cursor: pointer;
          min-height: auto; width: 30px;
          padding: 3px 0;
          border: 0;
          color: $primary-94;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.125;
          text-align: center;
          border-radius: 0;
          background: transparent;
          transition: $transition;
          appearance: textfield;
          &:focus{
            padding-left: 5px;
            padding-right: 5px;
          }

          &[data-size="1"]{
            width: 12px;
            &:focus{
              width: 22px;
            }
          }
          &[data-size="2"]{
            width: 20px;
            &:focus{
              width: 30px;
            }
          }
          &[data-size="3"]{
            width: 34px;
            &:focus{
              width: 44px;
            }
          }
          &[data-size="4"]{
            width: 45px;
            &:focus{
              width: 55px;
            }
          }
          &[data-size="5"]{
            width: 56px;
            &:focus{
              width: 66px;
            }
          }
          &[data-size="6"]{
            width: 65px;
            &:focus{
              width: 75px;
            }
          }
          &[data-size="7"]{
            width: 79px;
            &:focus{
              width: 89px;
            }
          }
          &[data-size="8"]{
            width: 94px;
            &:focus{
              width: 104px;
            }
          }
          &[data-size="9"]{
            width: 102px;
            &:focus{
              width: 112px;
            }
          }
          &[data-size="10"]{
            width: 112px;
            &:focus{
              width: 122px;
            }
          }

          &:focus{
              background: #f4f4f4;
          }
      }

      &__input-postfix{
          display: inline-block;
          min-height: 18px;
          padding: 3px 5px;
          margin-right: 5px;
          white-space: nowrap;
      }

    &__button{
      height: 16px; width: 16px;
      position: absolute;
      top: 50%; right: 16px;
      margin-top: -8px;
      border: 0;
      text-indent: -10000px;
      background: #c2c2c2;
      border-radius: 20px;
      transition: $transition;

      &:before{
        content: '\ec3c';
        font-family: 'fibank-icons';
        color: white;
        font-size: 10px;
        text-indent: 0;
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .irs{
      position: absolute;
      bottom: 0;
      left: 0; right: 0;
    }

    &__inline{
      display: flex;
      flex-flow: row;

      .calculator__box{
        width: 280px;
        margin: 0 24px 0 0;

        &:last-child{
          margin-right: 0;
        }

        .calculator-box{
          background: #f4f4f4;

          &__input-element:not([type="checkbox"]):not([type="radio"]){
            background: transparent;

            &:focus{
              background: white;
            }
          }
        }
      }
    }
  }

  .check-radio-box{
    margin-bottom: 32px;

    &:last-child{
      margin-bottom: 0;
    }
  }

  .check-radio-list{
      display: inline-flex;
      /*margin-bottom: 24px;*/

      &__item{
          display: inline-block;
          margin-left: -1px;
      }
  }

  .calculator__check-radio{
      display: flex;
      height: 100%;
      align-content: center;
      padding: 0;
      position: relative;
      cursor: pointer;
      user-select: none;

      &__input{
          border: 0;
          clip: rect(0 0 0 0);
          -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
          white-space: nowrap;

          &:checked ~ .calculator__check-radio__element{
              color: white;
              background: $primary-500;
              border-color: $primary-500;
          }
      }

      &__element{
          display: flex;
          align-items: center;
          padding: 7px 10px 8px;
          position: relative;
          color: $primary-94;
          font-size: 15px;
          font-weight: 500;
          line-height: 1.125;
          background: white;
          border: 1px solid #cbcbd3;
          transition: $transition;
          @media(max-width: $phone-h){
            flex-flow: column-reverse;
          }
      }

  }

  .check-radio-list{
      &__item{
          &:first-child{
              .calculator__check-radio__element{
                  border-radius: 4px 0 0 4px;
              }
          }

          &:last-child{
              .calculator__check-radio__element{
                  border-radius: 0 4px 4px 0;
              }
          }
      }
  }


  .irs--fibank {
      height: 1px;
    }
    .irs--fibank.irs-with-grid {
      height: 1px;
    }
    .irs--fibank .irs-line {
      top: 0;
      height: 1px;
      position: relative;
      overflow: visible;
      background: $primary-500;

      &:before{
          content: '';
          height: 20px; width: 100%;
          position: absolute;
          top: 50%; left: 0;
          margin-top: -10px;
          background: rgba(255,255,255,0);
          cursor: pointer;
      }
    }
    .irs--fibank .irs-bar {
      top: 0;
      height: 1px;
    }
    .irs--fibank .irs-bar--single {
      border-radius: 0;
    }
    .irs--fibank .irs-shadow {
      height: 1px;
      bottom: 16px;
      background: #e1e4e9;
    }
    .irs--fibank .irs-handle {
      top: -7px;
      width: 14px;
      height: 14px;
      cursor: pointer;

      &:before{
        content: '';
        height: 100%; width: 100%;
        position: absolute;
        top: 0; left: 0;
        transform: rotateZ(-45deg);
        background: $primary-500;
        transform-origin: center center;
        z-index: 1;
      }

      &:after{
        content: '';
        height: 200%; width: 200%;
        position: absolute;
        top: 0; left: 0;
        margin: -50% 0 0 -50%;
        background: rgba(255,255,255,0);
        z-index: 2;
      }

      i{
        display: block;
        height: 7px; width: 1px;
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(255,255,255,.32);
        pointer-events: none;
        z-index: 3;

        & + i{
          height: 5px;
          margin-left: -2px;
        }

        & + i + i{
          margin-left: 2px;
        }
      }
    }

    .irs--fibank{
      .irs-min,
      .irs-max {
        top: 0;
        padding: 1px 3px;
        color: #999;
        font-size: 10px;
        line-height: 1.333;
        text-shadow: none;
        background-color: #e1e4e9;
        border-radius: 4px;
      }

      .irs-from,
      .irs-to,
      .irs-single {
        color: white;
        font-size: 10px;
        line-height: 1.333;
        text-shadow: none;
        padding: 1px 5px;
        background-color: #ed5565;
        border-radius: 4px;
      }

      .irs-from:before,
      .irs-to:before,
      .irs-single:before {
        position: absolute;
        display: block;
        content: "";
        bottom: -6px;
        left: 50%;
        width: 0;
        height: 0;
        margin-left: -3px;
        overflow: hidden;
        border: 3px solid transparent;
        border-top-color: #ed5565;
      }

      .irs-grid-pol {
        background-color: #e1e4e9;
      }

      .irs-grid-text {
        color: #999;
      }
    }
}