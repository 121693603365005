.products-list{
    display: flex;
    flex-flow: row wrap;
    margin: 26px -16px 0;
    @media(max-width: $tablet-h){
        margin-left: -10px;
        margin-right: -10px;
    }
    @media(max-width: $phone-h){
        margin-top: 10px;
    }
    @media(max-width: $phone-s){
        margin-left: 0;
        margin-right: 0;
    }

    &__item{
        flex: 0 0 25%;
        padding: 16px 0;
        @media(max-width: $tablet-v){
            flex: 0 0 50%;
        }
        @media(max-width: $phone-s){
            flex: 0 0 100%;
        }

        @include hover{
            .products-list{
                &__preview{
                    transform: rotateZ(-1.5deg);
                }
                &__title{
                    color: $primary-500;
                }
            }
        }
    }

    &__inner{
        padding: 0 16px;
        perspective: 1200px;
        transform-style: preserve-3d;
        @media(max-width: $tablet-h){
            padding-left: 10px;
            padding-right: 10px;
        }
        @media(max-width: $phone-s){
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__preview{
        margin-bottom: 19px;
        transition: $transition;
        transform-style: left bottom;
        img{
            width: 100%;
        }
    }

    &__title{
        margin: 0;
        color: $dark-gray;
        font-size: 24px;
        font-family: $Noto;
        font-weight: 400;
        line-height: 1.125;

        a{
            color: inherit;
            text-decoration: none;
        }
    }
}