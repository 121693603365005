.footer{
    padding-bottom: 20px;
    color: white;
    background: #012241;

    &-contacts{
        margin: -80px 0 6px;
        padding: 26px 10px 10px;
        display: flex;
        flex-flow: row wrap;
        background: white;
        box-shadow: 0 0 12px rgba(0,0,0,.1);

        &__item{
            flex-basis: 25%;
            padding: 14px 0;
            overflow: hidden;
            color: black;
            line-height: 1.4;

            @media(max-width: $tablet-h){
                flex-basis: auto;
                flex: 0 0 50%;
            }

            @media(max-width: $phone-v){
                flex: 0 0 100%;
            }

            a{
                color: $red;
                @include hover{
                    color: darken($red, 10);
                }
            }
        }

        &__inner{
            padding: 0 16px;

            &-wrap{
                display: flex;
                flex-flow: row;
            }
        }

        &__icon{
            height: 50px; width: 50px;
            min-width: 50px;
            position: relative;
            margin: 12px 30px 0 0;
            background: $blue;
            transform: rotateZ(-45deg);
            transform-origin: center center;

            &:empty{
                display: none;
                @media(max-width: $phone-v){
                    display: block;
                    background: transparent;
                }
            }

            @media(max-width: $phone-h){
                height: 44px; width: 44px;
                min-width: 44px;
                margin: 7px 25px 0 0;
            }

            [class^="i-"],
            [class*=" i-"]{
                position: absolute;
                top: 50%; left: 50%;
                transform: rotateZ(45deg) translate(-70%, 0%);
                color: white;
                font-size: 24px;
            }
        }

        &__header{
            padding-bottom: 11px;
            color: #adadad;
            @include fluid-size(font-size, 320px, 1330px, 14px, 16px);
            font-family: $Noto;
            font-weight: 700;
            font-style: italic;
            @media(max-width: $phone-h){
                padding-bottom: 4px;
            }

            p ~ p{
                padding-top: 2px;
            }

            a{
                color: inherit;
                text-decoration: none;
                @include hover{
                    color: $primary-500;
                }

                &.big{
                    margin-right: 5px;
                    color: black;
                    line-height: 1;
                    @include fluid-size(font-size, 320px, 1330px, 20px, 26px);
                    @include hover{
                        color: $primary-500;
                    }
                }
            }
        }
    }

    &-top{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding: 35px 0 14px;

        &__item{
            padding: 10px 0;
            @media(max-width: $phone-h){
                flex: 0 0 100%;
                text-align: center;
            }
        }

        &__list{
            margin-top: -8px;

            &-item{
                display: inline-block;
                position: relative;
                padding: 4px 14px 4px 0;
                font-size: 13px;
                line-height: 1.125;

                &:after{
                    content: '';
                    height: 16px; width: 1px;
                    position: absolute;
                    top: 50%; right: 5px;
                    margin-top: -7px;
                    background: $primary-050;
                }

                &:last-child{
                    padding-right: 0;

                    &:after{
                        display: none;
                    }
                }

                a{
                    color: white;
                    text-decoration: none;
                    transition: $transition;

                    @include hover{
                        opacity: .6;
                    }

                    [class^="i-"],
                    [class*=" i-"]{
                        font-size: 18px;
                        margin-right: 3px;
                        position: relative;
                        top: 3px;
                    }
                }
            }
        }
    }

    &-middle{
        padding: 34px 0;
        border-top: 1px solid #1f3d58;
    }

    &-columns{
        display: flex;
        flex-flow: row wrap;
        margin: -25px 0 0 -25px;
        overflow: hidden;

        &__item{
            flex: 1 0 auto;
            flex-grow: 1;
            padding-top: 25px;

            &.column-app{
                width: 330px;
                @media(max-width: $tablet-v){
                    width: 100%;
                }
            }

            @media(max-width: $tablet-v){
                flex: 0 0 50%;
            }
            @media(max-width: $phone-sm){
                flex: 0 0 100%;
            }
        }

        &__inner{
            padding-left: 25px;
        }

        &__title{
            margin-bottom: 14px;
            font-family: $Noto;
            font-size: 19px;
            font-style: italic;
            font-weight: 700;
            color: $primary-050;
            a{
                color: inherit;
                text-decoration: none;
                @include hover{
                    color: rgba($primary-050, .8);
                }
            }
        }

        &__list{
            &-item{
                padding: 5px 0 4px;
                @media(max-width: $phone-s){
                    padding: 8px 0 7px;
                }

                a{
                    color: white;
                    text-decoration: none;
                    @include hover{
                        color: rgba(255,255,255,.6);
                    }
                }
            }
        }
    }

    &-bottom{
        color: $primary-050;
        font-size: 13px;

        &__author{
            position: relative;
            top: -1px;
        }
    }

    .copyright{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding-top: 20px;
        font-weight: 500;
        border-top: 1px solid #1f3d58;
        @media(max-width: $phone-v){
            flex-flow: column;
        }

        &__item{
            @media(max-width: $phone-v){
                flex: 0 0 100%;
                padding-top: 14px;
                text-align: center;
                &--social{
                    order: 0;
                    padding-top: 0;
                }
                &--copy{
                    order: 1;
                }
                &--author{
                    order: 2;
                }
            }
        }

        a{
            color: $primary-050;
            text-decoration: none;
        }
    }

    &-apps{
        width: 330px;
        display: flex;
        flex-flow: row;
        margin: auto;
        font-size: 12px;
        line-height: 1.125;
        @media(max-width: $tablet-v){
            width: 100%;
            margin: 0;
        }

        &__preview{
            min-width: 138px;
            @media(max-width: $phone-h){
                min-width: 118px;
            }
            @media(max-width: $phone-v){
                display: none;
            }
        }

        &__text{
            flex-grow: 1;
            padding: 8px 0 0 30px;
            @media(max-width: $phone-h){
                padding-left: 20px;
            }
            @media(max-width: $phone-v){
                padding-left: 0;
            }
            @media(max-width: $phone-s){
                padding: 0;
            }

            small{
                display: block;
                color: #65798c;
                font-size: 11px;
                font-weight: 500;
            }
        }

        &__list{
            margin: 18px 0 6px;
            &-item{
                padding: 6px 0;
                a{
                    @include hover{
                        opacity: .92;
                    }
                }
                img {
                    max-width: 140px;
                }
            }
        }
    }

    .social-links{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -4px;
        @media(max-width: $phone-v){
            margin-top: 0;
        }

        a{
            display: block;
            margin: 0 10px;
            color: #b9c1ca;
            &:hover{
                color: white;
            }
        }

        [class^="i-"],
        [class*=" i-"]{
            font-size: 20px;
        }
    }

    .header-top__dropdown{
        max-width: 240px;
        margin: -8px auto 0;
        &-header{
            > div{
                padding: 0 26px 0 0;
                color: white;
                font-size: 20px;
                font-weight: 700;
                font-style: italic;
                font-family: $Noto;
                &:after{
                    content: '\e99f';
                    font-family: 'fibank-icons';
                    font-weight: normal;
                    font-style: normal;
                    font-size: 24px;
                    height: auto; width: auto;
                    top: 50%; right: 0;
                    margin-top: 2px;
                    transform: translateY(-50%);
                    border: 0;
                    transition: $transition;
                }
            }
        }
        &-body{
            margin-top: 11px;
            background: white;
        }
        &--active{
            .header-top__dropdown{
                &-header{
                    > div{
                        &:after{
                            margin-top: 3px;
                            transform: translateY(-50%) rotateZ(180deg);
                        }
                    }
                }
            }
        }
    }
}