.accounts-row{
	display: flex;
	flex-flow: row wrap;
	overflow: hidden;
	margin: -75px -28px 0;
	@media(max-width: $tablet-h){
		margin-top: -50px;
	}
	&--offset-top{
		margin-top: -75px;
		@media(max-width: $tablet-h){
			margin-top: -50px;
		}
	}

	@media(max-width: $phone-h){
		margin-left: 0;
		margin-right: 0;
	}
	
	&__wrap{
		overflow: hidden;
	}

	&__item{
		flex: 0 0 33.3333%;
		margin-top: 50px;
		position: relative;
		order: 0;
		transform-style: preserve-3d;
		&:first-child{
			@media(max-width: $phone-h){
				.accounts-row__preview ~ .accounts-row__button-wrapper{
					top: 17px;
				}
			}
		}
		&:hover{
			.accounts{
				&-row{
					&__button{
						display: flex;
						@media(max-width: $phone-h){
							opacity: 1;
							visibility: visible;
							transform: translateX(0);
						}
						&-wrapper{
							max-height: 70px;
						}
					}
				}
			}
		}

		&--highlighted{
			flex: 0 0 50%;

			.accounts{
				&-row{
					&__title{
						font-size: 26px;
					}
				}
			}

			.card-features{
				margin-left: 0;
				&__item{
					display: flex;
					flex: 1 0 50%;
					align-items: center;
					@media(max-width: $tablet-v){
						flex: 1 0 100%;
					}
					&-icon{
						margin: 0;
						flex-basis: 56px;
						@media(max-width: $phone-v){
							flex-basis: 46px;
						}
			
						&__shape{
							height: 64px; width: 56px;
							@media(max-width: $phone-v){
								height: 53px; width: 47px;
							}
			
							&:before{
								height: 60px; width: 52px;
								@media(max-width: $phone-v){
									height: 49px; width: 43px;
								}
							}

							&:after{
								height: 54px; width: 48px;
								@media(max-width: $phone-v){
									height: 43px; width: 39px;
								}
							}
						}
					}
					&-text{
						margin: 0;
						padding: 0 15px;
					}
				}
			}
		}

		&:before{
			content: '';
			height: 1px;
			position: absolute;
			top: -25px;
			left: 0; right: 0;
			background: #d9dde3;
		}

		@media(max-width: $tablet-h){
			flex: 0 0 50%;
			&:nth-child(1),
			&:nth-child(2){
				margin-top: 25px;
			}
		}
		@media(max-width: $phone-h){
			flex: 0 0 100%;
			&:nth-child(1),
			&:nth-child(2){
				margin-top: 50px;
			}
		}

		&:nth-child(3n+1){
			.accounts{
				&-row{
					&__inner{
						background: $gray-002;
						@media(max-width: $tablet-h){
							background: transparent;
						}
					}
					&__button{
						background: white;
						&:before,
						&:after{
							background: white;
						}
					}
				}
			}
		}
		&:nth-child(1),
		&:nth-child(2){
			.accounts{
				&-row{
					&__inner{
						background: transparent;
					}
					&__button{
						background: $gray-002;
						&:before,
						&:after{
							background: $gray-002;
						}
					}
				}
			}
		}
	}

	&__link{
		display: flex;
		height: 100%;
		text-decoration: none;
		&:hover{
			.accounts{
				&-row{
					&__title{
						color: $primary-500;
					}
				}
			}

		}
	}

	&__inner{
        height: 100%; width: 100%;
		padding: 28px;
		overflow: hidden;
		
		@media(max-width: $phone-h){
			padding: 0;
		}
    }
    
    &__preview{
		overflow: hidden;
        position: relative;
		margin-bottom: 24px;
		transform-style: preserve-3d;

        img{
            display: block;
			width: 100%;
		}

		& ~ .accounts-row__button-wrapper{
			.accounts-row__button{
				@media(max-width: $phone-h){
					top: -3px; right: 16px;
				}
			}
		}
    }

	&__title{
		margin-bottom: 19px;
		color: $dark-gray;
		font-size: 22px;
		font-family: $Noto;
		font-weight: 700;
		font-style: italic;
		line-height: 1.125;
		transition: color $transition;
		
		@media (max-width: $phone-h){
			min-height: inherit !important;
		}
        
        a{
            color: inherit;
            text-decoration: none;

            @include hover{
                color: $primary-500;
            }
        }
	}
	
	.text{
		color: rgba($primary-94, .54);
		font-size: 14px;
		font-weight: 500;
		line-height: 1.571428;

		@media (max-width: $phone-h){
			min-height: inherit !important;
		}

		p{
			color: rgba($primary-94, .54) !important;
			line-height: 1.571428571428571‬ !important;
		}

		.button{
			margin-top: 16px;
		}
	}

	&__bottom{
		margin-top: 24px;

		.button{
			margin-left: 16px;
			[class^="i-"],
			[class*=" i-"]{
				font-size: 16px;
			}
		}
	}

	&__button{
		min-height: 30px;
		position: relative;
		margin: 24px 12px 0;
		color: $primary-500;
		font-size: 12px;
		background: $gray-002;
		@media(max-width: $phone-h){
			opacity: 0;
			visibility: hidden;
			display: flex;
			position: absolute;
			top: -22px; right: 16px;
			transition: $transition;
			transform: translateX(10px);
			background: $gray-002 !important;
			&:before,
			&:after{
				background: $gray-002 !important;
			}
		}
		@include hover{
			color: $primary-500;
			background: $gray-002;
			&:before,
			&:after{
				background: $gray-002;
			}
		}

		&:before,
		&:after{
			height: 21px; width: 21px;
			background: $gray-002;
		}

		[class^="i-"],
		[class*=" i-"]{
			margin-top: 1px;
			&:before{
				color: inherit;
			}
		}

		&-wrapper{
			max-height: 0;
			overflow: hidden;
			transition: $transition;
		}
	}
	
	/*
    .tags--small{
        min-height: 28px;
	}
	*/

	&--highlighted{
		margin-top: -28px;
		margin-left: -14px;
		margin-right: -14px;
		.accounts-row{
			&__item{
				flex: 0 0 50%;
				margin-top: 28px;
				@media(max-width: $phone-h){
					flex: 0 0 100%;
				}
				&:before{
					display: none;
				}
			}
			&__inner{
				margin-left: 14px;
				margin-right: 14px;
				background: $gray-002;
				&:nth-child(1),
				&:nth-child(2){
					background: $gray-002;
				}
				@media(max-width: $phone-h){
					padding: 28px 16px;
				}
			}
			&__preview{
				margin: -28px -28px 24px;
				@media(max-width: $phone-h){
					margin-left: -16px;
					margin-right: -16px;
				}
			}
			&__title{
				font-size: 26px;
				@media(max-width: $phone-h){
					font-size: 22px;
				}
			}			
			&__button{
				background: white !important;
				&:before,
				&:after{
					background: white !important;
				}
			}
		}
		
		&.filtered{
			.accounts{
				&-row{
					&__item{
						&:before{
							display: block;
							content: '';
							height: 1px;
							position: absolute;
							top: 0;
							left: 0; right: 0;
							background: #d9dde3;
						}
					}
					&__inner{
						padding-left: 0;
						padding-right: 0;
						background: transparent;
					}
					&__button{
						background: $gray-002 !important;
						&:before,
						&:after{
							background: $gray-002 !important;
						}
					}
				}
			}
		}
	}

	&--three-items{
		margin-top: -28px;
		margin-left: -14px;
		margin-right: -14px;
		.accounts-row{
			&__item{
				margin-top: 28px;
				@media(max-width: $phone-h){
					flex: 0 0 100%;
				}
				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3n+1){
					.accounts-row__inner{
						background: $gray-002;
					}
				}
				&:before{
					display: none;
				}
				&:first-child{
					.accounts-row__button{
						top: 0;
					}
				}
			}
			&__inner{
				margin-left: 14px;
				margin-right: 14px;
				background: $gray-002;
				@media(max-width: $phone-h){
					padding: 28px 16px;
				}
			}
			&__preview{
				margin: -28px -28px 24px;
				@media(max-width: $phone-h){
					margin-left: -16px;
					margin-right: -16px;
				}
			}
			&__title{
				font-size: 26px;
				@media(max-width: $phone-h){
					font-size: 22px;
				}
			}
			&__button{
				/*top: 0; right: 30px;*/
				background: white !important;
				&:before,
				&:after{
					background: white !important;
				}
			}
		}

		&.filtered{
			.accounts{
				&-row{
					&__item{
						&:before{
							display: block;
							content: '';
							height: 1px;
							position: absolute;
							top: -25px;
							left: 0; right: 0;
							background: #d9dde3;
						}
					}
					&__inner{
						padding: 0 0 28px; 
						background: transparent !important;
					}
					&__button{
						right: 28px !important;
						background: $gray-002 !important;
						&:before,
						&:after{
							background: $gray-002 !important;
						}
					}
					&__preview ~ .accounts-row__button{
						right: 16px !important;
						@media(max-width: $phone-h){
							top: -8px !important;
							right: 30px !important;
						}
					}
				}
			}
		}
	}

	&.filtered{
		.accounts{
			&-row{
				&__title{
					font-size: 26px;
					@media(max-width: $tablet-v){
						font-size: 22px;
					}
				}
				&__button{
					opacity: 0;
					visibility: hidden;
					display: flex;
					position: absolute;
					top: 3px; right: 44px;
					transition: $transition;
					background: $gray-002;
					transform: translateX(10px);
					@media(max-width: $phone-h){
						top: -20px; right: 16px;
					}
					&:before,
					&:after{
						background: $gray-002;
					}
				}
				&__preview ~ .accounts-row__button-wrapper{
					.accounts-row__button{
						top: 16px; right: 44px;
						@media(max-width: $phone-h){
							top: -4px; right: 14px;
						}
					}
				}
				&__item{
					&:hover{
						.accounts{
							&-row{
								&__button{
									opacity: 1;
									visibility: visible;
									transform: translateX(0);
								}
							}
						}
					}
				}
			}
		}

		.card-features .card-features__item,
		.card-features--full-width .card-features__item{
			@media(min-width: $phone-h){
				flex: 1 0 50%;
			}
		}

		@media(min-width: $phone-h){
			margin-top: 0;
			.accounts-row{

				&__item,
				&__item--highlighted{
					margin-top: 0;
					flex: 0 0 100%;
					background: transparent;

					&:before{
						top: 0;
					}

					&:nth-child(3n+1){
						.accounts-row__inner{
							background: transparent;
						}
					}
				}

				&__inner{
					display: flex;
					flex-flow: row;
					justify-content: space-between;
					padding-top: 24px;
					padding-bottom: 24px;
				}

				&__preview{
					max-width: 320px;
					order: 1;
					margin: 0;
				}
				&__text{
					order: 0;
					flex-grow: 1;
					flex: 0 0 70%;
					padding-right: 20px;
					@media(max-width: $tablet-v){
						&:only-child{
							flex: 0 0 100%;
							padding-right: 0;
						}
					}
				}
			}
		}
	}

	&--three-items{
		@media(min-width: $phone-h){
			&.accounts-row{
				&.filtered{
					margin-left: 0;
					margin-right: 0;
					.accounts-row{
						
						&__item{
							&:before{
								display: block;
							}

							&:nth-child(1),
							&:nth-child(2){
								.accounts-row__inner{
									background: transparent;
								}
							}
							
						}

						&__inner{
							margin-left: 0;
							margin-right: 0;
							padding: 24px 0;
							background: transparent;
						}

						&__preview{
							.button{
								right: 16px;
							}
						}
					}
				}
			}
		}
	}
}