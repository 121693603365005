/* Print styles */
@media print{
    
    thead{
        display: table-header-group;
    }

    tr,
    img{
        page-break-inside: avoid;
    }

    img{
        max-width: 100% !important;
    }

    p,
    h2,
    h3{
        orphans: 3;
        widows: 3;
    }

    h2,
    h3{
        page-break-after: avoid;
    }

    a[href]:empty::before{
        content: attr(href);
    }

    .header,
    .footer{
        display: none;
    }

    .breadcrumbs{
        margin-top: 18px;
    }
    
}