.vendors-list{
    display: flex;
    flex-flow: row wrap;
    margin: -24px;
    @media(max-width: $tablet-h){
        margin: -12px;
    }

    &__wrap{
        overflow: hidden;
    }
    &__link{
        display: block;
        text-decoration: none;
    }
    &__item{
        flex: 0 0 33.3333%;
        padding: 24px 0;
        @media(max-width: $tablet-v){
            flex: 0 0 50%;
            padding: 12px 0;
        }
        @media(max-width: $phone-v){
            flex: 0 0 100%;
        }
    }
    &__inner{
        height: 100%;
        margin: 0 24px;
        padding: 40px 48px 35px;
        background: rgba($primary-400, .8);
        @media(max-width: $tablet-h){
            margin: 0 12px;
        }
        @media(max-width: $phone-v){
            height: inherit;
            padding-left: 24px;
            padding-right: 24px;
        }
    }
    &__image{
        display: flex;
        align-items: center;
        min-height: 96px;
        margin-bottom: 24px;
        @media(max-width: $phone-v){
            min-height: inherit;
        }
    }
    &__text{
        color: rgba($primary-94, .54);
        font-size: 14px;
        font-weight: 500;
        line-height: 1.42857;
    }
    .button{
        margin-top: 33px;
        @media(max-width: $phone-v){
            margin-top: 23px;
        }
    }
}

.promo-list{
    display: flex;
    flex-flow: row wrap;
    margin: -20px -20px 0;
    @media(max-width: $phone-h){
        margin: -10px -10px 0;
    }
    &__wrap{
        overflow: hidden;
    }
    &__item{
        flex: 0 0 50%;
        @media(max-width: $phone-v){
            flex: 0 0 100%;
        }
    }
    &__inner{
        margin: 20px;
        @media(max-width: $phone-h){
            margin: 10px;
        }
    }
    img{
        display: block; width: 100%;
    }
}