.grid-container{
	width: 100%;
	width: calc(100% + 40px);
	margin: -40px 0 0 -40px!important;
	position: relative;
	display: flex;
	flex-flow: row wrap;
	&--padded{
		padding-left: 48px;
		padding-right: 48px;
		@media(max-width: $phone-v){
			padding-left: 0;
			padding-right: 0;
		}
	}
	&--reverse{
		@media(max-width: $tablet-v){
			flex-flow: column-reverse;
		}
	}

	[class*="grid-"]{
		width: 100%;
		padding: 40px 0 0 40px;
		min-height: 1px;
		position: relative;
	}

	.grid-d-12{ width: 100%; }
	.grid-d-11{ width: 91.666663%; }
	.grid-d-10{ width: 83.33%; }
	.grid-d-9{ width: 74.999997%; }
	.grid-d-8{ width: 66.66666664%; }
	.grid-d-7{ width: 58.333%; }
	.grid-d-6{ width: 50%; }
	.grid-d-5{ width: 41.6665%; }
	.grid-d-4{ width: 33.3333%; }
	.grid-d-3{ width: 24.99%; }
	.grid-d-2{ width: 16.66666%; }
	.grid-d-1{ width: 8.33%; }
	.grid-d-1-5{ width: 20%; }
	
	[class*="clearfix"]{
		padding-top: 0;
		min-height: inherit;
	}

	.grid-container{
		width: calc(100% + 40px);
		float: none;
		padding-left: 0;
	}

	[class*="grid-"] .grid-container{
		width: 100%;
		width: calc(100% + 40px);
		padding-top: 0;
		padding-left: 0;
	}

	&--form{
		&{
			width: calc(100% + 24px);
			margin: -24px 0 0 -24px!important;

			[class*="grid-"]{
				padding: 24px 0 0 24px;
			}
		}
	}

	&--a-center {
		align-items: center;
	}

	// &--gutter-20 {
	// 	// margin: -10px 0 0 -10px !important; 
	// 	margin-left: -10px;
	// }
	// &--gutter-20 > [class*="grid-"] {
			// padding: 10px 0 0 10px;
			// padding-left: 10px; 
	// }
}

.d-flex{
    display: flex;
    flex-flow: row wrap;
}

.justify-content-between{
	-webkit-justify-content: space-between;
			justify-content: space-between;
}

@media screen and (max-width: $tablet-v){

	.grid-container{
		.grid-t-12{
			width: 100%;
		}

		.grid-ts-6{
			width: 50%;
		}

		.grid-ts-8{ width: 66.66666664%; }
		.grid-ts-4{ width: 33.3333%; }

		.grid-ts-12{
			width: 100%;
		}
		
		.grid-ts-hide{
			display: none;
		}

		.grid-t-center {
			text-align: center;
		}
	}

}

@media screen and (max-width: $phone-h){

	.grid-container{

		.grid-mh-12{
			width: 100%;
		}
        
		.grid-mh-8{
				width: 66.66666664%;
		}
		
		.grid-mh-6 {
				width: 50%;
		}

		.grid-mh-4{
				width: 33.3333%;
		}

		.grid-mh-center {
			text-align: center;
		}

  }

}

@media screen and (max-width: $phone-v){

	.grid-container{
        
		.grid-mv-12,
		.grid-ms-12{
				width: 100%;
		}

  }

}