@keyframes bounceInDownManagement{
	from, 20%, 55%, 90%, to {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}

	0% {
		opacity: 0;
		transform: translate3d(0, 250px, 0);
    }

	to {
        opacity: 1;
		transform: none;
	}
}

.board{
    &-header{
        @include fluid-size(padding-top, 280px, 1330px, 32px, 68px);
        @include fluid-size(padding-bottom, 280px, 1330px, 32px, 68px);
        .wrapper{
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: space-between;
            > div{
                transition: $transition2;
                @media(max-width: $tablet-v){
                    &:first-child{ order: 0; width: 50%; }
                    &:nth-child(2){ order: 2; width: 100%; padding-top: 20px; }
                    &:nth-child(3){ order: 1; }
                }
            }
        }
        &__title{
            margin: 0;
            padding: 0;
            color: $primary-94;
            @include fluid-size(font-size, 280px, 1330px, 26px, 38px);
            font-family: $Noto;
            font-weight: 700;
            font-style: italic;
            line-height: .9;
            text-align: center;
        }
        &__link{
            display: inline-block;
            padding: 11px 35px 12px 11px;
            position: relative;
            color: #6f7b8d;
            font-size: 13px;
            text-decoration: none;
            border: 1px solid $gray-060;
            border-radius: 4px;
            @media(max-width: $phone-v){
                font-size: 11px;
            }
            &:after{
                content: '\ec34';
                color: inherit;
                font-size: 18px;
                font-family: 'fibank-icons';
                position: absolute;
                top: 50%; right: 12px;
                transform: translateY(-50%);
            }
            @include hover{
                color: white;
                background: $gray-060;
            }
        }
    }

    &-management{
        height: 100%;
        
        display: flex;
        flex-grow: 1;
        flex-flow: column;
        align-items: center;
        justify-content: flex-end;
        overflow: hidden;
        transition: $transition2;
    }

    &-slider{
        display: block;
        width: 100%;
        &.slick-initialized{
            animation: bounceInDownManagement 550ms cubic-bezier(0, 0, .2, 1);
            transition: $transition2;
        }
        &:before{
            content: '';
            display: none;
            height: 100%; width: 100%;
            position: absolute;
            top: 0; left: 0;
            background: rgba(255,255,255,0);
            z-index: 30;
        }

        &__item{
            display: flex !important;
            align-items: flex-end;
            text-align: center;
            position: relative;
            cursor: pointer;

            figure{
                max-height: 550px;
                margin: 0 auto;
                @media(max-width: $phone-h){
                    max-height: inherit;
                }

                img{
                    display: block;
                    height: 100%;
                    max-width: 100%;
                }
            }
        }

        .slick-slide{
            transition: $transition2;
        }
        .slick-slide,
        .slick-slide[aria-hidden="true"]:not(.slick-cloned) ~ .slick-cloned[aria-hidden="true"]{
            transform: scale(.7, .7);
            transform-origin: center bottom;
            
        }
        .slick-center,
        .slick-slide[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"]{
            transform: scale(1, 1);

            .board-slider__button{
                opacity: 1;
                visibility: visible;
            }
        }

        &__button{
            opacity: 0;
            visibility: hidden;
            height: 100%; width: 100%;
            position: absolute;
            top: 0; left: 0;
            border: 0;
            background: rgba(255,255,255,0);
        }

        &__bottom{
            height: 80px; width: 100%;
            position: relative;
            background: $primary-500;
            text-align: center;
        }

        &__info{
            display: flex;
            flex-flow: column;
            justify-content: flex-end;
            min-height: 155px;
            width: 500px;
            padding: 48px 82px 32px 32px;
            position: absolute;
            bottom: 0; left: 50%;
            color: rgba(255,255,255,.64);
            font-size: 13px;
            line-height: 1.53846;
            text-align: left;
            background: $accent-500;
            transform: translateX(-50%);
            clip-path: polygon(50% 0, 50% 0, 100% 25%, 100% 100%, 0 100%, 0% 25%);
            transition: $transition;
            z-index: 2;
            @media(max-width: $phone-v){
                height: auto;
                min-height: 134px;
                width: 100%;
                padding-left: 16px;
                padding-right: 50px;
                left: 0;
                font-size: 12px;
                transform: translateX(0);
                clip-path: polygon(50% 0, 50% 0, 100% 15%, 100% 100%, 0 100%, 0% 15%);
            }

            &-name{
                max-height: 60px;
                margin-bottom: 7px;
                overflow: hidden;
                color: white;
                font-size: 28px;
                font-family: $Noto;
                font-style: italic;
                font-weight: 700;
                line-height: 1;
                @media(max-width: $phone-v){
                    max-height: 45px;
                    font-size: 22px;
                }
            }

            &-position{
                max-height: 44px;
                overflow: hidden;
                @media(max-width: $phone-v){
                    max-height: 36px;
                }
            }

            &-button{
                height: 28px; width: 28px;
                position: absolute;
                bottom: 44px; right: 44px;
                background: transparent;
                transition: opacity $transition;
                @include hover{
                    opacity: .6;
                }
                @media(max-width: $phone-v){
                    right: 18px;
                }

                [class^="i-"],
                [class*=" i-"]{
                    font-size: 32px;
                    color: white;
                }
            }
        }

        &-prev,
        &-next{
            height: 80px;
            width: calc(100vw / 2 - 250px);
            position: absolute;
            top: 0; left: 0;
            border: 0;
            color: white;
            background: transparent;
            text-indent: -10000px;
            transition: opacity $transition;
            z-index: 1;
            @include hover{
                opacity: .6;
            }

            &:before{
                content: '\e904';
                font-family: 'fibank-icons';
                position: absolute;
                top: 50%; left: 50%;
                font-size: 32px;
                text-indent: 0;
                transform: translate(-50%, -50%) translateZ(0);
            }
        }

        &-next{
            left: auto; right: 0;
            &:before{
                content: '\e912';
            }
        }

        .slick{
            &-track{
                display: flex;
                min-height: 550px;
            }

            &-slide{
                height: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                > div{
                    height: 100%;
                    display: flex;
                    justify-content: flex-end;
                }
            }
            &-arrow{
                height: 25px; width: 14px;
                position: absolute;
                top: 50%; left: 10%;
                text-indent: -10000%;
                transform: translateY(-50%);
                background: transparent;
                z-index: 5;
                &:before{
                    content: '\e904';
                    font-family: 'fibank-icons';
                    position: absolute;
                    top: 50%; left: 50%;
                    transform: translate(-50%, -50%);
                    color: $primary-90;
                    font-size: 25px;
                    text-indent: 0;
                }
                &.slick-next{
                    left: auto; right: 10%;
                    &:before{
                        content: '\e912';
                    }
                }
            }
        }
    }

    &-popup{
        display: flex;
        flex-flow: column;
        height: 100%; width: 50%;
        position: fixed;
        top: 0; right: 0;
        padding: 80px;
        @include fluid-size(padding, 280px, 1330px, 50px 20px 30px, 50px 80px 50px);
        background: white;
        box-shadow: 0 2px 32px rgba(68,82,103,.09);
        transition: $transition2;
        transform: translateX(100%);
        z-index: 20;
        @media(max-width: $tablet-v){
            height: 50vh; width: 100%;
            min-height: 250px;
            top: auto; bottom: 0;
            transform: translate(0, 100%);
            @media(max-width: $phone-v){
                min-height: inherit;
            }
        }
        &__header{
            color: $dark-gray;
            font-family: $Noto;
            line-height: 1;
            @include fluid-size(padding-bottom, 280px, 1330px, 20px, 40px);
            @include fluid-size(font-size, 280px, 1330px, 24px, 38px);
        }
        &__body{
            padding-right: 20px;
            flex-grow: 1;
            overflow: hidden;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            overscroll-behavior: contain;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            scrollbar-width: var(--scrollbar-ff-width);
            scrollbar-color: var(--scrollbar-color) var(--scrollbar-track-color);
        }
        &__close{
            height: 32px; width: 32px;
            position: absolute;
            top: 16px; right: 16px;
            background: $primary-400;
            border-radius: 100%;
            text-indent: -10000px;
        
            &:before{
                content: "\ec3c";
                position: absolute;
                top: 50%; left: 50%;
                transform: translate(-50%, -50%);
                color: $primary-500;
                font-size: 18px;
                font-family: 'fibank-icons';
                text-indent: 0;
            }
        }
    }

    &-active{
        .board{
            &-header{
                .wrapper{
                    > div{
                        opacity: 0;
                        visibility: hidden;
                        @media(max-width: $tablet-v){
                            opacity: 1;
                            visibility: visible;
                        }
                        &:first-child{
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
            &-popup{
                transform: translateX(0);
                @media(max-width: $tablet-v){
                    transform: translate(0, 0);
                }
            }
            &-management{
                transform: translateX(-25%);
                @media(max-width: $tablet-v){
                    transform: translateX(0);
                }
            }
            &-slider{
                &:before{
                    display: block;
                }
                .slick-slide{
                    opacity: 0;
                    visibility: hidden;
                    &.slick-center{
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
        .main-content--logo{
            background-position: left -15px;
            @media(max-width: $tablet-v){
                background-position: center -15px;
            }
        }
    }
}

.no-clip-path{
    .board-slider__info{
        background: url('../images/bgr-board-slider-info.svg') no-repeat center top;
        background-size: cover;
    }
}