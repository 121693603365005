.paging{
    margin-top: 39px;
    padding: 0 50px;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;

    &__prev,
    &__next{
        height: 32px; width: 32px;
        position: absolute;
        top: 50%; left: 0;
        transform: translateY(-50%);

        &:after{
            content: '';
            height: 32px; width: 32px;
            position: absolute;
            top: 10px; left: 6px;
            transform: translate(-50%, -50%) rotateZ(-45deg);
            transform-origin: top right;
            text-indent: 0;
            background: $primary-400;
            transition: $transition;
            z-index: -1;
        }

        [class^="i-"],
        [class*=" i-"]{
            position: absolute;
            top: 50%; left: 50%;
            margin-left: 5px;
            transform: translate(-50%, -50%);
            font-size: 18px;
            transition: $transition;
        }

        @include hover{
            &:after{
                background: $primary-500;
            }
            [class^="i-"],
            [class*=" i-"]{
                color: white;
            }
        }

        &.disabled{
            cursor: default;
            &:after{
                display: none;
            }
            [class*="i-"]{
                color: $gray-060;
            }
        }
    }

    &__next{
        left: auto; right: 0;

        &:after{
            left: auto; right: 6px;
        }

        [class^="i-"],
        [class*=" i-"]{
            margin-left: -6px;
        }
    }

    &__link{
        display: inline-block;
        @include fluid-size(margin-left, 320px, 1330px, 2px, 6px);
        @include fluid-size(margin-right, 320px, 1330px, 2px, 6px);
        padding: 7px 10px;
        position: relative;
        color: $primary-94;
        font-size: 16px;
        font-weight: 700;
        font-family: $Noto;
        font-style: italic;
        text-decoration: none;

        @include hover{
            &:not(.paging__link--active){
                color: $primary-500;
            }
        }

        @media (max-width: $phone-s){
            position: absolute;
            top: -10000px;
            left: -10000px;

            &--prev,
            &--next{
                position: relative;
                top: 0; left: 0;
            }
        }

        &--active{
            @include fluid-size(margin-left, 320px, 1330px, 10px, 24px);
            @include fluid-size(margin-right, 320px, 1330px, 10px, 24px);
            color: white;

            @media (max-width: $phone-s){
                position: relative;
                top: 0;
                left: 0;
            }

            &:after{
                content: '';
                height: 32px; width: 32px;
                position: absolute;
                top: 10px; left: -1px;
                transform: translate(-50%, -50%) rotateZ(-45deg);
                transform-origin: top right;
                background: $primary-500;
                z-index: -1;
            }
        }
    }

    &__spacer{
        margin: 7px 4px;
        color: rgba($primary-94, .48);

        @media (max-width: $phone-s){
            position: absolute;
            top: -10000px;
            left: -10000px;
        }
    }
}