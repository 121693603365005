.tippy{
	display: inline-block;
	height: 20px; width: 20px;
	margin-left: 2px;
	position: relative;
	color: white;
	text-align: left;
	text-indent: -10000px;
	background: $gray-060;
	border-radius: 100%;

	&:before{
		content: '\e947';
		position: absolute;
		top: 50%; left: 50%;
		font-family: 'fibank-icons';
		font-size: 12px;
		font-weight: 400;
		text-indent: 0;
		transform: translate(-50%, -50%);
	}

	&--info{
		top: -2px;
		background: transparent;

		&:before{
			content: "\eaa4";
			font-size: 20px;
  			color: $primary-500;
		}
	}
}