.partners{
    &-slider{
        opacity: 0;
        visibility: hidden;
        position: relative;
        transition: $transition;
        z-index: 3;
    
        &.slick-initialized{
            opacity: 1;
            visibility: visible;
        }
    
        &__holder{
            position: relative;
        }

        &__item{
            &-wrap{
                text-align: center;
            }
        }
    
        .slick-track{
            display: flex;
        }
    
        .slick-slide{
            display: flex;
            height: auto;
            align-items: center;

            img{
                width: auto;
                margin: auto;
            }
    
            > div{
                display: flex;
                height: 100%;
                align-items: center;
            }
        }

        &__controls{
            position: absolute;
            bottom: 100%; right: 0;
            margin-bottom: 25px;
            padding: 0 15px;
            background: $primary-400;
            clip-path: polygon(18% 0%, 82% 0%, 100% 45%, 100% 45%, 82% 100%, 18% 100%, 0 45%, 0 45%);
    
            .slick-arrow{
                height: 40px; width: 20px;
                position: relative;
                margin: 0 4px;
                text-indent: -10000px;
                border: 0;
                background: transparent;
                @include hover{
                    &:before{
                        color: darken($primary-500, 10);
                    }
                }
    
                &:before{
                    content: '\e99c';
                    font-family: 'fibank-icons';
                    position: absolute;
                    top: 50%; left: 50%;
                    transform: translate(-50%, -50%);
                    color: $primary-500;
                    font-size: 24px;
                    text-indent: 0;
                    transition: color $transition;
                }
    
                &.slick-next{
                    &:before{
                        content: '\e99d';
                    }
                }
            }
        }
    
        .slick-dots{    
            button{
                height: 7px; width: 7px;
                border: 0;
                text-indent: -10000px;
                background: #cfd4d8;
                border-radius: 10px;
            }
    
            .slick-active{
                button{
                    background: $primary-500;
                }
            }
        }
    }
}