.quick-news{
	display: flex;
	flex-flow: row wrap;
	margin: 0 25px 0 -30px;
    padding: 40px 0;
    @media (max-width: $tablet-v){
		margin: 0 16px;
		padding: 30px 0;
    }

	&__column{
        flex: 1 0 50%;
        @media (max-width: $tablet-v){
            flex: 1 0 100%;

            & + &{
                margin-top: 22px;
                padding-top: 22px;
                border-top: 1px solid #dee0e2;
            }
        }
	}

	&__inner{
        padding-left: 50px;
        @media (max-width: $tablet-v){
            padding-left: 0;
        }
	}

	&__item{
		& + &{
			margin-top: 16px;
			padding-top: 20px;
			border-top: 1px solid #dee0e2;
        }
        
        &--primary{    
            .quick-news__label{
                font-size: 14px;
                @media(max-width: $tablet-v){
                    font-size: 12px;
                }
            }
    
            .quick-news__title{
                @include fluid-size(font-size, 320px, 1330px, 20px, 32px);
            }
        }
	}

	&__label{
		display: inline-block;
		margin: 0 5px 9px 0;
		padding: 3px 7px 5px;
		color: white;
		font-size: 12px;
		font-weight: 500;
		line-height: 1.125;
		vertical-align: middle;
		background: $gray-060;
	}

	&__date{
		display: inline-block;
		margin-bottom: 9px;
		color: rgba($primary-94,.48);
		font-size: 12px;
		font-weight: 500;
        vertical-align: middle;
	}

	&__title{
		margin: 0;
		padding: 0;
		color: $dark-gray;
		@include fluid-size(font-size, 320px, 1330px, 20px, 22px);
		font-family: $Noto;
		font-weight: 700;
		font-style: italic;
        line-height: 1.36;

		a{
			color: inherit;
			text-decoration: none;
			@include hover{
				color: $primary-500;
			}
		}
    }
	
	&__row{
		margin-top: 24px;
		@media(max-width: $tablet-v){
			display: none;
		}
		&--alt{
			display: none;
			flex-grow: 1;
			@media(max-width: $tablet-v){
				display: block;
				margin-top: 24px;
				padding: 28px 0 0;
				text-align: center;
				border-top: 1px solid #dee0e2;
			}
		}
	}

	&--alt{
		margin: 30px auto 48px;
		padding: 34px 24px;
		background: $gray-002;
		.quick-news{
			&__row{
				margin: 0;
				padding: 0;
				border: 0;
				text-align: left;
				&:not(:first-child){
					margin-top: 20px;
					padding-top: 14px;
					border-top: 1px solid rgba($primary-94,.12);
				}
				&:last-child{
					border-top: 0;
				}
			}
			&__inner{
				padding-left: 0;
			}
			&__item{
				&--primary{
					margin-bottom: 0;
				}
			}
		}
	}
}

.news-list{
	margin-top: -40px;
	&__item{
		padding: 40px 0 25px;
		border-bottom: 1px solid $primary-400;
		&--highlighted{
			.news-list{
				&__label{
					font-size: 14px;
				}
				&__date{
					font-size: 14px;
				}
				&__title{
					@include fluid-size(font-size, 320px, 1330px, 24px, 34px);
					line-height: 1.4;
					@media(max-width: $phone-h){
						line-height: 1.3;
					}
				}
			}
		}
	}
	&__label{
		display: inline-block;
		margin: 0 5px 9px 0;
		padding: 3px 7px 5px;
		color: white;
		font-size: 12px;
		font-weight: 500;
		line-height: 1.125;
		vertical-align: middle;
		background: $gray-060;
	}
	&__date{
		display: inline-block;
		margin-bottom: 9px;
		color: rgba($primary-94,.48);
		font-size: 12px;
		font-weight: 500;
        vertical-align: middle;
	}
	&__title{
		color: $dark-gray;
		@include fluid-size(font-size, 320px, 1330px, 20px, 24px);
		font-family: $Noto;
		font-style: italic;
		font-weight: 700;
		line-height: 1.5;
		@media(max-width: $phone-h){
			line-height: 1.4;
		}
		a{
			color: inherit;
			text-decoration: none;
			@include hover{
				color: $primary-500;
			}
		}
	}
}