.popup[aria-hidden="true"]{ opacity: 0; visibility: hidden; }

@keyframes mm-fadeIn{     
	from { opacity: 0; }
	to { opacity: 1; }
}

@keyframes bounceInDown{
	from, 60%, 75%, 90%, to {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}

	0% {
		opacity: 0;
		transform: translate3d(0, -3000px, 0);
	}

	60% {
		opacity: 1;
		transform: translate3d(0, 25px, 0);
	}

	75% {
		transform: translate3d(0, -10px, 0);
	}

	90% {
		transform: translate3d(0, 5px, 0);
	}

	to {
		transform: none;
	}
}

.popup{
	position: absolute;
	top: -100000px;
    left: -10000px;
    
    &-container,
    &-overlay{
	    will-change: transform;
    }

    &-overlay{
        position: relative;
        top: 0; bottom: 0;
        left: 0; right: 0;
        background: rgba(56,54,54,.48);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        z-index: 100000;
    }

    &-container{
        display: inline-block;
        width: 100%;
        max-width: 650px;
        max-height: 600px;
        max-height: 96vh;
        margin: 2vh 10px;
        position: relative;
        overflow: hidden;
        overflow-y: auto;
        font-size: 16px;
        text-align: left;
        background: white;
    }
    
    &-header{
        padding: 61px 40px 23px;
        color: $dark-gray;
        font-family: $Noto;
        line-height: 1.125;
        text-align: center;
        @media(max-width: $phone-h){
            padding: 50px 20px 0;
        }
        
        &__title{
            @include fluid-size(font-size, 320px, 1330px, 28px, 38px);
            margin-bottom: 9px;
            &:last-child{
                margin-bottom: 0;
            }
        }

        &__sub-title{
            color: $primary-90;
            font-size: 18px;
        }
    }
    
    &-content{
        padding: 20px 40px 40px;
        @media(max-width: $phone-h){
            padding: 20px;
        }

        .text{
            color: $primary-90;
            font-size: 14px;
            line-height: 1.57;

            h1, h2, h3, h4, h5, h6{
                margin-bottom: 10px;
                line-height: 1.2;
            }

            p{
                color: $primary-90;
            }
        }

    }
    
    &-close{
        height: 32px; width: 32px;
        position: absolute;
        top: 16px; right: 16px;
        background: $primary-400;
        border-radius: 100%;
        text-indent: -10000px;
    
        &:before{
            content: "\ec3c";
            position: absolute;
            top: 50%; left: 50%;
            transform: translate(-50%, -50%);
            color: $primary-500;
            font-size: 23px;
            font-family: 'fibank-icons';
            text-indent: 0;
        }
    }

    &--rhombus{
        .popup{
            &-container{
                display: flex;
                align-items: center;
                min-height: 590px;
                min-width: 590px;
                max-width: 590px;
                padding: 0 105px;
                text-align: center;
                clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                @media(max-width: $tablet-v) and (orientation: landscape){
                    min-height: 500px;
                    min-width: 500px;
                    max-width: 500px;
                }
                @media(max-width: $phone-h){
                    min-height: 500px;
                    min-width: 500px;
                    max-width: 500px;
                }
                > div{
                    width: 100%;
                }
            }

            &-header{
                padding: 6px 0 34px;

                .popup-header__title{
                    color: $primary-94;
                    font-size: 31px;
                    font-weight: 700;
                    font-family: $Noto;
                    font-style: italic;
                    @media(max-width: $phone-v){
                        font-size: 28px;
                    }
                }

                .popup-header__sub-title{
                    font-weight: 700;
                    font-family: $Noto;
                    font-style: italic;
                    @media(max-width: $phone-v){
                        font-size: 16px;
                    }
                }
            }

            &-content{
                padding: 0;

                p ~ p{
                    margin-top: .5em;
                }

                input{
                    padding: 22px 15px 21px;
                    border-color: #ebf1f5;
                    background: #ebf1f5;
                }
            }

            &-close{
                height: 52px; width: 52px;
                top: 49px;
                left: 50%; right: auto;
                margin-left: -26px;
                clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                border-radius: 0;
                background: $gray-060;
                &:before{
                    color: white;
                    font-size: 16px;
                }
            }
        }
    }

    &--map{
        .popup{
            &-container{
                height: 100%;
                max-height: 100vh !important;
                max-height: calc(var(--vh, 1vh) * 100) !important;
                max-width: inherit;
                margin: 0 !important;
            }
            &-content{
                height: 100vh;
                height: calc(var(--vh, 1vh) * 100);
                display: flex;
                flex-flow: column;
                padding: 40px 80px;
                @media(max-width: $phone-h){
                    padding: 40px 20px;
                }
            }
        }

        .search-form{
            width: 100%;
            margin: 0 0 40px !important;
            @media(max-width: $phone-h){
                padding-right: 40px;
            }
        }

        .locations-map__map{
            height: 100%;
            margin: 0;
        }
    }

    &--success {
        .popup-text {
            text-align: center;
        }
        [class*=" i-"], [class^=i-] {
            margin-bottom: 27px;
            font-size: 78px;
            color: #07a25d;
        }
        .popup-text-title {
            margin-bottom: 20px;
            font-family: $Noto;
            font-size: 34px;
            font-style: italic;
        }
        .popup-text-subtitle {
            font-family: $Noto;
            font-size: 28px;
            font-style: italic;
            color: $primary-90;
        }
    }

    &--banner {
        .popup-container {
            max-width: 928px;

            @media(max-width: 766px)  {
                max-width: 375px;
            }
        }

        .popup-content {
            padding: 0;
        }

        .popup__wrap-img {
            display: block;
            aspect-ratio: 928/590;

            @media(max-width: 766px) {
                aspect-ratio: 375/628;
            }

            img {
                height: 100%;
            }
        }

        .popup-close {
            z-index: 3;
        }
    }

    &--banner-grid {
        .popup__wrap-img {
            display: grid;
            text-decoration: none;
        }

        .popup__wrap-img picture {
            grid-column: 1/-1;
            grid-row: 1/-1;
            z-index: 1;
        }

        .popup__wrap-img .popup__text {
            grid-column: 1/-1;
            grid-row: 1/-1;
            z-index: 2;
            margin-top: auto;
            padding: 20px;
            color: $white;
            text-decoration: none;
        }
    }
}

.no-clip-path{
    .popup--rhombus{
        .popup{
            &-container{
                width: 100%;
                min-height: inherit;
                max-width: 490px;
                max-height: 600px;
                max-height: 96vh;
                padding: 40px;
            }
            &-content{
                padding: 0;
            }

            &-close{
                height: 32px; width: 32px;
                top: 16px;
                left: auto; right: 16px;
                border-radius: 100%;
                &:before{
                    font-size: 12px;
                }
            }
        }
    }
}

.popup-offer{
    overflow: hidden;
    &--visible{
        .popup-offer__content,
        .popup-offer__overlay{
            opacity: 1;
            visibility: visible;
        }
    }
    &--bottom{
        .popup-offer__content{
            top: auto !important;
            bottom: 85px;
        }
    }

    &__content{
        opacity: 0;
        visibility: hidden;
        width: 100%;
        max-width: 650px;
        margin-top: 175px;
        padding-top: 54px;
        @include fluid-size(padding-left, 320px, 1330px, 20px, 74px);
        @include fluid-size(padding-right, 320px, 1330px, 20px, 74px);
        @include fluid-size(padding-bottom, 320px, 1330px, 24px, 54px);
        position: absolute;
        top: 0; left: 50%;
        background: white;
        transform: translateX(-50%);
        transition: opacity $transition;
        z-index: 9001;
        @media(max-width: $phone-h){
            max-width: 90%;
            max-width: 90vw;
        }
    }

    &__image{
        position: relative;
        margin: -95px auto 36px;
        padding: 0 40px;
        text-align: center;
    }

    &__text{
        text-align: center;
        color: $dark-gray;
        font-size: 14px;
        line-height: 1.6;

        h2{
            margin: 0 0 22px;
            @include fluid-size(font-size, 320px, 1330px, 28px, 38px);
            font-weight: 400;
            font-family: $Noto;
            line-height: 1.125;
        }

        .text-light{
            color: $primary-90;
        }

        strong{
            font-size: 15px;
            font-weight: 500;
        }

        small{
            max-width: 330px;
            font-size: 12px;
            color: rgba($dark-gray, .54);
        }

        hr{
            height: 3px;
            margin: 32px auto 26px;
            position: relative;
            border: 0;
            &:after{
                content: '';
                height: 100%; width: 45px;
                position: absolute;
                top: 0; left: 50%;
                background: $gray-060;
                transform: translateX(-50%);
            }
        }
    }

    &__close{
        height: 32px; width: 32px;
        position: absolute;
        top: 16px; right: 16px;
        background: $primary-400;
        border-radius: 100%;
        text-indent: -10000px;
    
        &:before{
            content: "\ec3c";
            position: absolute;
            top: 50%; left: 50%;
            transform: translate(-50%, -50%);
            color: $primary-500;
            font-size: 18px;
            font-family: 'fibank-icons';
            text-indent: 0;
        }
    }

    &__overlay{
        opacity: 0;
        visibility: hidden;
        height: 100%; width: 100%;
        position: fixed;
        top: 0; left: 0;
        background: rgba(56,54,54,.48);
        transition: opacity $transition;
        z-index: 9000;
    }
}

[aria-hidden="false"]{
	position: relative;
	top: 0; left: 0;
}

[aria-hidden="false"] .popup-overlay {
	position: fixed;
	animation: mm-fadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

[aria-hidden="false"] .popup-container{
	animation: bounceInDown 1s cubic-bezier(0, 0, .2, 1);
}

.is-ios{
    .popup-container{
        max-height: 80vh;
        margin-top: 10vh;
        margin-bottom: 10vh;
    }
}