.services-list{
    display: flex;
    flex-flow: row wrap;
    margin: -24px -12px 0;

    &__item{
        flex: 1 0 25%;
        overflow: hidden;
        @media(max-width: $tablet-h){
            flex: 1 0 50%;
        }
        @media(max-width: $phone-s){
            flex: 1 0 100%;
        }
    }
    &__inner{
        display: block;
        height: 100%;
        display: flex;
        flex-flow: column;
        margin: 24px 12px;
        padding: 40px 20px 20px;
        background: $gray-002;
        text-align: center;
        text-decoration: none;
        transform-style: preserve-3d;
        @include hover{
            .services-list__title{
                color: $primary-500;
            }
        }
    }
    &__header{
        padding-bottom: 25px;
    }
    &__title{
        color: $dark-gray;
        @include fluid-size(font-size, 280px, 1330px, 20px, 24px);
        font-family: $Noto;
        font-weight: 700;
        font-style: italic;
        text-decoration: none;
        transition: color $transition;
        @include fluid-size(line-height, 280px, 1330px, 1.25, 1.5);
        @include hover{
            color: $primary-500;
        }
    }
}